import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "wc-toast";
import { postInsQna, postUpdQna } from "../../api/customerCenter";
import { useLocation, useNavigate } from "react-router-dom";
import { postNumbaDelQnaFile } from "../../api/mypage";

function QAEdit() {
  const location = useLocation();
  const [qnaEditReq, setqnaEditReq] = useState({
    files: null,
    title: "",
    contents: "",
    alarm_yn: "N",
  });
  const [addFiles, setAddFiles] = useState([]);
  const [deleteFileSeq, setDeleteFileSeq] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    const allowedExtensions = ["jpg", "jpeg", "png"];
    if (files?.length > 0) {
      const extension = files[0].name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        return toast.error("jpg, jpeg, png 파일만 첨부할 수 있습니다.");
      }
    }

    let newValue = null;

    if (name === "qna_files") {
      if (qnaEditReq?.files.length + addFiles.length > 4) {
        toast.error("파일은 5개만 등록 가능합니다.");
        return;
      }
      newValue = [...addFiles, files[0]];

      setAddFiles(newValue);
    } else {
      newValue = type === "checkbox" ? (checked ? "Y" : "N") : value;
      setqnaEditReq((prevQnaReq) => ({
        ...prevQnaReq,
        [name]: newValue,
      }));
    }
  };

  const handleSubmit = async () => {
    if (!qnaEditReq.title || qnaEditReq.title === "") {
      return toast.error("제목을 입력해 주세요.");
    }
    if (!qnaEditReq.contents || qnaEditReq.contents === "") {
      return toast.error("내용을 입력해 주세요.");
    }
    if (!qnaEditReq.alarm_yn || qnaEditReq.alarm_yn === "") {
      return toast.error("알림 여부를 선택해 주세요.");
    }

    try {
      const response = await postUpdQna({ ...qnaEditReq, qna_files: addFiles });
      if (response.code !== 1000) {
        return toast.error("수정 실패");
      }
      deleteFileSeq?.map(async (item) => {
        const response = await postNumbaDelQnaFile({ file_seq: item });
        switch (response?.code) {
          case -1000:
            toast.error("해당질문의 파일이 없거나 이미 답변완료 상태입니다.");
            break;
          default:
            toast.error("알수없는 오류가 발생했습니다.");
            break;
        }
      });

      toast.success("수정되었습니다.");
      navigate("/main-tab", { state: { main: 4, sub: "first" } });
    } catch (err) {}
  };

  useEffect(() => {
    setqnaEditReq(location.state.qna);
  }, []);

  useEffect(() => {}, [qnaEditReq]);

  return (
    <div className="customer_tab bg-white">
      <div className="container">
        <div className="main-heading d-md-block d-none">
          <h1 className="text-center">1:1 문의수정</h1>
        </div>
        <div className="question_answer">
          <div className="sub_title">
            <p>제목</p>
          </div>
          <div className="subject">
            <input
              type="text"
              className="w-100"
              placeholder="제목을 입력해 주세요"
              name="title"
              value={qnaEditReq?.title}
              onChange={handleChange}
              maxLength={50}
            />
            <span className="title">{qnaEditReq?.title.length}/50</span>
          </div>
          <div className="sub_title">
            <p>내용</p>
          </div>
          <div className="detail">
            <textarea
              className="w-100"
              placeholder="문의 내용을 입력해 주세요"
              name="contents"
              value={qnaEditReq?.contents}
              onChange={handleChange}
              maxLength={100}
            ></textarea>
            <span className="title">{qnaEditReq?.contents.length}/100</span>
          </div>
          <div className="sub_title">
            <p>
              첨부파일 <span>(선택)</span>
            </p>
          </div>
          <input
            type="file"
            id="upload-qa"
            className="w-100 d-none"
            name="qna_files"
            multiple
            accept=".jpg, .jpeg, .png"
            onChange={handleChange}
          />

          <label htmlFor="" className="attached_file">
            <label htmlFor="upload-qa">
              <img className="plus" src="/img/plus.png" alt="" />
            </label>
            <img className="attachment" src="/img/attach.svg" alt="" />
            <span className="title">
              ※ 첨부가능 : jpg, jpeg, png / 첨부파일은 최대 5개 등록 가능합니다.
            </span>

            <div className="mt-3">
              {qnaEditReq.files?.map((file, index) => (
                <div className="d-flex justify-content-between align-items-center">
                  <div key={index}>{file.file_org_nm}</div>
                  <img
                    role="button"
                    src="img/icon-close.png"
                    alt=""
                    className="delete-btn"
                    onClick={() => {
                      const newFiles = qnaEditReq?.files.filter(
                        (item) => item?.file_seq !== file.file_seq,
                      );
                      setqnaEditReq({ ...qnaEditReq, files: newFiles });
                      setDeleteFileSeq([...deleteFileSeq, file.file_seq]);
                    }}
                  />
                  {/* <div className="delete-btn">삭제</div> */}
                </div>
              ))}
              {addFiles?.map((file, index) => (
                <div className="d-flex justify-content-between">
                  <div key={index}>{file?.name}</div>
                  <img
                    role="button"
                    src="img/icon-close.png"
                    alt=""
                    className="delete-btn"
                    onClick={() => {
                      const newFiles = addFiles?.filter((item) => {
                        return item?.name !== file.name;
                      });
                      setAddFiles(newFiles);
                    }}
                  />
                  {/* <div className="delete-btn">삭제</div> */}
                </div>
              ))}
            </div>
          </label>
          <div className="qa-label">
            <Form.Check
              type="checkbox"
              name="alarm_yn"
              label="답변이 등록되면 알림으로 알려드릴까요?"
              onChange={handleChange}
              className="CustomCheckbox"
              checked={qnaEditReq?.alarm_yn === "Y"}
            />
          </div>
          <div className="qa-label">
            ※ 문의하신 내용은 <span>[마이페이지 &gt; 1:1 문의내역]</span> 에서
            확인 하실 수 있습니다.
          </div>
          <div className="main-btn d-flex justify-content-center">
            <button onClick={handleSubmit}>수정하기</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QAEdit;
