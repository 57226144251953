
import React from "react";
import { Table } from "react-bootstrap";
import * as R from "../../../css/PersonalSellingRequest/requestForm";

// 양도양수 내용
export const CommonData = () => {
  const date = new Date()
  const curYear = date.getFullYear();
  // const curMonth = String(date.getMonth() + 1).padStart(2, '0')
  const curMonth = date.getMonth() + 1;
  const curDay = date.getDate();
  
  return (
    <div className="FormDIv">
      <R.FormDetails_Heading>양도·양수 내용</R.FormDetails_Heading>
      <R.FormDetails_Div>
        {/* desktop table */}
        <div className="d-none d-md-block">
          <Table bordered responsive>
            <tbody>
              <tr>
                <R.FormTable_Th width={"20%"}>사업의 종류</R.FormTable_Th>
                <td className="paddingLeft">화물운송사업</td>
              </tr>
              <tr>
                <R.FormTable_Th>양도가격</R.FormTable_Th>
                <td className="paddingLeft"></td>
              </tr>
              <tr>
                <R.FormTable_Th>양도·양수의 시기</R.FormTable_Th>
                <td className="paddingLeft">시·구청장의 신고를 득함과 동시</td>
              </tr>
              <tr>
                <R.FormTable_Th>양도·양수 사유</R.FormTable_Th>
                <td className="paddingLeft">차량 양도·양수</td>
              </tr>              
            </tbody>
          </Table>
        </div>
        {/* mobile table */}
        <div className="d-block d-md-none">
          <Table bordered responsive className="table-form">
            <tbody>
              <tr>
                <R.FormTable_Th>사업의 종류</R.FormTable_Th>
                <td>화물운송사업</td>
              </tr>
              <tr>
                <R.FormTable_Th>양도가격</R.FormTable_Th>
                <td>
                </td>
              </tr>
              <tr>
                <R.FormTable_Th>양도·양수의 시기</R.FormTable_Th>
                <td>시·구청장의 신고를 득함과 동시</td>
              </tr>
              <tr>
                <R.FormTable_Th>양도·양수 사유</R.FormTable_Th>
                <td>차량 양도·양수</td>
              </tr>              
            </tbody>
          </Table>
        </div>
      </R.FormDetails_Div>
      <div> 「화물자동차 운수사업법」 제16조·제28조 및 제33조와 같은 법 시행규칙 제23조·제41조 및 제41조의 11에 따라 위와 같이 신고합니다.</div>
      <div className="text-end px-5 py-4"> {curYear}년 {curMonth}월 {curDay}일</div>
    </div>
  );
};

export const CommonData2 = () => {
  
  return (
    <div className="FormDIv">
      <R.FormDetails_Div>
        <div className="text-center p-4"> 국토교통부장관·특별시장·광역시장·특별자치시장·도지사·특별자치도지사 귀하</div>
        {/* desktop table */}
        <div className="d-none d-md-block">
          <Table bordered responsive>
            <tbody>
              <tr>                
                <td>신고인(대표자) 제출서류</td>
                <td>
                  1. 양도·양수 계약서 사본 1부 <br/>
                  2. 양수인이 「화물자동차 운수사업법」 제4조 각 호의 결격사유에 해당하지 않음을 증명하는 서류(양수인이 화물자동차 운수사업자가 아닌 경우만 해당합니다) 각 1부  <br/>
                  3. 양도인 또는 양수인이 법인인 경우에는 화물자동차 운수사업의 양도 또는 양수에 관한 해당 법인의 의사결정을 증명하는 서류 각 1부 <br/>
                  4. 「화물자동차 운수사업법 시행규칙」 별지 제2호서식의 차고지 설치 확인서. 다만, 양도·양수 계약서 사본 등으로 차고지의 양도·양수가 확인되는 경우에는 제출하지 않습니다. <br/>
                  5. 화물운송 종사자격증 또는 화물운송 종사자격증명의 사본 <br/>
                  6. 「화물자동차 운수사업법」제40조 제1항에 땨라 화물자동차 운송사업의 일부를 위탁받은 자의 동의서 (화물자동차 운송사업의 일부를 양도·양수하는 경우만 해당합니다.) <br/>
                </td>
              </tr>
              <tr>
                <td>국토교통부장관·특별시장·광역시장·특별자치시장·도지사·특별자치도지사 확인사항</td>
                <td>양수인이 법인인 경우에는 법인 등기사항증명서 (양수인이 화물자동차 운수사업자가 아닌 경우만 해당합니다.)</td>
              </tr>                        
            </tbody>
          </Table>
        </div>
        <div className="d-none d-md-block">
          <Table bordered responsive>
            <tbody>
              <tr>                
                <td>처리절차</td>                
              </tr>
              <tr>
                <td>신고서 작성</td>                
              </tr>                        
            </tbody>
          </Table>
        </div>
        {/* mobile table */}
        <div className="d-block d-md-none">
          <Table bordered responsive className="table-form">
          <tbody>
              <tr>                
                <td>신고인(대표자) 제출서류</td>
                <td>
                  1. 양도·양수 계약서 사본 1부 <br/>
                  2. 양수인이 「화물자동차 운수사업법」 제4조 각 호의 결격사유에 해당하지 않음을 증명하는 서류(양수인이 화물자동차 운수사업자가 아닌 경우만 해당합니다) 각 1부  <br/>
                  3. 양도인 또는 양수인이 법인인 경우에는 화물자동차 운수사업의 양도 또는 양수에 관한 해당 법인의 의사결정을 증명하는 서류 각 1부 <br/>
                  4. 「화물자동차 운수사업법 시행규칙」 별지 제2호서식의 차고지 설치 확인서. 다만, 양도·양수 계약서 사본 등으로 차고지의 양도·양수가 확인되는 경우에는 제출하지 않습니다. <br/>
                  5. 화물운송 종사자격증 또는 화물운송 종사자격증명의 사본 <br/>
                  6. 「화물자동차 운수사업법」제40조 제1항에 땨라 화물자동차 운송사업의 일부를 위탁받은 자의 동의서 (화물자동차 운송사업의 일부를 양도·양수하는 경우만 해당합니다.) <br/>
                </td>
              </tr>
              <tr>
                <td>국토교통부장관·특별시장·광역시장·특별자치시장·도지사·특별자치도지사 확인사항</td>
                <td>양수인이 법인인 경우에는 법인 등기사항증명서 (양수인이 화물자동차 운수사업자가 아닌 경우만 해당합니다.)</td>
              </tr>                        
            </tbody>
          </Table>
        </div>
      </R.FormDetails_Div>
    </div>
  );
};