import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BuyingFormDetails from "./BuyingForm";

function BuyingRegister() {
    const location = useLocation();
    const num = location.state?.main;  
    const [activeTab, setActiveTab] = useState(num);

    useEffect(() => {        
        setActiveTab(num);
      }, []);

    return (
        <>
            <BuyingFormDetails
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
        </>
    );
}

export default BuyingRegister;