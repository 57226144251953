import { APIClient } from "./apiCore";
import * as url from "./urls";
import {POST_NUMBA_DELQNA, POST_NUMBA_INSQNA, POST_NUMBA_QNALIST} from "./urls";

const api = new APIClient();

export const postInsQna = (data) =>
  api.createWithFile(url.POST_NUMBA_INSQNA, data);
export const postQnaList = (data) => api.create(url.POST_NUMBA_QNALIST, data);
export const noticeList = (data) => api.create(url.POST_NUMBA_NOTICELIST, data);
export const faqList = (data) => api.create(url.POST_NUMBA_FAQLIST, data);
export const postDelQna = (data) => api.create(url.POST_NUMBA_DELQNA, data);
export const postUpdQna = (data) => api.createWithFile(url.POST_NUMBA_UPDQNA, data);
