import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Customer from "./Customer";
import MobileFooter from "../../componant/mobileFooter";
import { headerText } from "../../App";
import { useAtom } from "jotai";

function CustomerTab() {
  const [activeTab, setActiveTab] = useState(1);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const tabShow = (id) => {
    setActiveTab(id);
  };

  const location = useLocation();

  useEffect(() => {
    setHeaderTextAtom("고객센터");
    if (location?.state) {
      setActiveTab(location.state);
    }
  }, [location?.state]);

  return (
    <>
      <div className="customer_tab bg-white">
        <div className="container">
          <div className="main-heading d-md-block d-none">
            <h1 className="text-center">고객센터</h1>
          </div>
          <div className="tab-section">
            <Link
              className={` nav_link ${activeTab === 1 ? "active" : ""}`}
              onClick={() => tabShow(1)}
            >
              공지사항
            </Link>
            <Link
              className={` nav_link ${activeTab === 2 ? "active" : ""}`}
              onClick={() => tabShow(2)}
            >
              1:1 문의
            </Link>
            <Link
              className={` nav_link ${activeTab === 3 ? "active" : ""}`}
              onClick={() => tabShow(3)}
            >
              자주하는 질문
            </Link>
          </div>

          <Customer activeTab={activeTab} />
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default CustomerTab;
