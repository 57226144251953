import React from "react";
import {
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";

const PrivateLayout = () => {
  const access_token = localStorage.getItem("access_token");

  return (
    <>
      <div className="headerFixedLayout">
        <Outlet />
        {/*{access_token ? <Outlet /> : <Navigate to="/login" replace />}*/}
      </div>
    </>
  );
};

export default PrivateLayout;
