import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
// import { Button } from '@mui/material';
import { Button } from "react-bootstrap";

const CanvasWrapper = styled.div`
  position: relative;
  border: 1px solid #c5c1c1;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 15px;
`;

const CanvasPlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #999;
`;

const Signature = ({isSigned, setIsSaved, setDataURL, setSaveFile, setIsSigned, onClose, reportReqData}) => {
  const canvasRef = useRef(null);
  // const [sign, setSign] = useState();

  const handleSave = () => {
    // console.log(sign);
    // setDataURL(sign.getTrimmedCanvas().toDataURL('image/png'));
    if (canvasRef.current) {
      const dataURL = canvasRef.current.toDataURL('image/png');
      const blob = convertDataUrlToFile(dataURL);
      const file = new File([blob], `${reportReqData.user_nm}_signature.png`, { type: 'image/png' });
      setSaveFile(file);
      setDataURL(dataURL);
      setIsSaved(true);
      onClose();
    }
  };

  const convertDataUrlToFile = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mimeString = header.split(':')[1].split(';')[0];
    const byteString = atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  };

  return (
    <>
      <CanvasWrapper>
        {!isSigned && (
          <CanvasPlaceholder>여기에 서명을 해주세요.</CanvasPlaceholder>
        )}
        <SignatureCanvas
          canvasProps={{
            className: "signature-canvas",
            width: 400, // 430
            height: 200, // 250
          }}
          // ref={data => setSign(data)}
          ref={canvasRef}
          onBegin={() => {
            setIsSigned(true);
          }}
        />
      </CanvasWrapper>
      <div className='d-flex align-items-center justify-content-center gap-3'>
        <Button
          variant="warning"
          disabled={!isSigned} // 버튼 disabled
          onClick={handleSave}
        >
          저장
        </Button>
        <Button
          variant="outline-warning"
          onClick={() => {
            canvasRef.current.clear();
            setDataURL("");
            setSaveFile("");
            setIsSigned(false);
          }}
        >
          지우기
        </Button>
      </div>
      {/* <div>{saveFile?.name}</div>    */}
      {/* <img src={dataURL} />    */}
    </>
  );
};

export default Signature;
