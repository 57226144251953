//AUTH
export const POST_NUMBA_LOGIN = "/auth/login";
export const POST_NUMBA_LOGOUT = "/auth/logout";
export const POST_NUMBA_SIGNUP = "/auth/signUp";
export const POST_NUMBA_FINDID = "/auth/findId";
export const POST_NUMBA_FINDPASSWORD = "/auth/updLostPwd";
export const POST_NUMBA_CHECKID = "/auth/checkId";
export const POST_NUMBA_SENDSMS = "/auth/sendSms";
export const POST_NUMBA_SELCHECKNO = "/auth/selCheckno";
export const POST_NUMBA_CODELIST = "/comm/codeList";
export const POST_NUMBA_SELUSERINFO = "/auth/selUserInfo";
export const POST_NUMBA_REFRESH = "/auth/refresh";
export const POST_NUMBA_REFRESHAFTERTIMEOUT = "/auth/refreshAfterTimeOut";
export const POST_NUMBA_CHKUSRFORW = "/auth/chkUsrforW";
export const POST_NUMBA_WITHDRAWUSER = "/auth/withdrawUser";

// SELL
export const POST_NUMBA_INSSELLINDI = "/sell/insSellIndi";
export const POST_NUMBA_INSSELLCORP = "/sell/insSellCorp"; // 법인넘버 매도, 임대 ()
export const POST_NUMBA_INSSELLLIC = "/sell/insSellLic";
export const POST_NUMBA_SELLLIST = "/sell/sellList"; // 매도리스트 (주선면허, 개인, 법인)
export const POST_NUMBA_SELLDETAILLIST = "/sell/sellDetailList";
export const POST_NUMBA_SELLCODEDESCRIPTION = "/comm/selCodeDescription";

// BUY
export const POST_NUMBA_INSBUYINDI = "/buy/insBuyIndi";
export const POST_NUMBA_INSBUYCORP = "/buy/insBuyCorp"; // 법인넘버 매수, 임대 ()
export const POST_NUMBA_INSBUYLIC = "/buy/insBuyLic";
export const POST_NUMBA_BUYLIST = "/buy/buyList"; // 매수리스트 (주선면허, 개인, 법인)
export const POST_NUMBA_BUYDETAILLIST = "/buy/buyDetailList";

// MyNumberOne
export const POST_NUMBA_INSINTEREST = "/user/insInterest";
export const POST_NUMBA_DELINTEREST = "/user/delInterest";
export const POST_NUMBA_INTERESTLIST = "/user/interestList";
export const POST_NUMBA_USERMAINLIST = "/user/userMainList";
export const POST_NUMBA_USERCLIST = "/user/userCList";
export const POST_NUMBA_UPDCSELLCBUY = "/sale/updCSellCBuy";
export const POST_NUMBA_DELCSELLCBUY = "/sale/delCSellCBuy";
export const POST_NUMBA_USERSELLBUYLIST = "/user/userSellBuyList";
export const POST_NUMBA_UPDSELLBUY = "/sell/updSellBuy";
export const POST_NUMBA_USERCOMPLETEDLIST = "/user/userCompletedList";

export const POST_NUMBA_SELLBUYDETAILLIST = "/user/sellbuyDetailList";
export const POST_NUMBA_DELSELLBUY = "/sell/delSellBuy";
export const POST_NUMBA_UPDUSERINFO = "/auth/updUserInfo";
export const POST_NUMBA_CHKUSERFORW = "/auth/chkUsrforW";

export const POST_NUMBA_CHGPASSWD = "/auth/chgPasswd";

export const POST_NUMBA_MVALUELIST = "/comm/mValueList";
export const POST_NUMBA_KAKAO_SIGNUP = "/kakao/kakaoSignUp";
export const POST_NUMBA_KAKAO_LOGIN = "/kakao/kakaoLogin";

// file
export const POST_NUMBA_GETFILE = "/comm/getFile";

// sale
export const POST_NUMBA_INSCBUY = "/sale/insCBuy";
export const POST_NUMBA_INSCSELL = "/sale/insCSell";
export const POST_NUMBA_SELLIC = "/sale/selLic";

// archive (자료실)
export const POST_NUMBA_ARCHIVELIST = "/supp/dataList";
export const POST_NUMBA_ARCHIVE_DETAIL = "/supp/selData";

// Customer Center
export const POST_NUMBA_INSQNA = "/supp/insQna";
export const POST_NUMBA_QNALIST = "/supp/qnaList";
export const POST_NUMBA_NOTICELIST = "/supp/noticeList";
export const POST_NUMBA_FAQLIST = "/supp/faqList";

// alarm
export const POST_NUMBA_SELALARM = "/user/selAlarm";
export const POST_NUMBA_CHGALARM = "/user/chgAlarm";
export const POST_NUMBA_ALARMLIST = "/user/alarmList";
export const POST_NUMBA_UPDALARM = "/user/updAlarm";
export const POST_NUMBA_DELALARM = "/user/delAlarm";
// fcm
export const POST_NUMBA_FCMTOKEN = "/fcm/insFcmToken";

export const POST_NUMBA_INSREF = "/comm/insRef";

export const POST_NUMBA_DELQNA = "/supp/delQna";
export const POST_NUMBA_UPDQNA = "/supp/updQna";
export const POST_NUMBA_DELQNAFILE = "/supp/delQnaFile";

// main
export const POST_NUMBA_MAIN_PRICE = "/comm/selMvalue";

// 파일 확인 메시지
export const POST_NUMBA_SEL_CODE_DESCRIPTION = "m/selCodeDescription";

// 신고증
export const POST_UPD_REPORT = "/user/updReport";
export const POST_SEL_REPORT = "/user/selReport";

export const POST_GETSIGNFILE = "/user/getSignFile";
export const POST_REPORT_LIST = "/user/reportList";
export const POST_GET_REPORTFILE = "/user/getReportFile";

