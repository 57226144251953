import React, { useEffect } from "react";
import * as S from "../../css/SignUp/userSelection.style";
import { Col, Container, Row } from "react-bootstrap";
import CardButton from "../../componant/buttons/cardButton";
import { Link } from "react-router-dom";
import { useAtom } from "jotai/index";
import { headerText } from "../../App";
import { isMobile } from "react-device-detect";
import useDeviceDetect from "../../helpers/useMobile";

function UserSelection() {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const isMobile = useDeviceDetect();

  useEffect(() => {
    setHeaderTextAtom("회원가입");
  }, []);

  return (
    <S.CardWrapper>
      <Container>
        <S.CardSection>
          <Row>
            <Col md={12}>
              <S.Heading>회원가입 유형을 선택해 주세요</S.Heading>
              <S.Para>일반회원과 법인회원을 선택해 주세요</S.Para>
            </Col>
            <Col md={6}>
              <S.WrapperBox>
                <S.Box>
                  <S.Img_div>
                    <img
                      src={process.env.PUBLIC_URL + "/img/sign_card1.png"}
                      alt="card"
                    />
                  </S.Img_div>
                  <S.Content_div>
                    <S.ContentHead>일반회원</S.ContentHead>
                    <S.ContentPara>넘버 매매를 원하시는 개인회원</S.ContentPara>
                    <Link to={`/RegularUser`} state={{ user_type_cd: `201` }}>
                      {isMobile ? (
                        <CardButton
                          bg={"#ff9d2f"}
                          width={"160px"}
                          height={"57px"}
                          name={"회원가입"}
                        ></CardButton>
                      ) : (
                        <CardButton
                          bg={"#ff9d2f"}
                          width={"200px"}
                          height={"57px"}
                          name={"회원가입"}
                        ></CardButton>
                      )}
                    </Link>
                  </S.Content_div>
                </S.Box>
              </S.WrapperBox>
            </Col>
            <Col md={6}>
              <S.WrapperBox>
                <S.Box>
                  <S.Img_div>
                    <img
                      src={process.env.PUBLIC_URL + "/img/sign_card2.png"}
                      alt="card"
                    />
                  </S.Img_div>
                  <S.Content_div>
                    <S.ContentHead>법인회원</S.ContentHead>
                    <S.ContentPara>넘버 매매를 원하시는 법인회원</S.ContentPara>
                    <Link
                      to={`/CorporationUser`}
                      state={{ user_type_cd: `202` }}
                    >
                      {isMobile ? (
                        <CardButton
                          bg={"#ff9d2f"}
                          width={"160px"}
                          height={"57px"}
                          name={"회원가입"}
                        ></CardButton>
                      ) : (
                        <CardButton
                          bg={"#ff9d2f"}
                          width={"200px"}
                          height={"57px"}
                          name={"회원가입"}
                        ></CardButton>
                      )}
                    </Link>
                  </S.Content_div>
                </S.Box>
              </S.WrapperBox>
            </Col>
          </Row>
        </S.CardSection>
      </Container>
    </S.CardWrapper>
  );
}

export default UserSelection;
