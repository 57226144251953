import React, { useEffect, useState } from "react";
import { TransactionInfo } from "./RequestProperty/TransactionInfo";
import { postNumbaUserClist } from "../../../api/mypage";
import useInfiniteScroll from "../../../utils/hooks/infinite-scroll/useInfiniteScroll";
import MobileFooter from "../../../componant/mobileFooter";

// 나의 거래신청 정보 메인
function RequestForTransaction({mainSeq}) {
  const [count, setCount] = useState(10);
  const [transaction, setTransAction] = useState([]);
  const filteredTransaction = transaction.filter(data => data.main_seq === mainSeq);

  // 나의 거래신청 리스트
  const callUserCList = async (cnt) => {
    const response = await postNumbaUserClist({
      startIdx: 0,
      rowCnt: cnt,
    });
    setTransAction(response?.resultList);
  };

  const { setBottom } = useInfiniteScroll(() => {
    setCount((prev) => {
      callUserCList(prev + 10);
      return prev + 10;
    });
  });

  useEffect(() => {
    callUserCList(count);
  }, []);

  
  return (
    <>
      <div className="request-transiction my-listing">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">
                <h1>나의 거래신청 정보</h1>
              </div>
            </div>
          </div>
          {transaction?.length > 0 ? (
            <div>
              {/* 마이페이지에서 상세보기 클릭시 mainSeq로 체크 */}
              {mainSeq !== 0 && mainSeq !== undefined ? (
                filteredTransaction?.map((data) => {
                  return (
                    <TransactionInfo
                      data={data}
                      callUserCList={callUserCList}
                      count={count}
                    />
                  );
                })
              ) : (
                // 마이페이지 상단 탭에서 나의 거래신청 선택시 모두 보이는 화면
                transaction?.map((data) => {
                  return (
                    <TransactionInfo
                      data={data}
                      callUserCList={callUserCList}
                      count={count}
                    />
                  );
                }))
              }
              {transaction.length > 0 && <div ref={setBottom} />}
            </div>
          ) : (
            <div className="mypage_wrapper top-border">
              <div className="container">
                <div className="card_part">
                  <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                    거래 신청 내역이 없습니다.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default RequestForTransaction;
