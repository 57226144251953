import { duration } from "moment";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import { postNumbaSelUserInfo } from "../../api/auth";

function MobileFooter(props) {
  const { main, activeTab, subActiveTab} = props;
  const location = useLocation();
  const navigate = useNavigate();

  const regCheck = async () => {
    const res = await postNumbaSelUserInfo({});
    if (res?.brno_auth_flag === "Y" && activeTab === 1) {
      return toast.error("개인회원만 등록가능합니다.", {
        duration: 500,
      });
    } else {
      return navigate("/selling-request", {state:{main: activeTab, sub: subActiveTab}});
    }
  }

  return (
    <>
      <div className="personalSellingPage">
        <div className="mobileFooterMainWrapper overflow-hidden position-fixed">
          <div className="mobileBottomFooterBlue d-md-block d-lg-none d-flex">
            <div className="mobileBottomFooterBlue__wrapper d-flex justify-content-between px-2">
              <div className="mobileBottomFooterBlue__menu text-center">
                <Link
                  to="/view-price"
                  className={`menu-view-price ${
                    location.pathname.includes("view-price") ? "active" : ""
                  } `}
                >
                  {/* <img src={process.env.PUBLIC_URL + "/img/ficon_chart.svg"} /> */}
                  <p
                    className={`${
                      location.pathname.includes("view-price") ? "active" : ""
                    }`}
                  >
                    시세보기
                  </p>
                </Link>
              </div>
              <div className="mobileBottomFooterBlue__menu text-center">
                <Link
                  to="/information"
                  className={`menu-information ${
                    location.pathname.includes("information") ? "active" : ""
                  }`}
                >
                  {/* <img src={process.env.PUBLIC_URL + "/img/ficon_guide.svg"} /> */}
                  <p
                    className={`${
                      location.pathname.includes("information") ? "active" : ""
                    }`}
                  >
                    이용안내
                  </p>
                </Link>
              </div>
              <div>
                {main ? (
                  <button
                    className="NewBtnColor"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/mobile-Icon/n_logo.svg"
                      }
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      // if (!localStorage.getItem("access_token")) {
                      //   toast.error("로그인 후 이용가능 합니다.", {
                      //     duration: 500,
                      //   });
                      //   return;
                      // }
                      if (!localStorage.getItem("access_token")) {
                        navigate("/login");
                        return;
                      } 
                      if (location?.pathname.includes("selling")) {
                        // navigate("/selling-request", {state:{main: activeTab, sub: subActiveTab}});
                        regCheck();
                      } else if (location?.pathname.includes("buying")) {
                        navigate("/buying-register", {state:{main: activeTab, sub: subActiveTab}});
                      }
                    }}
                    style={{
                      "--color": location?.pathname.includes("selling")
                        ? "#26BBE3"
                        : "#FF7F4E",
                      "--subColor": location?.pathname.includes("selling")
                        ? "#3B85DE"
                        : "rgba(233, 71, 94, 0.9)",
                    }}
                  >
                    <p className="pb-1">
                      {location?.pathname.includes("selling") ? (
                        <>
                          <div>팝니다</div>
                          <div style={{ fontSize: "12px" }}>등록하기</div>
                        </>
                      ) : (
                        <>
                          <div>삽니다</div>
                          <div style={{ fontSize: "12px" }}>등록하기</div>
                        </>
                      )}
                    </p>
                  </button>
                )}
              </div>
              <div className="mobileBottomFooterBlue__menu text-center">
                <Link
                  to="/announcement"
                  state={1}
                  className={`menu-announcement ${
                    location.pathname.includes("announcement") ? "active" : ""
                  }`}
                >
                  {/* <img
                    src={process.env.PUBLIC_URL + "/img/ficon_customer.svg"}
                  /> */}
                  <p
                    className={`${
                      location.pathname.includes("announcement") ? "active" : ""
                    }`}
                  >
                    고객센터
                  </p>
                </Link>
              </div>
              <div className="mobileBottomFooterBlue__menu text-center">
                <Link
                  onClick={(e) => {
                    // if (!localStorage.getItem("access_token")) {
                    //   toast.error("로그인을 해주세요.", {
                    //     duration: 600,
                    //   });
                    //   e.preventDefault();
                    //   return;
                    // }
                    if (!localStorage.getItem("access_token")) {
                      navigate("/login");
                      e.preventDefault();
                      return;
                    }
                  }}
                  to={"/main"}
                  className={`menu-mypage ${
                    location.pathname.includes("main") ? "active" : ""
                  }`}
                >
                  {/* <img src={process.env.PUBLIC_URL + "/img/ficon_mypage.svg"} /> */}
                  <p
                    className={`${
                      location.pathname.includes("main") ? "active" : ""
                    }`}
                  >
                    마이넘버로
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileFooter;
