import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import * as C from "../../../css/PersonalSellingRequest/request/requestComplete";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import CardButton from "../../../componant/buttons/cardButton";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../css/complete-buying-request/complete-buying-request.css";
import {
  BuyingInfo,
  PersonBuyingInfo,
} from "../RequestForm/PersonalRequestForm/PersonalRequestForm";
import { TradingBuyingInfo } from "../RequestForm/CoorporateRequestForm/Trading/TradingRequestForm";
import { RentalBuyingInfo } from "../RequestForm/CoorporateRequestForm/Rental/RentalRequestForm";
import { LicenseBuyingInfo } from "../RequestForm/LicenseRequestForm/LicenseReuestForm";
import { toast } from "wc-toast";
import { postNumbaSellCodeDescription } from "../../../api/sell";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { useAtom } from "jotai/index";
import { header_userInfo } from "../../../data/atom/mypage/mypage_atom";
import { scrollToTop } from "../../../utils/hooks/scrollToTop";

const GoodsInfo = ({ data }) => {
  const type = data?.type;
  switch (type) {
    case 2101:
      return <PersonBuyingInfo buyReqData={data} />;
    case 2102:
      return <TradingBuyingInfo buyReqData={data} />;
    case 2103:
      return <RentalBuyingInfo buyReqData={data} />;
    case 2104:
      return <LicenseBuyingInfo buyReqData={data} />;
  }
};

const fileDownLoad = (file) => {
  if (file) {
    const blob = new Blob([file]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.href = url;
    a.download = file.name; // 다운로드할 파일의 이름
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } else {
    toast.error("파일이 없습니다.");
  }
};

const BuyerInfo = ({ data }) => {
  const type = data?.type;

  return (
    <div className="FormDIv">
      <R.FormDetails_Heading>신청자 정보</R.FormDetails_Heading>
      <Table bordered responsive className="table-form">
        <tbody>
          <tr>
            <R.FormTable_Th width="20%">
              {type === 2103 ? "임차인 이름" : "구매자 이름"}
            </R.FormTable_Th>
            <td className="paddingLeft">{data?.user_nm}</td>
          </tr>
          <tr>
            <R.FormTable_Th width="20%">
              {type === 2103 ? "임차인 " : "구매자 "}
              휴대폰번호
            </R.FormTable_Th>
            <td className="paddingLeft">{formatPhoneNumber(data?.phone)}</td>
          </tr>
          <tr>
            <R.FormTable_Th width="20%">
              {type === 2103 ? "임차인 " : "구매자 "}
              주소
            </R.FormTable_Th>
            <td className="paddingLeft" colSpan={3}>
              {data?.zip + " " + data?.addr1 + " " + data?.addr2}
            </td>
          </tr>
          {type === 2103 && (
            <>
              <tr>
                <R.FormTable_Th width="20%">차량등록증</R.FormTable_Th>
                <td className="paddingLeft" colSpan={3}>
                  <div className="d-flex  align-items-center">
                    <label
                      className="fileBtn"
                      htmlFor="fw_file"
                      onClick={() => {
                        fileDownLoad(data?.car_file);
                      }}
                    >
                      파일보기
                    </label>
                    <div className="txt_attach ms-2">
                      {data?.car_file?.name}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <R.FormTable_Th width="30%">
                  화물운송종사자격증명서
                </R.FormTable_Th>
                <td className="paddingLeft" colSpan={3}>
                  <div className="d-flex  align-items-center">
                    <label
                      className="fileBtn"
                      htmlFor="fw_file"
                      onClick={() => {
                        fileDownLoad(data?.fw_file);
                      }}
                    >
                      파일보기
                    </label>
                    <div className="txt_attach ms-2">{data?.fw_file?.name}</div>
                  </div>
                </td>
              </tr>
            </>
          )}
          <tr>
            <R.FormTable_Th width="20%">요청내용</R.FormTable_Th>
            <td className="paddingLeft" colSpan={3}>
              {data?.req_comment}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const RequestComplete = ({ subActiveTab }) => {
  const [data, setData] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useAtom(header_userInfo);

  useEffect(() => {
    scrollToTop();
    setData(location.state);

    console.log("userInfo", userInfo);
    let cd_seq = 0;

    if (userInfo.user_type_cd === "201") {
      cd_seq = 2801;
    }

    // async function fetchData() {
    //   try {
    //     const response = await postNumbaSellCodeDescription({
    //       code: location.state?.type,
    //     });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    // fetchData();

    // try {
    //   const response = await postNumbaSellCodeDescription({
    //     code: location.state?.type,
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  }, []);

  if (subActiveTab === "first") {
    return (
      <>
        <div className="request__complete__wrapper">
          <Container>
            <Row>
              <Col md={12}>
                <div>
                  <C.FormHeading_div>
                    <C.Form_Heading>구매신청 완료</C.Form_Heading>
                  </C.FormHeading_div>
                </div>
                <GoodsInfo data={data} />
                <BuyerInfo data={data} />

                <div className="d-flex flex-column align-items-center selling-success">
                  <div className="d-flex flex-column align-items-center">
                    <div>구매신청이 완료되었습니다.</div>
                    <div>담당 매니저가 빠른 시간에 연락드리겠습니다.</div>
                  </div>
                  <div className="mt-3">
                    문의가 필요하실때는 <span>고객센터: 02-2276-0601</span> 로
                    연락주세요.
                  </div>
                </div>

                {/*<div className="FormDIv">*/}
                {/*  <R.FormDetails_Heading>꼭 확인하세요!</R.FormDetails_Heading>*/}

                {/*  <Table bordered>*/}
                {/*    <tbody>*/}
                {/*      <tr>*/}
                {/*        <R.FormTable_Th width="20%">입금계좌</R.FormTable_Th>*/}
                {/*        <td className="paddingLeft">*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <R.FormTable_Th width="20%">*/}
                {/*          제출서류 <br /> (등기제출)*/}
                {/*        </R.FormTable_Th>*/}
                {/*        <td className="paddingLeft">*/}
                {/*          위임장 3부 <br /> 인감증명서 2부 <br /> 운전면허증*/}
                {/*          사본 2부*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <R.FormTable_Th width="20%">*/}
                {/*          서류제출 주소*/}
                {/*        </R.FormTable_Th>*/}
                {/*        <td className="paddingLeft" colSpan={3}>*/}
                {/*          서울특별시 중구 퇴계로*/}
                {/*          */}
                {/*          <br />*/}
                {/*         주식회사 넘버로*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <R.FormTable_Th width="20%">고객센터</R.FormTable_Th>*/}
                {/*        <td className="paddingLeft" colSpan={3}>*/}
                {/*          02-2276-0601*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*    </tbody>*/}
                {/*  </Table>*/}
                {/*</div>*/}
                {/*<R.Para_Content>*/}
                {/*  ※ 임대금을 은행 에스크로 계좌에 입금 하시고 임대에 필요한*/}
                {/*  서류를 넘버로에 등기로 제출하시면 매매가 진행됩니다.*/}
                {/*</R.Para_Content>*/}

                <div className="d-flex justify-content-center mt-4">
                  <CardButton
                    width="390px"
                    bg="#FF9C00"
                    name="확인"
                    onClick={() => {
                      navigate("/selling", {
                        replace: true,
                      });
                    }}
                  ></CardButton>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  } else if (subActiveTab === "second") {
    return <>wekfgwejhfgjwefjwejhfgewgh</>;
  }
  return null;
};

export default RequestComplete;
