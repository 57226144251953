import { APIClient } from "./apiCore";
import * as url from "./urls";
import { POST_NUMBA_ARCHIVELIST } from "./urls";

const api = new APIClient();

export const archiveListApi = (data) => {
  return api.create(url.POST_NUMBA_ARCHIVELIST, data);
};

export const archiveDetailPageApi = (data) => {
  return api.create(url.POST_NUMBA_ARCHIVE_DETAIL, data);
};
