import "./App.css";
import "./assets/fonts/Font.css";
import { GlobalStyles } from "./css/globalStyle";
import Header from "./componant/header";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Footer from "./componant/footer";
import Router from "./routers/RouterConfig";
import { BrowserRouter, useLocation } from "react-router-dom";
import { atom, useAtom } from "jotai";
import { Provider } from "jotai";
import { useEffect } from "react";
// import { getFcmCommunication } from "./utils/hooks/app-communication/webToApp/getFcm";
import { isMobile } from "react-device-detect";
import { postNumbaInsRef } from "./api/auth";
import { toast } from "wc-toast";
import Sidebar from "./componant/sidebar";
import useRefresh from "./utils/hooks/useRefresh";
export const userLoginJotai = atom();
export const headerText = atom("");

function App() {
  const accessToken = localStorage.getItem("access_token");
  const { refreshTimeOut } = useRefresh();
  useEffect(() => {
    // getFcmCommunication();
    // if (accessToken) {
    //   refreshTimeOut(accessToken);
    // }
    if (isMobile) {
      // android
      document.addEventListener("message", handleMessage);
      // ios
      window.addEventListener("message", handleMessage);

      sessionStorage.setItem("isMobile", 'Y');

      return () => {
        // android
        document.removeEventListener("message", handleMessage);
        // ios
        window.removeEventListener("message", handleMessage);
      };
    } else {
      sessionStorage.setItem("isMobile", 'N');
    }
  }, [accessToken]);

  useEffect(() => {
    sessionStorage.removeItem("q");
    if (document?.referrer) {
      let ref = document?.referrer;
      const queryStringIndex = ref.indexOf("?");
      if (queryStringIndex !== -1) {
        ref = ref.substring(0, queryStringIndex);
        sessionStorage.setItem("ref", ref);
        const queryString = document.referrer.substring(queryStringIndex + 1);
        const params = new URLSearchParams(queryString);
        const queryParam = params.get("query");
        if (queryParam) {
          const decodedQueryParam = decodeURIComponent(queryParam);
          sessionStorage.setItem("ref_keyword", decodedQueryParam);
        }
      } else {
        sessionStorage.setItem("ref", ref);
        sessionStorage.setItem("ref_keyword", "");
      }
    }

    const path = window.location.pathname;
    const regex = /\/q=(.*)/;
    const match = path.match(regex);
    let value = null;

    if (match && match.length > 1) {
      value = match[1];
      sessionStorage.setItem("q", value);
    }

    const queryParams = new URLSearchParams(window.location.search);
    const q = queryParams.get("q");
    if (q) {
      sessionStorage.setItem("q", q);
    }
    insRef();
  }, [document?.referrer]);

  function insRef() {
    try {
      postNumbaInsRef({
        referer: sessionStorage.getItem("ref"),
        ref_keyword: sessionStorage.getItem("ref_keyword"),
        q: sessionStorage.getItem("q"),
        isMobile: sessionStorage.getItem("isMobile"),
      });
    } catch (e) {
      return toast.error(e);
    }
  }

  const handleMessage = (event) => {
    try {
      const { type, data } = JSON.parse(event?.data);
    } catch (err) {
      // alert(err);
    }
  };

  return (
    <>
      <Provider>
        <BrowserRouter>
          <wc-toast></wc-toast>
          <GlobalStyles />
          <Header />
          <Router />
          {/* <Sidebar /> */}
          <Footer />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
