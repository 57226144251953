import styled from "styled-components";

export const CardWrapper_div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  //align-items: center;
  background: #f5f5f5;
`;
export const MainDiv_Heading = styled.h1`
  color: #000;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  padding-bottom: 10px;
  border-bottom: 1px solid #cdcdcd;
`;
export const SignUpInput_Wrapper = styled.div`
  margin-top: 38px !important;
  width: 390px;
  margin: 0 auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const SignUpForm_Heading = styled.h3`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
export const SignUp_Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 20px;
  color: #000;
  font-size: 13.999px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  @media only screen and (max-width: 767px) {
    height: 40px;
    font-size: 12px;
  }

  &:focus-visible {
    outline: none;
  }
`;

export const SignUpBottomTxt_Corp = styled.p`
  color: #656565;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  align-self: center;
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
`;
