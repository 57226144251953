import { useLocation } from "react-router-dom";
import "../../css/terms.css";
import { useAtom } from "jotai";
import { headerText } from "../../App";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const location = useLocation();

  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);

  useEffect(() => {
    setHeaderTextAtom("개인정보처리방침");
  }, [location?.state]);
  return (
    <>
      <div className="container container-terms">
        <h1 className="terms_heading_main">개인정보 수집 및 이용 동의</h1>
        <p>
          주식회사 체인버스(이하 넘버로이라 함)가 제공하는 회사 서비스는 이용자의
          개인정보를 중시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
          개인정보와 관련된 법령상의 개인정보보호규정 및 방송통신위원회가 제정한
          개인정보보호지침을 준수하고 있습니다. 넘버로는 개인정보보호정책을
          통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고
          있으며, 개인정보보호를 위해 항상 노력하고 있음을 알려드립니다.
        </p>

        <p>
          넘버로의 개인정보보호정책은 다음과 같은 내용으로 구성되어 있습니다.
          <ol>
            <li>수집하는 개인정보의 항목 및 수집방법</li>
            <li>개인정보의 수집 및 이용목적</li>
            <li>개인정보의 공유 및 제공</li>
            <li>개인정보의 취급위탁</li>
            <li>개인정보의 보유 및 이용기간</li>
            <li>개인정보의 파기절차 및 방법</li>
            <li>이용자 및 법정대리인의 권리와 그 행사방법</li>
            <li>개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항</li>
            <li>개인정보의 기술적/관리적 보호 대책</li>
            <li>개인정보관리책임자 및 담당자</li>
            <li>적용범위</li>
            <li>고지의 의무</li>
          </ol>
        </p>

        <h6 className="terms_sub_heading">
          1. 수집하는 개인정보의 항목 및 수집방법
        </h6>
        <p>
          <ul>
            <li>
              ① 넘버로는 각종 서비스 제공을 위해 아래와 같은 항목의 개인정보를
              수집하고 있습니다.
              <br />- 성명, 휴대폰번호
            </li>
            <li>
              ② 또한 서비스 이용과정이나 행정업무대행에서 다음과 같은 정보들이
              생성되어 수집될 수 있습니다.
              <br />- IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용
              기록
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">2. 개인정보의 수집 및 이용목적</h6>
        <p>
          넘버로는 수집한 개인정보를 컨텐츠 제공, 특정 맞춤 서비스 제공 목적을
          위해 활용합니다.
        </p>

        <h6 className="terms_sub_heading">3. 개인정보의 공유 및 제공</h6>
        <p>
          넘버로는 이용자들의 개인정보를{" "}
          <strong>2. 개인정보의 수집 및 이용목적</strong>에서 고지한 범위 내에서
          사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나
          원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다.
          <br />
          다만, 아래의 경우에는 예외로 합니다.
          <ul>
            <li>① 이용자들이 사전에 공개에 동의한 경우</li>
            <li>
              ② 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
              방법에 따라 수사기관의 요구가 있는 경우
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">4. 개인정보의 취급위탁</h6>
        <p>
          넘버로는 이용자의 동의 없이 이용자 정보를 외부 업체에 위탁하지
          않습니다.
          <br />
          향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해
          이용자에게 통지하고 필요한 경우 사전 동의를 받을 것입니다.
        </p>

        <h6 className="terms_sub_heading">5. 개인정보의 보유 및 이용기간</h6>
        <p>
          넘버로는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체
          없이 파기합니다.
        </p>

        <h6 className="terms_sub_heading">6. 개인정보의 파기절차 및 방법</h6>
        <p>
          넘버로는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
          정보를 지체 없이 파기합니다.
          <br />
          파기절차 및 방법은 다음과 같습니다.
          <ul>
            <li>
              ① 파기절차
              <br />
              - 이용자가 상담 등을 위해 입력한 정보는 목적이 달성된 후 별도의
              DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련
              법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간
              저장된 후 파기됩니다.
              <br />- 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고는
              보유되는 이외의 다른 목적으로 이용되지 않습니다.
            </li>
            <li>
              ② 파기방법
              <br />
              - 전자적 파일 형태로 저장된 개인정보는 재생할 수 없는 기술적
              방법을 사용하여 삭제합니다.
              <br />- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다.
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">
          7. 이용자 및 법정대리인의 권리와 그 행사방법
        </h6>
        <p>
          이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를
          조회하거나 수정(질문과 답변)할 수 있으며 가입해지(게시물 삭제)를
          요청할 수도 있습니다.
        </p>

        <h6 className="terms_sub_heading">
          8. 개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항
        </h6>
        <p>
          넘버로는 귀하의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등을
          운용합니다.
          <br />
          쿠키(cookie)란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
          보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에
          저장됩니다.
          <br />
          넘버로는 방문기록을 위해서만 쿠키를 사용하며, 그 외 다른 용도로
          사용하지 않습니다.
        </p>

        <h6 className="terms_sub_heading">
          9. 개인정보의 기술적/관리적 보호 대책
        </h6>
        <p>
          넘버로는 고객의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
          변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
          기술적/관리적 대책을 강구하고 있습니다.
          <ul>
            <li>
              ① 비밀번호 암호화
              <br />
              넘버로 웹사이트에서 각종 게시판 등록 시 등록된 비밀번호는
              암호화저장 및 관리되어 고객 본인만이 알고 있으며, 게시물의 열람 및
              변경도 비밀번호를 알고 있는 고객 본인에 의해서만 가능합니다.
            </li>
            <li>
              ② 해킹 등에 대비한 대책
              <br />
              - 넘버로는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
              유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
              <br />
              - 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
              백신프로그램을 이용하여 고객의 개인정보나 자료가 누출되거나
              손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여
              네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
              <br />- 그리고 침입차단시스템, 침입탐지(방지)시스템을 이용하여
              외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로
              보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고
              있습니다.
            </li>
            <li>
              ③ 취급직원의 최소화 및 교육
              <br />- 넘버로의 개인정보관련 취급직원을 최소한의 인원으로
              한정시키고 있으며, 담당자에 대한 수시교육 및 외부 위탁교육을
              통하여 넘버로의 개인정보보호정책 준수를 항상 강조하고 있습니다.
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">10. 개인정보관리책임자 및 담당자</h6>
        <p>
          넘버로는 이용자의 정보를 보호하고 개인정보와 관련한 불만을 처리하기
          위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고
          있습니다.
          <br />
          넘버로의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
          개인정보관리책임자 혹은 담당부서로 신고하시면 신속하게 답변을 드릴
          것입니다.
          <br />
          기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
          문의하시기 바랍니다.
          <ul>
            <li>① 개인분쟁조정위원회 (www.1336.or.kr, 1336)</li>
            <li>
              ② 정보보호마크인증위원회 (www.eprivacy.or.kr, 02-580-0533~4)
            </li>
            <li>
              ③ 대검찰청 인터넷범죄수사센터 (www.icic.sppo.go.kr, 02-3480-3600)
            </li>
            <li>④ 경찰청 사이버테러대응센터 (www.ctrc.go.kr, 02-392-0330)</li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">11. 적용범위의 제한</h6>
        <p>
          본 넘버로의 개인정보보호정책은 넘버로가 제공하는 넘버로 사이트에
          적용되며, 넘버로에 링크되어 있는 웹사이트들이 개인정보를 수집하는
          행위에 대해서는 본 넘버로 개인정보보호정책이 적용되지 않음을 알려
          드립니다.
        </p>

        <h6 className="terms_sub_heading">12. 고지의 의무</h6>
        <p>
          현 개인정보보호정책 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
          7일전부터 홈페이지의 공지사항을 통해 고지할 것입니다.
          <br />
          <br />
          <br />
        </p>
      </div>
    </>
  );
};
export default PrivacyPolicy;
