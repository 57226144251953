import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table, Form, Button } from "react-bootstrap";
// import * as R from "../../css/PersonalSellingRequest/requestForm";
import styled from "styled-components";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { scrollToTop } from "../../../utils/hooks/scrollToTop";
import moment from "moment";
// import getUserInfo from "../../../../../utils/hooks/getUserInfo";
import { headerText } from "../../../App";
import { useAtom } from "jotai";
import { BtnYellow } from "../../../css/components/button/buttonYellow";
import { postSelReport, postGetSignFile } from "../../../api/sale";
import { toast } from "wc-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Signature from "../../../componant/Signature";
import { CommonData, CommonData2 } from "./CommonData";
import { PersonInfo } from "./PersonInfo";
// import CommonRegistration from "../registration-information/CommonRegistration";

// 서명
export const SignatureInfo = (props) => {
  const { reportData } = props;   // newData
  console.log('reportData========', reportData);
  const location = useLocation();
  const reportFormData = location.state?.data;
  console.log('reportFormData========', reportFormData); // api전송데이터?

  const [sellFileUrl, setSellFileUrl] = useState();
  const [buyFileUrl, setBuyFileUrl] = useState();


  const getFileApi = async (seq, flag) => {
    console.log(seq);
    console.log('flag', flag);
    try {
      const response = await postGetSignFile({
        file_seq: seq,
        main_seq: reportFormData?.main_seq,
        deal_str: reportFormData?.deal_str,
        target_seq: reportFormData?.target_seq || "",
      });
      console.log("============================", response);
      const contentType = response.headers["content-type"];
      const previewUrl = URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      if (flag === 'sell') {
        console.log(previewUrl);
        setSellFileUrl(previewUrl);
      } else if (flag === 'buy') {
        console.log(previewUrl);
        setBuyFileUrl(previewUrl);
      }
    } catch (e) {
      return console.log(e);
    }
  };


  useEffect(() => {
    const fetchFiles = async () => {
      if (reportData.sellsign_file_seq) {
        await getFileApi(reportData.sellsign_file_seq, 'sell');
      }
      if (reportData.buysign_file_seq) {
        await getFileApi(reportData.buysign_file_seq, 'buy');
      }
    };  
    fetchFiles();
  }, [reportData]);

  return (
    <div className="container">
      {/* desktop */}
      <div className="d-none d-md-block">
        <div className="d-flex align-items-center justify-content-evenly mb-5">
          <div className="d-flex justify-content-beetween gap-3">
            <div>양도인 (파는 사람) : {reportData?.sell_user_nm}</div>
            <div style={{ position: "relative", display: "inline-block" }}>
              <span style={{ fontSize: "12px" }}>(서명)</span>
              {sellFileUrl && (
                <img
                  src={sellFileUrl}
                  style={{
                    width: "100px",
                    position: "absolute",
                    top: "-10px",
                    left: "-10px",
                  }}
                  alt="서명"
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-beetween gap-3">
            <div>양수인 (사는 사람) : {reportData?.buy_user_nm}</div>
            <div style={{ position: "relative", display: "inline-block" }}>
              <span style={{ fontSize: "12px" }}>(서명)</span>
              {buyFileUrl && (
                <img
                  src={buyFileUrl}
                  style={{
                    width: "100px",
                    position: "absolute",
                    top: "-10px",
                    left: "-10px",
                  }}
                  alt="서명"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="d-block d-md-none">
        <div className="d-flex justify-content-beetween gap-3">
          <div>양도인 (파는 사람) : {reportData?.sell_user_nm}</div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <span style={{ fontSize: "12px" }}>(서명)</span>
            {sellFileUrl && (
              <img
                src={sellFileUrl}
                style={{
                  width: "100px",
                  position: "absolute",
                  top: "-10px",
                  left: "-10px",
                }}
                alt="서명"
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-beetween gap-3 mt-2">
          <div>양수인 (사는 사람) : {reportData?.buy_user_nm}</div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <span style={{ fontSize: "12px" }}>(서명)</span>
            {buyFileUrl && (
              <img
                src={buyFileUrl}
                style={{
                  width: "100px",
                  position: "absolute",
                  top: "-10px",
                  left: "-10px",
                }}
                alt="서명"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export const ReportComplete = () => {
  const location = useLocation();
  const reportData = location.state?.data;
  console.log(reportData);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [reportResultData, setReportResultData] = useState({});
  console.log(reportResultData);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderTextAtom("신고서 신청 완료");
    scrollToTop();
  }, []);

  useEffect(() => {
    const data = {
      deal_str: reportData.deal_str,
      main_seq: reportData.main_seq,
      target_seq: reportData?.target_seq || ''
    }
    async function fetchData(){
      const response = await postSelReport(data);
      if (response?.code === 1000) {
        setReportResultData({ ...response });
      } else {
        console.log("조회 실패하였습니다.");
      }      
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="requestFormMain-container">
        <Container className="p-0">
          <Row>
            <Col md={12}>
              <div className="pc_view">
                <R.FormHeading_div>
                  <R.Form_Heading>양도·양수 신고서 신청 완료</R.Form_Heading>
                </R.FormHeading_div>
              </div>
              <PersonInfo
                reportData={reportResultData}
                isSell
                isComplete
              />
              <PersonInfo
                reportData={reportResultData}
                isBuy
                isComplete
              />
              <CommonData/>
              <SignatureInfo reportData={reportResultData}/>
              {/* <Stripe /> */}
              <CommonData2/>
              <div className="mt-3 text-center">
                <BtnYellow
                  width="360px"
                  height="60px"
                  onClick={() => {                    
                    navigate("/main-tab", { state: {main: 5}});
                  }}
                >
                  확인
                </BtnYellow>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const Stripe = styled.div`
padding-bottom: 22px;
border-bottom: 1px solid #cdcdcd;
`

export default ReportComplete;
