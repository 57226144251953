import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileFooter from "../../componant/mobileFooter";
import { headerText } from "../../App";
import { useAtom } from "jotai";
import ViewPriceSection from ".";

function ViewPriceTab() {
  const [activeTab, setActiveTab] = useState(1);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const tabShow = (id) => {
    setActiveTab(id);
  };
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setActiveTab(location?.state);
    } else {
      setActiveTab(1);
    }
    setHeaderTextAtom("시세보기");
  }, [location?.state]);

  return (
    <>
      <div className="customer_tab viewPrice-tab bg-white">
        <div className="container">
          <div className="main-heading d-md-block d-none">
            <h1 className="text-center">시세보기</h1>
          </div>
          <div className="tab-section">
            <Link
              className={` nav_link ${activeTab === 1 ? "active" : ""}`}
              onClick={() => tabShow(1)}
            >
              개인넘버 (전기차)
            </Link>
            <Link
              className={` nav_link ${activeTab === 2? "active" : ""}`}
              onClick={() => tabShow(2)}
            >
              개인넘버 (일반)
            </Link>
            <Link
              className={` nav_link ${activeTab === 4 ? "active" : ""}`}
              onClick={() => tabShow(4)}
            >
              주선면허
            </Link>
          </div>
        </div>
        <ViewPriceSection activeTab={activeTab} />
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default ViewPriceTab;
