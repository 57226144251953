import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../../../css/Selling/Selling.style";
import ReqComplete from "./ReqComplete";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../../../../utils/hooks/scrollToTop";

// const RequestTab = ({ activeTab, subActiveTab, setSubActiveTab }) => {
//   if (activeTab === 1) {
//     return (
//       <>
//         <A.Box_Shadow>
//           <Container>
//             <Row>
//               <Col md={6} sm={9} className="m-auto">
//                 <A.SubNavTab>
//                   <A.SubNavTab_BtnPink
//                     className={subActiveTab === "first" ? "active" : ""}
//                     onClick={() => setSubActiveTab("first")}
//                   >
//                     소형 매물
//                   </A.SubNavTab_BtnPink>
//                   <A.SubNavTab_BtnPink
//                     className={subActiveTab === "second" ? "active" : ""}
//                     onClick={() => setSubActiveTab("second")}
//                   >
//                     중형 매물
//                   </A.SubNavTab_BtnPink>
//                   <A.SubNavTab_BtnPink
//                     className={subActiveTab === "third" ? "active" : ""}
//                     onClick={() => setSubActiveTab("third")}
//                   >
//                     대형 매물
//                   </A.SubNavTab_BtnPink>
//                 </A.SubNavTab>
//               </Col>
//             </Row>
//           </Container>
//         </A.Box_Shadow>

//         <ReqComplete subActiveTab={subActiveTab} />
//       </>
//     );
//   } else if (activeTab === 2) {
//     return (
//       <>
//         <A.Box_Shadow>
//           <Container>
//             <Row>
//               <Col md={6} sm={9} className="m-auto">
//                 <A.SubNavTab position="center">
//                   <A.SubNavTab_BtnPink
//                     className={subActiveTab === "first" ? "active" : ""}
//                     onClick={() => setSubActiveTab("first")}
//                   >
//                     매매 매물
//                   </A.SubNavTab_BtnPink>
//                   <A.SubNavTab_BtnPink
//                     className={subActiveTab === "second" ? "active" : ""}
//                     onClick={() => setSubActiveTab("second")}
//                   >
//                     임대 매물
//                   </A.SubNavTab_BtnPink>
//                 </A.SubNavTab>
//               </Col>
//             </Row>
//           </Container>
//         </A.Box_Shadow>
//         <ReqComplete subActiveTab={subActiveTab} />
//       </>
//     );
//   } else if (activeTab === 3) {
//     return (
//       <>
//         <A.Box_Shadow>
//           <Container>
//             <Row>
//               <Col md={6} sm={9} className="m-auto">
//                 <A.SubNavTab position="end">
//                   <A.SubNavTab_BtnPink
//                     className={subActiveTab === "first" ? "active" : ""}
//                     onClick={() => setSubActiveTab("first")}
//                   >
//                     주선면허 매물
//                   </A.SubNavTab_BtnPink>
//                 </A.SubNavTab>
//               </Col>
//             </Row>
//           </Container>
//         </A.Box_Shadow>
//         <ReqComplete subActiveTab={subActiveTab} />
//       </>
//     );
//   }
//   return null;
// };

const ActiveTabValue = (data) => {
  const type = data?.type;
  switch (type) {
    case 2101:
      return 1;
    case 2102:
      return 2;
    case 2103:
      return 2;
    case 2104:
      return 3;
  }
};

function ReqCompleteTab() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [subActiveTab, setSubActiveTab] = useState("first");
  
  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };

  useEffect(() => {
    let active = ActiveTabValue(location.state.data);
    setActiveTab(active);
    scrollToTop();
  }, []);

  return (
    <>
      <A.BuyingTab_Header>
        <Container>
          <A.NavTab_Wrapper>
            <A.NavTab
              className={activeTab === 1 ? "active" : ""}
              // onClick={() => tabShow(1)}
            >
              개인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 2 ? "active" : ""}
              // onClick={() => tabShow(2)}
            >
              법인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 3 ? "active" : ""}
              // onClick={() => tabShow(3)}
            >
              주선면허
            </A.NavTab>
          </A.NavTab_Wrapper>
        </Container>
      </A.BuyingTab_Header>

      {/* <RequestTab
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
      /> */}
      <ReqComplete subActiveTab={subActiveTab} />
    </>
  );
}

export default ReqCompleteTab;
