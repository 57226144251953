import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as S from "../../css/SignUp/signUpRegular";
import GrayButton from "../../componant/buttons/buttonGray";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import {
  postNumbaCheckId,
  postNumbaCodeList,
  postNumbaSelCheckno,
  postNumbaSendSms,
  postNumbaSignUp,
} from "../../api/auth";
import { isMobile } from "react-device-detect";

function Form2CorporationUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSendSms, setIsSendSms] = useState(false);
  const [regionCdList, setRegionCdList] = useState([]);
  const [regPathCdList, setRegPathCdList] = useState([]);
  const [corpKindCdList, setCorpKindCdList] = useState([]);
  const [signUpState, setSignUpState] = useState({
    user_id: "",
    check_id: "N",
    passwd: "",
    passwd2: "",
    user_nm: "",
    phone: "",
    send_sms: "N",
    selCheckno: "",
    user_type_cd: location?.state.user_type_cd,
    region_cd: "",
    reg_path_cd: "",
    agree_yn: location.state.agree_yn,
    privacy_yn: location.state.privacy_yn,
    alarm_yn: location.state.alarm_yn,
    ads_yn: location.state.ads_yn,
    corp_nm: location.state.corp_nm,
    ceo_nm: location.state.ceo_nm,
    brno: location.state.brno,
    corp_kind_cd: "",
    referer: isMobile
      ? "inApp"
      : sessionStorage?.getItem("ref")
      ? sessionStorage?.getItem("ref")
      : null,
    ref_keyword: isMobile
      ? "inApp"
      : sessionStorage?.getItem("ref_keyword")
      ? sessionStorage?.getItem("ref_keyword")
      : null,
  });

  const [errors, setErrors] = useState({
    user_id: "",
    check_id: "",
    passwd: "",
    passwd2: "",
    user_nm: "",
    phone: "",
    send_sms: "",
    corp_kind_cd: "",
    region_cd: "",
    reg_path_cd: "",
    agree_yn: "",
    privacy_yn: "",
    alarm_yn: "",
    ads_yn: "",
  });

  const needNumber = [
    "phone",
    "selCheckno",
    "corp_kind_cd",
    "region_cd",
    "reg_path_cd",
  ];

  const getRegionCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1000 });
      if (response.code !== 1000) {
        console.log("지역코드 가져오기 실패");
        return;
      }
      setRegionCdList(response.resultList[0]);
    } catch (err) {
      console.log("지역코드 가져오기 실패");
    }
  };

  const getRegPathCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 300 });
      if (response.code !== 1000) {
        console.log("가입경로 가져오기 실패");
        return;
      }
      setRegPathCdList(response.resultList[0]);
    } catch (err) {
      console.log("가입경로 가져오기 실패");
    }
  };

  const getCorpKindCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 2000 });
      if (response.code !== 1000) {
        console.log("법인구분 가져오기 실패");
        return;
      }
      setCorpKindCdList(response.resultList[0]);
    } catch (err) {
      console.log("법인구분 가져오기 실패");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSignUpState((prevProps) => ({
      ...prevProps,
      [name]:
        needNumber.indexOf(name) === -1 ? value : value.replace(/[^0-9]/g, ""),
      check_id: name === "user_id" ? "N" : prevProps.check_id,
    }));
  };

  useEffect(() => {
    getRegionCd();
    getRegPathCd();
    getCorpKindCd();
  }, []);

  const checkId = async () => {
    if (signUpState.user_id === "" || !signUpState.user_id) {
      return toast.error("아이디를 입력해 주세요.");
    }
    if (!/^[a-zA-Z0-9]{6,16}$/.test(signUpState.user_id)) {
      return toast.error("아이디는 6자리이상 16자리이하로 가능합니다.");
    }

    try {
      const response = await postNumbaCheckId({ user_id: signUpState.user_id });
      if (response?.code === 1000) {
        setSignUpState({ ...signUpState, check_id: "Y" });
        return toast.success("사용할 수 있는 아이디 입니다.");
      } else {
        setSignUpState({ ...signUpState, check_id: "N" });
        return toast.error("이미 존재하는 아이디 입니다.");
      }
    } catch (e) {
      return toast.error(e);
    }
  };
  const sendSms = async () => {
    if (signUpState.user_id === "" || !signUpState.user_id) {
      return toast.error("아이디를 입력해 주세요.");
    }
    if (signUpState.phone === "" || !signUpState.phone) {
      return toast.error("휴대폰 번호를 입력해 주세요.");
    }
    if (!/^\d{10,11}$/.test(signUpState.phone)) {
      return toast.error("휴대폰번호를 확인해 주세요.");
    }
    try {
      const response = await postNumbaSendSms({
        user_id: signUpState.user_id,
        phone: signUpState.phone,
      });
      if (response?.code === 1000) {
        setIsSendSms(true);
        return toast.success("인증번호를 발송하였습니다.");
      } else {
        return toast.error("인증번호 전송에 실패했습니다.");
      }
    } catch (e) {
      return toast.error(e);
    }
  };

  const selCheckno = async () => {
    if (signUpState.selCheckno === "" || !signUpState.selCheckno) {
      return toast.error("인증번호를 입력해 주세요.");
    }
    try {
      const response = await postNumbaSelCheckno({
        user_id: signUpState.user_id,
        pass_no: Number(signUpState.selCheckno),
      });
      if (response.code === 1000) {
        toast.success("인증되었습니다.");
        setSignUpState({ ...signUpState, send_sms: "Y" });
      } else {
        return toast.error("인증번호를 확인해 주세요.");
      }
    } catch (e) {
      return toast.error(e);
    }
  };

  const validate = () => {
    if (signUpState.check_id === "N") {
      return "아이디 중복확인을 해 주세요.";
    }
    if (
      !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&^#])[A-Za-z\d@$!%*?&^#]{8,}$/.test(
        signUpState.passwd
      )
    ) {
      return "비밀번호는 영문, 숫자, 특수문자를 포함한 6-16자 이내여야 합니다.";
    }
    if (signUpState.passwd !== signUpState.passwd2) {
      return "비밀번호가 일치하지 않습니다.";
    }
    if (!signUpState.user_nm || signUpState.user_nm.length < 2) {
      return "이름을 확인해 주세요.";
    }
    if (!/^[가-힣]*$/.test(signUpState.user_nm)) {
      return "이름은 한글만 입력 가능합니다.";
    }
    if (signUpState.send_sms === "N") {
      return "휴대폰을 인증해 주세요.";
    }
    if (signUpState.corp_kind_cd === 0 || signUpState.corp_kind_cd === "") {
      return "회원구분을 선택해 주세요.";
    }
    if (signUpState.region_cd === 0 || signUpState.region_cd === "") {
      return "지역을 선택해 주세요.";
    }
    if (signUpState.reg_path_cd === 0 || signUpState.reg_path_cd === "") {
      return "가입경로를 선택해 주세요.";
    }
  };

  const handleSignUp = async () => {
    const msg = validate();

    if (msg) {
      return toast.error(msg);
    }

    try {
      const response = await postNumbaSignUp(signUpState);
      if (response.code === 1000) {
        toast.success("회원가입 성공");
        setTimeout(function () {
          navigate("/login");
        }, 1000);
      } else if (response.code === -25) {
        return toast.error("이미 가입된 휴대폰번호 입니다.");
      } else {
        toast.error("회원가입 실패");
        return;
      }
    } catch (e) {
      switch (e.code) {
        case -30:
          return toast.error("제목 또는 내용이 비어 있습니다.");
        case -25:
          return toast.error("이미 가입된 휴대폰번호 입니다.");
      }
    }
  };

  return (
    <>
      <S.CardWrapper_div>
        <Container className="py-5">
          <Row className="d-md-block d-none">
            <Col md={10} className="m-auto">
              <S.MainDiv_Heading>법인 회원가입</S.MainDiv_Heading>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="m-auto">
              <S.SignUpInput_Wrapper>
                <S.SignUpForm_Heading>
                  넘버로 계정정보를 입력하세요
                </S.SignUpForm_Heading>
                <div className="d-flex mt-3 justify-content-between">
                  <S.SignUp_Input
                    name={"user_id"}
                    placeholder="아이디 (영문, 숫자만 가능)"
                    className="me-10px"
                    onChange={handleInputChange}
                  />
                  {errors.user_id && (
                    <span style={{ color: "red" }}>{errors.user_id}</span>
                  )}
                  <GrayButton
                    minWidth="100px"
                    width="30.76%"
                    height="50px"
                    name="중복확인"
                    onClick={checkId}
                  ></GrayButton>
                  {errors.check_id && (
                    <span style={{ color: "red" }}>{errors.check_id}</span>
                  )}
                </div>
                <S.SignUp_Input
                  type={"password"}
                  name={"passwd"}
                  placeholder="비밀번호 (영문, 숫자, 특수문자를 포함한 6-16자)"
                  className="mt-3"
                  onChange={handleInputChange}
                />
                {errors.passwd && (
                  <span style={{ color: "red" }}>{errors.passwd}</span>
                )}
                <S.SignUp_Input
                  type={"password"}
                  name={"passwd2"}
                  placeholder="비밀번호 재입력"
                  className="mt-3"
                  onChange={handleInputChange}
                />
                {errors.passwd2 && (
                  <span style={{ color: "red" }}>{errors.passwd2}</span>
                )}
                <S.SignUp_Input
                  name={"user_nm"}
                  placeholder="이름"
                  className="mt-3"
                  onChange={handleInputChange}
                />
                {errors.user_nm && (
                  <span style={{ color: "red" }}>{errors.user_nm}</span>
                )}
                <div className="d-flex mt-3 justify-content-between">
                  <S.SignUp_Input
                    name={"phone"}
                    placeholder="휴대폰번호"
                    className={`${!isSendSms && "me-10px"}`}
                    onChange={handleInputChange}
                  />
                  {errors.phone && (
                    <span style={{ color: "red" }}>{errors.phone}</span>
                  )}
                  {!isSendSms && (
                    <GrayButton
                      minWidth="100px"
                      width="30.76%"
                      height="50px"
                      name="인증번호 받기"
                      onClick={sendSms}
                    ></GrayButton>
                  )}
                </div>
                <div className="d-flex mt-3 justify-content-between">
                  <S.SignUp_Input
                    name={"selCheckno"}
                    placeholder="인증번호"
                    className="me-10px"
                    onChange={handleInputChange}
                  />
                  <GrayButton
                    minWidth="100px"
                    width="30.76%"
                    height="50px"
                    name="인증하기"
                    onClick={selCheckno}
                  ></GrayButton>
                  {errors.send_sms && (
                    <span style={{ color: "red" }}>{errors.send_sms}</span>
                  )}
                </div>
                <S.SignUp_Select
                  name={"corp_kind_cd"}
                  placeholder="회원구분을 선택해 주세요"
                  className="form-select mt-3"
                  onChange={handleInputChange}
                >
                  <option value="">회원구분을 선택해 주세요</option>
                  {corpKindCdList.map((corpKind, index) => (
                    <option key={index} value={corpKind.cd_seq}>
                      {corpKind.cd_nm}
                    </option>
                  ))}
                </S.SignUp_Select>
                {errors.corp_kind_cd && (
                  <span style={{ color: "red" }}>{errors.corp_kind_cd}</span>
                )}
                <S.SignUp_Select
                  name={"region_cd"}
                  placeholder="회원구분을 선택해 주세요"
                  className="form-select mt-3"
                  onChange={handleInputChange}
                >
                  <option>지역을 선택하세요</option>
                  {regionCdList.map((region, index) => (
                    <option key={index} value={region.cd_seq}>
                      {region.cd_nm}
                    </option>
                  ))}
                </S.SignUp_Select>
                {errors.region_cd && (
                  <span style={{ color: "red" }}>{errors.region_cd}</span>
                )}
                <S.SignUp_Select
                  name={"reg_path_cd"}
                  placeholder="가입경로를 선택해 주세요"
                  className="form-select mt-3"
                  onChange={handleInputChange}
                >
                  <option value="">가입경로를 선택해 주세요</option>
                  {regPathCdList.map((regPath, index) => (
                    <option key={index} value={regPath.cd_seq}>
                      {regPath.cd_nm}
                    </option>
                  ))}
                </S.SignUp_Select>
                {errors.reg_path_cd && (
                  <span style={{ color: "red" }}>{errors.reg_path_cd}</span>
                )}
                <div className="mt-4">
                  <GrayButton
                    onClick={() => handleSignUp()}
                    minWidth="100px"
                    width="100%"
                    bg={"#FF9C00"}
                    name="가입하기"
                    fontSize={"20px"}
                  ></GrayButton>
                </div>
              </S.SignUpInput_Wrapper>
            </Col>
          </Row>
        </Container>
      </S.CardWrapper_div>
    </>
  );
}

export default Form2CorporationUser;
