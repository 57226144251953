import styled from "styled-components";
export const Card_Wrapper = styled.div`
  padding: 8px 0px;
  @media screen and (max-width: 1080px) {
    padding: 10px 0px;
  }
  @media screen and (max-width: 592px) {
    padding: 5px 0px 5px 0px;
  }
`;
export const Card = styled.div`
  height: fit-content;
  margin: 0 auto;
  background: #fff;
  border-radius: 18px;
  padding: 35px 40px 35px 40px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
  border: 0 solid purple;
  @media screen and (max-width: 1080px) {
    padding: 40px;
    height: fit-content;
    min-height: 143px;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
  @media screen and (max-width: 592px) {
    padding: 20px;
  }
`;
export const CardNew = styled.div`
  margin: 0 auto;
  background: #fff;
  border-radius: 18px;
  padding: 30px 26px 30px 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export const Card_left = styled.div`
  width: 55%;
  @media screen and (max-width: 1080px) {
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    padding-bottom: 15px;
  }
`;
export const Card_Right = styled.div`
  width: 45%;
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

export const Main_Card = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  padding-right: 60px;
  border-right: 1px solid #e1e1e1;
  align-items: center;
  @media screen and (max-width: 1024px) {
    border-right: none;
    border-bottom: 0 solid red;
    padding-right: 0;
    padding-bottom: 0;
    align-items: center;
  }
`;
export const CardLeft_Part = styled.div`
  display: flex;
  column-gap: 5px;  
  align-items: flex-end;
  min-width: 130px;
  // justify-content: flex-end;
  justify-content: flex-start;
  margin-bottom: 12px;
  // padding-left: 30px;
  @media screen and (max-width: 1080px) {
    column-gap: 5px;
    min-width: 120px;
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const CardLeft_Part_Car = styled.div`
  display: flex;
  flex-direction: column;
  // column-gap: 5px;
  align-items: flex-start;
  min-width: 130px;
  // justify-content: flex-end;
  margin-bottom: 12px;
  @media screen and (max-width: 1080px) {
    column-gap: 5px;
    min-width: 120px;
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const LeftTop_Part = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  @media screen and (max-width: 1080px) {
    justify-content: flex-start;
  }
  // @media screen and (max-width: 592px) {
  //   flex-direction: column;
  //   & > div:first-child{
  //     border:0 solid pink;
  //     margin-bottom: 10px;
  //   }
  // }
`;
export const LeftBottom_Part = styled.div`
  display: flex;
  column-gap: 5px;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
  @media screen and (max-width: 1200px) {
    // column-gap: 0;
    letter-spacing: -0.65px;
  }
`;

export const NumberPart = styled.h3`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: 35.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.5px;
  margin-bottom: 0;
  @media screen and (max-width: 1080px) {
    font-size: 24px;
    font-family: "NotoSansKR-ExtraBold";
    letter-spacing: -1.2px;
    line-height: 24px;
  }
`;
export const ValuePart = styled.span`
  color: rgba(131, 131, 131, 0.9);
  font-size: 16px;
  font-family: "NotoSansKR-Regular";
  line-height: normal;
  text-wrap: nowrap;
  white-space: nowrap;
  @media screen and (max-width: 1080px) {
    color: rgba(131, 131, 131, 0.9);
    font-family: "NotoSansKR-Regular";
    font-size: 14px;
    letter-spacing: -0.65px;
  }
  @media screen and (max-width: 592px) {
    font-size: 14px;
  }
`;

export const ValuePartIncar = styled.span`
  color: rgba(146, 82, 180, 0.9);
  font-size: 16px;
  font-family: "NotoSansKR-Regular";
  line-height: normal;
  text-wrap: nowrap;
  white-space: nowrap;
  @media screen and (max-width: 1080px) {
    color: rgba(146, 82, 180, 0.9);
    font-family: "NotoSansKR-Regular";
    font-size: 14px;
    letter-spacing: -0.65px;
  }
  @media screen and (max-width: 592px) {
    font-size: 14px;
  }
`;

export const CardRight_Part = styled.div`
  display: flex;
  width: 275px;
  flex-direction: column;
  border: 0 solid red;
  @media screen and (max-width: 1080px) {
    width: 66%;
    min-width: 143px;
  }
  &[data-center="true"] {
    flex-grow: 1;
    align-items: center;
    padding-left: 16px;
  }
`;
export const RightTop_Part = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.mb ? props.mb : "12px")};
  @media screen and (max-width: 1080px) {
    justify-content: flex-start;
  }
  // @media screen and (max-width: 592px) {
  //   flex-direction: column;
  //   & > div:first-child{
  //     border:0 solid pink;
  //     margin-bottom: 10px;
  //   }
  // }
`;
export const RightTop_PartNew = styled.div`
  display: flex;
  gap: 3.3rem;
  margin-bottom: ${(props) => (props.mb ? props.mb : "12px")};
  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    gap: 3rem;
  }
  @media screen and (max-width: 767px) {
    gap: 1rem;
  }
  @media screen and (max-width: 480px) {
    gap: 1rem;
  }
`;

export const RightTopPart_Value = styled.div`
  display: flex;
  column-gap: 20px;
  &:last-child {
    width: 90px;
    // width: fit-content;
  }
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  @media screen and (max-width: 1080px) {
    column-gap: 10px;
    margin-left: ${(props) => (props.ml ? "10px" : "0px")};
    &:first-child {
      width: 65%;
    }
    &:last-child {
      width: 35%;
    }
  }
  @media screen and (max-width: 592px) {
    width: 100%;
    column-gap: 10px;
  }
`;
export const RightTopPart_ValueNew = styled.div`
  display: flex;
  column-gap: 20px;
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  @media screen and (max-width: 767px) {
    column-gap: 10px;
    margin-left: ${(props) => (props.ml ? "10px" : "0px")};
  }
`;
export const RightBottom_Part = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
  @media screen and (max-width: 1200px) {
    column-gap: 0;
  }
`;

export const Value = styled.span`
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : "#838383E6")};
  text-wrap: nowrap;
  white-space: nowrap;
  @media screen and (max-width: 1080px) {
    font-size: 14px;
    letter-spacing: -0.65px;
    line-height: 12px;
    font-family: "NotoSansKR-Regular";
  }
  @media screen and (max-width: 592px) {
    font-size: 14px;
  }
  &.elipsis {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 125px;
  }
`;

export const Value2 = styled.span`
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : "#838383E6")};
  text-wrap: nowrap;
  white-space: nowrap;
  @media screen and (max-width: 1080px) {
    font-size: 13px;
    letter-spacing: -0.65px;
    line-height: 14px;
    font-family: "NotoSansKR-Regular";
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 125px;
  }
  @media screen and (max-width: 592px) {
    font-size: 13px;
  }
  &.elipsis {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 125px;
  }
`;

export const ValueBtm = styled.span`
  font-size: 15px;
  color: ${(props) => (props.color ? props.color : "#838383E6")};
  text-wrap: nowrap;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    letter-spacing: -0.65px;
    line-height: 14px;
    font-family: "NotoSansKR-Regular";
  }
  @media screen and (max-width: 592px) {
    font-size: 13px;
  }
`;

export const MainRight_Part = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  @media screen and (max-width: 1080px) {
    padding-top: 15px;
    padding-left: 0;
  }
`;

export const CardLeftPart_Top = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: flex-end;
  @media screen and (max-width: 1200px) {
    column-gap: 5px;
  }
`;
export const RightLeft_Part = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  min-width: 170px;
  padding-right: 10px;
  padding-top: 0;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    min-width: 160px;
  }
  @media screen and (max-width: 1080px) {
    min-width: 120px;
    row-gap: 0;
    height: 41px;
    justify-content: space-between;
  }
`;
export const RightRight_Part = styled.div`
  width: 100%;
  @media screen and (max-width: 1080px) {
    width: 66%;
  }
  @media screen and (max-width: 767px) {
    // max-width: 165px;
  }
  & .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3b87df !important;
    --bs-btn-border-color: #3b87df !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3b87df !important;
    --bs-btn-hover-border-color: #3b87df !important;
    --bs-btn-focus-shadow-rgb: 0 !important;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3b87df !important;
    --bs-btn-active-border-color: #3b87df !important;
    --bs-btn-active-shadow: inset 0 !important;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3b87df !important;
    --bs-btn-disabled-border-color: #3b87df !important;
    width: 100% !important;
    height: 60px !important;
    font-size: 19.997px !important;
    font-weight: 700 !important;
  }

  @media screen and (max-width: 767px) {
    & .btn-primary {
      width: 100% !important;
      height: 40px !important;
      font-size: 15px !important;
      font-weight: 700 !important;
    }
  }
`;

export const SubCard = styled.div`
  // padding: 25px 0px;
  // border-bottom: 1px solid
  ${(props) => (props.border ? props.border : "transparent")};
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  &:not(:first-child) {
    padding: 10px 40px 10px 40px;
    border-bottom: 1px solid #e1e1e1;
  }
  @media screen and (max-width: 592px) {
    padding: 10px 20px !important;
  }
`;

export const BreadCrumb = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const BreadCrumb_Left = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: flex-end;
  @media screen and (max-width: 592px) {
    column-gap: 5px;
  }
`;

export const BreadCrumb_Right = styled.div`
  .btn-light {
    --bs-btn-color: #838383 !important;
    --bs-btn-bg: transparent !important;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #838383 !important;
    --bs-btn-hover-bg: transparent !important;
    --bs-btn-hover-border-color: transparent !important;
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: #838383 !important;
    --bs-btn-active-bg: transparent !important;
    --bs-btn-active-border-color: transparent !important;
    --bs-btn-active-shadow: transparent !important;
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: transparent !important;
    font-size: 16px !important;
    @media screen and (max-width: 592px) {
      font-size: 14px !important;
      font-family: "NotoSansKR-Medium";
      letter-spacing: -0.6px;
      padding-right: 5px;
    }
  }
`;
export const BreadCrumb_value = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  opacity: 0.9;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.8px;
  color: ${(props) =>
    props.color ? props.color : "rgba(131, 131, 131, 0.9);"};
  @media screen and (max-width: 592px) {
    font-size: ${(props) => (props.fontSizeSM ? props.fontSizeSM : "14px")};
    letter-spacing: -0.8px;
  }
`;

export const CardButton_A = styled.div`
  min-width: 38%;
  height: 100%;

  @media screen and (max-width: 767px) {
    min-width: 0;
  }
`;
