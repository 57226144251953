import React, { useEffect, useState } from "react";
import * as A from "../../css/SignUp/Form1CorporationUser";
import { Button, Col, Container, Row } from "react-bootstrap";
import GrayButton from "../../componant/buttons/buttonGray";
import WhiteButton from "../../componant/buttons/buttonWhite";
import ModalPopup from "../../componant/modal/modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "wc-toast";
import { businessAxios } from "../../api/apiCore";

function Form1CorporationUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [regObj, setRegObj] = useState({
    user_type_cd: location?.state?.user_type_cd,
    agree_yn: location?.state?.agree_yn,
    privacy_yn: location?.state?.privacy_yn,
    alarm_yn: location?.state?.alarm_yn,
    ads_yn: location?.state?.ads_yn,
    corp_nm: "",
    ceo_nm: "",
    brno: "",
  });
  const [corpInfo, setCorpInfo] = useState({
    b_no: "",
    b_nm: "",
    p_nm: "",
    start_dt: "",
  });

  // {
  //     b_no: '7688702406',
  //     start_dt: '20220627',
  //     p_nm: '최종욱',
  //     b_nm: '주식회사 마크애니체인버스'
  // },

  const corpCheckReq = async () => {
    await businessAxios
      .post(
        "",
        {
          businesses: [corpInfo],
        },
        {
          headers: {
            Accept: "application/json;charset=utf-8",
            Authorization:
              "Infuser zDKnYdVLWkFMvTOpWnNxkngD1aBrSiCe2wMM6XLTDeeMYX+SDIAw9tMW3wuS7MbIjqhv6+inohw64RLLRh9ZiQ==",
          },
        },
      )
      .then((res) => {
        if (res.data.data[0].valid === "02") {
          toast.error("확인할 수 없습니다.");
          return;
        }
        const newRegObj = {
          ...regObj,
          corp_nm: corpInfo.b_nm,
          brno: corpInfo.b_no,
          ceo_nm: corpInfo.p_nm,
        };
        navigate("/signup-form2corporationuser", { state: newRegObj });
      })
      .catch((error) => {
        toast.error("법인정보를 확인해 주세요.");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCorpInfo((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  return (
    <>
      <A.CardWrapper_div>
        <Container className="py-5">
          <Row className="d-md-block d-none">
            <Col md={10} className="m-auto">
              <A.MainDiv_Heading>법인 회원가입</A.MainDiv_Heading>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="m-auto">
              <A.SignUpInput_Wrapper>
                <A.SignUpForm_Heading>
                  법인정보를 입력하세요
                </A.SignUpForm_Heading>
                <A.SignUp_Input
                  placeholder="법인명을 입력하세요 ex) 주식회사 넘버로"
                  name={"b_nm"}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                <A.SignUp_Input
                  placeholder="사업자등록번호를 입력하세요 ('-' 없이 입력)"
                  name={"b_no"}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                <A.SignUp_Input
                  placeholder="대표자명을 입력하세요"
                  name={"p_nm"}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                <A.SignUp_Input
                  placeholder="개업일자 ex)20240101"
                  name={"start_dt"}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                <div className="mt-4">
                  {/*<Link to={`/signup-form2corporationuser`} state={regObj}></Link>*/}
                  <GrayButton
                    fontSize={"19.99px"}
                    width="100%"
                    name="다음"
                    bg={"#FF9C00"}
                    onClick={corpCheckReq}
                  ></GrayButton>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <A.SignUpBottomTxt_Corp className="me-2">
                    법인회원 실명확인이 안되시나요?
                  </A.SignUpBottomTxt_Corp>
                  <WhiteButton
                    onClick={() => setModalShow(true)}
                    width="152px"
                    bg="#fff"
                    height="40px"
                    name="법인 실명확인 안내"
                  ></WhiteButton>
                </div>
              </A.SignUpInput_Wrapper>
            </Col>
          </Row>
        </Container>
        <ModalPopup show={modalShow} onHide={() => setModalShow(false)} />
      </A.CardWrapper_div>
    </>
  );
}

export default Form1CorporationUser;
