import styled from "styled-components";

export const BuyingBanner_Wrapper = styled.div`
background: ${props => props.bg ? props.bg : "linear-gradient(270deg, #FFF2F7 0%, #FFD6E1 47.88%, #FFF2F7 100.79%)"};
`

export const Banner_Heading = styled.h3`
color: ${props => props.color ? props.color : "#fff"};
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 10px;
letter-spacing: 1.6px;
`

export const BannerContent_para = styled.p`
color: ${props => props.color ? props.color : "#fff"};
font-size: 16px;
font-style: normal;
  font-family: 'NotoSansKR-Medium';
line-height: normal;
margin-bottom: 0px;
`
export const BtnTxt_Absolute = styled.p`
position: absolute;
    top: 56%;
    left: 32%;
    color: #FFF;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
`
export const Banner_Img = styled.img`
margin-left: -78px;
`
