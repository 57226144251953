import Carousel from 'react-bootstrap/Carousel';
const MainCarousel = () => {
    return (
        <>
            <Carousel id="carousel_main">
                <Carousel.Item className="carousel-1">
                    <div className="carousel-caption-wrapper-1">
                        <h3>국내 최초 화물넘버<br />
                            안심거래 플랫폼 <span>넘버로</span></h3>
                        <h4>화물차 영업용 넘버, 주선면허<br />
                            사는 것 부터 파는 것 까지</h4>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="carousel-2">
                    <div className="carousel-caption-wrapper-2">
                        <h4>모든 번호판이 안심되게 거래 될 수 있도록</h4>
                        <h3>안전한 번호판 직거래 플랫폼 <span>넘버로</span></h3>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="carousel-3">
                    <div className="carousel-caption-wrapper-3">
                        <h5>언제 어디서나 쉽고 빠른</h5>
                        <h4>내 손안의 플랫폼 <span>넘버로</span></h4>
                        <h3>PC에서도 모바일에서도<br />
                            어디서든 빠른 거래가 가능한</h3>
                    </div>
                </Carousel.Item>
            </Carousel>
        </>
    );
}
export default MainCarousel;