import styled from "styled-components";

export const Modal_Heading = styled.h4`
color: #000;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
`

export const Modal_Para = styled.p`
color: #666;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 0px;
`

export const Modal_Ul = styled.ul`
padding-left: 1.4rem;
`
export const Modal_Li = styled.li`
color: #666;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 13px;
`
export const TableModal_HeadingThree = styled.h3`
color: #000;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 28px;
`
export const TablePopup_Input = styled.input`
border-radius: 5px;
border: 2px solid #E1E1E1;
background: #FFF;
width: 390px;
padding: 8px;
height: 51px;
&:focus-visible {
    outline: none;
}
`
export const TablePopupSearch_Btn = styled.div`
border-radius: 6px;
background: #FF9C00;
width: 65px;
height: 51px;
display: flex;
justify-content: center;
align-items: center;
margin-left: 10px;
cursor: pointer;
`
export const TableModal_HeadingFour = styled.h4`
color: #000;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`
export const BreadCrumb = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 1px solid #e1e1e1;
padding-bottom: 8px;
`
export const BreadCrumb_Left = styled.div`
display: flex;
column-gap: 10px;
align-items: center;
`

export const BreadCrumb_value = styled.span`
color: ${props => props.color ? props.color : "#838383"};
font-size: ${props => props.color ? "20px" : "14px"};

`

export const BreadCrumb_Right = styled.div`
color:red;
    .btn-light {
    --bs-btn-color: #838383 !important;
    --bs-btn-bg: transparent !important;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color:  #838383 !important;
    --bs-btn-hover-bg: transparent !important;
    --bs-btn-hover-border-color: transparent !important;
    --bs-btn-focus-shadow-rgb: 211,212,213;
    --bs-btn-active-color: #838383 !important;
    --bs-btn-active-bg: transparent !important;
    --bs-btn-active-border-color: transparent !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: transparent !important;
}
`
export const TableContent_BottomTxt = styled.p`
color: #000;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
`
export const PopupBtn_Right = styled.div`
background-color: #FF9C00;
width: 55px;
height: 41px;
border-radius: 6px;
color: #FFF;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
`
export const PopupBtn_Left = styled.div`
width: 40px;
height: 27px;
background-color: transparent;
border: 1px solid #e1e1e1;
border-radius: 6px;
color: #838383;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
`
export const PopupBtnTxt_Right = styled.p`
color: #838383;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: 10px;
`
export const SectionBorder_bottom = styled.div`
border-bottom: 1px solid #e1e1e1;
padding-bottom: 8px;
`
export const Bottom_Number = styled.p`
margin-bottom: 0px;
`