import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "NotoSansKR-Regular";
    /* font-family: 'Noto Sans', sans-serif !important; */
  }

  &::-webkit-scrollbar {
    display: none;
  }

.scroll-wrapper {
    max-height: 600px ;
    overflow: scroll;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: 767.98px) {
        overflow: visible;
        max-height: none;
    }
  }

  .scroll-wrapper-info {
  max-height: 1000px ;
    overflow: scroll;
  }

  .link {
  text-decoration: none;
  font-size: 16px;
  font-family: 'NotoSansKR-bold';
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: .3px;
  text-align: left;
  color: var(--white);
  position: relative;
}

.margin-left-auto {
  margin-left: auto !important;
}


.mobile-bottom-none {
  border-bottom: none !important;
}

.attachment-icon {
  width: 30px;
  height: 30px;

}

.qna_btn {
  margin: 0;
  padding: 0;
  border: none;
  /* font-family: 'Arial Bold'; */
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.7px;
  text-align: left;
  color: rgba(136, 136, 136, 0.9);
  background: #f5f5f5;
}

.delete-btn {

  width: 10px;
  height: 10px;
}

.h-500 {
  height: 500px;
}

.padding-none {
  padding: 0 !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.ms-35 {
  margin-left:35px !important;
}

.mobile-interest-number-font-size {
  @media (max-width:500px) {
    font-size: 13px !important;
  }
}
`;
