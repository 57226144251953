import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Row,
} from "react-bootstrap";
import * as A from "../../../../css/Selling/subTabContent.style";
import * as B from "../../../../css/buying/buying";
import CardButton from "../../../../componant/buttons/cardButton";
import RoundButton from "../../../../componant/buttons/buttonRound";
import { Link, useNavigate } from "react-router-dom";
import MobileFooter from "../../../../componant/mobileFooter";
import InsInterest from "../../../../pages/MyPage/number-of-interest/InsInterest";
import React, { useEffect, useState } from "react";
import { postNumbaSellList } from "../../../../api/sell";
import { postNumbaSelUserInfo } from "../../../../api/auth";
import { toast } from "wc-toast";
import { postNumbaInterestList } from "../../../../api/mypage";
import useInfiniteScroll from "../../../../utils/hooks/infinite-scroll/useInfiniteScroll";
import callMainData from "../../../../utils/hooks/callMainData";
import { useAtom } from "jotai";
import { header_mainData } from "../../../../data/atom/mypage/mypage_atom";
import moment from "moment/moment";
import { deInterest } from "../../../MyPage/number-of-interest/deInterest";

const PersonalSelling = ({ activeTab, subActiveTab }) => {
  const navigate = useNavigate();
  const [sellList, setSellList] = useState([]);
  const [option, setOption] = useState({
    order: 2701,
    carWeight: 2301,
    sellListCnt: 10,
    ecarYn: null,
  });
  const [selectedOption, setSelectedOption] = useState(2701);
  const [interestList, setInterestList] = useState([]);
  const [totCnt, setTotCnt] = useState(0);
  const [mainData, setMainList] = useAtom(header_mainData);

  const { setBottom } = useInfiniteScroll(() => {
    setOption((prev) => {
      getSellList({
        order: prev.order,
        carWeight: prev.carWeight,
        ecarYn: prev.ecarYn,
        rowCnt: prev.sellListCnt + 10,
      }).then((res) => {
        setSellList(res.resultList);
        console.log(res.resultList);
      });
      return { ...prev, sellListCnt: prev.sellListCnt + 10 };
    });
  });

  async function getSellList(data) {
    console.log("data", data);
    try {
      const response = await postNumbaSellList({
        startIdx: 0,
        rowCnt: data.rowCnt,
        order_cd: data.order,
        car_weight_cd: data.carWeight,
        ecar_yn: data.ecarYn,
        deal_category_cd: 2101,
      });
      if (response.code !== 1000) {
        console.log("팝니다 목록 가져오기 실패");
      }
      return response;
    } catch (error) {
      console.log("팝니다 목록 가져오기 서버 오류");
    }
  }

  useEffect(() => {
    console.log('-------',subActiveTab);
    if (subActiveTab === "first") {
      setOption({ ...option, order: 2701, carWeight: 2301, sellListCnt: 10, ecarYn: "N" });
    }
    if (subActiveTab === "second") {
      setOption({ ...option, order: 2701, carWeight: 2302, sellListCnt: 10, ecarYn: "N" });
    }
    if (subActiveTab === "third") {
      setOption({ ...option, order: 2701, carWeight: 2303, sellListCnt: 10, ecarYn: "N" });
    }
    if (subActiveTab === "fourth") {
      setOption({ ...option, order: 2701, carWeight: 2301, sellListCnt: 10, ecarYn: "Y" });
    }
  }, [subActiveTab]);

  useEffect(() => {
    getSellList({
      order: option.order,
      carWeight: option.carWeight,
      rowCnt: option.sellListCnt,
      ecarYn: option.ecarYn,
    })
      .then((res) => {
        console.log(res.resultList);
        setSellList(res.resultList);
        setTotCnt(res.totCnt);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [option]);

  const handleItemClick = async (order) => {
    setOption({ ...option, order: order });
    setSelectedOption(order);
  };

  const sellRequest = (data) => {
    // if (!localStorage.getItem("access_token")) {
    //   return toast.error("로그인 후 이용가능 합니다.", {
    //     duration: 500,
    //   });
    // }
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    navigate("/selling-request1", {
      state: { data: { ...data, type: 2101 }, isEdit: false },
    });
  };

  const getInterestList = async () => {
    if (!localStorage.getItem("access_token")) {
      return;
    }
    const data = await callMainData();
    setMainList(data);

    try {
      const response = await postNumbaInterestList({
        startIdx: -1,
      });
      if (response.code !== 1000) {
        console.log("관심목록 불러오기 실패");
        return;
      }
      setInterestList(response.resultList);
    } catch (err) {
      console.log("관심목록 불러오기 서버 오류");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      getInterestList();
    }
  }, []);

  const buyButton = (data) => {
    const process_cd = data?.process_cd;
    switch (+process_cd) {
      case 3005:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#adadad"}
            width="100%"
            height="60px"
            name="거래신청중"
            disabled={true}
          ></CardButton>
        );
      case 3006:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#7F7F7F"}
            width="100%"
            height="60px"
            name="거래승인"
            disabled={true}
          ></CardButton>
        );
      case 3007:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#7F7F7F"}
            width="100%"
            height="60px"
            name="거래진행중"
            disabled={true}
          ></CardButton>
        );
      case 3008:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#BFBFBF"}
            width="100%"
            height="60px"
            name="거래완료"
            disabled={true}
          ></CardButton>
        );
      default:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#26BBE3"}
            width="100%"
            height="60px"
            name="구매신청"
            onClick={() => {
              sellRequest(data);
            }}
          ></CardButton>
        );
    }
  };

  // 등록하기
  const goRequest = () => {
    // if (!localStorage.getItem("access_token")) {
    //   return toast.error("로그인 후 이용가능 합니다.", {
    //     duration: 500,
    //   });
    // }
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    // navigate("/selling-request", {state: {main: 1}});  //activeTab = 1
    postNumbaSelUserInfo({}).then((res) => {
      if (res?.brno_auth_flag === "Y") {
        return toast.error("개인회원만 등록가능합니다.", {
          duration: 500,
        });
      } else {
        navigate("/selling-request", { state: { main: 1, sub: subActiveTab } });
      }
    });
  };

  // console.log("sellList", sellList);
  // console.log("interestList", interestList);

  if (subActiveTab === "first") {
    return (
      <>
        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg="linear-gradient(270deg, #E5F1FF 0%, #D6E9FF 52%, #E5F1FF 100%);"
            className="py-3 py-md-4 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#2667B6">
                          넘버로에 양도 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#4C93E8">
                          넘버로에 양도 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#4C93E8">
                          빠르고 안전하게 양수자를 매칭 시켜서 양도를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/car.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            bg="linear-gradient(0deg, #3B85DE 1.33%, #26BBE3 101.67%);"
                            width="150px"
                            height="150px"
                            name="팝니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>

          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value color="#4E92E1" fontSizeSM={"16px"}>
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {sellList?.length > 0 ? (
                    <>
                      {sellList?.map((data) => (
                        <A.Card_Wrapper key={data.id}>
                          <A.Card>
                            <A.Card_left>
                              <A.Main_Card>
                                <A.CardLeft_Part_Car>
                                  <A.LeftTop_Part>
                                    <A.ValuePartIncar>
                                      {data.in_car_yn === "Y" && "차량포함"}
                                    </A.ValuePartIncar>
                                  </A.LeftTop_Part>
                                  <A.LeftBottom_Part>
                                    <A.NumberPart color="#4E92E1">
                                      {data.price
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </A.NumberPart>
                                    <A.ValuePart>만원</A.ValuePart>
                                  </A.LeftBottom_Part>
                                </A.CardLeft_Part_Car>
                                <A.CardRight_Part className="e_div">
                                  <A.RightTop_Part mb={"0"}>
                                    <A.RightTopPart_Value>
                                      <A.Value>톤수</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.car_weight} 톤
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value>지역</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.region_nm}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightTop_Part>
                                  <A.RightBottom_Part>
                                    <A.RightTopPart_Value>
                                      <A.Value>연식</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {moment(data?.car_yyyymm).format(
                                          "YYYY.MM"
                                        )}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value> </A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {" "}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightBottom_Part>
                                </A.CardRight_Part>
                              </A.Main_Card>
                            </A.Card_left>
                            <A.Card_Right>
                              <A.MainRight_Part>
                                <A.RightLeft_Part>
                                  <A.CardLeftPart_Top>
                                    {interestList?.some(
                                      (item) => item.main_seq === data.sell_seq
                                    ) ? (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest_click.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          deInterest({
                                            code: "sell",
                                            interest_seq: interestList.find(
                                              (el2) =>
                                                el2.main_seq === data.sell_seq
                                            ).interest_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          InsInterest({
                                            code: "sell",
                                            sell_seq: data.sell_seq,
                                            target_seq: data.target_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    )}
                                    <A.Value color="rgba(131, 131, 131, 0.9)">
                                      관심등록
                                    </A.Value>
                                  </A.CardLeftPart_Top>
                                  <A.CardLeftPart_Top>
                                    <A.ValueBtm
                                      color={"rgba(131, 131, 131, 0.9)"}
                                    >
                                      등록일
                                    </A.ValueBtm>
                                    <A.ValueBtm color={"rgba(68, 68, 68, 0.9)"}>
                                      {data.reg_dt}
                                    </A.ValueBtm>
                                  </A.CardLeftPart_Top>
                                </A.RightLeft_Part>
                                <A.RightRight_Part>
                                  {buyButton(data)}
                                </A.RightRight_Part>
                              </A.MainRight_Part>
                            </A.Card_Right>
                          </A.Card>
                        </A.Card_Wrapper>
                      ))}
                      {sellList?.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab} />
        </div>
      </>
    );
  } else if (subActiveTab === "second") {
    return (
      <>
        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg="linear-gradient(270deg, #E5F1FF 0%, #D6E9FF 52%, #E5F1FF 100%);"
            className="py-3 py-md-4 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#2667B6">
                          넘버로에 양도 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#4C93E8">
                          넘버로에 양도 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#4C93E8">
                          빠르고 안전하게 양수자를 매칭 시켜서 양도를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/car.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            bg="linear-gradient(0deg, #3B85DE 1.33%, #26BBE3 101.67%);"
                            width="150px"
                            height="150px"
                            name="팝니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>

          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value color="#4E92E1" fontSizeSM={"16px"}>
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            split
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {sellList.length > 0 ? (
                    <>
                      {sellList.map((data) => (
                        <A.Card_Wrapper key={data.id}>
                          <A.Card>
                            <A.Card_left>
                              <A.Main_Card>
                                <A.CardLeft_Part_Car>
                                  <A.LeftTop_Part>
                                    <A.ValuePartIncar>
                                      {data.in_car_yn === "Y" && "차량포함"}
                                    </A.ValuePartIncar>
                                  </A.LeftTop_Part>
                                  <A.LeftBottom_Part>
                                    <A.NumberPart color="#4E92E1">
                                      {data.price
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </A.NumberPart>
                                    <A.ValuePart>만원</A.ValuePart>
                                  </A.LeftBottom_Part>
                                </A.CardLeft_Part_Car>
                                <A.CardRight_Part className="e_div">
                                  <A.RightTop_Part mb={"0"}>
                                    <A.RightTopPart_Value>
                                      <A.Value>톤수</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.car_weight} 톤
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value>지역</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.region_nm}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightTop_Part>
                                  <A.RightBottom_Part>
                                    <A.RightTopPart_Value>
                                      <A.Value>연식</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {moment(data?.car_yyyymm).format(
                                          "YYYY.MM"
                                        )}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value> </A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {" "}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightBottom_Part>
                                </A.CardRight_Part>
                              </A.Main_Card>
                            </A.Card_left>
                            <A.Card_Right>
                              <A.MainRight_Part>
                                <A.RightLeft_Part>
                                  <A.CardLeftPart_Top>
                                    {interestList.some(
                                      (item) => item.main_seq === data.sell_seq
                                    ) ? (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest_click.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          deInterest({
                                            code: "sell",
                                            interest_seq: interestList.find(
                                              (el2) =>
                                                el2.main_seq === data.sell_seq
                                            ).interest_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          InsInterest({
                                            code: "sell",
                                            sell_seq: data.sell_seq,
                                            target_seq: data.target_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    )}
                                    <A.Value color="rgba(131, 131, 131, 0.9)">
                                      관심등록
                                    </A.Value>
                                  </A.CardLeftPart_Top>
                                  <A.CardLeftPart_Top>
                                    <A.Value2>등록일</A.Value2>
                                    <A.Value2 color={"rgba(68, 68, 68, 0.9)"}>
                                      {" "}
                                      {data.reg_dt}
                                    </A.Value2>
                                  </A.CardLeftPart_Top>
                                </A.RightLeft_Part>
                                <A.RightRight_Part>
                                  {buyButton(data)}
                                </A.RightRight_Part>
                              </A.MainRight_Part>
                            </A.Card_Right>
                          </A.Card>
                        </A.Card_Wrapper>
                      ))}
                      {sellList.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab} />
        </div>
      </>
    );
  } else if (subActiveTab === "third") {
    return (
      <>
        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg="linear-gradient(270deg, #E5F1FF 0%, #D6E9FF 52%, #E5F1FF 100%);"
            className="py-3 py-md-4 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#2667B6">
                          넘버로에 양도 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#4C93E8">
                          넘버로에 양도 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#4C93E8">
                          빠르고 안전하게 양수자를 매칭 시켜서 양도를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/car.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            bg="linear-gradient(0deg, #3B85DE 1.33%, #26BBE3 101.67%);"
                            width="150px"
                            height="150px"
                            name="팝니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>

          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value color="#4E92E1" fontSizeSM={"16px"}>
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            split
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {sellList.length > 0 ? (
                    <>
                      {sellList.map((data) => (
                        <A.Card_Wrapper key={data.id}>
                          <A.Card>
                            <A.Card_left>
                              <A.Main_Card>
                                <A.CardLeft_Part_Car>
                                  <A.LeftTop_Part>
                                    <A.ValuePartIncar>
                                      {data.in_car_yn === "Y" && "차량포함"}
                                    </A.ValuePartIncar>
                                  </A.LeftTop_Part>
                                  <A.LeftBottom_Part>
                                    <A.NumberPart color="#4E92E1">
                                      {data.price
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </A.NumberPart>
                                    <A.ValuePart>만원</A.ValuePart>
                                  </A.LeftBottom_Part>
                                </A.CardLeft_Part_Car>
                                <A.CardRight_Part className="e_div">
                                  <A.RightTop_Part mb={"0"}>
                                    <A.RightTopPart_Value>
                                      <A.Value>톤수</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.car_weight} 톤
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value>지역</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.region_nm}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightTop_Part>
                                  <A.RightBottom_Part>
                                    <A.RightTopPart_Value>
                                      <A.Value>연식</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {moment(data?.car_yyyymm).format(
                                          "YYYY.MM"
                                        )}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value> </A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {" "}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightBottom_Part>
                                </A.CardRight_Part>
                              </A.Main_Card>
                            </A.Card_left>
                            <A.Card_Right>
                              <A.MainRight_Part>
                                <A.RightLeft_Part>
                                  <A.CardLeftPart_Top>
                                    {interestList.some(
                                      (item) => item.main_seq === data.sell_seq
                                    ) ? (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest_click.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          deInterest({
                                            code: "sell",
                                            interest_seq: interestList.find(
                                              (el2) =>
                                                el2.main_seq === data.sell_seq
                                            ).interest_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          InsInterest({
                                            code: "sell",
                                            sell_seq: data.sell_seq,
                                            target_seq: data.target_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    )}
                                    <A.Value color="rgba(131, 131, 131, 0.9)">
                                      관심등록
                                    </A.Value>
                                  </A.CardLeftPart_Top>
                                  <A.CardLeftPart_Top>
                                    <A.Value2>등록일</A.Value2>
                                    <A.Value2 color={"rgba(68, 68, 68, 0.9)"}>
                                      {data.reg_dt}
                                    </A.Value2>
                                  </A.CardLeftPart_Top>
                                </A.RightLeft_Part>
                                <A.RightRight_Part>
                                  {buyButton(data)}
                                </A.RightRight_Part>
                              </A.MainRight_Part>
                            </A.Card_Right>
                          </A.Card>
                        </A.Card_Wrapper>
                      ))}
                      {sellList.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab} />
        </div>
      </>
    );
  } else if (subActiveTab === "fourth") {
    return (
      <>
        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg="linear-gradient(270deg, #E5F1FF 0%, #D6E9FF 52%, #E5F1FF 100%);"
            className="py-3 py-md-4 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#2667B6">
                          넘버로에 양도 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#4C93E8">
                          넘버로에 양도 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#4C93E8">
                          빠르고 안전하게 양수자를 매칭 시켜서 양도를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/car.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            bg="linear-gradient(0deg, #3B85DE 1.33%, #26BBE3 101.67%);"
                            width="150px"
                            height="150px"
                            name="팝니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>

          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value color="#4E92E1" fontSizeSM={"16px"}>
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {sellList?.length > 0 ? (
                    <>
                      {sellList?.map((data) => (
                        <A.Card_Wrapper key={data.id}>
                          <A.Card>
                            <A.Card_left>
                              <A.Main_Card>
                                <A.CardLeft_Part_Car>
                                  <A.LeftTop_Part>
                                    <A.ValuePartIncar>
                                      {data.in_car_yn === "Y" && "차량포함"}
                                    </A.ValuePartIncar>
                                  </A.LeftTop_Part>
                                  <A.LeftBottom_Part>
                                    <A.NumberPart color="#4E92E1">
                                      {data.price
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </A.NumberPart>
                                    <A.ValuePart>만원</A.ValuePart>
                                  </A.LeftBottom_Part>
                                </A.CardLeft_Part_Car>
                                <A.CardRight_Part className="e_div">
                                  <A.RightTop_Part mb={"0"}>
                                    <A.RightTopPart_Value>
                                      <A.Value>톤수</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.car_weight} 톤
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value>지역</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.region_nm}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightTop_Part>
                                  <A.RightBottom_Part>
                                    <A.RightTopPart_Value>
                                      <A.Value>연식</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {moment(data?.car_yyyymm).format(
                                          "YYYY.MM"
                                        )}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    <A.RightTopPart_Value>
                                      <A.Value> </A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {" "}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                  </A.RightBottom_Part>
                                </A.CardRight_Part>
                              </A.Main_Card>
                            </A.Card_left>
                            <A.Card_Right>
                              <A.MainRight_Part>
                                <A.RightLeft_Part>
                                  <A.CardLeftPart_Top>
                                    {interestList?.some(
                                      (item) => item.main_seq === data.sell_seq
                                    ) ? (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest_click.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          deInterest({
                                            code: "sell",
                                            interest_seq: interestList.find(
                                              (el2) =>
                                                el2.main_seq === data.sell_seq
                                            ).interest_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          InsInterest({
                                            code: "sell",
                                            sell_seq: data.sell_seq,
                                            target_seq: data.target_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    )}
                                    <A.Value color="rgba(131, 131, 131, 0.9)">
                                      관심등록
                                    </A.Value>
                                  </A.CardLeftPart_Top>
                                  <A.CardLeftPart_Top>
                                    <A.ValueBtm
                                      color={"rgba(131, 131, 131, 0.9)"}
                                    >
                                      등록일
                                    </A.ValueBtm>
                                    <A.ValueBtm color={"rgba(68, 68, 68, 0.9)"}>
                                      {data.reg_dt}
                                    </A.ValueBtm>
                                  </A.CardLeftPart_Top>
                                </A.RightLeft_Part>
                                <A.RightRight_Part>
                                  {buyButton(data)}
                                </A.RightRight_Part>
                              </A.MainRight_Part>
                            </A.Card_Right>
                          </A.Card>
                        </A.Card_Wrapper>
                      ))}
                      {sellList?.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab} />
        </div>
      </>
    );
  }
  return null;
};

export default PersonalSelling;
