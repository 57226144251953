/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import MobileFooter from "../../../componant/mobileFooter";
import { postGetReportFile } from "../../../api/sale";
import { headerText } from "../../../App";
import { useAtom } from "jotai";
import Button from 'react-bootstrap/Button';
import { scrollToTop } from "../../../utils/hooks/scrollToTop";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function ReportPdfView() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const location = useLocation();
  const { data } = location.state || {};
  const navigate = useNavigate();
 
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);


  useEffect(() => {    
    console.log("넘어오는 data", data); // main_seq, deal_Str
    const fetchFiles = async () => {
      const response = await postGetReportFile(data);
      console.log("pdf가져오기 response", response);

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition.match(/filename\*=UTF-8''(.+)/)[1];
      const decodedFilename = decodeURIComponent(filename);

      setPdfBlob(pdfBlob);
      setPdfUrl(pdfUrl);
      setPdfName(decodedFilename);
    };
    fetchFiles();
  }, []);

  useEffect(() => {
    setHeaderTextAtom("신고서 발급조회");
    scrollToTop();
  }, []);

  const handleFileDownload = () => {
    // app에서 파일다운로드 될 수 있도록 추가
    const base64Handler = (handler) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader.result;
        handler(pdfName, 'application/pdf', base64data);
      };
    };
  
    if ((typeof CHAINVERSE) === 'object' && (typeof CHAINVERSE.getBase64FromBlobData) === 'function') {
      base64Handler(CHAINVERSE.getBase64FromBlobData);      
    } else if ((typeof CHAINVERSE_IOS) === 'object' && (typeof CHAINVERSE_IOS.getBase64FromBlobData) === 'function'){
      base64Handler(CHAINVERSE_IOS.getBase64FromBlobData);      
    } else {      
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = pdfName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(pdfUrl);
    }
  }

  return (
    <>
      <div>
        {/* desktop */}
        <div className="content-container mypage_wrapper d-md-block d-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>신고서 발급조회</h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container pb-md-0 pb-5 mb-md-0 mb-5 top-border"
            style={{
              // height: "650px",
              overflow: "scroll",
            }}
          >
            <div className="d-flex justify-content-center mb-5">
              {/* <div
              style={{
                background: "#e4e4e4",
                display: "flex",
                justifyContent: "center",
                minHeight: "300px",
                height: "auto",
                width: "50%",
                marginBottom: "20px",
                overflow: "auto",
              }}
            > */}
              {pdfUrl ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfUrl}
                    // plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              ) : (
                <p>Loading PDF...</p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center report-d-button">
            <Button
              variant="outline"
              style={{
                borderColor: "#ff9e2f",
                color: "#ff9e2f",
                marginRight: "20px",
              }}
              onClick={() => navigate(-1)}
            >
              목록으로
            </Button>
            <Button
              variant="outline"
              style={{
                backgroundColor: "#ff9e2f",
                borderColor: "#ff9e2f",
                color: "white",
              }}
              onClick={handleFileDownload}
            >
              PDF 다운로드
            </Button>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="container d-md-none d-block mt-5">
        <div className="report-section">
          {/* <div
              style={{
                background: "#e4e4e4",
                display: "flex",
                justifyContent: "center",
                minHeight: "300px",
                height: "auto",
                width: "50%",
                marginBottom: "20px",
                overflow: "auto",
              }}
            > */}
          {pdfUrl ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfUrl}
                // plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          ) : (
            <p>Loading PDF...</p>
          )}
        </div>
        <div className="d-flex justify-content-center report-m-button">
          <Button
            variant="outline"
            style={{
              borderColor: "#ff9e2f",
              color: "#ff9e2f",
              marginRight: "20px",
            }}
            onClick={() => navigate(-1)}
          >
            목록으로
          </Button>
          <Button
            variant="outline"
            style={{
              backgroundColor: "#ff9e2f",
              borderColor: "#ff9e2f",
              color: "white",
            }}
            onClick={handleFileDownload}
          >
            PDF 다운로드
          </Button>
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default ReportPdfView;
