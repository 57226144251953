import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import CardButton from "../../../componant/buttons/cardButton";
import MobileFooter from "../../../componant/mobileFooter";
import { postNumbaAlarmChange, postNumbaAlarmSel } from "../../../api/mypage";
import { toast } from "wc-toast";

function NotificationSetting() {
  const [userAlarmSetting, setUserAlarmSetting] = useState({
    alarm_yn: "",
    deal_cd: "",
    deal_category_cd: "",
    car_weight_cd: "",
  });

  const changeUserAlarmSetting = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "deal_cd":
        setUserAlarmSetting((prevProps) => {
          let updatedDealCd = prevProps.deal_cd
            ? prevProps.deal_cd.split(",").map((code) => code.trim())
            : [];
          // 현재 선택한 값이 이미 포함되어 있는지 확인
          const isValueIncluded = updatedDealCd?.includes(value);

          // 값이 이미 포함되어 있다면 제거하고, 그렇지 않으면 추가한다.
          if (isValueIncluded) {
            updatedDealCd = updatedDealCd.filter((code) => code !== value);
          } else {
            updatedDealCd.push(value);
          }
          return {
            ...prevProps,
            [name]: updatedDealCd.join(","),
          };
        });
        break;
      case "deal_category_cd":
        setUserAlarmSetting((prevProps) => {
          let updatedDealCategoryCd = prevProps.deal_category_cd
            ? prevProps.deal_category_cd.split(",").map((code) => code.trim())
            : [];
          const isValueIncluded = updatedDealCategoryCd?.includes(value);

          if (isValueIncluded) {
            updatedDealCategoryCd = updatedDealCategoryCd?.filter(
              (code) => code !== value,
            );
          } else {
            updatedDealCategoryCd.push(value);
          }
          return {
            ...prevProps,
            [name]: updatedDealCategoryCd.join(","),
          };
        });
        break;
      case "car_weight_cd":
        setUserAlarmSetting((prevProps) => {
          let updatedCarWeightCd = prevProps.car_weight_cd
            ? prevProps.car_weight_cd.split(",").map((code) => code.trim())
            : [];
          const isValueIncluded = updatedCarWeightCd?.includes(value);
          if (isValueIncluded) {
            updatedCarWeightCd = updatedCarWeightCd.filter(
              (code) => code !== value,
            );
          } else {
            updatedCarWeightCd.push(value);
          }
          return {
            ...prevProps,
            [name]: updatedCarWeightCd.join(","),
          };
        });
        break;
      default:
        setUserAlarmSetting((prevProps) => ({
          ...prevProps,
          [name]: value,
        }));
    }
  };

  const callAlarm = async () => {
    const response = await postNumbaAlarmSel({});
    try {
      if (response.code === 1000) {
        setUserAlarmSetting(response);
      } else {
        return console.log("알림을 불러오는데 실패했습니다.");
      }
    } catch (err) {
      return console.log("알림을 불러오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    callAlarm();
  }, []);

  useEffect(() => {
    if (!userAlarmSetting.deal_category_cd?.includes("2101")) {
      setUserAlarmSetting((prevState) => {
        let updatedCarWeightCd = prevState.car_weight_cd
          ? prevState.car_weight_cd.split(",").map((code) => code.trim())
          : [];
        updatedCarWeightCd = updatedCarWeightCd.filter(
          (code) => !code.startsWith("23"),
        );
        return {
          ...prevState,
          car_weight_cd: updatedCarWeightCd.join(","),
        };
      });
    }
    if (
      !userAlarmSetting.deal_category_cd?.includes("2102") &&
      !userAlarmSetting.deal_category_cd?.includes("2103")
    ) {
      setUserAlarmSetting((prevState) => {
        let updatedCarWeightCd = prevState.car_weight_cd
          ? prevState.car_weight_cd.split(",").map((code) => code.trim())
          : [];
        updatedCarWeightCd = updatedCarWeightCd.filter(
          (code) => !code.startsWith("24"),
        );
        return {
          ...prevState,
          car_weight_cd: updatedCarWeightCd.join(","),
        };
      });
    }
  }, [userAlarmSetting.deal_category_cd]);

  const handleSubmit = async () => {
    if (
      userAlarmSetting?.deal_category_cd !== "" &&
      userAlarmSetting?.deal_cd === ""
    ) {
      return toast.error("매매종류를 선택하세요");
    }
    try {
      const response = await postNumbaAlarmChange(userAlarmSetting);
      if (response?.code !== 1000) {
        return toast.error("알람 설정 실패");
      }
      return toast.success("알람 설정되었습니다.");
    } catch (e) {
      return toast.error("알람 설정 실패");
    }
  };

  return (
    <>
      <div className="content-container notification-information-main notification-setting">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">
                <h1 className="">알림설정</h1>
              </div>
            </div>
          </div>
          <div className="row cardDIv">
            <div className="col-md-12">
              <Table bordered responsive className="table-form">
                <tbody>
                  <tr>
                    <R.FormTable_Th width="21%">알람선택</R.FormTable_Th>
                    <td className="tableTD">
                      <div className="checkbox-div d-flex">
                        <div className="w-20 w-sm-50">
                          <Form.Check
                            inline
                            label="켜기"
                            name="alarm_yn"
                            className="CustomCheckbox"
                            checked={userAlarmSetting?.alarm_yn === "Y"}
                            value={"Y"}
                            readOnly
                            onClick={(e) => changeUserAlarmSetting(e)}
                          />
                        </div>
                        <div className="checkBoxWidthXXX">
                          <Form.Check
                            inline
                            label="끄기"
                            name="alarm_yn"
                            className="CustomCheckbox"
                            checked={userAlarmSetting?.alarm_yn === "N"}
                            readOnly
                            value={"N"}
                            onClick={(e) => changeUserAlarmSetting(e)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th width="21%">매매종류</R.FormTable_Th>
                    <td className="tableTD">
                      <div className="checkbox-div d-flex">
                        <div className="w-20 w-sm-50">
                          <Form.Check
                            inline
                            label="팝니다"
                            name="deal_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.deal_cd?.includes("2201")}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            value={"2201"}
                            readOnly
                          />
                        </div>
                        <div className="checkBoxWidthXXX">
                          <Form.Check
                            inline
                            label="삽니다"
                            name="deal_cd"
                            value={"2202"}
                            className="CustomCheckbox"
                            checked={userAlarmSetting.deal_cd?.includes("2202")}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th width="21%">넘버종류</R.FormTable_Th>
                    <td className="tableTD">
                      <div className="checkbox-div d-flex chk_col4_container">
                        <div className="chk_col4">
                          <Form.Check
                            inline
                            label="개인넘버"
                            name="deal_category_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.deal_category_cd?.includes(
                              "2101",
                            )}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            value={"2101"}
                          />
                        </div>
                        <div className="chk_col4">
                          <Form.Check
                            inline
                            label="법인매매"
                            name="deal_category_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.deal_category_cd?.includes(
                              "2102",
                            )}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            value={"2102"}
                          />
                        </div>
                        <div className="chk_col4">
                          <Form.Check
                            inline
                            label="법인임대"
                            name="deal_category_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.deal_category_cd?.includes(
                              "2103",
                            )}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            value={"2103"}
                          />
                        </div>
                        <div className="chk_col4">
                          <Form.Check
                            inline
                            label="주선면허"
                            name="deal_category_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.deal_category_cd?.includes(
                              "2104",
                            )}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            value={"2104"}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th width="21%">톤수</R.FormTable_Th>
                    <td className="tableTD">
                      <div className="checkbox-div">
                        <div className="mb-2">
                          <Form.Check
                            inline
                            label="소형매물 (1.5톤 이하)"
                            name="car_weight_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.car_weight_cd?.includes(
                              "2301",
                            )}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            value={"2301"}
                            readOnly
                            disabled={
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2101",
                              ) && true
                            }
                          />
                        </div>
                      </div>
                      <div className="checkbox-div">
                        <div className="mb-2">
                          <Form.Check
                            inline
                            label="중형매물 (1.5톤 초과 ~ 16톤 이하)"
                            name="car_weight_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.car_weight_cd?.includes(
                              "2302",
                            )}
                            value={"2302"}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            disabled={
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2101",
                              ) && true
                            }
                          />
                        </div>
                      </div>
                      <div className="checkbox-div">
                        <div className="mb-2">
                          <Form.Check
                            inline
                            label="대형매물 (16톤 초과)"
                            name="car_weight_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.car_weight_cd?.includes(
                              "2303",
                            )}
                            value={"2303"}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            disabled={
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2101",
                              ) && true
                            }
                          />
                        </div>
                      </div>
                      <div className="checkbox-div">
                        <div className="mb-2">
                          <Form.Check
                            inline
                            label="10톤 이하"
                            name="car_weight_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.car_weight_cd?.includes(
                              "2401",
                            )}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            value={"2401"}
                            readOnly
                            disabled={
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2102",
                              ) &&
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2103",
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="checkbox-div">
                        <div className="mb-2">
                          <Form.Check
                            inline
                            label="10톤 초과 ~ 16톤 이하"
                            name="car_weight_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.car_weight_cd?.includes(
                              "2402",
                            )}
                            value={"2402"}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            disabled={
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2102",
                              ) &&
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2103",
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="checkbox-div">
                        <div className="mb-2">
                          <Form.Check
                            inline
                            label="16톤 초과"
                            name="car_weight_cd"
                            className="CustomCheckbox"
                            checked={userAlarmSetting.car_weight_cd?.includes(
                              "2403",
                            )}
                            value={"2403"}
                            onClick={(e) => changeUserAlarmSetting(e)}
                            readOnly
                            disabled={
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2102",
                              ) &&
                              !userAlarmSetting.deal_category_cd?.includes(
                                "2103",
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="text-center BtnDiv">
            <CardButton
              height={"60px"}
              maxWidth={"390px"}
              bg="#ff9d2f"
              name="설정"
              onClick={handleSubmit}
            ></CardButton>
          </div>
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default NotificationSetting;
