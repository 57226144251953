/* eslint-disable default-case */
import moment from "moment";

export const RegisterGoodsInfo = ({ data }) => {
  const dealStr = data?.deal_str;
  const subject = (dealStr) => {
    return dealStr === "cbuy" || dealStr === "sell" ? "판매" : "구매";
  };
  const type = data?.deal_category_cd;
  switch (type) {
    case 2101: // 개인 - 매매
      return (
        <>
          <div className="d-none d-md-block">
            <table className="table ">
              <tbody>
                <tr>
                  <td width="23%" className="grey">
                    매물번호
                  </td>
                  <td width="27%">
                    {data?.target_seq}
                  </td> 
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td width="27%">
                    {/* {data?.main_seq} */}
                  </td>                 
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td width="27%">
                    {data?.deal_category_nm} / {data?.car_weight_nm}
                  </td>
                  <td width="23%" className="grey">
                    톤수
                  </td>
                  <td width="27%">{data?.car_weight} 톤</td>
                </tr>
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <>
                    <tr>
                      <td className="grey">차량연식</td>
                      <td width="27%">
                        {data?.car_yyyymm ?
                          moment(data?.car_yyyymm).format("YYYY.MM") : "-"}
                      </td>
                      <td className="grey">판매자 지역</td>
                      <td width="27%">{data?.region_nm}</td>
                    </tr>
                    <tr>
                      <td className="grey">판매금액</td>
                      <td>
                        <span>{data?.price.toLocaleString()}</span> 만원 &nbsp;
                        {data?.in_car_yn ==='Y' && "(차량포함)"}
                      </td>
                      <td className="grey">차량구분</td>
                      <td>{data?.ecar_yn ==='Y' ? "전기차" : "일반차"}</td>
                    </tr>
                  </>
                )}
                {(dealStr === "csell" || dealStr === "buy") && (
                  <>
                    <tr>
                      <td className="grey">차량연식</td>
                      <td width="27%">
                        {data?.car_yyyymm ?
                          moment(data?.car_yyyymm).format("YYYY.MM") : "-"}
                      </td>
                      <td className="grey">차량구분</td>
                      <td>{data?.ecar_yn === 'Y' ? "전기차" : "일반차"}</td>
                    </tr>
                    <tr>
                      <td className="grey">구매금액</td>
                      <td colSpan={3}>
                        <span style={{ color: "#f66176" }}>
                          {data?.price.toLocaleString()}
                        </span>{" "}
                        만원
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          {/* ===========Mobile ========== */}
          <div className="d-block d-md-none">
            <table className="table ">
              <tbody>
                <tr>
                  <td className="grey">
                    매물번호
                  </td>
                  <td>
                    {data?.target_seq}
                  </td>               
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td>
                    {/* {data?.main_seq} */}
                  </td>
                </tr>                
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td colSpan={3}>
                    {data?.deal_category_nm} / {data?.car_weight_nm}
                  </td>
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    톤수
                  </td>
                  <td colSpan={3}>{data?.car_weight} 톤</td>
                </tr>
                <tr>
                  <td className="grey">차량구분</td>
                  <td colSpan={3}>{data?.ecar_yn === 'Y' ? "전기차" : "일반차"}</td>
                </tr>
                {data?.car_yyyymm && (
                  <tr>
                    <td className="grey">차량연식</td>
                    <td colSpan={3}>
                      {moment(data?.car_yyyymm).format("YYYY.MM")}
                    </td>
                  </tr>
                )}
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <>
                    <tr>
                      <td className="grey">판매자 지역</td>
                      <td colSpan={3}>{data?.region_nm}</td>
                    </tr>
                    <tr>
                      <td className="grey">판매금액</td>
                      <td colSpan={3}>
                        <span>{data?.price.toLocaleString()}</span> 만원 &nbsp;
                        {data?.in_car_yn ==='Y' && "(차량포함)"}
                      </td>
                    </tr>
                  </>
                )}
                {(dealStr === "csell" || dealStr === "buy") && (
                  <tr>
                    <td className="grey">구매금액</td>
                    <td colSpan={3}>
                      <span style={{ color: "#f66176" }}>
                        {data?.price.toLocaleString()}
                      </span>{" "}
                      만원
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      );
    case 2102: // 법인 - 매매
      return (
        <>
          <div className="d-none d-md-block">
            <table className="table ">
              <tbody>
                <tr>
                  <td width="23%" className="grey">
                    매물번호
                  </td>
                  <td width="27%">
                    {data?.target_seq}
                  </td> 
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td width="27%">
                    {/* {data?.main_seq} */}
                  </td>                 
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td width="27%">{data?.deal_category_nm}</td>
                  <td width="23%" className="grey">
                    톤수
                  </td>
                  <td width="27%">{data?.car_weight_nm}</td>
                </tr>
                <tr>
                  <td className="grey">차량연식</td>
                  <td width="27%">
                    {data?.car_yyyymm
                      ? moment(data?.car_yyyymm).format("YYYY.MM")
                      : "-"}
                  </td>
                  <td className="grey">대수</td>
                  <td width="27%">
                    {`1대 (${data?.jigong_flag === "G" ? "공 T/E" : "지입"})`}
                  </td>
                </tr>
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <tr>
                    <td className="grey">판매자지역</td>
                    <td width="27%">{data?.region_nm}</td>
                    <td className="grey">판매금액</td>
                    <td>
                      <span>{data?.price.toLocaleString()}</span> 만원 &nbsp;
                      {data?.in_car_yn ==='Y' && "(차량포함)"}
                    </td>
                  </tr>
                )}
                {(dealStr === "csell" || dealStr === "buy") && (
                  <tr>
                    <td className="grey">구매금액</td>
                    <td colSpan="3">
                      <span style={{ color: "#f66176" }}>
                        {data?.price.toLocaleString()}
                      </span>{" "}
                      만원
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* ===========Mobile ========== */}
          <div className="d-block d-md-none">
            <table className="table ">
              <tbody>
                <tr>
                  <td className="grey">
                    매물번호
                  </td>
                  <td>
                    {data?.target_seq}
                  </td>               
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td>
                    {/* {data?.main_seq} */}
                  </td>
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td colSpan={3}>{data?.deal_category_nm}</td>
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    톤수
                  </td>
                  <td colSpan={3}>{data?.car_weight_nm} </td>
                </tr>
                {data?.car_yyyymm && (
                  <tr>
                    <td className="grey">차량연식</td>
                    <td colSpan={3}>
                      {moment(data?.car_yyyymm).format("YYYY.MM")}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="grey">대수</td>
                  <td colSpan={3}>
                    {`1대 (${data?.jigong_flag === "G" ? "공 T/E" : "지입"})`}
                  </td>
                </tr>
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <>
                    <tr>
                      <td className="grey">판매자 지역</td>
                      <td colSpan={3}>{data?.region_nm}</td>
                    </tr>
                    <tr>
                      <td className="grey">판매금액</td>
                      <td colSpan={3}>
                        <span>{data?.price.toLocaleString()}</span> 만원&nbsp;
                        {data?.in_car_yn ==='Y' && "(차량포함)"}
                      </td>
                    </tr>
                  </>
                )}
                {(dealStr === "csell" || dealStr === "buy") && (
                  <tr>
                    <td className="grey">구매금액</td>
                    <td colSpan={3}>
                      <span style={{ color: "#f66176" }}>
                        {data?.price.toLocaleString()}
                      </span>{" "}
                      만원
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      );
    case 2103: // 법인 - 임대
      return (
        <>
          <div className="d-none d-md-block">
            <table className="table ">
              <tbody>
                <tr>
                  <td width="23%" className="grey">
                    매물번호
                  </td>
                  <td width="27%">
                    {data?.target_seq}
                  </td> 
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td width="27%">
                    {/* {data?.main_seq} */}
                  </td>                 
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td width="27%">{data?.deal_category_nm}</td>
                  <td width="23%" className="grey">
                    톤수
                  </td>
                  <td width="27%">{data?.car_weight_nm}</td>
                </tr>
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <>
                    <tr>
                      <td className="grey">차량연식</td>
                      <td width="27%">
                        {data?.car_yyyymm ?
                          moment(data?.car_yyyymm).format("YYYY.MM") : "-"}
                      </td>
                      <td className="grey">지입료</td>
                      <td colSpan="3">
                        <span>{data?.ji_price.toLocaleString()}</span> 만원
                      </td>
                    </tr>
                    <tr>
                      <td className="grey">임대인 지역</td>
                      <td width="27%">{data?.region_nm}</td>
                      <td className="grey">임매금액</td>
                      <td colSpan="3">
                        <span>{data?.price.toLocaleString()}</span> 만원
                      </td>
                    </tr>
                  </>
                )}
                {(dealStr === "csell" || dealStr === "buy") && (
                  <tr>
                    <td className="grey">임대금액</td>
                    <td>
                      <span style={{ color: "#f66176" }}>
                        {data?.price.toLocaleString()}
                      </span>{" "}
                      만원
                    </td>
                    <td className="grey">지입료</td>
                    <td>
                      <span style={{ color: "#f66176" }}>
                        {data?.ji_price.toLocaleString()}
                      </span>{" "}
                      만원
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* ===========Mobile ========== */}
          <div className="d-block d-md-none">
            <table className="table ">
              <tbody>
                <tr>
                  <td className="grey">
                    매물번호
                  </td>
                  <td>
                    {data?.target_seq}
                  </td>               
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td>
                    {/* {data?.main_seq} */}
                  </td>
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td colSpan={3}>{data?.deal_category_nm}</td>
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    톤수
                  </td>
                  <td colSpan={3}>{data?.car_weight_nm}</td>
                </tr>
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <tr>
                    <td className="grey">차량연식</td>
                    <td colSpan={3}>
                      {data?.car_yyyymm ?
                        moment(data?.car_yyyymm).format("YYYY.MM") : "-"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="grey">지입료</td>
                  <td colSpan={3}>
                    <span>{data?.ji_price.toLocaleString()}</span> 만원
                  </td>
                </tr>
                {(dealStr === "cbuy" || dealStr === "sell") && (
                  <tr>
                    <td className="grey">임대인 지역</td>
                    <td colSpan={3}>{data?.region_nm}</td>
                  </tr>
                )}
                <tr>
                  <td className="grey">임대금액</td>
                  <td colSpan={3}>
                    <span style={{ color: (dealStr === "csell" || dealStr === "buy") ? "#f66176" : "rgba(27, 111, 207, 0.9)" }}>
                      {data?.price.toLocaleString()}
                    </span> 만원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    case 2104:
      return (
        <>
          <div className="d-none d-md-block">
            <table className="table ">
              <tbody>
                <tr>
                  <td width="23%" className="grey">
                    매물번호
                  </td>
                  <td width="27%">
                    {data?.target_seq}
                  </td> 
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td width="27%">
                    {/* {data?.main_seq} */}
                  </td>                 
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td width="27%" colSpan="3">
                    {data?.deal_category_nm}
                  </td>
                </tr>
                <tr>
                  <td className="grey">{subject(dealStr)}자 지역</td>
                  <td width="27%">{data?.region_nm}</td>
                  <td className="grey">{subject(dealStr)}금액</td>
                  <td>
                    <span style={{ color: (dealStr === "csell" || dealStr === "buy") ? "#f66176" : "rgba(27, 111, 207, 0.9)" }}>
                      {data?.price.toLocaleString()}
                    </span>{" "}
                    만원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* ===========Mobile ========== */}
          <div className="d-block d-md-none">
            <table className="table ">
              <tbody>
                <tr>
                  <td className="grey">
                    매물번호
                  </td>
                  <td>
                    {data?.target_seq}
                  </td>               
                  <td width="23%" className="grey">
                    매매번호
                  </td>
                  <td>
                    {/* {data?.main_seq} */}
                  </td>
                </tr>
                <tr>
                  <td width="23%" className="grey">
                    유형
                  </td>
                  <td colSpan={3}>{data?.deal_category_nm}</td>
                </tr>
                <tr>
                  <td className="grey">{subject(dealStr)}자 지역</td>
                  <td colSpan={3}>{data?.region_nm}</td>
                </tr>
                <tr>
                  <td className="grey">{subject(dealStr)}금액</td>
                  <td colSpan={3}>
                    <span style={{ color: (dealStr === "csell" || dealStr === "buy") ? "#f66176" : "rgba(27, 111, 207, 0.9)" }}>
                      {data?.price.toLocaleString()}
                    </span> 만원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
  }
};
