import Container from "react-bootstrap/Container";
import NavLink from "react-bootstrap/esm/NavLink";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import * as H from "../../css/header/header";
import { Col, Row } from "react-bootstrap";
import { toast } from "wc-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { headerText, userLoginJotai } from "../../App";
import { useAtom } from "jotai";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";

// Import Swiper styles
import "swiper/css";
import useDeviceDetect from "../../helpers/useMobile";
import {
  postNumbaLogout,
  postNumbaRefresh,
  postNumbaRefreshAfterTimeOut,
} from "../../api/auth";
import axios from "axios";
import React, { useEffect, useState } from "react";
import getUserInfo from "../../utils/hooks/getUserInfo";
import { postNumbaUserMainList } from "../../api/mypage";
import {
  header_mainData,
  header_userInfo,
} from "../../data/atom/mypage/mypage_atom";
import CallMainData from "../../utils/hooks/callMainData";
import callMainData from "../../utils/hooks/callMainData";
import { TopStripe_Hi } from "../../css/header/header";
 import { getFcmCommunication } from "../../utils/hooks/app-communication/webToApp/getFcm";
import { isMobile } from "react-device-detect";
import { scrollToTop } from "../../utils/hooks/scrollToTop";

function Header() {
  const isMobile = useDeviceDetect();
  const location = useLocation();
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [userInfo, setUserInfo] = useAtom(header_userInfo);
  const [mainData, setMainList] = useAtom(header_mainData);
  const [width, setWidth] = useState(window.innerWidth);
  const [expanded, setExpanded] = useState(false);
  const servicePhoneNum = "02-2276-0601";

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [window.innerWidth]);

  const handleShowOffcanvas = () => {
    setExpanded(true);
  };

  const handleCloseOffcanvas = () => {
    setExpanded(false);
  };

  const handleLogOut = async () => {
    try {
      const response = await postNumbaLogout();

      toast.loading("로그아웃", { duration: 1000 });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("device_id");
      setMainList({
        cList: [],
        cListCnt: 0,
        sellBuyList: [],
        sellBuyListCnt: 0,
        interestCnt: 0,
        alramCnt: 0,
      });
      setTimeout(function () {
        navigate("/login");
      }, 2000);
    } catch (err) {
      // toast.error("로그아웃 실패");
      console.log("로그아웃 실패");
    }
  };

  const checkHandleActive = (pathname) => {
    let check = "LinkTag";
    if (pathname === location.pathname) {
      if (pathname.includes("sell")) {
        check = "LinkTagActive";
      } else {
        check = "LinkTagActiveBuy";
      }
    }
    return check;
  };
  const checkHandle = (pathname) => {
    let check = "YellowLink";
    if (pathname === location.pathname) {
      check = "YellowLinkActive";
    }
    return check;
  };

  const callInfo = async () => {
    const userInfo = await getUserInfo();
    setUserInfo(userInfo);
  };

  // API call - 마이넘버원 메인페이지
  const callMainList = async () => {
    const data = await callMainData();
    setMainList(data);
  };

  useEffect(() => {
    if (access_token) {
      callInfo();
      callMainList();
      // getFcmCommunication();
    }
  }, [location.pathname, access_token]);

  useEffect(() => {
    if (access_token) {
      getFcmCommunication();
    }
  }, [access_token]);
  const goToPhonCallCommunicationToApp = (phoneNum) => {
    // if (isMobile) {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       type: "TO_CALL",
    //       data: {
    //         phoneNum: phoneNum,
    //       },
    //     }),
    //   );
    // }
  };

  return (
    <>
      {[""].map((expand) => (
        <H.HeaderWrapper_div>
          <H.TopStripe_Header className="d-md-block d-none d-lg-block">
            <Container>
              <Row>
                <Col>
                  <div className="d-flex justify-content-end align-items-center">
                    {access_token ? (
                      <>
                        <H.TopStripe_Hi>
                          안녕하세요 <span>{userInfo?.user_id}</span>님
                        </H.TopStripe_Hi>
                        <H.TopStripe_Link
                          className={`${
                            location.pathname.includes("/main")
                              ? "f-orange"
                              : ""
                          }`}
                          onClick={() => {
                            navigate("/main");
                          }}
                        >
                          마이넘버로
                        </H.TopStripe_Link>
                        <H.TopStripe_Link
                          onClick={() => {
                            handleLogOut();
                            handleCloseOffcanvas();
                          }}
                        >
                          로그아웃
                        </H.TopStripe_Link>
                      </>
                    ) : (
                      <>
                        <H.TopStripe_Link
                          onClick={() => {
                            navigate("/login");
                            handleCloseOffcanvas();
                          }}
                        >
                          로그인
                        </H.TopStripe_Link>
                        <H.TopStripe_Link
                          onClick={() => {
                            navigate("/signup");
                            handleCloseOffcanvas();
                          }}
                        >
                          회원가입
                        </H.TopStripe_Link>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </H.TopStripe_Header>
          <Navbar
            key={expand}
            expand={expand}
            className={`bg-white customHeader ${
              width <= 768 && location.pathname === "/" && "mobile-bottom-none"
            }`}
          >
            <Container>
              <div className="d-md-flex d-none d-flex align-items-center">
                <Navbar.Brand
                  onClick={() => {
                    navigate("/");
                    scrollToTop();
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/img/logo.svg"}
                    alt=""
                    role="button"
                    style={{ height: "30px", marginBottom: "4px" }}
                  />
                </Navbar.Brand>
                <div className="header_menu-items d-flex flex-wrap">
                  <div className="header_menu_item">
                    <Link
                      // className={checkHandleActive("/selling")}
                      className={`${
                        location.pathname.includes("/selling")
                          ? "LinkTagActive"
                          : ""
                      }`}
                      to="/selling"
                      onClick={scrollToTop}
                    >
                      {/* <H.Header_Link > */}
                      팝니다
                      {/* </H.Header_Link> */}
                    </Link>
                  </div>
                  <div className="header_menu_item">
                    {/*<Link className={checkHandleActive("/buying")} to="/buying">*/}
                    <Link
                      className={`${
                        location.pathname.includes("/buying")
                          ? "LinkTagActiveBuy"
                          : ""
                      }`}
                      to="/buying"
                      onClick={scrollToTop}
                    >
                      {/* <H.Header_Link> */}
                      삽니다
                      {/* </H.Header_Link> */}
                    </Link>
                  </div>
                  <div className="header_menu_item">
                    <div className="header_menu_child d-flex flex-wrap">
                      <div className="header_menu_child_item">
                        <Link
                          className={checkHandle("/view-price")}
                          to="/view-price"
                          onClick={scrollToTop}
                        >
                          시세보기
                        </Link>
                      </div>
                      <div className="header_menu_child_item">
                        <Link
                          to="/information"
                          className={checkHandle("/information")}
                          onClick={scrollToTop}
                        >
                          이용안내
                        </Link>
                      </div>
                      <div className="header_menu_child_item">
                        <Link
                          to="/archive"
                          className={checkHandle("/archive")}
                          onClick={scrollToTop}
                        >
                          자료실
                        </Link>
                      </div>
                      <div className="header_menu_child_item">
                        <Link
                          className={checkHandle("/announcement")}
                          to="/announcement"
                          onClick={scrollToTop}
                        >
                          고객센터
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!(width <= 400 && location.pathname === "/") && (
                <div className="d-md-none d-block align-items-center mobileHeader">
                  <div className="d-flex justify-content-between align-items-center">
                    <Link
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <img
                        width="8.5px"
                        height="15px"
                        src={process.env.PUBLIC_URL + "/img/backArrow.svg"}
                        alt=""
                      />
                    </Link>
                    <span>{headerTextAtom}</span>
                  </div>
                </div>
              )}

              {width <= 400 && location.pathname === "/" && (
                <img
                  src={process.env.PUBLIC_URL + "/img/logo.svg"}
                  alt=""
                  style={{ height: "27px" }}
                />
              )}

              <div
                className={`d-flex ${
                  width <= 400 &&
                  location.pathname === "/" &&
                  "margin-left-auto"
                }`}
              >
                {!(width <= 400 && location.pathname === "/") && (
                  <div className="d-md-none d-flex align-items-center">
                    <NavLink
                      className="me-3"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <img
                        width="23px"
                        height="22px"
                        src={process.env.PUBLIC_URL + "/img/top_icon_home.svg"}
                        alt=""
                      />
                    </NavLink>
                  </div>
                )}
                {!(width <= 400 && location.pathname === "/") && (
                  <div className="d-md-flex d-flex align-items-center">
                    <NavLink
                      className="header__right__action position-relative"
                      onClick={() => {
                        // if (!localStorage.getItem("access_token")) {
                        //   return toast.error("로그인 후 이용가능 합니다.", {
                        //     duration: 500,
                        //   });
                        // }
                        // navigate("/main-tab", {
                        //   state: { main: 2 },
                        // });
                        if (!localStorage.getItem("access_token")) {
                          navigate("/login");
                        } else {
                          navigate("/main-tab", {
                            state: { main: 2 },
                          });
                        }
                      }}
                    >
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {mainData?.alramCnt}
                        <span className="visually-hidden"></span>
                      </span>
                      <img
                        width="23px"
                        height="22px"
                        src={process.env.PUBLIC_URL + "/img/top_icon_tell.svg"}
                        alt=""
                      />
                    </NavLink>
                    {/* 관심매물 아이콘 */}
                    <NavLink
                      className="header__right__action position-relative"
                      onClick={() => {
                        // if (!localStorage.getItem("access_token")) {
                        //   return toast.error("로그인 후 이용가능 합니다.", {
                        //     duration: 500,
                        //   });
                        // }
                        // navigate("/main-tab", {
                        //   state: { main: 3 },
                        // });
                        if (!localStorage.getItem("access_token")) {
                          navigate("/login");
                        } else {
                          navigate("/main-tab", {
                            state: { main: 3 },
                          });
                        }
                      }}
                    >
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {mainData?.interestCnt}
                        <span className="visually-hidden"></span>
                      </span>
                      <img
                        width="23px"
                        height="22px"
                        src={process.env.PUBLIC_URL + "/img/top_icon_heart.svg"}
                        alt=""
                      />
                    </NavLink>
                  </div>
                )}

                <Navbar.Toggle
                  className="my_offcanvas"
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                  onClick={handleShowOffcanvas}
                />
              </div>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement={isMobile ? "end" : "top"}
                scrollable={true}
                data-bs-scroll="true"
                className="headerTopOffcanvas"
                onShow={handleShowOffcanvas}
                onHide={handleCloseOffcanvas}
                show={expanded}
              >
                <div className="desktopHeader">
                  <H.TopStripe_Header className="d-md-block d-none d-lg-block">
                    <Container>
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-end">
                            {access_token ? (
                              <H.TopStripe_Link
                                onClick={() => {
                                  handleLogOut();
                                  handleCloseOffcanvas();
                                }}
                              >
                                로그아웃
                              </H.TopStripe_Link>
                            ) : (
                              <>
                                <H.TopStripe_Link
                                  onClick={() => {
                                    navigate("/login");
                                    handleCloseOffcanvas();
                                  }}
                                >
                                  로그인
                                </H.TopStripe_Link>
                                <H.TopStripe_Link
                                  onClick={() => {
                                    navigate("/signup");
                                    handleCloseOffcanvas();
                                  }}
                                >
                                  회원가입
                                </H.TopStripe_Link>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </H.TopStripe_Header>
                  <div className="container">
                    <Offcanvas.Header closeButton className="p-0 pt-3">
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${expand}`}
                        style={{ width: "32px", height: "32px" }}
                      >
                        <Link
                          to="/"
                          onClick={handleCloseOffcanvas}
                          className="w-100 h-100 d-flex align-items-center justify-content-center"
                        >
                          {isMobile ? (
                            <img
                              width="23px"
                              height="23px"
                              src={
                                process.env.PUBLIC_URL +
                                "/img/top_icon_home.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <img
                              width="140px"
                              height="30px"
                              src={process.env.PUBLIC_URL + "/img/logo.svg"}
                              alt=""
                            />
                          )}
                        </Link>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                  </div>
                </div>
                <Offcanvas.Body className="p-0">
                  {/* <Nav className="justify-content-end flex-grow-1 pe-3"></Nav> */}
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="CanvasWrapper">
                          <div className="canvasLeftSection">
                            <div className="canvasLeftBlock">
                              <h6>팝니다</h6>
                              <ul className="canvasLeftBlockMenu">
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    개인넘버
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/selling"
                                          state={{ main: 1, sub: "fourth" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          전기차 소형
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/selling"
                                          state={{ main: 1, sub: "first" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          소형 매물
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/selling"
                                          state={{ main: 1, sub: "second" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          중형 매물
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/selling"
                                          state={{ main: 1, sub: "third" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          대형 매물
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    법인넘버
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/selling"
                                          state={{ main: 2, sub: "first" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          매매 매물
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/selling"
                                          state={{ main: 2, sub: "second" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          임대 매물
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    주선면허
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li>
                                        <Link
                                          to="/selling"
                                          state={{ main: 3, sub: "first" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          주선면허 매물
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="canvasLeftBlock OrangeHeading">
                              <h6>삽니다</h6>
                              <ul className="canvasLeftBlockMenu">
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    개인넘버
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/buying"
                                          state={{ main: 1, sub: "fourth" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          전기차 소형
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/buying"
                                          state={{ main: 1, sub: "first" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          소형 매물
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/buying"
                                          state={{ main: 1, sub: "second" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          중형 매물
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/buying"
                                          state={{ main: 1, sub: "third" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          대형 매물
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    법인넘버
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/buying"
                                          state={{ main: 2, sub: "first" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          매매 매물
                                        </Link>
                                      </li>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/buying"
                                          state={{ main: 2, sub: "second" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          임대 매물
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    주선면허
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li>
                                        <Link
                                          to="/buying"
                                          state={{ main: 3, sub: "first" }}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          주선면허 매물
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="canvasLeftBlock">
                              <ul className="canvasLeftBlockMenu">
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    시세보기
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li className="canvasLeftBlockMenuMainListsHeader">
                                        <Link
                                          to="/view-price"
                                          state={1}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          개인넘버(전기차)
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/view-price"
                                          state={2}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          개인넘버(일반)
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/view-price"
                                          state={4}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          주선면허
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div
                                    className="canvasLeftBlockMenuMain"
                                    onClick={() => {
                                      handleCloseOffcanvas();
                                      navigate("/information");
                                    }}
                                    role="button"
                                  >
                                    이용안내
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>

                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul></ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div
                                    className="canvasLeftBlockMenuMain"
                                    onClick={() => {
                                      handleCloseOffcanvas();
                                      navigate("/archive");
                                    }}
                                    role="button"
                                  >
                                    자료실
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>

                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul></ul>
                                  </div>
                                </li>
                                <li className="canvasLeftBlockMenuItem">
                                  <div className="canvasLeftBlockMenuMain">
                                    고객센터
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="canvasLeftBlockMenuMainLists">
                                    <ul>
                                      <li>
                                        <Link
                                          to="/announcement"
                                          state={1}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          공지사항
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/announcement"
                                          state={2}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          1:1 문의
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/announcement"
                                          state={3}
                                          onClick={handleCloseOffcanvas}
                                        >
                                          자주하는 질문
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <div className="canvasLeftBlock">
                              <ul className="canvasLeftBlockMenu">
                                {access_token && (
                                  <li className="canvasLeftBlockMenuItem">
                                    <div
                                      className="canvasLeftBlockMenuMain"
                                      onClick={async () => {
                                        try {
                                          const response =
                                            await postNumbaLogout();
                                          toast.loading("로그아웃", {
                                            duration: 1000,
                                          });
                                          localStorage.removeItem(
                                            "access_token"
                                          );
                                          localStorage.removeItem(
                                            "refresh_token"
                                          );
                                          localStorage.removeItem("device_id");
                                          setMainList({
                                            cList: [],
                                            cListCnt: 0,
                                            sellBuyList: [],
                                            sellBuyListCnt: 0,
                                            interestCnt: 0,
                                            alramCnt: 0,
                                          });
                                          setTimeout(function () {
                                            navigate("/");
                                            setExpanded(false);
                                          }, 2000);
                                        } catch (err) {
                                          console.log("로그아웃 실패");
                                        }
                                      }}
                                      style={{
                                        textDecoration: "underline",
                                        color: "rgba(131, 131, 131, 0.9)",
                                      }}
                                    >
                                      로그아웃
                                    </div>
                                  </li>
                                )}
                                <div className="phone-box d-nonex d-flex justify-content-between mt-3">
                                  <div className="phone-text">
                                    <div
                                      style={{
                                        color: "rgba(131, 131, 131, 0.9)",
                                      }}
                                    >
                                      넘버로 고객센터(평일 09:00~18:00)
                                    </div>
                                    <div
                                      className="phone"
                                      style={{
                                        fontFamily: "NotoSansKR-bold",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {/* 전화번호 텍스트 연결 */}
                                      {/* <a href={`tel:${servicePhoneNum}`}
                                        style={{
                                          textDecoration: 'none',
                                          fontFamily: "NotoSansKR-bold",
                                          fontSize: "18px",
                                          color: 'inherit',
                                        }}
                                      > */}
                                      {servicePhoneNum}
                                      {/* </a> */}
                                    </div>
                                  </div>
                                  {/*<div*/}
                                  {/*  className="footer-phone-img"*/}
                                  {/*  onClick={() => {*/}
                                  {/*    goToPhonCallCommunicationToApp(*/}
                                  {/*      servicePhoneNum,*/}
                                  {/*    );*/}
                                  {/*  }}*/}
                                  {/*>*/}
                                  {/*  <img*/}
                                  {/*    src="/img/icon_phone.svg"*/}
                                  {/*    alt=""*/}
                                  {/*    style={{ width: "50px", height: "50px" }}*/}
                                  {/*  />*/}
                                  {/*</div>*/}

                                  <a href={`tel:${servicePhoneNum}`}>
                                    <img
                                      src={"/img/icon_phone.svg"}
                                      alt=""
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </a>
                                </div>
                                <div
                                  className="phone-text"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div
                                    style={{
                                      color: "rgba(131, 131, 131, 0.9)",
                                    }}
                                  >
                                    서울특별시 중구 퇴계로 286, 10층(쌍림동, 쌍림빌딩)
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          <div className="canvasRightSection ">
                            <div className="d-md-block d-lg-block d-none">
                              <Swiper
                                modules={[Pagination]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                              >
                                <SwiperSlide>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/roolling-img.png"
                                    }
                                    alt=""
                                  />
                                </SwiperSlide>
                                {/*<SwiperSlide>*/}
                                {/*  <div className="sliderCard">롤링 이미지</div>*/}
                                {/*</SwiperSlide>*/}
                                {/*<SwiperSlide>*/}
                                {/*  <div className="sliderCard">롤링 이미지</div>*/}
                                {/*</SwiperSlide>*/}
                              </Swiper>
                            </div>
                            {access_token ? (
                              <div className="canvasRightContentBottomDiv">
                                <div className="canvasRightContentBtnDiv d-flex justify-content-between align-items-center">
                                  <p className="afterLoginTxt">
                                    <span style={{ fontSize: "18px" }}>
                                      안녕하세요
                                    </span>{" "}
                                    <span style={{ fontSize: "18px" }}>
                                      {userInfo?.user_id}님
                                    </span>
                                  </p>
                                  <button
                                    className="yellowBtn"
                                    onClick={() => {
                                      handleCloseOffcanvas();
                                      navigate("/main");
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/icon_mynamba1.png"
                                      }
                                      alt=""
                                    />
                                    마이넘버로
                                  </button>
                                </div>
                                <div className="canvasRightBottomAfterLoginDiv d-flex justify-content-between">
                                  <div className="largeBtnDiv">
                                    <Link
                                      to="/main-tab"
                                      state={{ main: 3 }}
                                      className="link d-flex justify-content-between align-items-center"
                                      onClick={handleCloseOffcanvas}
                                    >
                                      <span>나의 관심넘버</span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </Link>
                                    <div className="numberDIv">
                                      {mainData?.interestCnt}
                                    </div>
                                  </div>
                                  <div className="largeBtnDiv">
                                    <Link
                                      to="/main-tab"
                                      state={{ main: 2 }}
                                      className="link d-flex justify-content-between align-items-center"
                                      onClick={handleCloseOffcanvas}
                                    >
                                      <span>알림보기</span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Li_icon.png"
                                        }
                                        alt=""
                                      />
                                    </Link>
                                    <div className="numberDIv">
                                      {mainData?.alramCnt}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="canvasRightContentBottomDiv">
                                <h5>로그인 해 주세요</h5>
                                <p>
                                  회원가입하고 편리한 넘버로 서비스를 이용해
                                  보세요
                                </p>
                                <div className="canvasRightContentBtnDiv d-flex justify-content-between">
                                  <Link
                                    to="/login"
                                    onClick={() => {
                                      handleCloseOffcanvas();
                                    }}
                                  >
                                    <button className="yellowBtn">
                                      로그인
                                    </button>
                                  </Link>
                                  <button
                                    className="yellowBtnBdr"
                                    onClick={() => {
                                      navigate("/signup");
                                      handleCloseOffcanvas();
                                    }}
                                  >
                                    회원가입
                                  </button>
                                </div>
                                <div className="canvasRightContentBtnBottomDiv">
                                  <span
                                    className="bdr"
                                    onClick={() => {
                                      navigate("/find-id");
                                      handleCloseOffcanvas();
                                    }}
                                    role="button"
                                  >
                                    아이디 찾기
                                  </span>
                                  <span
                                    className=""
                                    onClick={() => {
                                      navigate("/find-password");
                                      handleCloseOffcanvas();
                                    }}
                                    role="button"
                                  >
                                    비밀번호 찾기
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </H.HeaderWrapper_div>
      ))}
    </>
  );
}

export default Header;
