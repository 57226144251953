import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import * as A from "../../css/Selling/Selling.style";
import CorporateRequestFrom from './CorporateRequestFrom';

function CorporateRequestTab() {
    const [activeTab, setActiveTab] = useState(1);
    const tabShow = (id) => {
        setActiveTab(id);

    };
    return (
        <>
            <A.SellingTab_Header>
                <Container>
                    <A.NavTab_Wrapper>
                        <A.NavTab className={activeTab === 1 ? "active" : ""} onClick={() => tabShow(1)}>
                            개인넘버
                        </A.NavTab>
                        <A.NavTab className={activeTab === 2 ? "active" : ""} onClick={() => tabShow(2)}>
                            법인넘버
                        </A.NavTab>
                        <A.NavTab className={activeTab === 3 ? "active" : ""} onClick={() => tabShow(3)}>
                            주선면허
                        </A.NavTab>
                    </A.NavTab_Wrapper>
                </Container>
            </A.SellingTab_Header>
            <CorporateRequestFrom activeTab={activeTab} />
        </>
    );
}

export default CorporateRequestTab;