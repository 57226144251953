import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import CardButton from "../../../componant/buttons/cardButton";
import { headerText } from "../../../App";
import { useAtom } from "jotai";
import { postNumbaCodeList, postNumbaSelUserInfo } from "../../../api/auth";
import { toast } from "wc-toast";
import {
  postNumbaInsBuyCorp,
  postNumbaInsBuyIndi,
  postNumbaInsBuyLic,
} from "../../../api/buy";
import { DaumPostcode } from "../../../utils/DaumPostCode";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../utils/hooks/scrollToTop";
import axios from "axios";
import config from "../../../config";

const BuyingFormDetails = ({ activeTab, setActiveTab }) => {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const initialBuyReq = {
    fbl_file: null,
    car_file: null,
    car_files: [],
    deal_category_cd: activeTab === 1 ? "2101" : activeTab === 3 ? "2104" : "",
    region_cd: "",
    user_nm: "",
    phone: "",
    zip: "",
    addr1: "",
    addr2: "",
    req_comment: "",
    car_weight_cd: "",
    car_weight: "",
    car_yyyymm: "",
    price: "",
    brno_file: null,
    corp_nm: "",
    whole_yn: "",
    nambaList: [],
    fabl_file: null,
    ecar_yn: "",
  };
  const [buyReq, setBuyReq] = useState(initialBuyReq);
  const [regionCdList, setRegionCdList] = useState([]);
  const [corpCarCdList, setCorpCarCdList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [buyCnt, setBuyCnt] = useState(0);
  const [corpNamba, setCorpNamba] = useState({
    car_weight_cd: "",
    car_yyyymm: "",
    price: "",
    ji_price: "",
    jigong_flag: "",
  });
  const [nambaList, setNambaList] = useState([]);
  const [userType, setUserType] = useState(201);
  const [isUserCorp, setIsUserCorp] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isCar, setIsCar] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [priceData, setPriceData] = useState([]);
  const [newPrice, setNewPrice] = useState(0);

  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  const now = new Date();
  let years = [];
  for (let y = now.getFullYear(); y >= 1995; y -= 1) {
    years.push(y);
  }
  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push("0" + m.toString());
    } else {
      month.push(m.toString());
    }
  }

  const callGetPrice = async () => {
    await axios({
      url: config.API_URL + "/comm/selMvalue",
      method: "post",
      data: {},
      headers: {
        ...axios.defaults.headers,
        Authorization: "",
      },
    }).then((res) => {
      setPriceData(res.data.resultList);
      if (parseInt(buyReq.deal_category_cd) === 2104) {
        const foundData = res.data.resultList.find(data => data.deal_category_cd === 2104);
        if (foundData) {
          setNewPrice(foundData.avg_price);
          console.log('---', foundData.avg_price);
        }
      }
    }).catch((error)=>{
      console.error('Error getPrice:', error);
      return 0;    
    });   
  }

  const getRegionCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1000 });
      if (response.code !== 1000) {
        console.log("지역코드 가져오기 실패");
        return;
      }
      setRegionCdList(response.resultList[0]);
    } catch (error) {
      console.log("지역코드 가져오기 실패");
    }
  };

  const getCorpCarCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 2400 });
      if (response.code !== 1000) {
        console.log("법인 톤수 가져오기 실패");
        return;
      }
      setCorpCarCdList(response.resultList[0]);
    } catch (err) {
      console.log("법인 톤수 가져오기 실패");
    }
  };

  useEffect(() => {
    // getRegionCd();
    setHeaderTextAtom("삽니다 등록하기");
    getUserInfo();
    scrollToTop();
    callGetPrice();  
    console.log('-------------------', activeTab);
  }, []);

  useEffect(() => {
    setBuyReq(initialBuyReq);
    setBuyCnt(0);
    // setIsBuyChecked(false);
    setIsChecked(true);
    getUserInfo();
    callGetPrice();

    if (activeTab === 2) {
      getCorpCarCd();
    } else if (activeTab === 3) {
      getRegionCd();
    }
  }, [activeTab]);

  useEffect(() => {
    const updatedNambaList = [];
    for (let i = 0; i < buyCnt; i++) {
      updatedNambaList.push({ ...corpNamba });
    }
    setNambaList(updatedNambaList);
  }, [buyCnt]);

  useEffect(() => {
    setBuyReq((prevState) => ({
      ...prevState,
      nambaList: nambaList,
    }));
  }, [nambaList]);

  useEffect(() => {
    if (
      parseInt(buyReq.deal_category_cd) === 2102 ||
      parseInt(buyReq.deal_category_cd) === 2103
    ) {
      setBuyReq((prevState) => ({
        ...prevState,
        nambaList: [],
      }));
      setBuyCnt(0);
    }
  }, [buyReq.deal_category_cd]);

  const handleInputChange = (event) => {
    console.log('buyReq4', buyReq);
    const { name, value } = event.target;
    if (name === "car_weight_cd"){      
      const foundData = priceData.find(data => 
        data.deal_category_cd === 2101 && data.car_weight_cd === parseInt(value)
      );
      if (foundData) {
        setNewPrice(foundData.avg_price);
        console.log('---', foundData.avg_price);
      }
    }

    const yyyymm =
      document.getElementById("yyyy")?.value +
      document.getElementById("mm")?.value;

    if (name === "car_weight") {
      setBuyReq((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9.]/g, ""),
        car_yyyymm: isCar ? yyyymm : "",
      }));
    } else if (name === "price") {
      setBuyReq((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: isCar ? yyyymm : "",
      }));
    } else {
      setBuyReq((prevProps) => ({
        ...prevProps,
        [name]: value,
        car_yyyymm: isCar ? yyyymm : "",
      }));
    }
  };

  const handleFblFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setBuyReq((prevState) => ({
        ...prevState,
        fbl_file: file,
      }));
    }
  };
  const renderFblFileInfo = () => {
    if (!buyReq.fbl_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {buyReq.fbl_file.name}
      </R.Btn_Value>
    );
  };

  const handleFablFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setBuyReq((prevState) => ({
        ...prevState,
        fabl_file: file,
      }));
    }
  };
  const renderFablFileInfo = () => {
    if (!buyReq.fabl_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {buyReq.fabl_file.name}
      </R.Btn_Value>
    );
  };

  const handleBrnoFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setBuyReq((prevState) => ({
        ...prevState,
        brno_file: file,
      }));
    }    
  };
  const renderBrnoFileInfo = () => {
    if (!buyReq.brno_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {buyReq.brno_file.name}
      </R.Btn_Value>
    );
  };

  const handleCarFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setBuyReq((prevState) => ({
        ...prevState,
        car_file: file,
      }));
    }    
  };
  const renderCarFileInfo = () => {
    if (!buyReq.car_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {buyReq.car_file.name}
      </R.Btn_Value>
    );
  };

  // const handleCarFilesSelect = (event, index) => {
  //   const file = event.target.files[0];
  //   const updatedCarFiles = [...buyReq.car_files];
  //   updatedCarFiles[index] = file;
  //   setBuyReq((prevState) => ({
  //     ...prevState,
  //     car_files: updatedCarFiles,
  //   }));
  // };

    const handleCarFilesSelect = (event, index) => {
    const file = event.target.files[0];
    const updatedCarFiles = [...buyReq.car_files];
    
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      updatedCarFiles[index] = file; // 선택된 파일로 업데이트 
    }

    // map 대신 for 루프 사용
    const preparedCarFiles = [];
    for (let i = 0; i < buyCnt; i++) {  // updatedCarFiles.length
      if (updatedCarFiles[i] === null ||  updatedCarFiles[i] === undefined) {
        preparedCarFiles[i] = new Blob([], { type: 'application/octet-stream' }); // null, string 모두 안들어감
      } else {
        preparedCarFiles[i] = updatedCarFiles[i];
      }
    }  
    setBuyReq((prevState) => ({
      ...prevState,
      car_files: preparedCarFiles,
    }));
  };

  const renderCarFilesInfo = (index) => {
    if (!buyReq.car_files[index]) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {buyReq.car_files[index].name}
      </R.Btn_Value>
    );
  };

  const getUserInfo = async () => {
    try {
      const response = await postNumbaSelUserInfo({});
      if (response.code === 1000) {
        setUserInfo(response);
        setUserType(response.user_type_cd);
        setBuyReq({
          ...buyReq,
          corp_nm: response.corp_nm || "",
          user_nm: response?.user_nm || "",
          phone: response?.phone || "",
          zip: response?.zip || "",
          addr1: response?.addr1 || "",
          addr2: response?.addr2 || "",
        });
        if (activeTab === 2) {
          setBuyReq({
            ...buyReq,
            deal_category_cd: "2102",
            corp_nm: response?.corp_nm || "",
            user_nm: response?.user_nm || "",
            phone: response?.phone || "",
            zip: response?.zip || "",
            addr1: response?.addr1 || "",
            addr2: response?.addr2 || "",
          });
        }
      } else {
      }
    } catch (err) { }
  };

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      getUserInfo();
    } else {
      setBuyReq({
        ...buyReq,
        corp_nm: "",
        user_nm: "",
        phone: "",
        zip: "",
        addr1: "",
        addr2: "",
      });
    }
  };

  const validateBuyReq = (activeTab, buyReq) => {
    if (activeTab === 1) {
      if (!buyReq.car_weight_cd || buyReq.car_weight_cd === "") {
        return "넘버 구분을 선택하세요.";
      }
      if (parseInt(buyReq.car_weight_cd) === 2301) {
        if (parseFloat(buyReq.car_weight) > 1.5) {
          return "차량 톤수를 확인하세요.";
        }
        if (!buyReq.ecar_yn || buyReq.ecar_yn === "") {
          return "차량구분을 선택하세요.";
        }
      } else if (parseInt(buyReq.car_weight_cd) === 2302) {
        if (
          parseFloat(buyReq.car_weight) <= 1.5 ||
          parseFloat(buyReq.car_weight) > 16
        ) {
          return "차량 톤수를 확인하세요.";
        }
      } else {
        if (parseFloat(buyReq.car_weight) <= 16) {
          return "차량 톤수를 확인하세요.";
        }
      }
      if (
        parseFloat(buyReq.car_weight) <= 0 ||
        parseFloat(buyReq.car_weight) > 100
      ) {
        return "차량 톤수를 확인하세요.";
      }
      if (isCar) {
        if (!buyReq.car_yyyymm || buyReq.car_yyyymm === "") {
          return "차량연식을 입력하세요.";
        }
        if (!/^(19|20)\d{2}(0[1-9]|1[0-2])$/.test(buyReq.car_yyyymm)) {
          return "차량연식을 확인하세요.";
        }
        if (!buyReq.car_file) {
          return "자동차등록증을 확인 해 주세요.";
        }
      }
      // 선택사항
      if (userType === 202 && !buyReq.brno_file) {
        return "사업자등록증을 입력하세요.";
      }
    }

    if (activeTab === 2) {
      if (buyReq.nambaList.length < 1) {
        return "구매/임대 대수를 확인 해 주세요.";
      }
      if (parseInt(buyReq.deal_category_cd) === 2102) {
        if (!buyReq.whole_yn || buyReq.whole_yn === "") {
          return "구매방법을 선택하세요.";
        }
        // 선택사항
        // let cnt = 0;
        // let carFileCnt = 0;
        // for (const carFile of buyReq.car_files) {
        //   if (carFile !== null && carFile !== undefined) {
        //     carFileCnt += 1;
        //   }
        // }
        for (const namba of buyReq.nambaList) {
          // 선택사항(공T)
          // if (namba.jigong_flag === "G") {
          //   cnt += 1;
          // }
          if (!namba.jigong_flag || namba.jigong_flag === "") {
            return "넘버종류를 선택하세요.";
          }
          if (!namba.car_weight_cd || namba.car_weight_cd === "") {
            return "톤수를 선택하세요.";
          }
          if (
            !namba.price ||
            namba.price === "" ||
            parseInt(namba.price) <= 0
          ) {
            return "구매금액을 입력하세요.";
          }
          if (namba.price % 10 !== 0) {
            return "구매금액을 확인해 주세요(10만원 단위)";
          } 
          if (namba.jigong_flag === "G") {
            if (!namba.car_yyyymm || namba.car_yyyymm === "") {
              return "차량연식을 입력하세요.";
            }
            if (!/^(19|20)\d{2}(0[1-9]|1[0-2])$/.test(namba.car_yyyymm)) {
              return "차량연식을 확인하세요.";
            }
          }
        }
        // 선택사항
        // if (cnt !== carFileCnt) {
        //   return "자동차등록증을 확인해 주세요.";
        // }
        if (userType === 202 && !buyReq.brno_file) {
          return "사업자등록증을 입력하세요.";
        }
        // if (isUserCorp && !buyReq.brno_file) {
        //   return "사업자등록증을 입력하세요.";
        // }
      }
      if (buyReq.deal_category_cd === "2103") {
        let cnt = 0;
        let carFileCnt = 0;
        for (const carFile of buyReq.car_files) {
          if (carFile !== null && carFile !== undefined) {
            carFileCnt += 1;
          }
        }
        for (const namba of buyReq.nambaList) {
          cnt += 1;
          if (!namba.car_weight_cd || namba.car_weight_cd === "") {
            return "톤수를 선택하세요.";
          }
          if (
            !namba.price ||
            namba.price === "" ||
            parseInt(namba.price) <= 0
          ) {
            return "임대금액을 입력하세요.";
          }
          if (namba.price % 10 !== 0) {
            return "임대금액을 확인해 주세요(10만원 단위)";
          }
          if (
            !namba.ji_price ||
            namba.ji_price === "" ||
            parseInt(namba.ji_price) <= 0
          ) {
            return "지입료를 입력하세요.";
          }
        }
        if (cnt !== carFileCnt) {
          return "자동차등록증을 확인해 주세요.";
        }
        // if (!buyReq.fbl_file) {
        //   return "화물자동차운송사업허가증을 확인해 주세요.";
        // }
      }
      if (isUserCorp) {
        if (!buyReq.corp_nm || buyReq.corp_nm === "") {
          return "법인명을 입력하세요.";
        }
      }
    }

    if (activeTab === 3) {
      if (!buyReq.region_cd || buyReq.region_cd === "") {
        return "구매자 지역을 선택하세요.";
      }
      // 선택사항
      if (userType === 202 && !buyReq.brno_file) {
        return "사업자등록증을 입력하세요.";
      }
    }
    if (userType === 202) {
      if (!buyReq.corp_nm || buyReq.corp_nm === "") {
        return "법인명을 입력하세요.";
      }
      // 선택사항
      // if (!buyReq.brno_file) {
      //   return "사업자등록증을 입력하세요.";
      // }
    }

    if (activeTab === 1 || activeTab === 3) {
      if (!buyReq.price || buyReq.price === "" || parseInt(buyReq.price) <= 0) {
        return "구매금액을 입력하세요.";
      }
      if (buyReq.price % 10 !== 0) {
        return "구매금액을 확인해 주세요(10만원 단위)";
      }
      if (
        parseInt(buyReq.price) < newPrice - 400 ||
        parseInt(buyReq.price) > newPrice + 400
      ) {
        return "구매금액을 확인해 주세요 (시세의 상하 400만원 범위)";
      }
    }
    if (!buyReq.deal_category_cd || buyReq.deal_category_cd === "") {
      return "매물 종류를 선택하세요.";
    }

    if (!buyReq.user_nm || buyReq.user_nm === "" || buyReq.user_nm === undefined) {
      return "이름을 입력하세요.";
    }

    if (!buyReq.phone || buyReq.phone === "" || buyReq.phone === undefined) {
      return "휴대폰 번호를 입력하세요.";
    }

    if (!buyReq.zip || buyReq.zip === "" || buyReq.zip === undefined) {
      return "우편번호를 입력하세요.";
    }

    if (!buyReq.addr1 || buyReq.addr1 === "" || buyReq.addr1 === undefined) {
      return "도로명 주소를 입력하세요.";
    }

    if (!buyReq.addr2 || buyReq.addr2 === "" || buyReq.addr2 === undefined) {
      return "상세 주소를 입력하세요.";
    }
  };

  const handleSubmit = async () => {
    if (isClicked) {
      return;
    }
    setIsClicked(true);
    const msg = validateBuyReq(activeTab, buyReq);
    if (msg) {
      setIsClicked(false);
      return toast.error(msg);
    }

    try {
      let response = null;
      if (activeTab === 1) {
        response = await postNumbaInsBuyIndi(buyReq);
      }
      if (activeTab === 2) {
        const modify = {
          ...buyReq,
          nambaList: JSON.stringify(nambaList),
        };
        console.log('data', modify);
        response = await postNumbaInsBuyCorp(modify);
      }
      if (activeTab === 3) {
        console.log(buyReq);
        response = await postNumbaInsBuyLic(buyReq);
      }
      if (response.code !== 1000) {
        setIsClicked(false);
        toast.error("등록 실패");
        return;
      }
      toast.success("등록되었습니다.");
      navigate("/buying");
    } catch (e) {
      setIsClicked(false);
      toast.error("등록 서버 오류");
    }
  };

  const setCntChange = () => {
    if (buyReq.deal_category_cd === "") {
      toast.error("매매/임대를 선택 해 주세요");
      return;
    }
    const inputValue = document.getElementById("buyCnt").value;
    const value = parseInt(inputValue);
    if (inputValue === "" || value < 1) {
      toast.error("구매대수를 확인 해 주세요");
      return;
    }
    setBuyCnt(value);
    setBuyReq((prevState) => ({
      ...prevState,
      car_files: [],
    }));
  };

  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;

    const updatedNambaList = [...nambaList];

    const yyyymm =
      document.getElementById(`yyyy${index}`)?.value +
      document.getElementById(`mm${index}`)?.value;

    if (name.startsWith("jigong_flag")) {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        jigong_flag: value,
      };
      setNambaList(updatedNambaList);
    } else if (name === "price") {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: yyyymm,
      };
      setNambaList(updatedNambaList);
    } else if (name === "ji_price") {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        [name]: value.replace(/[^0-9]/g, ""),
      };
      setNambaList(updatedNambaList);
    } else {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        [name]: value,
        car_yyyymm: yyyymm,
      };
      setNambaList(updatedNambaList);
    }
  };

  if (activeTab === 1) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div className="pc_view">
              <div className="FormHeading_div">
                <h1 className="Form_Heading">삽니다 등록하기</h1>
              </div>
            </div>
            <div className="">
              <div className="FormDetails_Div">
                <div className="FormDiv">
                  <div className="d-flex justify-content-between">
                    <h3 className="FormDetails_Heading">매물정보 등록</h3>
                  </div>
                  <div className="FormDetails_Div">
                    <div className="">
                      <table className="table table-bordered table-responsive table-form">
                        <tbody>
                          <tr>
                            <th style={{ width: "25.92%" }}>매물 종류</th>
                            <td>
                              <div className="d-flex">
                                <Form.Check
                                  inline
                                  label="개인넘버"
                                  name="deal_category_cd"
                                  type="radio"
                                  value={2101}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  onClick={() => setActiveTab(1)}
                                  checked
                                />
                                <Form.Check
                                  inline
                                  label="법인넘버"
                                  name="deal_category_cd"
                                  type="radio"
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  onClick={() => setActiveTab(2)}
                                />
                                <Form.Check
                                  inline
                                  label="주선면허"
                                  name="deal_category_cd"
                                  type="radio"
                                  value={2104}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  onClick={() => setActiveTab(3)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>넘버구분</th>
                            <td>
                              <div className="td_chk3_outer">
                                <Form.Check
                                  inline
                                  label="소형(용달 1.5톤 이하)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2301}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                // checked={subActiveTab==='first'}
                                // onClick={() => setSubActiveTab('first')}
                                />
                                <Form.Check
                                  inline
                                  label="중형(1.5톤 초과~16톤 이하)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2302}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                />
                                <Form.Check
                                  inline
                                  label="대형(16톤 초과)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2303}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </td>
                          </tr>
                          {buyReq.car_weight_cd === "2301" && (
                            <tr>
                              <th>차량구분</th>
                              <td>
                                <div className="d-flex">
                                  <Form.Check
                                    inline
                                    label="전기차"
                                    name="ecar_yn"
                                    type="radio"
                                    value={`Y`}
                                    className="CustomCheckbox w-33"
                                    onChange={handleInputChange}
                                  />
                                  <Form.Check
                                    inline
                                    label="일반차"
                                    name="ecar_yn"
                                    type="radio"
                                    value={`N`}
                                    className="CustomCheckbox w-33"
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>톤수입력</th>
                            <td>
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control Table_Input w-300px w-sm-175px"
                                  name={"car_weight"}
                                  value={buyReq.car_weight}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>구매금액</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  placeholder="10만원 단위로 입력"
                                  type="text"
                                  className="form-control Table_Input w-300px w-sm-175px me-2"
                                  name={"price"}
                                  onChange={handleInputChange}
                                  value={buyReq.price}
                                />
                                <span className="text-nowrap">만원</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>차량보유여부</th>
                            <td>
                              <div className="td_chk3_outer">
                                <Form.Check
                                  inline
                                  label="있음"
                                  name="is_car"
                                  type="radio"
                                  // value={isCar}
                                  className="CustomCheckbox w-33"
                                  checked={isCar}
                                  onChange={() => setIsCar(true)}
                                // onChange={handleInputChange}
                                />
                                <Form.Check
                                  inline
                                  label="없음"
                                  name="is_car"
                                  type="radio"
                                  // value={!isCar}
                                  className="CustomCheckbox w-33"
                                  // onChange={handleInputChange}
                                  checked={!isCar}
                                  onChange={() => {
                                    setIsCar(false);
                                    setBuyReq((prev) => ({
                                      ...prev,
                                      car_yyyymm: "",
                                    }));
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                          {isCar && (
                            <>
                              <tr>
                                <th>차량연식</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <select
                                      className="form-select Table_Select w-100px me-10px"
                                      id={"yyyy"}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">년</option>
                                      {years.map((item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                    <select
                                      className="form-select Table_Select w-75px w-sm-65px"
                                      id={"mm"}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">월</option>
                                      {month.map((item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>자동차등록증</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <label
                                      className="fileBtn"
                                      htmlFor="carFile"
                                    >
                                      파일선택
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id="carFile"
                                      onChange={handleCarFileSelect}
                                    />
                                    <div className="txt_attach ms-2">
                                      {renderCarFileInfo()}
                                    </div>
                                  </div>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#c1c1c1"
                                  >
                                    ※ 사진 또는 이미지 파일로 제출해 주세요.
                                  </R.Btn_Value>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#f60"
                                  >
                                    &nbsp;(관리자 확인용입니다)
                                  </R.Btn_Value>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frm_request_wrapper">
              <div className="frm_request_container2">
                <h3 className="FormDetails_Heading m-0">구매자정보 등록</h3>
                <div className="CustomCheckerBox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={isChecked}
                  />
                  <label
                    className="form-check-label f-medium"
                    htmlFor="inlineCheckbox7"
                  >
                    회원정보와 동일
                  </label>
                </div>
              </div>
              <div className="FormDetails_Div">
                <div className="">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      {userType === 202 && (
                        <tr>
                          <th style={{ width: "25.92%" }}>법인명</th>
                          <td>
                            <input
                              type="text"
                              className="form-control Table_Input w-100"
                              name={"corp_nm"}
                              value={buyReq.corp_nm}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>{userType === 201 ? "구매자" : "담당자"} 이름</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-300px"
                            name={"user_nm"}
                            value={buyReq.user_nm}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {userType === 201 ? "구매자" : "담당자"} 휴대폰번호
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-300px"
                            name={"phone"}
                            maxLength={11}
                            value={buyReq.phone.replace(/[^0-9]/g, "")}
                            placeholder={"(-)없이 입력"}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{userType === 201 ? "구매자" : "법인회사"} 주소</th>
                        <td>
                          <div className="d-flex flex-column">
                            <div className="daum_search_wrapper">
                              <div className="d-flex w-100 mb-2">
                                <input
                                  type="text"
                                  name={"zip"}
                                  value={buyReq.zip}
                                  onChange={handleInputChange}
                                  className="form-control Table_Input input-zip"
                                  readOnly={true}
                                />
                                <DaumPostcode
                                  className="fileBtn btn btn-primary btn-zip h-sm-31px border-1"
                                  onCompleteCallback={(data) => {
                                    setBuyReq({
                                      ...buyReq,
                                      zip: data.zonecode,
                                      addr1: data.fullAddr,
                                    });
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                name={"addr1"}
                                value={buyReq.addr1}
                                readOnly={true}
                                className="form-control Table_Input ms-0 mb-2"
                              />
                            </div>
                            <input
                              type="text"
                              name={"addr2"}
                              value={buyReq.addr2}
                              onChange={handleInputChange}
                              placeholder={"상세 주소 입력"}
                              className="form-control Table_Input"
                            />
                          </div>
                        </td>
                      </tr>
                      {userType === 202 && (
                        <tr>
                          <th>사업자등록증</th>
                          <td>
                            <div className="d-flex align-items-center">
                              <label className="fileBtn" htmlFor="brnoFile">
                                파일선택
                              </label>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="brnoFile"
                                onChange={handleBrnoFileSelect}
                              />
                              <div className="txt_attach ms-2">
                                {renderBrnoFileInfo()}
                              </div>
                            </div>
                            <R.Btn_Value
                              className="d-lg-inline-block"
                              color="#c1c1c1"
                            >
                              ※ 사진 또는 이미지 파일로 제출해 주세요
                            </R.Btn_Value>
                          </td>
                        </tr>
                      )}
                      {/* {buyReq.deal_category_cd === "2103" && (
                        <tr>
                          <th>화물자동차운송사업허가증</th>
                          <td>
                            <div className="d-flex align-items-center">
                              <label className="fileBtn" htmlFor="fblFile">
                                파일선택
                              </label>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="fblFile"
                                onChange={handleFblFileSelect}
                              />
                              <div className="txt_attach ms-2">
                                {renderFblFileInfo()}
                              </div>
                            </div>
                            <R.Btn_Value
                              className="d-lg-inline-block"
                              color="#c1c1c1"
                            >
                              ※ 사진 또는 이미지 파일로 제출해 주세요
                            </R.Btn_Value>                             
                          </td>
                        </tr>
                      )} */}
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea
                            className="form-control form-textarea"
                            name={"req_comment"}
                            onChange={handleInputChange}
                            value={buyReq.req_comment}
                            maxLength={200}
                          ></textarea>
                          <div className="textarea_count">
                            {buyReq?.req_comment.length}/200
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="Para_Content">
              {/* ※ 구매자정보는 관리자 확인용입니다. */}
              ※ 구매자정보는 관리자 확인용이며 자동차등록증을 반드시 제출해야
              정보 등록이 가능합니다.
            </div>
            <div className="d-flex justify-content-center mt-4">
              <CardButton
                width="390px"
                bg="#FF9C00"
                name="등록하기"
                className="btn-main"
                onClick={handleSubmit}
                // disabled={isClicked}
                loading={isClicked}
              ></CardButton>
            </div>
          </div>
        </div>
      </>
    );
  } else if (activeTab === 2) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div className="pc_view">
              <div className="FormHeading_div">
                <h1 className="Form_Heading">삽니다 등록하기</h1>
              </div>
            </div>
            <div className="">
              <h3 className="FormDetails_Heading">매물정보 등록</h3>
              <div className="FormDetails_Div">
                <div className="">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      <tr>
                        <th style={{ width: "25.92%" }}>유형</th>
                        <td>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="개인넘버"
                              name="deal_category_cd"
                              type="radio"
                              value={2101}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              onClick={() => setActiveTab(1)}
                            />
                            <Form.Check
                              inline
                              label="법인넘버"
                              type="radio"
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              onClick={() => setActiveTab(2)}
                              checked
                            />
                            <Form.Check
                              inline
                              label="주선면허"
                              name="deal_category_cd"
                              type="radio"
                              value={2104}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              onClick={() => setActiveTab(3)}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>매매/임대</th>
                        <td>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="매매"
                              name="deal_category_cd"
                              type="radio"
                              value={2102}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              checked={
                                buyReq.deal_category_cd === "2102" && true
                              }
                            />
                            <Form.Check
                              inline
                              label="임대"
                              name="deal_category_cd"
                              type="radio"
                              value={2103}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              checked={
                                buyReq.deal_category_cd === "2103" && true
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      {buyReq.deal_category_cd === "2102" && (
                        <tr>
                          <th>구매 방법</th>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                inline
                                label="일부구매"
                                name="whole_yn"
                                type="radio"
                                value={`N`}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                              />
                              <Form.Check
                                inline
                                label="전체구매"
                                name="whole_yn"
                                type="radio"
                                value={`Y`}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>
                          {buyReq.deal_category_cd === "2102"
                            ? "구매대수"
                            : "임대대수"}
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="number"
                              id="buyCnt"
                              className="form-control Table_Input w-135px me-2"
                            />{" "}
                            대
                            <button
                              className="btn btn-primary ms-30px"
                              onClick={setCntChange}
                            >
                              넘버등록
                            </button>
                            <span className="pc_view color-orange ms-3">
                              ※ 넘버등록을 클릭후 넘버정보를 등록하세요
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="Para_Content mt-2 mobile_view">
                    ※ 넘버등록을 클릭후 넘버정보를 등록하세요.
                  </div>
                  {buyCnt > 0 &&
                    (buyReq.deal_category_cd === "2102" ? (
                      <>
                        {Array.from({ length: buyCnt }, (_, index) => (
                          <table
                            className="table table-bordered table-responsive table-form mt-10px"
                            key={index}
                          >
                            <thead>
                              <tr className="tr_header">
                                <th colSpan={2}>{index + 1}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th style={{ width: "25.92%" }}>넘버종류</th>
                                <td>
                                  <div className="d-flex">
                                    <div className="CustomCheckbox m-0 w-33">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`jigong_flag_${index}`}
                                        id={`inlineRadio${index}_1`}
                                        value="J"
                                        onChange={(e) =>
                                          handleInputChange2(e, index)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`inlineRadio${index}_1`}
                                      >
                                        지입넘버
                                      </label>
                                    </div>
                                    <div className="CustomCheckbox m-0 w-33x">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`jigong_flag_${index}`}
                                        id={`inlineRadio${index}_2`}
                                        value="G"
                                        onChange={(e) =>
                                          handleInputChange2(e, index)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`inlineRadio${index}_2`}
                                      >
                                        공T/E넘버
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>톤수선택</th>
                                <td>
                                  <div className="d-flex">
                                    <select
                                      className="form-select Table_Select w-300px w-sm-175px"
                                      name={"car_weight_cd"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">
                                        톤수를 선택하세요
                                      </option>
                                      {corpCarCdList.map((corpCarCd, index) => (
                                        <option
                                          key={index}
                                          value={corpCarCd.cd_seq}
                                        >
                                          {corpCarCd.cd_nm}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              {nambaList[index]?.jigong_flag === "G" && (
                                <>
                                  <tr>
                                    <th>차량연식</th>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <select
                                          className="form-select Table_Select w-100px me-10px"
                                          id={`yyyy${index}`}
                                          onChange={(e) =>
                                            handleInputChange2(e, index)
                                          }
                                        >
                                          <option value="">년</option>
                                          {years.map((item) => (
                                            <option value={item} key={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                        <select
                                          className="form-select Table_Select w-75px w-sm-65px"
                                          id={`mm${index}`}
                                          onChange={(e) =>
                                            handleInputChange2(e, index)
                                          }
                                        >
                                          <option value="">월</option>
                                          {month.map((item) => (
                                            <option value={item} key={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>자동차등록증(선택)</th>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <label
                                          className="fileBtn"
                                          htmlFor={`carFile${index}`}
                                        >
                                          파일선택
                                        </label>
                                        <input
                                          type="file"
                                          style={{ display: "none" }}
                                          id={`carFile${index}`}
                                          onChange={(e) =>
                                            handleCarFilesSelect(e, index)
                                          }
                                        />
                                        <div className="txt_attach ms-2">
                                          {renderCarFilesInfo(index)}
                                        </div>
                                      </div>
                                      <R.Btn_Value
                                        className="d-lg-inline-block"
                                        color="#c1c1c1"
                                      >
                                        ※ 사진 또는 이미지 파일로 제출해 주세요.
                                      </R.Btn_Value>
                                      <R.Btn_Value
                                        className="d-lg-inline-block"
                                        color="#f60"
                                      >
                                        &nbsp;(관리자 확인용입니다)
                                      </R.Btn_Value>
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <th>구매금액</th>
                                <td>                               
                                  <div className="d-flex align-items-center">
                                    <input                                    
                                      placeholder="10만원 단위로 입력"
                                      type="text"
                                      className="form-control Table_Input w-300px w-sm-175px"
                                      width="180px"
                                      name={"price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.price}
                                    />
                                    <span className="text-nowrap ms-2">
                                      만원
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: buyCnt }, (_, index) => (
                          <table
                            className="table table-bordered table-responsive table-form mt-10px"
                            key={index}
                          >
                            <thead>
                              <tr className="tr_header">
                                <th colSpan={2}>{index + 1}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>톤수선택</th>
                                <td>
                                  <div className="d-flex">
                                    <select
                                      className="form-select Table_Select w-300px w-sm-175px"
                                      name={"car_weight_cd"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">
                                        톤수를 선택하세요
                                      </option>
                                      {corpCarCdList.map((corpCarCd, index) => (
                                        <option
                                          key={index}
                                          value={corpCarCd.cd_seq}
                                        >
                                          {corpCarCd.cd_nm}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>임대금액</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      placeholder="10만원 단위로 입력"
                                      className="form-control Table_Input w-300px "
                                      type="text"
                                      name={"price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.price}
                                    />
                                    <span className="text-nowrap ms-2">
                                      만원
                                    </span>
                                    {/* <R.Table_Input
                                      className="form-control Table_Input w-sm-175px me-10px ms-0"
                                      width="180px"
                                      name={"price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.price}
                                    ></R.Table_Input>
                                    <span className="text-nowrap">만원</span> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>지입료</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      placeholder="1만원 단위로 입력"
                                      className="form-control Table_Input w-300px "
                                      type="text"
                                      name={"ji_price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.ji_price}
                                    />
                                    <span className="text-nowrap ms-2">만원</span>
                                    {/* <R.Table_Input
                                      className="form-control Table_Input w-sm-175px me-10px ms-0"
                                      width="180px"
                                      name={"ji_price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.ji_price}
                                    ></R.Table_Input>
                                    <span className="text-nowrap">만원</span> */}
                                    {/*<R.Para_Content>(VAT 별도)</R.Para_Content>*/}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>자동차등록증</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <label
                                      className="fileBtn"
                                      htmlFor={`carFile${index}`}
                                    >
                                      파일선택
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id={`carFile${index}`}
                                      onChange={(e) =>
                                        handleCarFilesSelect(e, index)
                                      }
                                    />
                                    <div className="txt_attach ms-2">
                                      {renderCarFilesInfo(index)}
                                    </div>
                                  </div>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#c1c1c1"
                                  >
                                    ※ 사진 또는 이미지 파일로 제출해 주세요.
                                  </R.Btn_Value>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#f60"
                                  >
                                    &nbsp;(관리자 확인용입니다)
                                  </R.Btn_Value>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))}
                      </>
                    ))}
                </div>
              </div>
            </div>

            <div className="frm_request_wrapper">
              <div className="frm_request_container2">
                <h3 className="FormDetails_Heading m-0">구매자정보 등록</h3>
                <div className="CustomCheckerBox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox7"
                    value="option1"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label f-medium"
                    htmlFor="inlineCheckbox7"
                  >
                    회원정보와 동일
                  </label>
                </div>
              </div>
            </div>
            <div className="FormDetails_Div">
              <table className="table table-bordered table-responsive table-form">
                {/* 법인로그인일때 - 구분선택 불필요 */}
                {userType === 202 && (
                  <tbody>
                    <tr>
                      <th style={{ width: "25.92%" }}>법인명</th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-100"
                          name={"corp_nm"}
                          value={buyReq.corp_nm}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>담당자 이름</th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-300px"
                          name={"user_nm"}
                          value={buyReq.user_nm}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>담당자 휴대폰번호</th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-300px"
                          name={"phone"}
                          maxLength={11}
                          value={buyReq.phone.replace(/[^0-9]/g, "")}
                          placeholder={"(-)없이 입력"}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>법인회사 주소</th>
                      <td>
                        <div className="d-flex flex-column">
                          <div className="daum_search_wrapper">
                            <div className="d-flex w-100 mb-2">
                              <input
                                type="text"
                                name={"zip"}
                                value={buyReq.zip}
                                onChange={handleInputChange}
                                className="form-control Table_Input input-zip"
                                readOnly={true}
                              />
                              <DaumPostcode
                                className="fileBtn btn btn-primary btn-zip h-sm-31px border-1"
                                onCompleteCallback={(data) => {
                                  setBuyReq({
                                    ...buyReq,
                                    zip: data.zonecode,
                                    addr1: data.fullAddr,
                                  });
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              name={"addr1"}
                              value={buyReq.addr1}
                              readOnly={true}
                              className="form-control Table_Input ms-0 mb-2"
                            />
                          </div>
                          <input
                            type="text"
                            name={"addr2"}
                            value={buyReq.addr2}
                            onChange={handleInputChange}
                            placeholder={"상세 주소 입력"}
                            className="form-control Table_Input"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        사업자등록증
                        {buyReq.deal_category_cd === "2102" ? "" : "(선택)"}
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <label className="fileBtn" htmlFor="brnoFile">
                            파일선택
                          </label>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="brnoFile"
                            onChange={handleBrnoFileSelect}
                          />
                          <div className="txt_attach ms-2">
                            {renderBrnoFileInfo()}
                          </div>
                        </div>
                        <R.Btn_Value
                          className="d-lg-inline-block"
                          color="#c1c1c1"
                        >
                          ※ 사진 또는 이미지 파일로 제출해 주세요
                        </R.Btn_Value>
                      </td>
                    </tr>
                    {/* {buyReq.deal_category_cd === "2103" && (
                      <tr>
                        <th>화물자동차운송사업허가증</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="fblFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="fblFile"
                              onChange={handleFblFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderFblFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                    )} */}
                    <tr>
                      <th>요청내용</th>
                      <td>
                        <textarea
                          className="form-control form-textarea"
                          name={"req_comment"}
                          onChange={handleInputChange}
                          value={buyReq.req_comment}
                          maxLength={200}
                        ></textarea>
                        <div className="textarea_count">
                          {buyReq?.req_comment.length}/200
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
                {/* 개인로그인이면서 매매 - 법인과 개인선택가능 */}
                {userType === 201 && buyReq.deal_category_cd === "2102" && (
                  <tbody>
                    <tr>
                      <th style={{ width: "25.92%" }}>구매자 구분</th>
                      <td>
                        <div className="d-flex">
                          <Form.Check
                            inline
                            label="법인"
                            name="isUserCorp"
                            type="radio"
                            className="CustomCheckbox w-33"
                            // onChange={handleInputChange}
                            onChange={() => setIsUserCorp(true)}
                            checked={isUserCorp === true}
                          />
                          <Form.Check
                            inline
                            label="개인"
                            name="isUserCorp"
                            type="radio"
                            className="CustomCheckbox w-33"
                            onChange={() => {
                              setIsUserCorp(false); 
                              setBuyReq((prev) => ({
                                ...prev,
                                corp_nm: "",
                              }));                              
                            }}
                            checked={isUserCorp === false}
                          />
                        </div>
                      </td>
                    </tr>
                    {isUserCorp && (
                      <tr>
                        <th style={{ width: "25.92%" }}>법인명</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-100"
                            name={"corp_nm"}
                            value={buyReq.corp_nm}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>{isUserCorp ? "담당자 이름" : "구매자 이름"} </th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-300px"
                          name={"user_nm"}
                          value={buyReq.user_nm}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {isUserCorp ? "담당자 휴대폰번호" : "구매자 휴대폰번호"}{" "}
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-300px"
                          name={"phone"}
                          maxLength={11}
                          value={buyReq.phone.replace(/[^0-9]/g, "")}
                          placeholder={"(-)없이 입력"}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{isUserCorp ? "법인회사 주소" : "구매자 주소"}</th>
                      <td>
                        <div className="d-flex flex-column">
                          <div className="daum_search_wrapper">
                            <div className="d-flex w-100 mb-2">
                              <input
                                type="text"
                                name={"zip"}
                                value={buyReq.zip}
                                onChange={handleInputChange}
                                className="form-control Table_Input input-zip"
                                readOnly={true}
                              />
                              <DaumPostcode
                                className="fileBtn btn btn-primary btn-zip h-sm-31px border-1"
                                onCompleteCallback={(data) => {
                                  setBuyReq({
                                    ...buyReq,
                                    zip: data.zonecode,
                                    addr1: data.fullAddr,
                                  });
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              name={"addr1"}
                              value={buyReq.addr1}
                              readOnly={true}
                              className="form-control Table_Input ms-0 mb-2"
                            />
                          </div>
                          <input
                            type="text"
                            name={"addr2"}
                            value={buyReq.addr2}
                            onChange={handleInputChange}
                            placeholder={"상세 주소 입력"}
                            className="form-control Table_Input"
                          />
                        </div>
                      </td>
                    </tr>
                    {/* {isUserCorp && ( */}
                    <tr>
                      <th>사업자등록증(선택)
                        {/* {isUserCorp ? "" : "(선택)"} */}
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <label className="fileBtn" htmlFor="brnoFile">
                            파일선택
                          </label>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="brnoFile"
                            onChange={handleBrnoFileSelect}
                          />
                          <div className="txt_attach ms-2">
                            {renderBrnoFileInfo()}
                          </div>
                        </div>
                        <R.Btn_Value
                          className="d-lg-inline-block"
                          color="#c1c1c1"
                        >
                          ※ 사진 또는 이미지 파일로 제출해 주세요
                        </R.Btn_Value>
                      </td>
                    </tr>
                    {/* )} */}
                    {/* {buyReq.whole_yn === 'N' && !isUserCorp && ( */}
                    {buyReq.whole_yn === 'N' && (
                      <tr>
                        <th>화물자동차운송사업허가증(선택)</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="fblFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="fblFile"
                              onChange={handleFblFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderFblFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>요청내용</th>
                      <td>
                        <textarea
                          className="form-control form-textarea"
                          name={"req_comment"}
                          onChange={handleInputChange}
                          value={buyReq.req_comment}
                          maxLength={200}
                        ></textarea>
                        <div className="textarea_count">
                          {buyReq?.req_comment.length}/200
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
                {/* 개인로그인이면서 임대 - 법인과 개인중 선택가능 */}
                {userType === 201 && buyReq.deal_category_cd === "2103" && (
                  <tbody>
                    <tr>
                      <th style={{ width: "25.92%" }}>임차인 구분</th>
                      <td>
                        <div className="d-flex">
                          <Form.Check
                            inline
                            label="법인"
                            name="isUserCorp"
                            type="radio"
                            className="CustomCheckbox w-33"
                            // onChange={handleInputChange}
                            onChange={() => setIsUserCorp(true)}
                            checked={isUserCorp === true}
                          />
                          <Form.Check
                            inline
                            label="개인"
                            name="isUserCorp"
                            type="radio"
                            className="CustomCheckbox w-33"
                            onChange={() => {
                              setIsUserCorp(false); 
                              setBuyReq((prev) => ({
                                ...prev,
                                corp_nm: "",
                              }));                              
                            }}                            
                            checked={isUserCorp === false}
                          />
                        </div>
                      </td>
                    </tr>
                    {isUserCorp && (
                      <tr>
                        <th style={{ width: "25.92%" }}>법인명</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-100"
                            name={"corp_nm"}
                            value={buyReq.corp_nm}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>{isUserCorp ? "담당자 이름" : "임차인 이름"} </th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-300px"
                          name={"user_nm"}
                          value={buyReq.user_nm}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {isUserCorp ? "담당자 휴대폰번호" : "임차인 휴대폰번호"}{" "}
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control Table_Input w-300px"
                          name={"phone"}
                          maxLength={11}
                          value={buyReq.phone.replace(/[^0-9]/g, "")}
                          placeholder={"(-)없이 입력"}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{isUserCorp ? "법인회사 주소" : "임차인 주소"}</th>
                      <td>
                        <div className="d-flex flex-column">
                          <div className="daum_search_wrapper">
                            <div className="d-flex w-100 mb-2">
                              <input
                                type="text"
                                name={"zip"}
                                value={buyReq.zip}
                                onChange={handleInputChange}
                                className="form-control Table_Input input-zip"
                                readOnly={true}
                              />
                              <DaumPostcode
                                className="fileBtn btn btn-primary btn-zip h-sm-31px border-1"
                                onCompleteCallback={(data) => {
                                  setBuyReq({
                                    ...buyReq,
                                    zip: data.zonecode,
                                    addr1: data.fullAddr,
                                  });
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              name={"addr1"}
                              value={buyReq.addr1}
                              readOnly={true}
                              className="form-control Table_Input ms-0 mb-2"
                            />
                          </div>
                          <input
                            type="text"
                            name={"addr2"}
                            value={buyReq.addr2}
                            onChange={handleInputChange}
                            placeholder={"상세 주소 입력"}
                            className="form-control Table_Input"
                          />
                        </div>
                      </td>
                    </tr>
                    {isUserCorp && (
                      <tr>
                        <th>사업자등록증(선택)</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="brnoFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="brnoFile"
                              onChange={handleBrnoFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderBrnoFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <th>화물자동차운송사업허가증</th>
                      <td>
                        <div className="d-flex align-items-center">
                          <label className="fileBtn" htmlFor="fblFile">
                            파일선택
                          </label>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="fblFile"
                            onChange={handleFblFileSelect}
                          />
                          <div className="txt_attach ms-2">
                            {renderFblFileInfo()}
                          </div>
                        </div>
                        <R.Btn_Value
                          className="d-lg-inline-block"
                          color="#c1c1c1"
                        >
                          ※ 사진 또는 이미지 파일로 제출해 주세요
                        </R.Btn_Value>
                      </td>
                    </tr> */}
                    <tr>
                      <th>요청내용</th>
                      <td>
                        <textarea
                          className="form-control form-textarea"
                          name={"req_comment"}
                          onChange={handleInputChange}
                          value={buyReq.req_comment}
                          maxLength={200}
                        ></textarea>
                        <div className="textarea_count">
                          {buyReq?.req_comment.length}/200
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {buyReq.deal_category_cd === "2103" && (
                <div className="Para_Content">
                  ※ 임차인정보는 관리자 확인용입니다.
                  {/* ※ 임차인정보는 관리자 확인용이며{" "}
                  <span>화물자동차운송사업허가증</span>을 반드시 제출해야 정보
                  등록이 가능합니다. */}
                </div>
              )}
              <div className="d-flex justify-content-center mt-4">
                <CardButton
                  width="390px"
                  bg="#FF9C00"
                  name="등록하기"
                  className="btn-main"
                  onClick={handleSubmit}
                  loading={isClicked}
                ></CardButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (activeTab === 3) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div>
              <div className="pc_view">
                <div className="FormHeading_div">
                  <h1 className="Form_Heading">삽니다 등록하기</h1>
                </div>
              </div>
              <div className="">
                <h3 className="FormDetails_Heading">매물정보 등록</h3>
                <div className="FormDetails_Div">
                  <div className="">
                    <table className="table table-bordered table-responsive table-form">
                      <tbody>
                        <tr>
                          <th style={{ width: "25.92%" }}>유형</th>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                inline
                                label="개인넘버"
                                name="deal_category_cd"
                                type="radio"
                                value={2101}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                onClick={() => setActiveTab(1)}
                              />
                              <Form.Check
                                inline
                                label="법인넘버"
                                name="deal_category_cd"
                                type="radio"
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                onClick={() => setActiveTab(2)}
                              />
                              <Form.Check
                                inline
                                label="주선면허"
                                name="deal_category_cd"
                                type="radio"
                                value={2104}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                onClick={() => setActiveTab(3)}
                                checked
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>구매금액</th>
                          <td>
                            <div className="d-flex align-items-center mb-2">
                              <input
                                className="form-control Table_Input w-300px me-2"
                                width="180px"
                                name={"price"}
                                onChange={handleInputChange}
                                value={buyReq.price}
                              />
                              <span className="text-nowrap ms-2">만원</span>
                            </div>
                            <div className="Para_Content mt-0 mb-2">
                              ※ 금액은 10만원 단위로 입력
                            </div>
                            <div className="td_caption mb-2">
                              마지막 1개월 평균 거래가 : <span>5,260</span>
                              만원 + 500만원 / 구매금액는 <span>5,760</span>
                              만원 보다 높게 입력할 수 없음
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>구매자 지역</th>
                          <td>
                            <select
                              className="form-select Table_Select w-300px"
                              name={"region_cd"}
                              onChange={handleInputChange}
                            >
                              <option>지역을 선택하세요</option>
                              {regionCdList.map((region, index) => (
                                <option key={index} value={region.cd_seq}>
                                  {region.cd_nm}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="frm_request_wrapper">
                <div className="frm_request_container2">
                  <h3 className="FormDetails_Heading m-0">구매자정보 등록</h3>
                  <div className="CustomCheckerBox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox7"
                      value="option1"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label f-medium"
                      htmlFor="inlineCheckbox7"
                    >
                      회원정보와 동일
                    </label>
                  </div>
                </div>
                <div className="FormDetails_Div">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      {userType === 202 && (
                        <tr>
                          <th style={{ width: "25.92%" }}>법인명</th>
                          <td>
                            <input
                              type="text"
                              className="form-control Table_Input w-100"
                              name={"corp_nm"}
                              value={buyReq.corp_nm}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>{userType === 201 ? "구매자" : "담당자"} 이름</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-300px"
                            name={"user_nm"}
                            value={buyReq.user_nm}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {userType === 201 ? "구매자" : "담당자"} 휴대폰번호
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-300px"
                            name={"phone"}
                            maxLength={11}
                            value={buyReq.phone.replace(/[^0-9]/g, "")}
                            placeholder={"(-)없이 입력"}
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{userType === 201 ? "구매자" : "법인회사"} 주소</th>
                        <td>
                          <div className="d-flex flex-column">
                            <div className="daum_search_wrapper">
                              <div className="d-flex w-100 mb-2">
                                <input
                                  type="text"
                                  name={"zip"}
                                  value={buyReq.zip}
                                  onChange={handleInputChange}
                                  className="form-control Table_Input input-zip"
                                  readOnly={true}
                                />
                                <DaumPostcode
                                  className="fileBtn btn btn-primary btn-zip h-sm-31px border-1"
                                  onCompleteCallback={(data) => {
                                    setBuyReq({
                                      ...buyReq,
                                      zip: data.zonecode,
                                      addr1: data.fullAddr,
                                    });
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                name={"addr1"}
                                value={buyReq.addr1}
                                readOnly={true}
                                className="form-control Table_Input ms-0 mb-2"
                              />
                            </div>
                            <input
                              type="text"
                              name={"addr2"}
                              value={buyReq.addr2}
                              onChange={handleInputChange}
                              placeholder={"상세 주소 입력"}
                              className="form-control Table_Input"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>사업자등록증
                          {userType === 201 && "(선택)"}
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="brnoFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="brnoFile"
                              onChange={handleBrnoFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderBrnoFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                      {/* {userType === 202 && (
                        <tr>
                          <th>화물자동차운송 주선사업허가증</th>
                          <td>
                            <div className="d-flex align-items-center">
                              <label className="fileBtn" htmlFor="fablFile">
                                파일선택
                              </label>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="fablFile"
                                onChange={handleFablFileSelect}
                              />
                              <div className="txt_attach ms-2">
                                {renderFablFileInfo()}
                              </div>
                            </div>
                            <R.Btn_Value
                              className="d-lg-inline-block"
                              color="#c1c1c1"
                            >
                              ※ 사진 또는 이미지 파일로 제출해 주세요
                            </R.Btn_Value>
                          </td>
                        </tr>
                      )} */}
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea
                            className="form-control form-textarea"
                            name={"req_comment"}
                            onChange={handleInputChange}
                            value={buyReq.req_comment}
                            maxLength={200}
                          ></textarea>
                          <div className="textarea_count">
                            {buyReq?.req_comment.length}/200
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="Para_Content">
                    ※ 구매자정보는 관리자 확인용입니다.
                    {/*※ 구매자정보는 관리자 확인용이며{" "}
                     <span>화물자동차운송 주선사업허가증을</span> 반드시 제출해야
                    정보 등록이 가능합니다. */}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <CardButton
                      width="390px"
                      bg="#FF9C00"
                      name="등록하기"
                      className="btn-main"
                      onClick={handleSubmit}
                      loading={isClicked}
                    ></CardButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default BuyingFormDetails;
