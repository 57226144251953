import React, { useEffect, useState, useRef } from "react";
import { Table } from "react-bootstrap";
import { postNumbaCodeList } from "../../../api/auth";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import * as R from "../../../css/PersonalSellingRequest/requestForm";

export const PersonInfo = (props) => {
  const { reportData, setReportData, isSell, isBuy, isComplete, doneSocialNo, doneReportRegion } = props;
  const inputRef = useRef(null);

  // 신고서 신청완료 : 양도인(isSell), 양수인(isBuy) 모두 출력(신고서조회데이터 받음)
  const Deal = (isSell && 'sell_') || (isBuy && 'buy_') || '';
  console.log(Deal);
  const socialNoMask = reportData[`${Deal}socialno`];
  const socialNoMaskValue = socialNoMask ? socialNoMask.replace(/(\d{6}-\d)\d{6}/, "$1******") : '' ;
  // console.log('socialNoMaskValue', socialNoMaskValue);

  // 신청서 수정 시 주민번호
  console.log(doneSocialNo);
  const [front, back] = doneSocialNo ? doneSocialNo.split('-') :  ['', ''] ;
  // 주민등록번호 입력, 수정값
  const [inputValue1, setInputValue1] = useState(front ? front : null);
  const [inputValue2, setInputValue2] = useState(back ? back : null);

  // 신청서 양수인 지자체 입력
  console.log(doneReportRegion);
  const [localGovCd, setLocalGovCd] = useState(doneReportRegion ? doneReportRegion : '');  // 지자체코드값: cd_seq
  const [localGovCdList, setLocalGovCdList] = useState([]);
  const [localGovFilterList, setLocalGovFilterList] = useState([]);
  const [regionCd, setRegionCd] = useState(''); // cd_nm : 경기도  == (지자체) description : 경기도
  const [regionCdList, setRegionCdList] = useState([]);

  // 주민등록번호 입력
  const handleChange1 = (e) => {
    const value = e.target.value;    
    const cleanValue = value.replace(/[^0-9]/g, ''); // 숫자만 허용
    setInputValue1(cleanValue);
  };
  const handleChange2 = (e) => {
    const value = e.target.value;
    const cleanValue = value.replace(/[^0-9]/g, '');
    setInputValue2(cleanValue);
  }; 

  const handleLocalChange = (e) => {
    const value = e.target.value;
    console.log('지자체선택값', value);    
    setLocalGovCd(value);
  };  

  const getRegionCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1000 });
      if (response.code !== 1000) {
        console.log("지역코드 가져오기 실패");
        return;
      }
      console.log(response.resultList[0]);      
      setRegionCdList(response.resultList[0]);      
    } catch (error) {
      console.log("지역코드 가져오기 실패");
    }
  };

  const getLocalGovCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1200 });
      if (response.code !== 1000) {
        console.log("지자체코드 가져오기 실패");
        return;
      }
      console.log(response.resultList[0]);
      setLocalGovCdList(response.resultList[0]);              
    } catch (error) {
      console.log("지자체코드 가져오기 실패");
    }
  };

  const handleRegionChange = (e) => {
    const value = e.target.value;
    console.log('지역선택값', value);    
    setRegionCd(value);
    setLocalGovCd('');

    const filteredList = localGovCdList.filter(local => local.description === value);
    setLocalGovFilterList(filteredList);
  };  

  // useState는 처음 한번만 렌더링 되어서 초기값이 계속 들어가지 않음. 그래서 렌더링때마다 초기값 설정
  useEffect(() => {
    setInputValue1(front ? front : null);    
    setInputValue2(back ? back : null); 
    console.log(doneReportRegion);
    
    if(doneReportRegion){
      const doneRegionCd = localGovCdList.filter(local => local.cd_seq === doneReportRegion)[0]?.description;
      const filteredList = localGovCdList.filter(local => local.description === doneRegionCd);
      console.log('doneRegionCd', doneRegionCd);      
      setRegionCd(doneRegionCd);
      setLocalGovCd(doneReportRegion ? doneReportRegion : '');
      setLocalGovFilterList(filteredList);
    }
  }, [doneSocialNo, doneReportRegion, localGovCdList]);

  // 신고서 수정시 기존 주민등록번호는 수정안하면 액션(handle)취해지지 않으므로 기존값을 넣어줌
  // 신청,수정시만 setReportData를 props로 가진다. 완료페이지때는 없음.
  useEffect(() => {
    if(setReportData){
      setReportData({ ...reportData, social_no: `${inputValue1}-${inputValue2}`, local_gov_cd: localGovCd });    
    }
  }, [inputValue1, inputValue2, localGovCd]);

  useEffect(() => {
    getRegionCd();
    getLocalGovCd();

    // 페이지가 렌더링될 때 input에 포커스    
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="FormDIv">
      <R.FormDetails_Heading_DIV>
        {isSell && reportData.sellsign_dt ? (
          <>
            양도인<span>(신청완료)</span>
          </>
        ) : isBuy && reportData.buysign_dt ? (
          <>
            양수인<span>(신청완료)</span>
          </>
        ) : !isSell && !isBuy ? (
          "정보등록"
        ) : (
          <>
            {isSell ? "양도인" : "양수인"}
            <span style={{ color: "#ff824e" }}>(신청전)</span>
          </>
        )}
      </R.FormDetails_Heading_DIV>
      <R.FormDetails_Div>
        {/* desktop table */}
        <div className="d-none d-md-block">
          <Table bordered responsive>
            <tbody>
              <tr>
                <R.FormTable_Th width={"20%"}>성명</R.FormTable_Th>
                <td className="paddingLeft">{reportData[`${Deal}user_nm`]}</td>
              </tr>
              <tr>
                <R.FormTable_Th>주민등록번호</R.FormTable_Th>
                {isComplete ? (
                  reportData[`${Deal}socialno`] ? (
                    <td className="paddingLeft">
                      {/* 완료페이지는 마스킹 된 것을 보여줌 */}
                      {socialNoMaskValue}
                    </td>
                  ) : (
                    <td>
                      <span style={{ color: "#ff824e", marginLeft: "35px" }}>
                        (신고서 신청전)
                      </span>
                    </td>
                  )
                ) : (
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{ marginLeft: "40px" }}
                    >
                      <R.SSN_Input
                        name={"social_no"}
                        type="text"
                        value={inputValue1}
                        onChange={handleChange1}
                        maxLength={6}
                        // autocomplete="off"
                        placeholder={"6자리입력"}
                        ref={inputRef}
                      />
                      <div className="m-2">-</div>
                      <R.SSN_Input
                        name={"social_no"}
                        type="password"
                        value={inputValue2}
                        onChange={handleChange2}
                        maxLength={7}
                        // autocomplete="off"
                        placeholder={"7자리입력"}
                      />
                    </div>
                  </td>
                )}
              </tr>
              <tr>
                <R.FormTable_Th>휴대폰번호</R.FormTable_Th>
                <td className="paddingLeft">
                  {formatPhoneNumber(reportData[`${Deal}phone`])}
                </td>
              </tr>
              <tr>
                <R.FormTable_Th rowSpan={2}>주소</R.FormTable_Th>
                <td className="paddingLeft">
                  <span style={{ color: "#888", fontSize: "15px" }}>
                    우편번호
                  </span>
                  &nbsp;&nbsp; {reportData[`${Deal}zip`]}
                </td>
              </tr>
              <tr>
                <td className="paddingLeft">
                  {`${reportData[`${Deal}addr1`]}, ${
                    reportData[`${Deal}addr2`]
                  }`}
                </td>
              </tr>
              {(reportData.deal_str === "buy" ||
                reportData.deal_str === "cbuy" ||
                isBuy) && (
                <tr>
                  <R.FormTable_Th width={"20%"}>지역</R.FormTable_Th>
                  <td className="paddingLeft">
                    {isComplete ? (
                      // localGovCdList
                      //   .filter(
                      //     (data) => data.cd_seq === reportData.local_gov_cd
                      //   )
                      //   .map((data) => (
                      //     <div key={data.cd_seq}>
                      //       {data.description} {data.cd_val}
                      //     </div>
                      //   ))
                      reportData?.local_gov_nm ?? ""
                    ) : (
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select Table_Select w-200px w-sm-175px me-10px"
                          name={"region_cd"}
                          onChange={handleRegionChange}
                          value={regionCd}
                        >
                          <option value="">지역을 선택하세요</option>
                          {regionCdList?.map((region, index) => (
                            <option key={index} value={region.cd_nm}>
                              {region.cd_nm}
                            </option>
                          ))}
                        </select>
                        <select
                          className="form-select Table_Select w-200px w-sm-175px"
                          name={"local_gov_cd"}
                          onChange={handleLocalChange}
                          value={localGovCd}
                        >
                          <option value="">지역을 선택하세요</option>                          
                          {localGovFilterList.map((region, index) => (
                            <option key={index} value={region.cd_seq}>
                              {region.cd_val}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {/* mobile table */}
        <div className="d-block d-md-none">
          <Table bordered responsive className="table-form">
            <tbody>
              <tr>
                <R.FormTable_Th>성명</R.FormTable_Th>
                <td>{reportData[`${Deal}user_nm`]}</td>
              </tr>
              <tr>
                <R.FormTable_Th>주민등록번호</R.FormTable_Th>
                <td>
                  {isComplete ? (
                    reportData[`${Deal}socialno`] ? (
                      socialNoMaskValue
                    ) : (
                      <span style={{ color: "#ff824e" }}>(신고서 신청전)</span>
                    )
                  ) : (
                    <div className="d-flex align-items-center">
                      <R.SSN_Input
                        name={"social_no"}
                        type="text"
                        value={inputValue1}
                        onChange={handleChange1}
                        maxLength={6}
                        autocomplete="off"
                        placeholder={"6자리입력"}
                      />
                      <div className="m-1">-</div>
                      <R.SSN_Input
                        name={"social_no"}
                        type="password"
                        value={inputValue2}
                        onChange={handleChange2}
                        maxLength={7}
                        autocomplete="off"
                        placeholder={"7자리입력"}
                      />
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <R.FormTable_Th>휴대폰번호</R.FormTable_Th>
                <td>{formatPhoneNumber(reportData[`${Deal}phone`])}</td>
              </tr>
              <tr>
                <R.FormTable_Th rowSpan={2}>주소</R.FormTable_Th>
                <td>
                  <span style={{ color: "#888" }}>우편번호</span>&nbsp;&nbsp;{" "}
                  {reportData[`${Deal}zip`]}
                </td>
              </tr>
              <tr>
                <td>
                  {`${reportData[`${Deal}addr1`]}, ${
                    reportData[`${Deal}addr2`]
                  }`}
                </td>
              </tr>
              {(reportData.deal_str === "buy" ||
                reportData.deal_str === "cbuy" ||
                isBuy) && (
                <tr>
                  <R.FormTable_Th>지역</R.FormTable_Th>
                  <td>
                    {isComplete ? (
                      // localGovCdList
                      // .filter(
                      //   (data) => data.cd_seq === reportData.local_gov_cd
                      // )
                      // .map((data) => (
                      //   <div key={data.cd_seq}>
                      //     {data.description} {data.cd_val}
                      //   </div>
                      // ))
                      reportData?.local_gov_nm ?? ""
                    ) : (
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select Table_Select w-sm-120px me-1"
                          name={"region_cd"}
                          onChange={handleRegionChange}
                          value={regionCd}
                        >
                          <option>지역을 선택하세요</option>
                          {regionCdList.map((region, index) => (
                            <option key={index} value={region.cd_nm}>
                              {region.cd_nm}
                            </option>
                          ))}
                        </select>
                        <select
                          className="form-select Table_Select w-sm-120px"
                          name={"local_gov_cd"}
                          onChange={handleLocalChange}
                          value={localGovCd}
                        >
                          <option>지역을 선택하세요</option>                          
                          {localGovFilterList.map((region, index) => (
                            <option key={index} value={region.cd_seq}>
                              {region.cd_val}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </R.FormDetails_Div>
    </div>
  );
}