import React from "react";
import * as B from "../../css/components/button/buttonRound"

const RoundButton = ({ height, width, name, bg, secondName }) => {
    return (
        <>
            <B.BannerButton_Circle height={height} width={width} bg={bg}>{name}
                <B.BtnTxt_Bottom>{secondName}</B.BtnTxt_Bottom>
            </B.BannerButton_Circle>
        </>
    );
};

export default RoundButton;