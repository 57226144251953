import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileFooter from "../../../../componant/mobileFooter";
import { toast } from "wc-toast";
import {
  postNumbaChgPasswd,
  postNumbaCodeList,
  postNumbaSelCheckno,
  postNumbaSelUserInfo,
  postNumbaSendSms,
} from "../../../../api/auth";
import * as R from "../../../../css/PersonalSellingRequest/requestForm";
import { DaumPostcode } from "../../../../utils/DaumPostCode";
import axios from "axios";
import { color } from "chart.js/helpers";
import getUserInfo from "../../../../utils/hooks/getUserInfo";
import { EditMemberUser } from "./EditMemberUser";
import { EditMemberCorp } from "./EditMemberCorp";

function EditMember() {
  const regex_password =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const passwordsInit = {
    old_passwd: "",
    new_passwd1: "",
    new_passwd2: "",
    wrong_password: false,
  };
  const [loginUserInfo, setLoginUserInfo] = useState({
    user_nm: "",
    ceo_nm: "",
    zip: "",
    addr1: "",
    addr2: "",
    phone: "",
    region_cd: "",
    user_type_cd: 0,
    corp_nm: "",
    brno: "",
    brno_auth_flag: "",
    corp_kind_cd: 0,
  });

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    // selUserInfo();
    getUserInfo().then((res) => {
      setUserInfo(res);
    });
  }, []);

  return (
    <>
      {userInfo.user_type_cd === 201 && <EditMemberUser />}
      {userInfo.user_type_cd === 202 && <EditMemberCorp />}

      <MobileFooter main={true} />
    </>
  );
}

export default EditMember;
