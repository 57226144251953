import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
// import * as R from "../../css/PersonalSellingRequest/requestForm";
import * as R from "../../../../css/PersonalSellingRequest/requestForm";
import { DaumPostcode } from "../../../../utils/DaumPostCode";
import moment from "moment";
import getUserInfo from "../../../../utils/hooks/getUserInfo";
import { ClipLoader } from "react-spinners";
import { headerText } from "../../../../App";
import { useAtom } from "jotai";
import { BtnYellow } from "../../../../css/components/button/buttonYellow";
import { postSaleInsCBuy } from "../../../../api/sale";
import { toast } from "wc-toast";
import { useNavigate } from "react-router-dom";
import CommonRegistration from "../registration-information/CommonRegistration";
import { postNumbaUpdCSellCBuy } from "../../../../api/mypage";

// 개인넘버 매매 구매요청
export const LicenseBuyingInfo = ({ buyReqData }) => {
  return (
    <div className="FormDIv">
      <R.FormDetails_Heading>매물정보</R.FormDetails_Heading>
      <R.FormDetails_Div>
        {/* desktop table */}
        <div className="d-none d-md-block">
          <Table bordered responsive>
            <tbody>
              <tr>
                <R.FormTable_Th width={"20%"}>유형</R.FormTable_Th>
                <td className="paddingLeft" colSpan={3}>
                  {buyReqData?.type === 2104 && "주선번호 매매"}
                </td>
              </tr>
              <tr>
                <R.FormTable_Th>판매자 지역</R.FormTable_Th>
                <td className="paddingLeft">{buyReqData?.region_nm}</td>
                <R.FormTable_Th>판매금액</R.FormTable_Th>
                <td className="paddingLeft" colSpan={3}>
                  <R.Table_Link>
                    {buyReqData?.price?.toLocaleString()}
                  </R.Table_Link>
                  &nbsp; 만원
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* mobile table */}
        <div className="d-block d-md-none">
          <Table bordered responsive className="table-form">
            <tbody>
              <tr>
                <R.FormTable_Th width={"25%"}>유형</R.FormTable_Th>
                <td> {buyReqData?.type === 2104 && "주선번호 매매"}</td>
              </tr>
              <tr>
                <R.FormTable_Th>판매자 지역</R.FormTable_Th>
                <td>{buyReqData?.region_nm}</td>
              </tr>
              <tr>
                <R.FormTable_Th>판매금액</R.FormTable_Th>
                <td colSpan={3}>
                  <R.Table_Link>
                    {buyReqData?.price?.toLocaleString()}
                  </R.Table_Link>
                  &nbsp; 만원
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </R.FormDetails_Div>
    </div>
  );
};

export const LicenseRequestForm = ({ subActiveTab, buyingData, isEdit }) => {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buyReqData, setBuyReqData] = useState({});
  const navigate = useNavigate();
  //   type 2101 : 개인 매매

  // 신청
  const callInsCBuy = async (data) => {
    setLoading(true);
    const msg = validateSellReq(buyReqData);
    if (msg) {
      setLoading(false);
      return toast.error(msg);
    }

    try {
      const response = await postSaleInsCBuy({
        ...data,
      });
      switch (response?.code) {
        case 1000:
          navigate("/complete-request", {
            state: data,
            replace: true,
          });
          break;
        case -19:
          toast.error("이미 진행중인 매물입니다.");
          break;
        case -22:
          toast.error("자기 자신의 매물입니다.");
          break;
        case -23:
          toast.error("이미 신청한 매물입니다.");
          break;
        default:
          toast.error("구매신청 실패");
          navigate(-1);
          break;
      }
    } catch (err) {
      switch (err.code) {
        case -19:
          toast.error("이미 진행중인 매물입니다.");
          break;
        case -22:
          toast.error("자기 자신의 매물입니다.");
          break;
        case -23:
          toast.error("이미 신청한 매물입니다.");
          break;
        default:
          toast.error("구매신청 실패");
          navigate(-1);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  // 수정
  const editCallInsCBuy = async (data) => {
    setLoading(true);
    try {
      const response = await postNumbaUpdCSellCBuy(data);
      if (response?.code === 1000) {
        toast.success("수정되었습니다.");
      } else {
        toast.error("수정이 실패하였습니다.");
      }
    } catch (err) {
      toast.error("수정이 실패하였습니다.");
    } finally {
      setLoading(false);
    }
    navigate(-1);
  };

  useEffect(() => {
    setHeaderTextAtom("팝니다 구매신청");
  }, []);

  useEffect(() => {
    setBuyReqData({ ...buyingData });
  }, [buyingData]);

  const validateSellReq = (buyReqData) => {
    if (!buyReqData.user_nm || buyReqData.user_nm === "" || buyReqData.user_nm === undefined) {
      return "구매자 이름을 확인 해 주세요.";
    }
    if (!buyReqData.phone || buyReqData.phone === "" || buyReqData.phone === undefined) {
      return "구매자 휴대폰번호를 확인 해 주세요.";
    }
    if (!buyReqData.zip || buyReqData.zip === "" || buyReqData.zip === undefined) {
      return "우편번호를 확인 해 주세요.";
    }
    if (!buyReqData.addr1 || buyReqData.addr1 === "" || buyReqData.addr1 === undefined) {
      return "도로명주소를 확인 해 주세요.";
    }
    if (!buyReqData.addr2 || buyReqData.addr2 === "" || buyReqData.addr2 === undefined) {
      return "상세주소를 확인 해 주세요.";
    }
  };

  return (
    <>
      <div className="requestFormMain-container">
        <Container className="p-0">
          <Row>
            <Col md={12}>
              <div className="pc_view">
                <R.FormHeading_div>
                  <R.Form_Heading>구매신청</R.Form_Heading>
                </R.FormHeading_div>
              </div>
              <LicenseBuyingInfo buyReqData={buyingData} />
              <CommonRegistration
                buyReqData={buyReqData}
                setBuyReqData={setBuyReqData}
                buyingData={buyingData}
              />
              <div className="mt-3 text-center">
                <BtnYellow
                  width="360px"
                  height="60px"
                  disabled={loading}
                  onClick={() => {
                    if (isEdit) {
                      editCallInsCBuy(buyReqData);
                    } else {
                      callInsCBuy(buyReqData);
                    }
                  }}
                >
                  {loading ? (
                    <ClipLoader loading={true} color="#fff" size={23} />
                  ) : (
                    isEdit ? "수정하기" : "신청하기"
                  )}
                </BtnYellow>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <TablePopup show={modalShow} onHide={() => setModalShow(false)} /> */}
      </div>
    </>
  );
};

// export default PersonalRequestForm;
