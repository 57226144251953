import React, { useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import DaumPostcodeEmbed from "react-daum-postcode";
import Modal from "react-bootstrap/Modal";

export const DaumPostcode = ({
  onCompleteCallback,
  className = "btn btn-primary table-button",
}) => {
  const [open, setOpen] = useState(false);
  const CURRENT_URL =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  // const open = useDaumPostcodePopup(CURRENT_URL);

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    if (onCompleteCallback) {
      setOpen(false);
      onCompleteCallback({
        zonecode: data.zonecode,
        fullAddr: fullAddress,
      });
    }
  };

  return (
    <>
      <label
        className={`fileBtn ${className}`}
        htmlFor="addr"
        role="button"
        onClick={(e) => setOpen(true)}
      >
        우편번호
      </label>
      <Modal
        show={open}
        centered
        onHide={() => setOpen(false)}
        fullscreen={"sm-down"}
        dialogClassName="my_modal modal-552px"
      >
        <Modal.Header closeButton>우편번호 찾기</Modal.Header>
        <Modal.Body>
          <DaumPostcodeEmbed onComplete={handleComplete} />
        </Modal.Body>
      </Modal>
    </>
  );
};
