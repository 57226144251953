import React from "react";
import PersonalNumber from "./PersonalNumber";
import BrokerageLincencePrice from "./BrokerageLincence";
import CorporateSaleNumber from "./CorporateSaleNumber";
import CorporateLeaseNumber from "./CorporateLeaseNumber";

function ViewPriceSection({ activeTab }) {
  if (activeTab === 1) {
    return <PersonalNumber state_number={"2304"} />;
  }
  //    else if (activeTab == 2) {
  //     return <CorporateSaleNumber />;
  //   } else if (activeTab == 3) {
  //     return <CorporateLeaseNumber />;
  //   }  
  else if (activeTab === 2) {
    // return <BrokerageLincencePrice />
    return <PersonalNumber state_number={"2101"} />;
  }
  else if (activeTab === 4) {
    // return <BrokerageLincencePrice />
    return <PersonalNumber state_number={"2104"} />;
  }
}

export default ViewPriceSection;
