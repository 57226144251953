import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

export const postNumbaInsInterest = (data) =>
  api.create(url.POST_NUMBA_INSINTEREST, data);
export const postNumbaDelInterest = (data) =>
  api.create(url.POST_NUMBA_DELINTEREST, data);
export const postNumbaInterestList = (data) =>
  api.create(url.POST_NUMBA_INTERESTLIST, data);
export const postNumbaUserMainList = (data) =>
  api.create(url.POST_NUMBA_USERMAINLIST, data);
export const postNumbaUserClist = (data) =>
  api.create(url.POST_NUMBA_USERCLIST, data);
export const postNumbaUpdCSellCBuy = (data) =>
  api.createWithFile(url.POST_NUMBA_UPDCSELLCBUY, data);
export const postNumbaDelCSellCBuy = (data) =>
  api.create(url.POST_NUMBA_DELCSELLCBUY, data);
export const postNumbaUserSellBuyList = (data) =>
  api.create(url.POST_NUMBA_USERSELLBUYLIST, data);
export const postNumbaUpdSellBuy = (data) =>
  api.createWithFile(url.POST_NUMBA_UPDSELLBUY, data);
export const postNumbaMValueList = (data) =>
  api.create(url.POST_NUMBA_MVALUELIST, data);
export const postNumbaUserCompletedList = (data) =>
  api.create(url.POST_NUMBA_USERCOMPLETEDLIST, data);
export const postNumbaUpdUserInfo = (data) =>
  api.create(url.POST_NUMBA_UPDUSERINFO, data);
export const postNumbaUserDelSellBuy = (data) =>
  api.create(url.POST_NUMBA_DELSELLBUY, data);
export const postNumbaGetFile = (data) =>
  api.blobFile(url.POST_NUMBA_GETFILE, data);
export const postNumbaChkUserForW = (data) =>
  api.create(url.POST_NUMBA_CHKUSERFORW, data);
export const postNumbaAlarmSel = (data) =>
  api.create(url.POST_NUMBA_SELALARM, data);
export const postNumbaAlarmChange = (data) =>
  api.create(url.POST_NUMBA_CHGALARM, data);
export const postNumbaAlarmList = (data) =>
  api.create(url.POST_NUMBA_ALARMLIST, data);
export const postNumbaUpdAlarm = (data) =>
  api.create(url.POST_NUMBA_UPDALARM, data);
export const postNumbaDelAlarm = (data) =>
  api.create(url.POST_NUMBA_DELALARM, data);
export const postNumbaDelQnaFile = (data) => {
  api.create(url.POST_NUMBA_DELQNAFILE, data);
};
export const postNumbaFCMToken = (data) => {
  api.create(url.POST_NUMBA_FCMTOKEN, data);
};
export const postNumbaTodayPrice = (data) => {
  api.create(url.POST_NUMBA_MAIN_PRICE, data);
};
export const postCodeDescription = (data) => {
  api.create(url.POST_NUMBA_SEL_CODE_DESCRIPTION, data);
};
