import { useState, useEffect, useCallback, useMemo } from "react";
// import { useWindowSize } from "./useWindowSize";
import useWindowSize from "./useWindowSize";

function useTable(props) {
  const { width } = useWindowSize();

  const {
    defaultDense,
    defaultOrder = "name",
    defaultOrderDirection = "asc",
    defaultCurrentPage = 1,
    defaultRowsPerPage = 10,
    defaultSelected = {},
    mobileBreakpoint = 768,
    maxPagesMobile,
    maxPagesDesktop,
    paginationType = "dynamic",
  } = props;

  // width값이 바뀔 때마다 계산됨.
  const isMobile = width < mobileBreakpoint;
  const maxVisiblePages = isMobile ? maxPagesMobile : maxPagesDesktop;

  // 행 높이 축소 여부
  const [dense, setDense] = useState(!!defaultDense);
  // 정렬할 항목(orderBy)
  const [orderBy, setOrderBy] = useState(defaultOrder);
  // 정렬방식(asc:오름차, desc:내림차)
  const [order, setOrder] = useState(defaultOrderDirection);
  // 현재페이지 설정
  const [page, setPage] = useState(defaultCurrentPage);
  // 총 페이지 수
  const [totalPage, setTotalPage] = useState(1);
  // 페이지 행 개수
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  // 체크된 항목(체크박스)
  const [selected, setSelected] = useState(defaultSelected);

  // 총 페이지 수를 배열로 만듦. 반복문을 사용하여 쉽게 사용하기 위해
  const totalPageArray = useMemo(
    () => Array.from({ length: totalPage }, (_, i) => i + 1),
    [totalPage]
  );

  // useEffect(() => {
  //   console.log("page from useTable: ", page);
  // }, [page]);

  // 동적 페이지 계산 함수: 페이지 값들이  계속 변경됨
  // 한 페이지에 5개를 보여줄 때: 1 2 [3] 4 5 ==> 2 3 [4] 5 6
  const dynamicVisiblePages = useMemo(() => {
    const halfVisiblePages = Math.floor(maxVisiblePages / 2); // 10인 경우 5, 5인 경우 2

    let startPage = Math.max(page - halfVisiblePages, 0);
    let endPage = startPage + maxVisiblePages;

    if (endPage > totalPage) {
      endPage = totalPage;
      startPage = Math.max(endPage - maxVisiblePages, 0);
    }

    return totalPageArray.slice(startPage, endPage);
  }, [page, totalPage, totalPageArray, maxVisiblePages]);

  // 정적 페이지 계산 함수: 페이지 값이 변경되지 않음
  // 한 페이지에 5개를 보여줄 때: 1 2 3 4 5 ==> 1 2 3 [4] 5
  const staticVisiblePages = useMemo(() => {
    let startPage = Math.floor(page / maxVisiblePages) * maxVisiblePages;
    let endPage = Math.min(startPage + maxVisiblePages, totalPage);

    return totalPageArray.slice(startPage, endPage);
  }, [page, totalPage, maxVisiblePages]);

  const visiblePages =
    paginationType === "dynamic" ? dynamicVisiblePages : staticVisiblePages;

  const onSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    },
    [order, orderBy]
  );

  const onSelectRow = useCallback((id) => {
    setSelected((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const onSelectAllRow = useCallback(({ checked, rows, idKey }) => {
    if (checked) {
      const newSelected = rows.reduce(
        (acc, row) => ({ ...acc, [row[idKey]]: true }),
        {}
      );
      setSelected(newSelected);
    } else {
      setSelected({});
    }
  }, []);

  const onChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const onChangeTotalPage = useCallback((total) => {
    // console.log("total from useTable: ", total);
    setTotalPage(total);
  }, []);

  const onChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const onChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, []);

  return {
    dense,
    order,
    page,
    totalPage,
    orderBy,
    rowsPerPage,
    selected,
    visiblePages,
    onSort,
    onSelectRow,
    onSelectAllRow,
    onChangePage,
    onChangeTotalPage,
    onChangeDense,
    onChangeRowsPerPage,
    // setTotalPage,
  };
}

export default useTable;

// import { useState, useCallback } from "react";

// // 화면 크기를 감지하는 커스텀 훅
// function useWindowSize() {
//   const [windowSize, setWindowSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   useEffect(() => {
//     // 리사이즈 이벤트 핸들러
//     function handleResize() {
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }
//     // 리사이즈 이벤트 리스터 추가
//     window.addEventListener("resize", handleResize);

//     // 클린업 함수
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return windowSize;
// }

// export default function useTable(props) {
//   // 화면의 너비
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   // 테이블 행 높이를 조절하는 상태
//   const [dense, setDense] = useState(!!props?.defaultDense);

//   // 테이블의 정렬 시의 기준을 조절하는 상태(이름, 아이디, 순번 등)
//   const [orderBy, setOrderBy] = useState(props?.defaultOrder || "name");

//   // 테이블 정렬 방식(오름차순, 내림차순)
//   const [order, setOrder] = useState(props?.defaultOrder || "asc");

//   // 테이블 페이지를 조절하는 상태(페이지 값을 사용해 행의 서버에서 받아올 첫 번째 행의 인덱스를 계산한다.)
//   const [page, setPage] = useState(props?.defaultCurrentPage || 0);

//   // 페이지네이션에 보여질 총 페이지의 개수:  서버에서 받은 총 데이터(페이지X) 개수를 설정
//   const [totalPage, setTotalPage] = useState(1);

//   //
//   const [totalPageArray, setTotalPageArray] = useState([]);

//   //  테이블에 보여질 행의 개수를 조절하는 상태
//   const [rowsPerPage, setRowsPerPage] = useState(
//     props?.defaultRowsPerPage || 10
//   );

//   // 동적 윈도우 계산
//   // 현재 페이지와 총 페이지 수에 따라 보여줄 페이지 번호들을 계산
//   const visiblePages = useMemo(() => {
//     let startPage = Math.max(currentPage - HALF_VISIBLE_PAGES, 1);
//     let endPage = startPage + MAX_VISIBLE_PAGES - 1;

//     if (endPage > totalPage) {
//       endPage = totalPage;
//       startPage = Math.max(totalPage - MAX_VISIBLE_PAGES + 1, 1);
//     }

//     return totalPageArray.slice(startPage - 1, endPage);
//   }, [page, totalPage, totalPageArray, windowWidth]);

//   // 체크된 행들을 관리하는 상태(객체로 관리한다.)
//   const [selected, setSelected] = useState(props?.defaultSelected || {});

//   const onSort = useCallback(
//     (id) => {
//       // true/false: orderBY(정렬기준)가 파라미터값이고, 현재 order가 asc(오름차순)인 경우 true 할당
//       const isAsc = orderBy === id && order === "asc";

//       if (id !== "") {
//         // 만약 id가 공백문자가 아니고, isAsc가 true라면
//         // 정렬방식을 desc(내림차순)으로 변경하고 정렬 기준을 id로 설정
//         setOrder(isAsc ? "desc" : "asc");
//         setOrderBy(id);
//       }
//     },
//     [order, orderBy]
//   );

//   //  행을 체크했을 때 호출되는 함수
//   const onSelectRow = useCallback((id) => {
//     setSelected((prevSelected) => ({
//       ...prevSelected,
//       [id]: !prevSelected[id],
//     }));
//   }, []);

//   const onSelectAllRow = useCallback(({ checked, rows, idKey }) => {
//     /*
//    전체 체크박스 체크 시 호출되는 함수: 체크할 때마다 서버에서 받은 배열 데이터를 가지고 객체를 만든다.

//     - checked는 true/false값. rows는 서버에서 수신한 배열 데이터. 객체의 idKey는 객체의 프로퍼티의 key값으로 사용할 고유값.

//    서버에서 데이터를 받자마자 객체를 만들 수도 있지만 전체 체크를 사용하는 경우가 드물기 때문에 사용할 때 객체를 구성하는 방법 선택

//    개별 페이지에서 핸들러를 구성할 때는 아래와 같이 사용 가능

//     const handleSelectAllRows = (event) => {
//         onSelectAllRow({
//             checked: event.target.checked,  // 체크박스의 현재 체크 상태
//             rows: tableData,               // 전체 테이블 데이터
//             idKey: 'id'                    // 각 행을 식별하는 데 사용할 키(여기서는 'id'로 프로퍼티의 키값을 설정하겠다는 의미)
//         });
//     };
//     ...
//     <Checkbox onChange={(event) => handleSelectAllRows(event)} />
// />
//    */

//     if (checked) {
//       const allSelected = rows.reduce((acc, row) => {
//         // 객체를 구성할 때 프로퍼티의 key를 동적으로 구성하기 위해 아래처럼 사용
//         acc[row[idKey]] = true;
//         return acc;
//       }, {});
//       setSelected(allSelected);
//     } else {
//       setSelected({});
//     }
//   }, []);

//   const onChangePage = useCallback((event, newPage) => {
//     setPage(newPage);
//   }, []);

//   const onChangeRowsPerPage = useCallback((event) => {
//     setPage(0);
//     setRowsPerPage(parseInt(event.target.value, 10));
//   }, []);

//   const onChangeDense = useCallback((event) => {
//     setDense(event.target.checked);
//   }, []);

//   return {
//     dense,
//     order,
//     page,
//     orderBy,
//     rowsPerPage,
//     //
//     selected,
//     onSelectRow,
//     onSelectAllRow,
//     //
//     onSort,
//     onChangePage,
//     onChangeDense,
//     onChangeRowsPerPage,
//     //
//     setPage,
//     setDense,
//     setOrder,
//     setOrderBy,
//     setSelected,
//     setRowsPerPage,
//   };
// }

// /*
// 개별 페이지에서는 아래와 같이 가져다 쓴다.

// import { Helmet } from 'react-helmet-async';
// import { useState } from 'react';
// import sumBy from 'lodash/sumBy';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// // @mui
// import { useTheme } from '@mui/material/styles';
// import {
//   Tab,
//   Tabs,
//   Card,
//   Table,
//   Stack,
//   Button,
//   Tooltip,
//   Divider,
//   TableBody,
//   Container,
//   IconButton,
//   TableContainer,
// } from '@mui/material';
// // routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// // utils
// import { fTimestamp } from '../../utils/formatTime';
// // _mock_
// import { _invoices } from '../../_mock/arrays';
// // components
// import Label from '../../components/label';
// import Iconify from '../../components/iconify';
// import Scrollbar from '../../components/scrollbar';
// import ConfirmDialog from '../../components/confirm-dialog';
// import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// import { useSettingsContext } from '../../components/settings';
// import {
//   useTable,
//   getComparator,
//   emptyRows,
//   TableNoData,
//   TableEmptyRows,
//   TableHeadCustom,
//   TableSelectedAction,
//   TablePaginationCustom,
// } from '../../components/table';
// // sections
// import InvoiceAnalytic from '../../sections/@dashboard/invoice/InvoiceAnalytic';
// import { InvoiceTableRow, InvoiceTableToolbar } from '../../sections/@dashboard/invoice/list';

// // ----------------------------------------------------------------------

// const SERVICE_OPTIONS = [
//   'all',
//   'full stack development',
//   'backend development',
//   'ui design',
//   'ui/ux design',
//   'front end development',
// ];

// const TABLE_HEAD = [
//   { id: 'invoiceNumber', label: 'Client', align: 'left' },
//   { id: 'createDate', label: 'Create', align: 'left' },
//   { id: 'dueDate', label: 'Due', align: 'left' },
//   { id: 'price', label: 'Amount', align: 'center', width: 140 },
//   { id: 'sent', label: 'Sent', align: 'center', width: 140 },
//   { id: 'status', label: 'Status', align: 'left' },
//   { id: '' },
// ];

// // ----------------------------------------------------------------------

// export default function InvoiceListPage() {
//   const theme = useTheme();

//   const { themeStretch } = useSettingsContext();

//   const navigate = useNavigate();

//   const {
//     dense,
//     page,
//     order,
//     orderBy,
//     rowsPerPage,
//     setPage,
//     //
//     selected,
//     setSelected,
//     onSelectRow,
//     onSelectAllRows,
//     //
//     onSort,
//     onChangeDense,
//     onChangePage,
//     onChangeRowsPerPage,
//   } = useTable({ defaultOrderBy: 'createDate' });

//   const [tableData, setTableData] = useState(_invoices);

//   const [filterName, setFilterName] = useState('');

//   const [openConfirm, setOpenConfirm] = useState(false);

//   const [filterStatus, setFilterStatus] = useState('all');

//   const [filterEndDate, setFilterEndDate] = useState(null);

//   const [filterService, setFilterService] = useState('all');

//   const [filterStartDate, setFilterStartDate] = useState(null);

//   const dataFiltered = applyFilter({
//     inputData: tableData,
//     comparator: getComparator(order, orderBy),
//     filterName,
//     filterService,
//     filterStatus,
//     filterStartDate,
//     filterEndDate,
//   });

//   const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   const denseHeight = dense ? 56 : 76;

//   const isFiltered =
//     filterStatus !== 'all' ||
//     filterName !== '' ||
//     filterService !== 'all' ||
//     (!!filterStartDate && !!filterEndDate);

//   const isNotFound =
//     (!dataFiltered.length && !!filterName) ||
//     (!dataFiltered.length && !!filterStatus) ||
//     (!dataFiltered.length && !!filterService) ||
//     (!dataFiltered.length && !!filterEndDate) ||
//     (!dataFiltered.length && !!filterStartDate);

//   const getLengthByStatus = (status) => tableData.filter((item) => item.status === status).length;

//   const getTotalPriceByStatus = (status) =>
//     sumBy(
//       tableData.filter((item) => item.status === status),
//       'totalPrice'
//     );

//   const getPercentByStatus = (status) => (getLengthByStatus(status) / tableData.length) * 100;

//   const TABS = [
//     { value: 'all', label: 'All', color: 'info', count: tableData.length },
//     { value: 'paid', label: 'Paid', color: 'success', count: getLengthByStatus('paid') },
//     { value: 'unpaid', label: 'Unpaid', color: 'warning', count: getLengthByStatus('unpaid') },
//     { value: 'overdue', label: 'Overdue', color: 'error', count: getLengthByStatus('overdue') },
//     { value: 'draft', label: 'Draft', color: 'default', count: getLengthByStatus('draft') },
//   ];

//   const handleOpenConfirm = () => {
//     setOpenConfirm(true);
//   };

//   const handleCloseConfirm = () => {
//     setOpenConfirm(false);
//   };

//   const handleFilterStatus = (event, newValue) => {
//     setPage(0);
//     setFilterStatus(newValue);
//   };

//   const handleFilterName = (event) => {
//     setPage(0);
//     setFilterName(event.target.value);
//   };

//   const handleFilterService = (event) => {
//     setPage(0);
//     setFilterService(event.target.value);
//   };

//   const handleDeleteRow = (id) => {
//     const deleteRow = tableData.filter((row) => row.id !== id);
//     setSelected([]);
//     setTableData(deleteRow);

//     if (page > 0) {
//       if (dataInPage.length < 2) {
//         setPage(page - 1);
//       }
//     }
//   };

//   const handleDeleteRows = (selectedRows) => {
//     const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id));
//     setSelected([]);
//     setTableData(deleteRows);

//     if (page > 0) {
//       if (selectedRows.length === dataInPage.length) {
//         setPage(page - 1);
//       } else if (selectedRows.length === dataFiltered.length) {
//         setPage(0);
//       } else if (selectedRows.length > dataInPage.length) {
//         const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
//         setPage(newPage);
//       }
//     }
//   };

//   const handleEditRow = (id) => {
//     navigate(PATH_DASHBOARD.invoice.edit(id));
//   };

//   const handleViewRow = (id) => {
//     navigate(PATH_DASHBOARD.invoice.view(id));
//   };

//   const handleResetFilter = () => {
//     setFilterName('');
//     setFilterStatus('all');
//     setFilterService('all');
//     setFilterEndDate(null);
//     setFilterStartDate(null);
//   };

//   return (
//     <>
//       <Helmet>
//         <title> Invoice: List | Minimal UI</title>
//       </Helmet>

//       <Container maxWidth={themeStretch ? false : 'lg'}>
//         <CustomBreadcrumbs
//           heading="Invoice List"
//           links={[
//             {
//               name: 'Dashboard',
//               href: PATH_DASHBOARD.root,
//             },
//             {
//               name: 'Invoices',
//               href: PATH_DASHBOARD.invoice.root,
//             },
//             {
//               name: 'List',
//             },
//           ]}
//           action={
//             <Button
//               component={RouterLink}
//               to={PATH_DASHBOARD.invoice.new}
//               variant="contained"
//               startIcon={<Iconify icon="eva:plus-fill" />}
//             >
//               New Invoice
//             </Button>
//           }
//         />

//         <Card sx={{ mb: 5 }}>
//           <Scrollbar>
//             <Stack
//               direction="row"
//               divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
//               sx={{ py: 2 }}
//             >
//               <InvoiceAnalytic
//                 title="Total"
//                 total={tableData.length}
//                 percent={100}
//                 price={sumBy(tableData, 'totalPrice')}
//                 icon="ic:round-receipt"
//                 color={theme.palette.info.main}
//               />

//               <InvoiceAnalytic
//                 title="Paid"
//                 total={getLengthByStatus('paid')}
//                 percent={getPercentByStatus('paid')}
//                 price={getTotalPriceByStatus('paid')}
//                 icon="eva:checkmark-circle-2-fill"
//                 color={theme.palette.success.main}
//               />

//               <InvoiceAnalytic
//                 title="Unpaid"
//                 total={getLengthByStatus('unpaid')}
//                 percent={getPercentByStatus('unpaid')}
//                 price={getTotalPriceByStatus('unpaid')}
//                 icon="eva:clock-fill"
//                 color={theme.palette.warning.main}
//               />

//               <InvoiceAnalytic
//                 title="Overdue"
//                 total={getLengthByStatus('overdue')}
//                 percent={getPercentByStatus('overdue')}
//                 price={getTotalPriceByStatus('overdue')}
//                 icon="eva:bell-fill"
//                 color={theme.palette.error.main}
//               />

//               <InvoiceAnalytic
//                 title="Draft"
//                 total={getLengthByStatus('draft')}
//                 percent={getPercentByStatus('draft')}
//                 price={getTotalPriceByStatus('draft')}
//                 icon="eva:file-fill"
//                 color={theme.palette.text.secondary}
//               />
//             </Stack>
//           </Scrollbar>
//         </Card>

//         <Card>
//           <Tabs
//             value={filterStatus}
//             onChange={handleFilterStatus}
//             sx={{
//               px: 2,
//               bgcolor: 'background.neutral',
//             }}
//           >
//             {TABS.map((tab) => (
//               <Tab
//                 key={tab.value}
//                 value={tab.value}
//                 label={tab.label}
//                 icon={
//                   <Label color={tab.color} sx={{ mr: 1 }}>
//                     {tab.count}
//                   </Label>
//                 }
//               />
//             ))}
//           </Tabs>

//           <Divider />

//           <InvoiceTableToolbar
//             filterName={filterName}
//             isFiltered={isFiltered}
//             filterService={filterService}
//             filterEndDate={filterEndDate}
//             onFilterName={handleFilterName}
//             optionsService={SERVICE_OPTIONS}
//             filterStartDate={filterStartDate}
//             onResetFilter={handleResetFilter}
//             onFilterService={handleFilterService}
//             onFilterStartDate={(newValue) => {
//               setFilterStartDate(newValue);
//             }}
//             onFilterEndDate={(newValue) => {
//               setFilterEndDate(newValue);
//             }}
//           />

//           <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
//             <TableSelectedAction
//               dense={dense}
//               numSelected={selected.length}
//               rowCount={tableData.length}
//               onSelectAllRows={(checked) =>
//                 onSelectAllRows(
//                   checked,
//                   tableData.map((row) => row.id)
//                 )
//               }
//               action={
//                 <Stack direction="row">
//                   <Tooltip title="Sent">
//                     <IconButton color="primary">
//                       <Iconify icon="ic:round-send" />
//                     </IconButton>
//                   </Tooltip>

//                   <Tooltip title="Download">
//                     <IconButton color="primary">
//                       <Iconify icon="eva:download-outline" />
//                     </IconButton>
//                   </Tooltip>

//                   <Tooltip title="Print">
//                     <IconButton color="primary">
//                       <Iconify icon="eva:printer-fill" />
//                     </IconButton>
//                   </Tooltip>

//                   <Tooltip title="Delete">
//                     <IconButton color="primary" onClick={handleOpenConfirm}>
//                       <Iconify icon="eva:trash-2-outline" />
//                     </IconButton>
//                   </Tooltip>
//                 </Stack>
//               }
//             />

//             <Scrollbar>
//               <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
//                 <TableHeadCustom
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={tableData.length}
//                   numSelected={selected.length}
//                   onSort={onSort}
//                   onSelectAllRows={(checked) =>
//                     onSelectAllRows(
//                       checked,
//                       tableData.map((row) => row.id)
//                     )
//                   }
//                 />

//                 <TableBody>
//                   {dataFiltered
//                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                     .map((row) => (
//                       <InvoiceTableRow
//                         key={row.id}
//                         row={row}
//                         selected={selected.includes(row.id)}
//                         onSelectRow={() => onSelectRow(row.id)}
//                         onViewRow={() => handleViewRow(row.id)}
//                         onEditRow={() => handleEditRow(row.id)}
//                         onDeleteRow={() => handleDeleteRow(row.id)}
//                       />
//                     ))}

//                   <TableEmptyRows
//                     height={denseHeight}
//                     emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
//                   />

//                   <TableNoData isNotFound={isNotFound} />
//                 </TableBody>
//               </Table>
//             </Scrollbar>
//           </TableContainer>

//           <TablePaginationCustom
//             count={dataFiltered.length}
//             page={page}
//             rowsPerPage={rowsPerPage}
//             onPageChange={onChangePage}
//             onRowsPerPageChange={onChangeRowsPerPage}
//             //
//             dense={dense}
//             onChangeDense={onChangeDense}
//           />
//         </Card>
//       </Container>

//       <ConfirmDialog
//         open={openConfirm}
//         onClose={handleCloseConfirm}
//         title="Delete"
//         content={
//           <>
//             Are you sure want to delete <strong> {selected.length} </strong> items?
//           </>
//         }
//         action={
//           <Button
//             variant="contained"
//             color="error"
//             onClick={() => {
//               handleDeleteRows(selected);
//               handleCloseConfirm();
//             }}
//           >
//             Delete
//           </Button>
//         }
//       />
//     </>
//   );
// }

// // ----------------------------------------------------------------------

// function applyFilter({
//   inputData,
//   comparator,
//   filterName,
//   filterStatus,
//   filterService,
//   filterStartDate,
//   filterEndDate,
// }) {
//   const stabilizedThis = inputData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   inputData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     inputData = inputData.filter(
//       (invoice) =>
//         invoice.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
//         invoice.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
//     );
//   }

//   if (filterStatus !== 'all') {
//     inputData = inputData.filter((invoice) => invoice.status === filterStatus);
//   }

//   if (filterService !== 'all') {
//     inputData = inputData.filter((invoice) =>
//       invoice.items.some((c) => c.service === filterService)
//     );
//   }

//   if (filterStartDate && filterEndDate) {
//     inputData = inputData.filter(
//       (invoice) =>
//         fTimestamp(invoice.createDate) >= fTimestamp(filterStartDate) &&
//         fTimestamp(invoice.createDate) <= fTimestamp(filterEndDate)
//     );
//   }

//   return inputData;
// }

// */
