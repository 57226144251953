import React, { useEffect, useState } from "react";
import * as S from "../../css/SignUp/RegularUser.style";
import * as A from "../../css/login/login";
import { Col, Container, Row } from "react-bootstrap";
import AccordianRegularUser from "./component/AccordianRegularUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { toast } from "wc-toast";
import { BtnLightYellow, CardButton } from "../../css/SignUp/RegularUser.style";
function RegularUser() {
  const isIOS = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
  const location = useLocation();
  const initialState = {
    user_type_cd: "",
    agree_yn: "N",
    privacy_yn: "N",
    alarm_yn: "N",
    ads_yn:"N",
  };
  const [regObj, setRegObj] = useState(initialState);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);

  // 카카오 로그인
  const KAKAO_URL =
    process.env.REACT_APP_KAKAO_HOST_URL +
    "redirect_uri=" +
    process.env.REACT_APP_KAKAO_REDIRECT_URL;

  const handleAllIconClick = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setIsChecked1(false);
      setIsChecked2(false);
      setIsChecked3(false);
      setIsChecked4(false);
      setRegObj({ ...regObj, agree_yn: "N", privacy_yn: "N", alarm_yn: "N", ads_yn:"N" });
    } else {
      setIsChecked1(true);
      setIsChecked2(true);
      setIsChecked3(true);
      setIsChecked4(true);
      setRegObj({ ...regObj, agree_yn: "Y", privacy_yn: "Y", alarm_yn: "Y", ads_yn:"Y" });
    }
  };

  const validate = () => {
    if (regObj.agree_yn === "N") {
      toast.error("서비스 이용 약관에 동의해주세요");
    } else if (regObj.privacy_yn === "N"){
      toast.error("개인 정보 취급 방침에 동의해주세요");
    } else if (regObj.alarm_yn === "N") {
      toast.error("매물등록, 시세 수신에 동의해주세요");
    } else if (regObj.ads_yn === "N") {
      toast.error("광고성 정보 수신에 동의해주세요");
    } 
  };

  useEffect(() => {
    if (isChecked1 && isChecked2 && isChecked3 && isChecked4) {
      setIsChecked(true);
    }
  }, [isChecked, isChecked1, isChecked2, isChecked3, isChecked4]);

  useEffect(() => {
    if (location.state) {
      setRegObj({ ...regObj, ...location.state });
    }
  }, []);

  return (
    <>
      <S.Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <S.MainHeading>일반 회원가입</S.MainHeading>
              <S.SubHeading>약관동의</S.SubHeading>
              <S.SubPara>
                전체동의는 체인버스의 서비스 동의를 포함하고 있습니다.
                <br />
                필수 동의사항 거부시 서비스 이용이 불가능합니다.
              </S.SubPara>
              <S.AccordionDiv>
                <S.AccordionHeading>
                  {isChecked ? (
                    <S.AccordionIcon onClick={handleAllIconClick}>
                      <FontAwesomeIcon icon={faCheck} />
                    </S.AccordionIcon>
                  ) : (
                    <S.AccordionIcon
                      color="#DADADA"
                      onClick={handleAllIconClick}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </S.AccordionIcon>
                  )}
                  <S.AccordionValue>전체동의</S.AccordionValue>
                </S.AccordionHeading>
                <AccordianRegularUser
                  regObj={regObj}
                  setRegObj={setRegObj}
                  setIsChecked={setIsChecked}
                  isChecked1={isChecked1}
                  setIsChecked1={setIsChecked1}
                  isChecked2={isChecked2}
                  setIsChecked2={setIsChecked2}
                  isChecked3={isChecked3}
                  setIsChecked3={setIsChecked3}
                  isChecked4={isChecked4}
                  setIsChecked4={setIsChecked4}
                />
              </S.AccordionDiv>
              <S.AccordionBtn_Div>
                {isChecked ? (
                  <Link to={`/signup-regularuser`} state={regObj}>
                    <CardButton bg={"#ff9d2f"}>넘버로 회원가입</CardButton>
                  </Link>
                ) : (
                  <Link to={`#`} state={regObj}>
                    <CardButton bg={"#C7C7C7"} onClick={validate}>
                      넘버로 회원가입
                    </CardButton>
                  </Link>
                )}
                {!isIOS && (
                  isChecked ? (
                    <a href={KAKAO_URL} className="d-flex justify-content-center">
                      <BtnLightYellow
                        mt="20px"
                        color="#000"
                        className="position-relative"
                      >
                        <A.Message_icon
                          src={process.env.PUBLIC_URL + "/img/message_icon.svg"}
                          alt=""
                        ></A.Message_icon>
                        카카오 계정 회원가입
                      </BtnLightYellow>
                    </a>
                  ) : (
                    <BtnLightYellow
                      mt="20px"
                      color="#000"
                      className="position-relative"
                      onClick={() => {
                        validate();
                      }}
                    >
                      <A.Message_icon
                        src={process.env.PUBLIC_URL + "/img/message_icon.svg"}
                        alt=""
                      ></A.Message_icon>
                      카카오 계정 회원가입
                    </BtnLightYellow>
                  )
                )}
              </S.AccordionBtn_Div>
            </Col>
          </Row>
        </Container>
      </S.Wrapper>
    </>
  );
}

export default RegularUser;
