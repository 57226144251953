import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TransactionInfo } from "../TransactionInformation/RequestProperty/TransactionInfo";

export default function ShowDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    type: "1",
    data: {},
  });
  useEffect(() => {
    if (location?.state) {
      setState(location?.state);
    } else {
      navigate(-1);
    }
  }, []);
  // type 1 : 거래신청 정보
  // type 2 : 등록매물 정보
  return (
    <>
      {state.type === 1 ? (
        <div className="request-transiction my-listing">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>나의 거래신청 정보</h1>
                </div>
              </div>
            </div>
          </div>
          <TransactionInfo data={state?.data} />
        </div>
      ) : (
        <div className="request-transiction my-listing">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>나의 등록매물 정보</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
