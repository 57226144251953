import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileFooter from "../../../componant/mobileFooter";
import { useAtom } from "jotai";
import { headerText } from "../../../App";
import moment from "moment";

function Main(props) {
  const { mainData } = props;
  console.log(mainData);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);

  const navigate = useNavigate();
  // 상세보기 버튼 클릭시
  const ShowDetail = (type, seq) => {
    navigate("/main-tab", {
      state: {
        main: 1,
        sub: type,
        mainSeq: seq,
      },
    });
  };

  // 나의 거래신청 정보 카드 리스트
  const userCList = useMemo(() => {
    if (mainData.cListCnt > 0) {
      return mainData?.cList?.map((data) => {
        return (
          <div className="card_box">
            <div className="top_part">
              <div className="breadcrumb">
                <ul>
                  <li
                    className={data?.deal_str === "cbuy" ? "blueLi" : "RedLi"}
                  >
                    {data?.deal_str === "cbuy" ? "구매신청" : "판매신청"}
                  </li>
                  <li>{data?.deal_category_nm}</li>
                  {parseInt(data?.deal_category_cd) === 2101 && (
                    <li>{data?.car_weight_nm.slice(0,2)}</li>
                  )}
                </ul>
              </div>
            </div>
            <div className="bottom_part">
              <div className="left_part">
                <div className="part_top_car">
                  {data?.in_car_yn === "Y" && "차량포함"}
                  <div className="inner_left_part">
                    <div
                      className="number"
                      style={{
                        "--color":
                          data?.deal_str === "cbuy"
                            ? "rgba(27, 111, 207, 0.9) "
                            : "rgba(233, 71, 94, 0.9)",
                      }}
                    >
                      {data?.price?.toLocaleString()}
                    </div>
                    <div className="value">만원</div>
                  </div>
                </div>
                <div className="inner_right_part">
                  <div className="part_top">
                    {data?.deal_category_cd !== 2104 && (
                      <div className="right_part_value">
                        <div className="first_value">톤수</div>
                        <div className="second_value">
                          {data?.car_weight
                            ? data?.car_weight + " 톤"
                            : data?.car_weight_nm}
                        </div>
                      </div>
                    )}
                    {data?.region_nm && (
                      <div className="right_part_value">
                        <div className="first_value">지역</div>
                        <div className="second_value">{data?.region_nm}</div>
                      </div>
                    )}
                  </div>
                  <div className="part_bottom">
                    {data?.car_yyyymm && (
                      <div className="right_part_value">
                        <div className="first_value">연식</div>
                        <div className="second_value">
                          {data?.car_yyyymm
                            ? moment(data?.car_yyyymm).format("YYYY.MM")
                            : data?.car_yyyymm}
                        </div>
                      </div>
                    )}
                    {data?.ecar_yn === "Y" && (
                      <div className="right_part_value">
                        <div className="first_value">차량구분</div>
                        <div className="second_value">
                          {data?.ecar_yn === "Y" ? "전기차" : "일반차"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right_part">
                <div className="inner_left_part">
                  <div className="first_value">신청일</div>
                  <div className="second_value">
                    {data?.reg_dt
                      ? moment(data?.reg_dt).format("YYYY.MM.DD")
                      : data?.reg_dt}
                  </div>
                </div>
                <div className="inner_right_part">
                  <button
                    onClick={() => {
                      ShowDetail("first", data.main_seq);
                    }}
                  >
                    상세보기
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="card_box text-center py-4">
          거래 신청 정보가 없습니다.
        </div>
      );
    }
  }, [mainData]);

  // 나의 등록매물 정보 카드 리스트
  const sellBuy = useMemo(() => {
    if (mainData.sellBuyListCnt > 0) {
      return mainData?.sellBuyList.map((data) => {
        return (
          <div className="card_box">
            <div className="top_part">
              <div className="breadcrumb">
                <ul>
                  <li
                    className={data?.deal_str === "sell" ? "blueLi" : "RedLi"}
                  >
                    {data?.deal_str === "sell" ? "팝니다" : "삽니다"}
                  </li>
                  <li>{data?.deal_category_nm}</li>
                  {parseInt(data?.deal_category_cd) === 2101 && (
                    <li>{data?.car_weight_nm.slice(0,2)}</li>
                  )}
                </ul>
              </div>
            </div>
            <div className="bottom_part">
              <div className="left_part">
                <div className="part_top_car">
                  {data?.in_car_yn === "Y" && "차량포함"}
                  <div className="inner_left_part">
                    <div
                      className="number"
                      style={{
                        "--color":
                          data?.deal_str === "sell"
                            ? "rgba(27, 111, 207, 0.9) "
                            : "rgba(233, 71, 94, 0.9)",
                      }}
                    >
                      {data?.price?.toLocaleString()}
                    </div>
                    <div className="value">만원</div>
                  </div>
                </div>
                <div className="inner_right_part">
                  <div className="part_top">
                    {parseInt(data?.deal_category_cd) === 2101 && (
                      <div className="right_part_value">
                        <div className="first_value">톤수</div>
                        <div className="second_value">
                          {(data?.car_weight && data?.car_weight !== "0" && data?.car_weight !=="")
                            ? data?.car_weight + " 톤"
                            : data?.car_weight_nm}
                        </div>
                      </div>
                    )}
                    {(parseInt(data?.deal_category_cd) === 2102 || parseInt(data?.deal_category_cd) === 2103) && (
                      data?.car_weight_nm ? (
                        <div className="right_part_value">
                          <div className="first_value">톤수</div>
                          <div className="second_value">
                            {data?.car_weight_nm}
                          </div>
                        </div>
                      ) : (
                        <div className="right_part_value">
                          <div className="first_value">대수</div>
                          <div className="second_value">
                            {data?.cnt}
                          </div>
                        </div>
                      )
                    )}
                    {data?.region_nm && (
                      <div className="right_part_value">
                        <div className="first_value">지역</div>
                        <div className="second_value">{data?.region_nm}</div>
                      </div>
                    )}
                  </div>
                  <div className="part_bottom">
                    {data?.car_yyyymm && (
                      <div className="right_part_value">
                        <div className="first_value">연식</div>
                        <div className="second_value">
                          {data?.car_yyyymm
                            ? moment(data?.car_yyyymm).format("YYYY.MM")
                            : data?.car_yyyymm}
                        </div>
                      </div>
                    )}
                    {data?.ecar_yn === "Y" && (
                      <div className="right_part_value">
                        <div className="first_value">차량구분</div>
                        <div className="second_value">
                          {data?.ecar_yn === "Y" ? "전기차" : "일반차"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right_part">
                <div className="inner_left_part">
                  <div className="first_value">등록일</div>
                  <div className="second_value">
                    {data?.reg_dt
                      ? moment(data?.reg_dt).format("YYYY.MM.DD")
                      : data?.reg_dt}
                  </div>
                </div>
                <div className="inner_right_part">
                  <button
                    onClick={() => {
                      ShowDetail("second", data.main_seq);
                    }}
                  >
                    상세보기
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="card_box text-center py-4">
          등록 매물 정보가 없습니다.
        </div>
      );
    }
  }, [mainData]);

  return (
    <>
      <div className="mypage_wrapper">
        <div className="banner_part">
          <div className="container h-100">
            <div className="banner_inner_part h-100">
              <Link
                to="/main-tab"
                state={{ main: 1, sub: "first" }}
                className="link"
              >
                <div className="banner_icon_box">
                  <div className="circle_box">{mainData?.cListCnt}</div>
                  <div className="circle_tittle">나의 거래신청</div>
                </div>
              </Link>
              <Link
                to="/main-tab"
                state={{ main: 1, sub: "second" }}
                className="link"
              >
                <div className="banner_icon_box">
                  <div className="circle_box">{mainData?.sellBuyListCnt}</div>
                  <div className="circle_tittle">나의 등록매물</div>
                </div>
              </Link>
              <Link to="/main-tab" state={{ main: 2 }} className="link">
                <div className="banner_icon_box">
                  <div className="circle_box">{mainData?.alramCnt}</div>
                  <div className="circle_tittle">읽지 않은 알림</div>
                </div>
              </Link>
              <Link to="/main-tab" state={{ main: 3 }} className="link">
                <div className="banner_icon_box">
                  <div className="circle_box">{mainData?.interestCnt}</div>
                  <div className="circle_tittle">나의 관심넘버</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="container mw-1110px pb-md-0 pb-5 px-0  mb-5">
          <div className="card_part mt_top">
            <h2 className="ms-md-15px">나의 거래신청 정보</h2>
            <div className="scroll-wrapper">{userCList}</div>
          </div>
          <div className="card_part">
            <h2 className="ms-md-15px">나의 등록매물 정보</h2>
            <div className="scroll-wrapper">{sellBuy}</div>
          </div>
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default Main;
