import React, { useEffect, useState } from "react";
import MobileFooter from "../../../componant/mobileFooter";
import { postDelQna, postQnaList } from "../../../api/customerCenter";
import { toast } from "wc-toast";
import moment from "moment/moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postNumbaGetFile } from "../../../api/mypage";
import { fileDownload } from "../../../utils/fileDownload";

function QADetailsPage() {
  const [qnaList, setQnaList] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showQnaDetail, setShowQnaDetail] = useState(false);
  const [readItems, setReadItems] = useState([0]);
  const navigate = useNavigate();
  const location = useLocation();
  const handleCheckRead = (index) => {
    if (!readItems.includes(index)) {
      const newItems = [...readItems, index];
      setReadItems(newItems);
    }
    setSelectedIndex(index);
  };

  const delQna = async (qna) => {
    if (qna.answer) {
      return toast.error("답변완료된 질문은 삭제할 수 없습니다.");
    }
    try {
      const response = await postDelQna({ qna_seq: qna.qna_seq });
      if (response.code !== 1000) {
        return toast.error("삭제 실패");
      }
      toast.success("삭제되었습니다.");
      postQnaList({}).then((res) => {
        if (res?.code !== 1000) {
          return console.log("질문 목록 불러오기 실패");
        }
        setQnaList(res.resultList);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getFileApi = async (seq) => {
    try {
      const response = await postNumbaGetFile({
        file_seq: seq,
      });
      switch (response?.code) {
        case -16:
          toast.error("토큰 확인에 실패했습니다.");
          break;
        case -17:
          toast.error("토큰이 유효하지 않습니다.");
          break;
        case -30:
          toast.error("파일이 비어 있습니다.");
          break;
        case -31:
          toast.error("파일 크기가 너무 큽니다.");
          break;
        case -32:
          toast.error("파일 업로드 오류");
          break;
        case -33:
          toast.error("파일이 없습니다.");
          break;
        case -39:
          toast.error("파일이 너무 많습니다.");
          break;
        case -50:
          toast.error("서버오류");
          break;
        case -51:
          toast.error("서버오류");
          break;
        case -52:
          toast.error("서버오류");
          break;
        case -1001:
          toast.error("알 수 없는 오류");
          break;
        case -1000:
          toast.error("권한 오류");
          break;
        default:
          fileDownload(response);
          break;
      }
    } catch (e) {
      return console.log(e);
    }
  };

  useEffect(() => {
    postQnaList({}).then((res) => {
      if (res?.code !== 1000) {
        return console.log("질문 목록 불러오기 실패");
      }
      setQnaList(res.resultList);
    });
  }, [location.state]);

  useEffect(() => {
    if (qnaList?.length === 1 && qnaList[0]?.qna_seq === 0) {
      setQnaList([]);
    }
  }, [qnaList]);

  return (
    <>
      <div className="customer_tab padding-qna">
        <div className="content-container  mypage_wrapper number-interest QAMainDiv padding-none ">
          <div className="container ">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>1:1 문의내역</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-15 top-border">
            <div className="customer-service padding-none">
              {qnaList?.length > 0 ? (
                qnaList?.map((qna, index) => {
                  return (
                    <div className="accordion" id="accordionExample">
                      <div
                        className="accordion-item"
                        onClick={() => handleCheckRead(index)}
                      >
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button collapsed`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`${"#collapse" + index}`}
                            aria-expanded="false"
                            aria-controls={`${"collapse" + index}`}
                          >
                            <div className="d-flex flex-column">
                              <div className={"btn-text"}>{qna?.title}</div>
                              <div className="d-flex align-items-center">
                                <div className="btn-date">
                                  {qna?.reg_dt
                                    ? moment(qna.reg_dt).format("YYYY.MM.DD")
                                    : "0000-00-00"}
                                </div>
                                <div className="btn-date ms-3">
                                  {qna.answer && qna.answer !== ""
                                    ? "답변완료"
                                    : "문의중"}
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`${"collapse" + index}`}
                          className={`accordion-collapse collapse`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="under-accordian">
                            {qna?.contents}
                            <div className="mt-4 d-flex align-items-center">
                              <img
                                className="attachment-icon me-2"
                                src="/img/attach.svg"
                                alt=""
                              />
                              <div className="qna_btn">첨부파일</div>
                            </div>
                            <div>
                              {qna?.files?.map((item) => {
                                return (
                                  <div
                                    className="qna_btn"
                                    role="button"
                                    onClick={() => {
                                      getFileApi(item?.file_seq);
                                    }}
                                  >
                                    {item?.file_org_nm}
                                  </div>
                                );
                              })}
                            </div>
                            <div className="d-flex justify-content-end ">
                              <button
                                onClick={() => delQna(qna)}
                                className="qna_btn"
                              >
                                삭제
                              </button>
                              <button
                                className="qna_btn ms-2"
                                onClick={() => {
                                  navigate("/qna-edit", {
                                    state: { qna: qna },
                                  });
                                }}
                              >
                                수정
                              </button>
                            </div>
                          </div>
                          {selectedIndex === index && (
                            <>
                              {qna.answer && qna.answer !== "" && (
                                <>
                                  <div className="QADivBgGray mt-0">
                                    <span className="iconLine"></span>
                                    <div className="iconLineDiv">
                                      <p className="mb-0">넘버로 고객센터</p>
                                      <span>
                                        안녕하세요.
                                        <br />
                                        국내 최초 영업용번호판, 주선면허
                                        안심거래 플랫폼 "넘버로" 입니다.
                                        <br />
                                        {qna?.answer}
                                        {/* {qna?.answer?.split("\n").length} */}
                                        {/* {qna?.answer
                                          ?.split("\n")
                                          .map((line, index) => (
                                            // <p key={index}>{line}</p>
                                            <span>{line}</span>
                                          ))} */}
                                      </span>
                                      <p className="dateTxt mb-0">
                                        {/* 2024. 01. 25 */}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="mypage_wrapper ">
                  <div className="container">
                    <div className="card_part">
                      <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                        문의내역이 없습니다.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <MobileFooter main={true} />
    </>
  );
}

export default QADetailsPage;
