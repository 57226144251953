import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../../css/Selling/Selling.style";
import RequestComplete from "./RequestComplete";
import { useLocation } from "react-router-dom";

const RequestTab = ({ activeTab, subActiveTab, setSubActiveTab }) => {
  if (activeTab === 1) {
    return (
      <>
        {/* <A.Box_Shadow>
          <Container>
            <Row>
              <Col md={6} sm={9} className="m-auto">
                <A.SubNavTab>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "first" ? "active" : ""}
                    onClick={() => setSubActiveTab("first")}
                  >
                    소형 매물
                  </A.SubNavTab_Btn>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "second" ? "active" : ""}
                    onClick={() => setSubActiveTab("second")}
                  >
                    중형 매물
                  </A.SubNavTab_Btn>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "third" ? "active" : ""}
                    onClick={() => setSubActiveTab("third")}
                  >
                    대형 매물
                  </A.SubNavTab_Btn>
                </A.SubNavTab>
              </Col>
            </Row>
          </Container>
        </A.Box_Shadow> */}
        <RequestComplete subActiveTab={subActiveTab} />
      </>
    );
  } else if (activeTab === 2) {
    return (
      <Container>
        <h1>Hello 2</h1>
      </Container>
    );
  } else if (activeTab === 3) {
    return (
      <Container>
        <h1>Hello 3</h1>
      </Container>
    );
  }
  return null;
};
function RequestCompleteTab() {
  const [activeTab, setActiveTab] = useState(1);
  const [subActiveTab, setSubActiveTab] = useState("first");
  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };

  return (
    <>
      {/* <A.SellingTab_Header>
        <Container>
          <A.NavTab_Wrapper>
            <A.NavTab
              className={activeTab === 1 ? "active" : ""}
              onClick={() => tabShow(1)}
            >
              개인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 2 ? "active" : ""}
              onClick={() => tabShow(2)}
            >
              법인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 3 ? "active" : ""}
              onClick={() => tabShow(3)}
            >
              주선면허
            </A.NavTab>
          </A.NavTab_Wrapper>
        </Container>
      </A.SellingTab_Header> */}
      <RequestTab
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
      />
    </>
  );
}

export default RequestCompleteTab;
