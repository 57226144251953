import axios from "axios";
import config from "../config";
import authHeader from "../helpers/jwt-token-access/auth-token-header";
import { toast } from "wc-toast";
import logout from "../utils/hooks/logout";
import { postNumbaRefreshAfterTimeOut } from "./auth";
import refresh from "../utils/hooks/refresh";
import { jwtDecode } from "jwt-decode";
import { isMobile } from "react-device-detect";

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
const businessAxios = axios.create({
  baseURL: "https://api.odcloud.kr/api/nts-businessman/v1/validate",
});

const basicApi = axios.create({
  baseURL: config.API_URL,
});

basicApi.interceptors.request.use(async (config) => {
  const auth = authHeader();

  config.headers.Authorization = `Bearer ${auth?.access_token}`;

  return config;
});

basicApi.interceptors.response.use(
  function (response) {
    if (response.config.responseType === "blob") {
      return response;
    } else {
      return response.data ? response.data : response;
    }
  },
  async (error) => {
    let message;
    switch (error?.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 400:
        message = error.response.data;
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
        case 403:
          if (isMobile) {
            const { refreshAPI, validateRefreshAPI } = refresh();
            const originalRequest = error?.config;
            try {
              const token = localStorage.getItem("access_token");
              const decode = jwtDecode(token);
              // console.log("토큰", token);
              if (new Date(decode?.token_expire_time) < new Date()) {
                // console.log("만료된 토큰", decode?.token_expire_time);
                await refreshAPI().then((res) => {
                  const accessToken = res?.data?.access_token;
                  localStorage.setItem("access_token", accessToken);
                  basicApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                  originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                });
              } else {
                // 리프레시 검증
                await validateRefreshAPI()
                  .then((res) => {
                    // console.log(res?.data?.code);
                    if (res?.data?.code !== 1000) {
                      console.log("유효하지 않은 로그인입니다. 로그아웃합니다.");
                      localStorage.clear();
                      setTimeout(() => {
                        window.location.href = process.env.PUBLIC_URL + "/login";
                      }, 1000);
                    }
                  })
                  .catch((err) => {
                    console.log("유효하지 않은 로그인입니다. 로그아웃합니다.");
                    localStorage.clear();
                    setTimeout(() => {
                      window.location.href = process.env.PUBLIC_URL + "/login";
                    }, 1000);
                    return;
                  });
              }
              return basicApi(originalRequest);
            } catch (err) {
              console.log("유효하지 않은 로그인입니다. 로그아웃합니다.");
              localStorage.clear();
              window.location.href = process.env.PUBLIC_URL + "/login";
              return;
            }
          } else {
            console.log("시간이 만료되어 로그인이 필요합니다.");
            localStorage.clear();
            // window.location.href = process.env.PUBLIC_URL + "/login";
            setTimeout(() => {
              window.location.href = process.env.PUBLIC_URL + "/login";
            }, 1000);
            return;
          }

      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

const setAuthorization = (token) => {};
const auth = authHeader();
axios.defaults.headers.common["Authorization"] = `Bearer ${auth?.access_token}`;

class APIClient {
  get = (url, params) => {
    return basicApi.get(url, params);
  };

  create = (url, data) => {
    return basicApi.post(url, data);
  };

  update = (url, data) => {
    return basicApi.put(url, data);
  };

  delete = (url, config) => {
    return basicApi.delete(url, { ...config });
  };

  updateWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...basicApi.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return basicApi.post(url, formData, config);
  };

  blobFile = (url, data) => {
    const config = {
      headers: {
        ...basicApi.defaults.headers,
        Accept: "application/json;charset=utf-8",
      },
      responseType: "blob",
    };
    return basicApi.post(url, data, config);
  };

  /*
   file upload post method
   */
  createWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      if (k === "car_files") {
        data[k].map((file) => {
          formData.append(k, file);
        });
      } else if (k === "qna_files") {
        data[k].map((file) => {
          formData.append(k, file);
        });
      } else {
        formData.append(k, data[k]);
      }
    }
    const config = {
      headers: {
        ...basicApi.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return basicApi.post(url, formData, config);
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
// authHeader

export { APIClient, setAuthorization, getLoggedinUser, businessAxios };
