import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { headerText } from "../../../App";
import Main from "./Main";
import { postNumbaUserMainList } from "../../../api/mypage";
import { toast } from "wc-toast";
//API

// 마이넘버원 첫 페이지지
const TabContent = ({ activeTab, subActiveTab, setSubActiveTab, isMain }) => {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  // const [userCListData, setUserCListData] = useState([]);
  // const [sellBuyData, setSellBuyData] = useState([]);
  // const [interestData, setInterestData] = useState([]);
  const [mainData, setMainList] = useState({
    cList: [],
    cListCnt: 0,
    sellBuyList: [],
    sellBuyListCnt: 0,
    interestCnt: 0,
    alramCnt: 0,
  });

  // API call - 마이넘버원 메인페이지
  const callMainList = () => {
    postNumbaUserMainList({
      startIdx: 0,
      rowCnt: 10,
    })
      .then((res) => {
        if (res.code === 1000) {
          const cntInfo = res?.cntList?.reduce((acc, cur) => {
            if (cur?.cntStr === "alramCnt") {
              return { ...acc, alramCnt: cur?.cnt };
            } else if (cur?.cntStr === "interestCnt") {
              return { ...acc, interestCnt: cur?.cnt };
            }
          }, {});
          setMainList({
            cList: res?.cList,
            cListCnt: res?.cListCnt,
            sellBuyList: res?.sellBuyList,
            sellBuyListCnt: res?.sellBuyListCnt,
            ...cntInfo,
          });
        } else {
          console.log("정보 조회에 실패했습니다.");
        }
      })
      .catch((err) => {
        console.log("메인리스트", err);
      });
  };

  useEffect(() => {
    setHeaderTextAtom("마이넘버로");

    callMainList();
  }, []);

  return (
    <div>
      <Main
        // sellBuyData={sellBuyData}
        // userCListData={userCListData}
        // interestData={interestData}
        mainData={mainData}
      />
    </div>
  );
};
function MainTab2({ isMain }) {
  const [activeTab, setActiveTab] = useState(0);
  const [subActiveTab, setSubActiveTab] = useState("first");
  const navigate = useNavigate();

  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };

  useEffect(() => {
    setActiveTab(0);
  }, [isMain]);

  return (
    <div className="parentTab_wrapper">
      <div className="parent_tab">
        <div className="container h-100">
          <div className="main_tab h-100">
            <Link
              to="/main-tab"
              state={{ main: 1, sub: "first" }}
              className={` nav_link ${activeTab === 1 ? "active" : ""}`}
            >
              거래정보
            </Link>
            <Link
              to="/main-tab"
              state={{ main: 5 }}
              onClick={() => tabShow(5)}
              className={` nav_link ${activeTab === 5 ? "active" : ""}`}
            >
              신고서 발급조회
            </Link>
            <Link
              to="/main-tab"
              className={` nav_link ${activeTab === 2 ? "active" : ""}`}
              state={{ main: 2 }}
              onClick={() => tabShow(2)}
            >
              알림정보
            </Link>
            <Link
              to="/main-tab"
              className={` nav_link ${activeTab === 3 ? "active" : ""}`}
              onClick={() => tabShow(3)}
              state={{ main: 3 }}
            >
              관심넘버
            </Link>
            <Link
              to="/main-tab"
              className={` nav_link ${activeTab === 4 ? "active" : ""}`}
              onClick={() => tabShow(4)}
              state={{ main: 4, sub: "first" }}
            >
              정보관리
            </Link>
          </div>
        </div>
      </div>

      <TabContent
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
        isMain={isMain}
      />
    </div>
  );
}

export default MainTab2;
