import React from "react";
import * as B from "../../css/components/button/buttonGray";

const GrayButton = ({
  height,
  width,
  minWidth,
  name,
  onClick,
  bg,
  fontSize,
}) => {
  return (
    <>
      <B.BtnGray
        onClick={onClick}
        height={height}
        width={width}
        minWidth={minWidth}
        bg={bg}
        fontSize={fontSize}
      >
        {name}
      </B.BtnGray>
    </>
  );
};

export default GrayButton;
