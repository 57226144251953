import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../css/Selling/Selling.style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Index from "./TransactionInformation/Index";
import NotificationTab from "./notification-Information";
import ReportList from "./Report/ReportList";
import NumberOfInterest from "./number-of-interest/NumberOfInterest";
import { useAtom } from "jotai";
import { headerText } from "../../App";
import QADetails from "../CustomerCenter/QA-details";
import {
  postNumbaUserSellBuyList,
  postNumbaUserClist,
  postNumbaInterestList,
  postNumbaUserMainList,
} from "../../api/mypage";
import { toast } from "wc-toast";
import { header_mainData } from "../../data/atom/mypage/mypage_atom";
//API

const TabContent = ({ mainSeq, activeTab, subActiveTab, setSubActiveTab }) => {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  // const [userCListData, setUserCListData] = useState([]);
  // const [sellBuyData, setSellBuyData] = useState([]);
  const [interestData, setInterestData] = useState([]);
  const [intersetCount, setIntersetCount] = useState(10);
  // const location = useLocation();

  // const [mainData, setMainList] = useState({
  //   cList: [],
  //   cListCnt: 0,
  //   sellBuyList: [],
  //   sellBuyListCnt: 0,
  //   interestCnt: 0,
  //   alramCnt: 0,
  // });

  // // API call - 마이넘버원 메인페이지
  // const callMainList = () => {
  //   postNumbaUserMainList({
  //     startIdx: 0,
  //     rowCnt: 10,
  //   })
  //     .then((res) => {
  //       console.log("메인페이지", res);
  //       if (res.code === 1000) {
  //         const cntInfo = res?.cntList?.reduce((acc, cur) => {
  //           if (cur?.cntStr === "alramCnt") {
  //             return { ...acc, alramCnt: cur?.cnt };
  //           } else if (cur?.cntStr === "interestCnt") {
  //             return { ...acc, interestCnt: cur?.cnt };
  //           }
  //         }, {});
  //         setMainList({
  //           cList: res?.cList,
  //           cListCnt: res?.cListCnt,
  //           sellBuyList: res?.sellBuyList,
  //           sellBuyListCnt: res?.sellBuyListCnt,
  //           ...cntInfo,
  //         });
  //       } else {
  //         console.log("정보 조회에 실패했습니다.");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("정보조회 에러");
  //     });
  // };

  // // API call - 나의 거래 신청 리스트
  // const callUserClist = () => {
  //   postNumbaUserClist({
  //     startIdx: 0,
  //     rowCnt: 10,
  //   })
  //     .then((res) => {
  //       setUserCListData(res?.resultList);
  //     })
  //     .catch(() => console.log("나의 거래 신청 리스트 불러오기 서버 오류"));
  // };

  // // API call - 나의 등록매물 리스트
  // const callSellBuyList = () => {
  //   postNumbaUserSellBuyList({
  //     startIdx: 0,
  //     rowCnt: 10,
  //   })
  //     .then((res) => {
  //       setSellBuyData(res?.resultList);
  //     })
  //     .catch((err) => console.log("나의 등록매물 리스트 불러오기 오류"));
  // };

  // // API call - 관심넘버 리스트
  const callInterestList = (cnt) => {
    console.log('cnt', cnt);
    postNumbaInterestList({
      startIdx: 0,
      rowCnt: cnt,
    }).then((res) => {
      setInterestData(res?.resultList);
    });
  };

  // --> 리스트들 탭을 클릭했을 때 따로 다른 페이지에서 부르기 때문에 첫 렌더링 때는 안불러도 될 것 같은데...
  useEffect(() => {
    setHeaderTextAtom("마이넘버로");
    // callUserClist();
    // callSellBuyList();
    // callMainList();
    callInterestList(intersetCount);
  }, [activeTab]);

  if (activeTab === 1) {  // 거래정보
    return (
      <div>
        <A.Box_Shadow>
          <div className="subTab_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-9 col-md-8 m-auto">
                  <div className="sub_main_tab">
                    <Link
                      className={`navsub_tab ${
                        subActiveTab === "first" ? "active" : ""
                      }`}
                      onClick={() => setSubActiveTab("first")}
                    >
                      나의 거래신청
                    </Link>
                    <Link
                      className={`navsub_tab ${
                        subActiveTab === "second" ? "active" : ""
                      }`}
                      onClick={() => setSubActiveTab("second")}
                    >
                      나의 등록매물
                    </Link>
                    <Link
                      className={`navsub_tab ${
                        subActiveTab === "third" ? "active" : ""
                      }`}
                      onClick={() => setSubActiveTab("third")}
                    >
                      나의 거래내역
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </A.Box_Shadow>
        <Index mainSeq={mainSeq} subActiveTab={subActiveTab} />
      </div>
    );
  } else if (activeTab === 5) {  // 신고서 발급조회
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Col md={6} sm={9} className="m-auto">
              <A.SubNavTab position="end" className="py-4"></A.SubNavTab>
            </Col>
          </Container>
        </A.Box_Shadow>
        <ReportList />
      </>
    );
  }else if (activeTab === 2) {  // 알림정보
    return (
      <>
        <A.Box_Shadow>
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-md-6 m-auto">
                <div className="sub_main_tab ps-md-3 ps-0">
                  <Link
                    className={`navsub_tab ${
                      subActiveTab === "first" ? "active" : ""
                    }`}
                    onClick={() => setSubActiveTab("first")}
                  >
                    알림보기
                  </Link>
                  <Link
                    className={`navsub_tab ${
                      subActiveTab === "second" ? "active" : ""
                    }`}
                    onClick={() => setSubActiveTab("second")}
                  >
                    알림설정
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </A.Box_Shadow>
        <NotificationTab subActiveTab={subActiveTab} />
      </>
    );
  } else if (activeTab === 3) {  // 관심넘버
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Col md={6} sm={9} className="m-auto">
              <A.SubNavTab position="end" className="py-4"></A.SubNavTab>
            </Col>
          </Container>
        </A.Box_Shadow>
        <NumberOfInterest
          subActiveTab={subActiveTab}
          interestData={interestData}
          callInterestList={callInterestList}
          setIntersetCount={setIntersetCount}
        />
      </>
    );
  } else if (activeTab === 4) {  // 정보관리
    return (
      <>
        <A.Box_Shadow>
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-md-8 offset-md-4 ps-md-5">
                <div className="sub_main_tab">
                  <Link
                    className={`navsub_tab ${
                      subActiveTab === "first" ? "active" : ""
                    }`}
                    onClick={() => setSubActiveTab("first")}
                  >
                    1:1 문의내역
                  </Link>
                  <Link
                    className={`navsub_tab ${
                      subActiveTab === "second" ? "active" : ""
                    }`}
                    onClick={() => setSubActiveTab("second")}
                  >
                    회원정보수정
                  </Link>
                  <Link
                    className={`navsub_tab ${
                      subActiveTab === "third" ? "active" : ""
                    }`}
                    onClick={() => setSubActiveTab("third")}
                  >
                    회원탈퇴
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </A.Box_Shadow>
        <QADetails subActiveTab={subActiveTab} />
      </>
    );
  }
};

function MainTab() {
  const [activeTab, setActiveTab] = useState(null);
  const [subActiveTab, setSubActiveTab] = useState("first");
  const [mainSeq, setMainSeq] = useState(0);
  console.log(mainSeq);

  const navigate = useNavigate();
  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };
  const location = useLocation();
  useEffect(() => {
    setMainSeq(0);
    if (location?.state) {  // --> 마이넘버로 클릭시에만 한번 실행, sub가 main2,3,5에는 없음.
      const locationState = location.state;
      if (locationState?.main === 1) {
        setActiveTab(locationState?.main);
        setSubActiveTab(locationState?.sub);
        setMainSeq(locationState?.mainSeq);
      } else if (locationState?.main === 5) {
        setActiveTab(locationState?.main);
        setSubActiveTab("first");
      } else if (locationState?.main === 2) {
        setActiveTab(locationState?.main);
        setSubActiveTab("first");
      } else if (locationState?.main === 4) {
        setActiveTab(locationState?.main);
        setSubActiveTab(locationState?.sub);
      } else {
        setActiveTab(locationState?.main);
      }
    } else {
      if (!activeTab) {
        navigate("/main");
      }
    }
  }, [location.state]);

  return (
    <div className="parentTab_wrapper">
      <div className="parent_tab">
        <div className="container h-100">
          <div className="main_tab h-100">
            <Link
              className={` nav_link ${activeTab === 1 ? "active" : ""}`}
              onClick={() => tabShow(1)}
            >
              거래정보
            </Link>
            <Link
              className={` nav_link ${activeTab === 5 ? "active" : ""}`}
              onClick={() => tabShow(5)}
            >
              신고서 발급조회
            </Link>
            <Link
              className={` nav_link ${activeTab === 2 ? "active" : ""}`}
              onClick={() => tabShow(2)}
            >
              알림정보
            </Link>
            <Link
              className={` nav_link ${activeTab === 3 ? "active" : ""}`}
              onClick={() => tabShow(3)}
            >
              관심넘버
            </Link>
            <Link
              className={` nav_link ${activeTab === 4 ? "active" : ""}`}
              onClick={() => tabShow(4)}
            >
              정보관리
            </Link>
          </div>
        </div>
      </div>
      <TabContent
        mainSeq={mainSeq}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
      />
    </div>
  );
}

export default MainTab;
