import React, { useEffect, useState } from "react";
import moment from "moment";
import { RegisterGoodsInfo } from "./goodsInfo";
import { ProcessTab } from "./process";
import { postNumbaUserDelSellBuy } from "../../../../api/mypage";
import { RegisterUserInfo } from "./userInfo";
import { useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import { postSelReport } from "../../../../api/sale";

// 나의 등록매물정보
// data: 매물정보
export const GoodsInfo = ({ data, callSellBuyList, rowCount }) => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState();  // 신고서 데이터
  const [isReportData, setIsReportData] = useState();

  const callSelReport = async (seqObj) => {
    console.log(seqObj);
    try {
      const response = await postSelReport(seqObj);
      if (response.code === 1000) {
        setReportData({ ...response });
        console.log("신고서 조회 성공");
      } else {
        console.log("신고서 조회 실패");
      }
    } catch (e) {
       console.log("신고서 조회 오류");
    }
  };

  const callDelSellBuy = async (seqObj) => {
    const response = await postNumbaUserDelSellBuy(seqObj);
    if (response.code !== 1000) {
      return toast.error("취소실패");
    }
    if (response.code === -1000){
      return toast.error("해당 사용자의 매물이 아닙니다.")
    }
    toast.success("취소되었습니다.");
    callSellBuyList(rowCount);
  };

  const goToReport = (reportData) => {
    console.log('data', reportData);    
    navigate("/report", {
      state: { data: { ...data}, reportData: reportData ? {...reportData} : null },
    });
  };

  useEffect(() => {
    callSelReport({
      main_seq: data?.main_seq,
      deal_str: data?.deal_str,
      target_seq: data?.target_seq,
    });
  }, []);

  useEffect(() => {
    const isData = !!(
      (data?.deal_str === "buy" && reportData?.buysign_dt) ||
      (data?.deal_str === "sell" && reportData?.sellsign_dt)
    );
    setIsReportData(isData);
    console.log("신고데이터있어?", isData);
    console.log("report?", reportData);
  }, [data?.deal_str, reportData]);

  return (
    <div className="request-information">
      <div className="row">
        <div className="col-md-12">
          <div className="breadcrumb">
            <ul>
              {data?.deal_str === "sell" ? (
                <li style={{ "--color": "rgba(27, 111, 207, 0.9)" }}>팝니다</li>
              ) : (
                <li style={{ "--color": "#f66176" }}>삽니다</li>
              )}
              <li>{data?.deal_category_nm}</li>
            </ul>
            <div className="date-part">
              <p>신청일</p>
              <p>{moment(data?.reg_dt).format("YYYY.MM.DD")}</p>
            </div>
          </div>

          <ProcessTab data={data} />
          <div className="table-part">
            <div className="sub-heading">
              <h3>매물정보</h3>
            </div>
            <RegisterGoodsInfo data={data} />
          </div>
          {/* 신청자 정보 */}
          <RegisterUserInfo data={data} />
          {(data?.process_cd === 3001 || data?.process_cd === 3003) && (
            <div className="button-wrapper">
              <button
                style={{ "--bg-color": "#b9b9b9" }}
                onClick={() => {
                  callDelSellBuy({
                    deal_str: data?.deal_str,
                    main_seq: data?.main_seq,
                    target_seq: data?.target_seq,
                  });
                }}
              >
                신청취소
              </button>
              <button
                style={{ "--bg-color": "#ff9e2f" }}
                onClick={() => {
                  data?.deal_str === "sell"
                    ? navigate("/selling-edit", { state: data })
                    : navigate("/buying-edit", { state: data });
                }}
              >
                신청수정
              </button>
            </div>
          )}
          {data?.process_cd === 3007 && (
            <div className="button-wrapper">
            <button
              style={{ "--bg-color": "#ff9e2f" }}
              onClick={() => {
                isReportData ? goToReport(reportData) : goToReport();
              }}
            >
              { isReportData ? "신고서 수정" : "신고서 신청" }
            </button>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
