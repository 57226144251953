import styled from "styled-components";

export const CardButton = styled.button`
  // background-color: #ff9d2f;
  background: ${(props) => (props.bg ? props.bg : "white")};
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : props.width)};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: ${(props) => (props.height ? props.height : "56px")};
  color: ${(props) => (props.bg ? "#fff" : "#000")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "19.997px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  letter-spacing: 1.6px;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    /* max-width: 167px; */
    font-size: 14px !important;
    // width: ${(props) => (props.width ? "320px" : "100%")};
    height: ${(props) => (props.height ? "41px" : "56px")};

    & > div > div {
      font-size: 15px;
    }
  }
`;
