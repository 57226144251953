import styled from "styled-components";

export const BannerButton_Circle = styled.button`
border-radius: 50%;
background: linear-gradient(180deg, #FF7F4E 0%, #F03752 100%);
width: ${props=>props.width?props.width:"40px"};
height:${props=>props.height?props.height:"51px"};
background: ${props=>props.bg?props.bg:"linear-gradient(180deg, #FF7F4E 0%, #F03752 100%);"};
border: none;
color: #FFF;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
`
export const BtnTxt_Bottom = styled.p`
    top: 56%;
    left: 32%;
    color: #FFF;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0px;
`

