import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import SellingFormDetails from "./SellingForm";
import { postNumbaSelUserInfo } from "../../../api/auth";

function SellingRequest() {
  const location = useLocation();
  const navigate = useNavigate();
  const num = location.state?.main; 
  const [activeTab, setActiveTab] = useState(num);

  useEffect(() => {
    setActiveTab(num);
    // postNumbaSelUserInfo({}).then((res) => {
    //   if (res?.brno_auth_flag === "N") {
    //     if (num === 2) {
    //       toast.error("법인회원만 등록가능합니다.");
    //       // navigate("/selling", {state: { main: 2, sub: "first" }});
    //       // setActiveTab(1);
    //     } else {
    //       setActiveTab(num);
    //     }
    //   } else if (res?.brno_auth_flag === "Y") {
    //     if (num === 1) {
    //       toast.error("개인회원만 등록가능합니다.");
    //       // navigate("/selling",{state: { main: 1, sub: "first" }});
    //       // setActiveTab(2);
    //     } else {
    //       setActiveTab(num);
    //     }
    //   }
    // });
  }, []);

  return (
    <>
      <SellingFormDetails activeTab={activeTab} setActiveTab={setActiveTab} />
    </>
  );
}

export default SellingRequest;
