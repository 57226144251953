import React, { useEffect, useState } from "react";
import "../../css/main.css";
import MainCarousel from "./MainCarousel";
import { headerText } from "../../App";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import axios from "axios";
import config from "../../config";
import MobildIntro from "./MobildIntro";

export default function NumberOneMain() {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [priceData, setPriceData] = useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);

  const navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setHeaderTextAtom("");
  }, []);

  function goReg(url) {
    if (localStorage.getItem("access_token")) {
      navigate(url, {state: {main: 1}});
      return;
    }
    // return toast.error("로그인 후 이용가능 합니다.", {
    //   duration: 500,
    // });
    navigate("/login");
  }

  const callTodayMain = async () => {
    axios({
      url: config.API_URL + "/comm/selMvalue",
      method: "post",
      data: {},
      headers: {
        ...axios.defaults.headers,
        Authorization: "",
      },
    }).then((res) => {
      const data = res?.data?.resultList;
      const temp = data[3];
      for (let i = 3; i > 0; i--) {
        data[i] = data[i - 1];
      }
      data[0] = temp;
      console.log('new---------', data);
      setPriceData(data);
    });
  };

  useEffect(() => {
    callTodayMain();
  }, []);

  return width <= 768 ? (
    <MobildIntro priceData={priceData} />
  ) : (
    <>
      <MainCarousel />

      <section>
        <div className="section1_wrapper">
          <div className="container container-outer-section1">
            <div className="card card-banner">
              <div className="card-body">
                <h1>이달의 시세</h1>
                <ul>
                  {priceData?.length > 0 && 
                    priceData?.map((item) => {
                      return (                        
                        <li>
                          <label>
                            {/* {item?.deal_category_cd === 2101 && "개인"} */}
                            {item?.deal_category_cd === 2102 && "법인매매"}
                            {item?.deal_category_cd === 2103 && "법인임대"}
                            {item?.deal_category_cd === 2104 && "주선면허"}
                            {item?.car_weight_cd === 2301 && "개인소형"}
                            {item?.car_weight_cd === 2302 && "개인중형"}
                            {item?.car_weight_cd === 2303 && "개인대형"}
                            {item?.car_weight_cd === 2304 && "전기차 소형"}
                          </label>
                          <span
                            className={`${
                              (item?.deal_category_cd === 2101 &&
                                item?.car_weight_cd === 2301 &&
                                "lbl-red") ||
                              (item?.deal_category_cd === 2101 &&
                                item?.car_weight_cd === 2302 &&
                                "lbl-blue") ||
                              (item?.deal_category_cd === 2101 &&
                                item?.car_weight_cd === 2303 &&
                                "lbl-purple") ||                          
                              (item?.deal_category_cd === 2101 &&
                                item?.car_weight_cd === 2304 &&
                                "lbl-green") ||                          
                              (item?.deal_category_cd === 2104 && "lbl-orange")
                            } amount`}
                          >
                            {item?.avg_price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                          <span className="unit">만원</span>
                        </li>
                      );
                    })}
                    {/* <>
                      <li>
                        <label>개인소형</label>
                        <span className="lbl-red amount">2,800</span>
                        <span className="unit">만원</span>
                      </li>
                      <li>
                        <label>개인중형</label>
                        <span className="lbl-blue amount">2,800 </span>
                        <span className="unit">만원</span>
                      </li>
                      <li>
                        <label>개인대형</label>
                        <span className="lbl-purple amount">2,800 </span>
                        <span className="unit">만원</span>
                      </li>
                      <li>
                        <label>주선면허</label>
                        <span className="lbl-orange amount">2,800</span>
                        <span className="unit">만원</span>
                      </li>
                    </>
                  )} */}
                </ul>
              </div>
            </div>
          </div>

          <div className="container container-section1">
            <div className="row gx-5">
              <div className="col">
                <h2 className="blue">팝니다</h2>
                <div className="card card-section1">
                  <div className="card-body">
                    <div className="blue-container container-1 pe-5x">
                      <div>
                        <h3>
                          넘버로의 <span>‘팝니다’</span> 에 등록된
                          <br />
                          양도 매물들을 확인해 보세요
                        </h3>
                        <a style={{ textDecoration: "none" }} href={"/selling"}>
                          매물정보 확인하기
                        </a>
                      </div>
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/img/section1-banner1.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="blue-container container-2">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/img/section1-icon1.png"
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <h3>
                          <span>‘팝니다’</span> 매물을 등록하면 빠르게
                          <br />
                          구매자와 매칭됩니다.
                        </h3>
                        <a
                          style={{ textDecoration: "none" }}
                          onClick={() => goReg("/selling-request")}
                        >
                          팝니다 등록하기
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <h2 className="red">삽니다</h2>
                <div className="card card-section1">
                  <div className="card-body">
                    <div className="red-container container-1">
                      <div>
                        <h3>
                          넘버로의 <span>‘삽니다’</span> 에 등록된
                          <br />
                          양수 매물들을 확인해 보세요
                        </h3>
                        <a style={{ textDecoration: "none" }} href={"/buying"}>
                          매물정보 확인하기
                        </a>
                      </div>
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/img/section1-banner2.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="red-container container-2">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/img/section1-icon2.png"
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <h3>
                          <span>‘삽니다’</span> 매물을 등록하면 빠르게
                          <br />
                          판매자와 매칭됩니다.
                        </h3>
                        <a
                          style={{ textDecoration: "none" }}
                          onClick={() => goReg("/buying-register")}
                        >
                          삽니다 등록하기
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="section2_wrapper">
          <div className="container container-section2">
            <h2 className="section2_heading">
              <span>넘버로</span>과 함께하면 안심 할 수 있어요
            </h2>
            <p className="section2_caption">
              직접 만나서 확인하지 않아도 넘버로이 모든 거래를 안전하게 대행해
              드립니다
            </p>

            <div className="row sec2-row">
              <div className="col">
                <div className="card card-sec2">
                  <div className="card-body">
                    <img
                      src={process.env.PUBLIC_URL + "/img/sec2-img1.png"}
                      alt=""
                    />
                    <h4 className="sec2-card-heading">
                      실명인증을 통한
                      <br />
                      고객관리
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-sec2">
                  <div className="card-body">
                    <img
                      className="img2"
                      src={process.env.PUBLIC_URL + "/img/sec2-img2.png"}
                      alt=""
                    />
                    <h4 className="sec2-card-heading">
                      양수양도를 위한
                      <br />
                      꼼꼼한 서류검증
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-sec2">
                  <div className="card-body">
                    <img
                      className="img3"
                      src={process.env.PUBLIC_URL + "/img/sec2-img3.png"}
                      alt=""
                    />
                    <h4 className="sec2-card-heading pt-1">
                      블록체인 기반의
                      <br />
                      보안 서비스
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-sec2">
                  <div className="card-body">
                    <img
                      className="img4"
                      src={process.env.PUBLIC_URL + "/img/sec2-img4.png"}
                      alt=""
                    />
                    <h4 className="sec2-card-heading last">
                      넘버로 플랫폼에서
                      <br />
                      실시간 진행상황 확인
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-90px pb-sm-0">
        <div className="section3_wrapper">
          <div className="container container-section3">
            <h2 className="section3_heading">
              왜? <span>넘버로</span>을 찾을 까요?
            </h2>

            <img
              className="section3_mainbanner"
              src={process.env.PUBLIC_URL + "/img/section3_mainbanner.png"}
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
}
