import { useRef, useEffect, useState } from "react";

function useInfiniteScroll(fetchMore) {
  const bottomObserver = useRef(null);
  const [bottom, setBottom] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMore();
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, []);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  return {
    setBottom,
  };
}

export default useInfiniteScroll;
