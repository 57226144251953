import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../css/Selling/Selling.style";
import { PersonalRequestForm } from "./RequestForm/PersonalRequestForm/PersonalRequestForm";
import { TradingRequestForm } from "./RequestForm/CoorporateRequestForm/Trading/TradingRequestForm";
import { useLocation, useNavigate } from "react-router-dom";
import { RentalRequestForm } from "./RequestForm/CoorporateRequestForm/Rental/RentalRequestForm";
import { LicenseRequestForm } from "./RequestForm/LicenseRequestForm/LicenseReuestForm";
import { scrollToTop } from "../../utils/hooks/scrollToTop";
// 구매신청 공통 탭
const ShowRequestTab = ({
  activeTab,
  subActiveTab,
  sellingData,
  buyingData,
  isEdit,
}) => {
  if (sellingData?.type === 2101) {
    // 개인 매매 (소형, 중형, 대형), 전기차
    return (
      <PersonalRequestForm
        subActiveTab={subActiveTab}
        buyingData={buyingData}
        isEdit={isEdit}
      />
    );
  } else if (sellingData?.type === 2102) {
    // 법인 매매
    return (
      <TradingRequestForm
        subActiveTab={subActiveTab}
        buyingData={buyingData}
        isEdit={isEdit}
      />
    );
  } else if (sellingData?.type === 2103) {
    // 법인 임대
    return (
      <RentalRequestForm
        subActiveTab={subActiveTab}
        buyingData={buyingData}
        isEdit={isEdit}
      />
    );
  } else {
    return (
      <LicenseRequestForm
        subActiveTab={subActiveTab}
        buyingData={buyingData}
        isEdit={isEdit}
      />
    );
  }
};

function RequestTab() {
  const [activeTab, setActiveTab] = useState(1);
  const [subActiveTab, setSubActiveTab] = useState("first");
  const [buyingData, setBuyingData] = useState({
    car_weight_nm: "",
    car_yyyymm: "",
    price: 0,
    process_cd: 0,
    reg_dt: "",
    region_nm: "",
    sell_seq: 0,
    target_seq: 0,
  });

  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();

    if (location?.state) {
      setBuyingData(location.state.data);
      console.log(location.state.data);
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <ShowRequestTab
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
        buyingData={buyingData}
        sellingData={location?.state?.data}
        isEdit={location?.state?.isEdit}
      />
    </>
  );
}

export default RequestTab;
