import React, { useEffect, useState } from "react";
import CardButton from "../../../componant/buttons/cardButton";
import MobileFooter from "../../../componant/mobileFooter";
import WithdrawSecond from "./WithdrawSecond";
import { toast } from "wc-toast";
import { postNumbaChkUsrForW, postNumbaCodeList } from "../../../api/auth";
import { DaumPostcode } from "../../../utils/DaumPostCode";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import getUserInfo from "../../../utils/hooks/getUserInfo";

function WithdrawSection() {
  const [active, setActive] = useState(false);
  const [inputIdPw, setInputIdPw] = useState({
    user_id: "",
    passwd: "",
  });

  const confirmExit = async () => {
    if (inputIdPw.user_id.length <= 0 || inputIdPw.passwd.length <= 0) {
      return toast.error("올바른 계정 정보를 입력해주세요.");
    }

    try {
      const response = await postNumbaChkUsrForW(inputIdPw);
      if (response.code !== 1000) {
        toast.error("계정 정보를 확인 해 주세요.");
        return;
      }
      setActive(true);
    } catch (err) {
      toast.error("회원정보가 일치하지 않습니다.");
    }
  };

  const callGetUser = async () => {
    const { reg_path_cd } = await getUserInfo();
    if (reg_path_cd && reg_path_cd === 302) {
      setActive(true);
    }
  };

  useEffect(() => {
    callGetUser();
  }, []);

  return (
    <>
      {active ? (
        <WithdrawSecond />
      ) : (
        <div className="content-container number-interest QAMainDivMember WithdrawMainDiv QAMainDiv">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>회원탈퇴</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="editMemberMainDiv withdrawDiv">
                  <div className="borderDiv">
                    <p className="mb-0">
                      회원님의 개인정보 보호를 위해 비밀번호 인증 후 회원탈퇴를
                      하실 수 있습니다.
                    </p>
                    <p className="mb-0">
                      회원 탈퇴를 하시면 기존 정보 데이터는 더이상 제공 되지
                      않습니다.
                    </p>
                    <p className="YellowTxt mb-0">
                      ※ 매매가 진행중인 경우에는 관리자 확인 후 탈퇴 처리됩니다.
                    </p>
                    <div
                      className="formDiv d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <label style={{ marginRight: "5px" }}>아이디</label>
                        <input
                          style={{ border: "1px solid black", fontSize: 10 }}
                          type="text"
                          id="text"
                          name="text"
                          value={inputIdPw.user_id}
                          onChange={(e) => {
                            setInputIdPw({
                              ...inputIdPw,
                              user_id: e.target.value,
                            });
                          }}
                          placeholder="아이디"
                        />
                      </div>
                      <div>
                        <label style={{ marginRight: "5px" }}>비밀번호</label>
                        <input
                          style={{ border: "1px solid black", fontSize: 10 }}
                          type="password"
                          id="text"
                          name="text"
                          value={inputIdPw.passwd}
                          onChange={(e) => {
                            setInputIdPw({
                              ...inputIdPw,
                              passwd: e.target.value,
                            });
                          }}
                          placeholder="비밀번호"
                        />
                      </div>
                    </div>
                    <div className="btnDiv text-center">
                      <CardButton
                        className={"m-auto"}
                        maxWidth={"390px"}
                        onClick={confirmExit}
                        width="100%"
                        name="확인"
                        height="60px"
                        bg="#FF9C00"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MobileFooter main={true} />
    </>
  );
}

export default WithdrawSection;
