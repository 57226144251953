import styled from "styled-components";
export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 72px 0px 150px 0px;
  @media screen and (max-width: 600px) {
    padding: 25px 0px 50px 0px;
    margin-top: 56px;
  }
`;
export const MainHeading = styled.div`
  font-size: 33px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  letter-spacing: 2.5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
`;
export const SubHeading = styled.h3`
  margin-top: 35px;
  text-align: center;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: normal;
`;
export const SubPara = styled.p`
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
  letter-spacing: 1.9px;
  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
`;
export const AccordionDiv = styled.div`
  border: 2px solid #e2e2e2;
  background: #fff;
  margin-top: 24px;
  border-radius: 5px;
  .accordion {
    --bs-accordion-border-width: 0px !important;
  }
  .accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .accordion-button:focus {
    border-color: 0 !important;
    box-shadow: none !important;
  }
  .accordion-body {
    padding-left: 30px !important;
    background-color: #eeeff0 !important;
    color: #888888 !important;
  }
  .accordion-button::after {
    background-image: url("/img/up_down.png") !important;
    background-size: auto !important;
    position: absolute;
    right: 10px;
    top: 28px;
  }
  .accordion-button:not(.collapsed)::after {
    margin-right: 6px !important;
    position: absolute;
    right: 10px;
    top: 17px;
  }
  .accordion-item {
    border-top: 1px solid #e2e2e2 !important;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
`;
export const AccordionHeading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: ${(props) => (props.padding ? "props.padding" : "15")}px
    ${(props) => (props.padding ? props.padding : "23")}px;
`;
export const AccordionIcon = styled.div`
  background-color: ${(props) => (props.color ? props.color : "#ff9d2f")};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
export const AccordionValue = styled.p`
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
`;

export const AccordionCollapse_Text = styled.p`
  color: #888;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`;
export const AccordionBtn_Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 46px;
  width: 100%;

  & > a {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
`;

export const CardButton = styled.button`
  // background-color: #ff9d2f;
  background: ${(props) => (props.bg ? props.bg : "white")};
  width: 360px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : props.width)};
  height: 56px;
  color: ${(props) => (props.bg ? "#fff" : "#000")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "19.997px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  letter-spacing: 1.6px;
  border-radius: 5px;
  @media screen and (max-width: 767px) {
    /* max-width: 167px; */
    font-size: 15px !important;
    width: 100%;
    //height: ${(props) => (props.height ? "40px" : "51px")};
    height: 51px;
  }
`;

export const BtnLightYellow = styled.button`
  background-color: #ffdc13;
  max-width: 100%;
  height: 60px;
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  width: 360px;
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: 19.997px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-radius: 6px;
  @media only screen and (max-width: 767px) {
    height: 51px;
    padding: 12px 24px;
    font-size: 15px;
    width: 100%;
  }
`;
