import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { postNumbaMValueList } from "../../api/mypage";
import { toast } from "wc-toast";
// import { setLabels } from 'react-chartjs-2/dist/utils';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
// 주선면허
const ChartOptions = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      // position: 'top'
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  elements: {
    arc: {
      borderwidth: 0,
    },
  },
};

const BrokerageLincencePrice = () => {
  const refSelectYYYY = useRef();
  //
  const [selectYYYY, setSelectYYYY] = useState("2023");
  //
  const [selectCategory, setSelectCategory] = useState("2104");
  //
  const [dataWeightCat1, setDataWeightCat1] = useState([]);
  const [dataWeightCat2, setDataWeightCat2] = useState([]);
  const [dataWeightCat3, setDataWeightCat3] = useState([]);
  //
  const [lineChartData, setLineChartData] = useState(null);

  const generateLabels = (year) => {
    let labels = [];
    for (let i = 1; i <= 12; i++) {
      labels.push(year + i.toString().padStart(2, "0"));
    }
    return labels;
  };

  const callPostMValueList = (deal_category_cd, yyyy) => {
    var labels = generateLabels(yyyy);
    postNumbaMValueList({
      deal_category_cd: deal_category_cd,
      yyyy: yyyy,
    })
      .then((res) => {
        var avg_price1 = [];
        var avg_price2 = [];
        var avg_price3 = [];
        if (res.code === 1000) {
          //
          if (typeof res[2301] !== "undefined") {
            setDataWeightCat1(res[2301]);
            avg_price1 = res[2301].map((item) => item.avg_price);
          } else {
            setDataWeightCat1([]);
          }
          if (typeof res[2302] !== "undefined") {
            setDataWeightCat2(res[2302]);
            avg_price2 = res[2302].map((item) => item.avg_price);
          } else {
            setDataWeightCat2([]);
          }
          if (typeof res[2303] !== "undefined") {
            setDataWeightCat3(res[2303]);
            avg_price3 = res[2303].map((item) => item.avg_price);
          } else {
            setDataWeightCat3([]);
          }
          setLineChartData({
            labels,
            datasets: [
              {
                label: "Dataset 1",
                data: avg_price1,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                borderWidth: 1,
              },
              {
                label: "Dataset 2",
                data: avg_price2,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
                borderWidth: 1,
              },
              {
                label: "Dataset 3",
                data: avg_price3,
                borderColor: "#c36ceb",
                backgroundColor: "#c36ceb",
                borderWidth: 1,
              },
            ],
          });
        } else {
          console.log("정보 조회에 실패했습니다.");
        }
      })
      .catch((err) => console.log("정보 조회에 실패했습니다."));
  };

  useEffect(() => {
    callPostMValueList(selectCategory, selectYYYY);
  }, [selectYYYY]);
  return (
    <>
      <div className="virePrice-personalNumberDiv">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="perNumbContentDIv">
                <p className="mb-0 text-end">단위 : 만원</p>
                <div className="tableDiv">
                  <table
                    className="table table-bordered mb-0"
                    style={{ width: "1056px" }}
                  >
                    <thead>
                      <tr>
                        <th className="mainTh" scope="col">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectYYYY}
                            onChange={(e) => setSelectYYYY(e.target.value)}
                            ref={refSelectYYYY}
                          >
                            <option value="2023">2023년</option>
                            <option value="2022">2022년</option>
                            <option value="2021">2021년</option>
                          </select>
                        </th>
                        <th scope="col">1월</th>
                        <th scope="col">2월</th>
                        <th scope="col">3월</th>
                        <th scope="col">4월</th>
                        <th scope="col">5월</th>
                        <th scope="col">6월</th>
                        <th scope="col">7월</th>
                        <th scope="col">8월</th>
                        <th scope="col">9월</th>
                        <th scope="col">10월</th>
                        <th scope="col">11월</th>
                        <th scope="col">12월</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataWeightCat1.length > 0 && (
                        <tr key={0} className="redTr">
                          <th className="mainTh" scope="row">
                            소형매물
                          </th>
                          {dataWeightCat1.map((item, index) => (
                            <td key={"0" + index}>
                              {Number(item.avg_price).toLocaleString("en-US")}
                            </td>
                          ))}
                        </tr>
                      )}
                      {dataWeightCat2.length > 0 && (
                        <tr key={1} className="blueTr redTr">
                          <th className="mainTh" scope="row">
                            중형매물
                          </th>
                          {dataWeightCat2.map((item, index) => (
                            <td key={"1" + index}>
                              {Number(item.avg_price).toLocaleString("en-US")}
                            </td>
                          ))}
                        </tr>
                      )}
                      {dataWeightCat3.length > 0 && (
                        <tr key={2} className="purpleTr redTr">
                          <th className="mainTh" scope="row">
                            대형매물
                          </th>
                          {dataWeightCat3.map((item, index) => (
                            <td key={"2" + index}>
                              {Number(item.avg_price).toLocaleString("en-US")}
                            </td>
                          ))}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="graphDiv">
                  {lineChartData != null && (
                    <Line
                      responsive={true}
                      maintainAspectRatio={false}
                      options={ChartOptions}
                      data={lineChartData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrokerageLincencePrice;
