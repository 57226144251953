import React from "react";
import RequestForTransaction from "./RequestForTransaction";
import MyListing from "./MyListing";
import TransactionHistory from "./TransactionHistory";
function Index({ mainSeq, subActiveTab }) {
  if (subActiveTab === "first") {
    return <RequestForTransaction mainSeq={mainSeq}/>;
  } else if (subActiveTab === "second") {
    return <MyListing mainSeq={mainSeq}/>;
  } else if (subActiveTab === "third") {
    return <TransactionHistory />;
  } else return null;
}

export default Index;
