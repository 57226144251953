
export const ProcessTab = ({ data }) => {
  const processCd = data?.process_cd;
  const processStr = (processCd) => {
    switch (processCd) {
      case 3001:
        return "매물 등록신청이 반려되었습니다. 등록취소 또는 매물 정보를 수정해 주세요.";
      case 3003:
        return "매물 등록신청 승인이 진행중입니다.";
      case 3004:
        return "매물 등록이 승인되어 매물정보가 게시중입니다.";
      case 3005:
        return "거래신청 승인중입니다.";
      case 3006:
        return "거래승인이 완료되었습니다. 필요한 서류를 보내시면 거래가 진행됩니다.";
      case 3007:
        return "현재 거래가 진행중입니다.";
      case 3008:
        return "거래가 완료되었습니다.";
    }
  };

  return (
    <div className="approvel-main-section">
      <div className="approvel-top-section">
        {(data?.deal_str === "sell" || data?.deal_str === "buy") && (
          <>
            <div className="icons-part">
              <div className="icons ">
                <img
                  className="w-100"
                  src={`${
                    data?.process_cd === 3003
                      ? "/img/icon_application_on.png"
                      : "/img/icon_application_off.png"
                  }`}
                  alt=""
                />
              </div>
              {/* 3003 */}
              <div
                className={`icon-title ${
                  data?.process_cd === 3003 && "active"
                }`}
              >
                등록신청
              </div>
            </div>
            <div className="icons-part">
              <div className="icons">
                <img
                  className="w-100"
                  src={`${
                    data?.process_cd === 3004
                      ? "/img/icon_registration_on.png"
                      : "/img/icon_registration_off.png"
                  }`}
                  alt=""
                />
              </div>
              {/* 3004 */}
              <div
                className={`icon-title ${
                  data?.process_cd === 3004 && "active"
                }`}
              >
                등록승인
              </div>
            </div>
          </>
        )}

        <div className="icons-part">
          <div className="icons">
            <img
              className="w-100"
              src={`${
                data?.process_cd === 3005
                  ? "/img/icon_transaction_on.png"
                  : "/img/icon_transaction_off.png"
              }`}
              alt=""
            />
          </div>
          {/* 3005 */}
          <div
            className={`icon-title ${data?.process_cd === 3005 && "active"}`}
          >
            거래신청
          </div>
        </div>
        <div className="icons-part">
          <div className="icons">
            <img
              className="w-100"
              src={`${
                data?.process_cd === 3006
                  ? "/img/icon_transaction_approval_on.png"
                  : "/img/icon_transaction_approval_off.png"
              }`}
              alt=""
            />
          </div>
          {/* 3006 */}
          <div
            className={`icon-title ${data?.process_cd === 3006 && "active"}`}
          >
            거래승인
          </div>
        </div>
        <div className="icons-part">
          <div className="icons">
            <img
              className="w-100"
              src={`${
                data?.process_cd === 3007
                  ? "/img/icon_proceeding_on.png"
                  : "/img/icon_proceeding_off.png"
              }`}
              alt=""
            />
          </div>
          {/* 3007 */}
          <div
            className={`icon-title ${data?.process_cd === 3007 && "active"}`}
          >
            거래진행중
          </div>
        </div>
        <div className="icons-part">
          <div className="icons">
            <img
              className="w-100"
              src={`${
                data?.process_cd === 3008
                  ? "/img/icon_complete_on.png"
                  : "/img/icon_complete_off.png"
              }`}
              alt=""
            />
          </div>
          {/* 3008 */}
          <div
            className={`icon-title ${data?.process_cd === 3008 && "active"}`}
          >
            거래완료
          </div>
        </div>
      </div>
      <div className="approvel-bottom-section">
        <span>{processStr(processCd)}</span>
      </div>
    </div>
  );
};
