import React from "react";
import { postNumbaSelUserInfo } from "../../api/auth";

export default async function getUserInfo() {
  try {
    const response = await postNumbaSelUserInfo({});
    if (response.code === 1000) {
      return {
        user_nm: response?.user_nm,
        user_id: response?.user_id,
        phone: response?.phone,
        user_type_cd: response?.user_type_cd,
        corp_nm: response?.corp_nm,
        reg_path_cd: response?.reg_path_cd,
        zip: response?.zip,
        addr1: response?.addr1,
        addr2: response?.addr2,
      };
    } else {
    }
  } catch (err) {
    console.log("err-2", err);
  }
}
