import React, { useEffect, useState } from "react";
import { postNumbaKaKaoSignUp, postNumbaKakaoLogin } from "../../api/auth";
import { toast } from "wc-toast";
import { useAtom } from "jotai";
import { userLoginJotai } from "../../App";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";

export default function OAuthPage() {
  const [userLoginAtom, setUserLoginAtom] = useAtom(userLoginJotai);
  let [color, setColor] = useState("#ffffff");
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const navigate = useNavigate();
  const processKAKAOSignUp = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get("code");

    try {
      const response = await postNumbaKakaoLogin({
        code: code,
      });

      console.log("response", response);

      if (response?.code === 1000) {
        setUserLoginAtom(response);
        setTimeout(function () {
          console.log("인증완료");
        }, 1000);
        setTimeout(function () {
          navigate("/");
        }, 2000);
        localStorage.setItem("access_token", response?.access_token);
        localStorage.setItem("refresh_token", response?.refresh_token);
        localStorage.setItem("device_id", response?.device_id);
      } else if (response?.code === -21) {
        toast.error("아이디가 중지 또는 휴먼상태 입니다.");
      } else if (response?.code === -25) {
        toast.error("일반회원 가입자이므로 일반회원으로 로그인 해 주세요.");
      } else {
        if (response?.user_state_val === "withdraw") {
          toast.error("회원 탈퇴 시 하루 뒤에 가입이 가능합니다.");
        } else {
          toast.error("카카오 로그인이 실패하였습니다.");
        }
        setTimeout(function () {
          navigate("/");
        }, 1000);
      }
    } catch (err) {
      console.log("err", err);

      if (err.code === -25) {
        toast.error("일반회원 가입자이므로 일반회원으로 로그인 해 주세요.");
      } else {
        toast.error("카카오 로그인이 실패하였습니다");
      }

      setTimeout(function () {
        navigate("/");
      }, 1000);
    }
  };
  useEffect(() => {
    processKAKAOSignUp();
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center h-500">
      <HashLoader
        loading={true}
        color="#FFDD00"
        // cssOverride={override}
        size={150}
        // aria-label="Loading Spinner"
        // data-testid="loader"
      />
    </div>
  );
}
