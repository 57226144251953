import styled from "styled-components";

export const FormHeading_div = styled.div`
  border-bottom: 1px solid #e1e1e1;
`;
export const Form_Heading = styled.h1`
  color: #000;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  @media screen and (max-width: 592px) {
    font-size: 25px;
    letter-spacing: -0.85px;
  }
`;
export const FormDetails_Div = styled.div`
  /* border-top: 1px solid #E1E1E1;
border-bottom: 1px solid #E1E1E1; */

  //@media screen and (max-width: 720px) {
  //  border-right: 10px #1b6fcf !important;
  //}
`;

export const FormDetails_Heading = styled.h3`
  color: #000;
  font-size: 24.01px;
  font-family: "NotoSansKR-SemiBold";
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.7px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media screen and (max-width: 592px) {
    font-size: 17px;
    letter-spacing: -0.85px;
    margin-top: 20px;
    margin-bottom: 5px;
  } 
`;

export const FormDetails_Heading_DIV = styled.div`
  color: #000;
  font-size: 24.01px;
  font-family: "NotoSansKR-SemiBold";
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.7px;
  margin-bottom: 20px;

  @media screen and (max-width: 592px) {
    font-size: 17px;
    letter-spacing: -0.85px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
    span {
      color: #086def;
      font-size: 18px;
      font-weight: bold;
      
      @media screen and (max-width: 592px) {
        font-size: 15px; 
        margin-left: 5px;
      }
    }
`;

export const FormTable_Th = styled.th`
  background-color: #f5f5f5 !important;
  color: #888888 !important;
  border-right: 1px solid #e2e2e2 !important;
  text-align: center;
  font-size: 16.01px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  width: ${(props) => (props.width ? props.width : "110px")} !important;

  @media screen and (max-width: 592px) {
    font-family: "NotoSansKR-Medium";
    font-size: 12px;
    letter-spacing: -0.6px;
    width: 28.125% !important;
  }
`;

export const Table_Link = styled.a`
  color: #4e92e1;
  font-size: 22.01px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  @media screen and (max-width: 592px) {
    font-family: "NotoSansKR-Medium";
    font-size: 15px;
    letter-spacing: -0.75px;
  }
`;

export const Table_Input = styled.input`
  width: ${(props) => (props.width ? props.width : "300px")};
  border-radius: 5px;
  height: 46px;
  border: 2px solid #e1e1e1;
  padding-left: 6px;
  background: #fff;
  margin-left: 35px;
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 592px) {
    margin-left: 0;
    font-size: 12px;
    border: 1px solid #e1e1e1;
    height: 36px;
    width: ${(props) => (props.width == "100px" ? "80px" : "100%")};
  }
`;

export const SSN_Input = styled.input`
  width: ${(props) => (props.width ? props.width : "150px")};
  border-radius: 5px;
  height: 46px;
  border: 2px solid #e1e1e1;
  padding-left: 6px;
  background: #fff;
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 592px) {
    margin-left: 0;
    font-size: 12px;
    border: 1px solid #e1e1e1;
    height: 36px;
    width: ${(props) => (props.width == "100px" ? "80px" : "100%")};
  }
`;

export const Table_Select = styled.select`
  width: ${(props) => (props.width ? props.width : "300px")};
  border-radius: 5px;
  height: 40px;
  border: 2px solid #e1e1e1;
  padding-left: 6px;
  background: #fff;
  margin-left: 35px;
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 592px) {
    width: ${(props) => (props.width == "100px" ? "80px" : "200px")};
    border: 1px solid #e1e1e1;
  }
`;

export const TableInput_Search = styled.input`
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  border: 2px solid #e1e1e1;
  padding-left: 6px;
  height: 40px;
  margin-left: 33px;
  background: #fff;
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 592px) {
    margin-left: 0;
    font-size: 12px;
    border: 1px solid #e1e1e1;
    height: 32px;
  }
`;
export const Table_Textarea = styled.textarea`
  display: block;
  border-radius: 5px;
  border: 2px solid #e1e1e1;
  padding-left: 6px;
  background: #fff;
  margin-left: 33px;
  resize: none;
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 592px) {
    margin-left: 0;
    font-size: 12px;
    border: 1px solid #e1e1e1;
    height: 103px;
  }
`;
export const TableInput_Icon = styled.img`
  position: absolute;
  right: 50px;
  height: 24px;
  top: 16px;
`;
export const Table_Btn = styled.label`
  width: 120px;
  height: 40px;
  background: #ff9c00;
  color: #fff;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 592px) {
    width: 80px;
  }
`;
// export const Btn_Value = styled.span`
//   color: ${(props) => (props.color ? props.color : "#838383")};
//   font-size: 16px;
//   font-weight: 400;
//   line-height: normal;
//   @media screen and (max-width: 592px) {
//     font-size: 14px;
//   }
// `;
export const Btn_Value = styled.span`
  color: ${(props) => (props.color ? props.color : "#838383")};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 992px) {
    font-size: 12px;
  }
`;
export const Para_Content = styled.p`
  color: #f60;
  font-family: "Malgun Gothic";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  @media screen and (max-width: 592px) {
    font-size: 14px;
  }
`;
