import React, { useEffect, useState } from "react";
import MobileFooter from "../../../componant/mobileFooter";
import { postNumbaDelInterest } from "../../../api/mypage";
import { toast } from "wc-toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useInfiniteScroll from "../../../utils/hooks/infinite-scroll/useInfiniteScroll";
import { useAtom } from "jotai";
import { header_mainData } from "../../../data/atom/mypage/mypage_atom";
import callMainData from "../../../utils/hooks/callMainData";
function NumberOfInterest(props) {
  const { interestData, callInterestList, setIntersetCount } = props;
  const navigate = useNavigate();
  const [mainData, setMainList] = useAtom(header_mainData);

  const delInterest = async (seq) => {
    try {
      const response = await postNumbaDelInterest({
        interest_seq: seq,
      });
      if (response.code === 1000) {
        console.log("삭제 성공");
        callInterestList();
        const data = await callMainData();
        setMainList(data);
      } else {
        toast.error("삭제 실패");
      }
    } catch (err) {
      toast.error("삭제 서버 오류");
    }
  };

  const sellRequest = (data) => {
    if (data?.deal_category_cd === 2102 || data?.deal_category_cd === 2103) {
      navigate("/selling-request1", {
        state: {
          data: { 0: data, type: data?.deal_category_cd },
          isEdit: false,
        },
      });
    } else {
      navigate("/selling-request1", {
        state: {
          data: { ...data, type: data?.deal_category_cd },
          isEdit: false,
        },
      });
    }
  };

  const buyRequest = (data) => {
    if (data?.deal_category_cd === 2102 || data?.deal_category_cd === 2103) {
      navigate("/buying-request1", {
        state: {
          data: { 0: data, type: data?.deal_category_cd },
          isEdit: false,
        },
      });
    } else {
      navigate("/buying-request1", {
        state: {
          data: { ...data, type: data?.deal_category_cd },
          isEdit: false,
        },
      });
    }
  };

  const { setBottom } = useInfiniteScroll(() => {
    setIntersetCount((prev) => {
      callInterestList(prev + 10);
      return prev + 10;
    });
  });

  const buyButton = (data) => {
    console.log(data);
    switch (+data?.process_cd) {
      case 3005:
        return (
          <button style={{ backgroundColor: "#adadad" }}>거래신청중</button>
        );
      case 3006:
        return <button style={{ backgroundColor: "#7F7F7F" }}>거래승인</button>;
      case 3007:
        return (
          <button style={{ backgroundColor: "#7F7F7F" }}> 거래진행중</button>
        );
      case 3008:
        return <button style={{ backgroundColor: "#BFBFBF" }}>거래완료</button>;
      default:
        return (
          <button
            style={{
              backgroundColor:
                data?.deal_str === "sell" ? "#00bce1" : "#ff824e",
            }}
            onClick={() => {
              data?.deal_str === "sell" ? sellRequest(data) : buyRequest(data);
            }}
          >
            {data?.deal_str === "sell" ? "구매신청" : "판매신청"}
          </button>
        );
    }
  };

  return (
    <>
      <div>
        <div className="content-container  mypage_wrapper number-interest">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>나의 관심넘버</h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container pb-md-0 pb-5 mb-md-0 mb-5 top-border"
            style={{ height: "650px", overflow: "scroll" }}
          >
            <div className="card_part">
              {interestData.length > 0 ? (
                <>
                  {interestData?.map((data) => {
                    return (
                      <div className="card_box intrestCard">
                        <div className="top_part">
                          <div className="breadcrumb d-flex justify-content-between">
                            <ul>
                              <li
                                className={
                                  data?.deal_str === "sell" ? "blueLi" : "RedLi"
                                }
                              >
                                {data?.deal_str === "sell"
                                  ? "팝니다"
                                  : "삽니다"}
                              </li>
                              <li>
                                {data?.deal_category_cd === 2101 && "개인넘버"}
                                {data?.deal_category_cd === 2102 &&
                                  "법인넘버 매매"}
                                {data?.deal_category_cd === 2103 &&
                                  "법인넘버 임대"}
                                {data?.deal_category_cd === 2104 && "주선면허"}
                              </li>
                              {data?.car_weight_nm &&
                                data?.deal_category_cd === 2101 && (
                                  <li>
                                    {data.car_weight_nm?.slice(0, 2) + "매물"}
                                  </li>
                                )}
                            </ul>
                            <div className="align-self-center">
                              {/* <i className="fa-solid fa-2x fa-xmark" style={{ color: "rgba(45, 70, 98, 0.15)" }}></i> */}
                              <img
                                role="button"
                                src="/img/cross.png"
                                alt=""
                                onClick={() => delInterest(data?.interest_seq)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bottom_part">
                          <div className="left_part">
                            <div className="inner_left_part">
                              <div
                                className="number"
                                style={{
                                  "--color":
                                    data?.deal_str === "sell"
                                      ? "rgba(27, 111, 207, 0.9) "
                                      : "rgba(233, 71, 94, 0.9)",
                                }}
                              >
                                {data?.price?.toLocaleString()}
                              </div>
                              <div className="value">만원</div>
                            </div>
                            <div className="inner_right_part">
                              <div className="part_top ">
                                {data?.deal_category_cd !== 2104 && (
                                  <div className="right_part_value align-itmes-center">
                                    <div className="first_value">톤수</div>
                                    <div className="second_value2">
                                      {data?.car_weight === 0
                                        ? data?.car_weight_nm
                                        : data?.car_weight + " 톤"}
                                    </div>
                                  </div>
                                )}
                                {data?.region_nm && (
                                  <div className="right_part_value">
                                    <div className="first_value">지역</div>
                                    <div className="second_value2">
                                      {data?.region_nm}
                                    </div>
                                  </div>
                                )}
                              </div>
                              {data?.car_yyyymm && (
                                <div className="part_bottom">
                                  <div className="right_part_value">
                                    <div className="first_value">연식</div>
                                    <div className="second_value2">
                                      {moment(data?.car_yyyymm).format(
                                        "YYYY.MM"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="right_part">
                            <div className="inner_left_part">
                              <div className="first_value">신청일</div>
                              <div className="second_value">
                                {moment(data?.reg_dt).format("YYYY.MM.DD")}
                              </div>
                            </div>
                            <div className="inner_right_part">
                              {buyButton(data)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {interestData.length > 0 && <div ref={setBottom} />}
                </>
              ) : (
                <div className="mypage_wrapper">
                  <div className="container">
                    <div className="card_part">
                      <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                        관심 넘버가 없습니다.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default NumberOfInterest;
