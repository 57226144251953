import React, { useEffect, useState } from "react";
import "../../css/mobile-intro/mobile-intro.css";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { isMobile } from "react-device-detect";

export default function MobildIntro({ priceData }) {
  const [text, setText] = useState({
    buy: "",
    sell: "",
  });
  const [opacity, setOpacity] = useState(1);
  const [opacity2, setOpacity2] = useState(0);
  const [opacityLeft, setOpacityLeft] = useState(0);
  const [opacityRight, setOpacityRight] = useState(0);
  const [textColor1, setTextColor1] = useState("#3264ce");
  const [textColor2, setTextColor2] = useState("#F34250");
  const [leftX, setLeftX] = useState("-100px");
  const [rightX, setRightX] = useState("-100px");
  const navigate = useNavigate();
  const servicePhoneNum = "02-2276-0601";

  useEffect(() => {
    setLeftX("-2px");
    setRightX("-2px");

    setTimeout(function () {
      setOpacity2(1);
    }, 1000);

    setTimeout(function () {
      setText({ buy: "삽니다", sell: "팝니다" });
    }, 1500);
  }, []);

  const goToPhonCallCommunicationToApp = (phoneNum) => {
    // if (isMobile) {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       type: "TO_CALL",
    //       data: {
    //         phoneNum: phoneNum,
    //       },
    //     }),
    //   );
    // }
  };

  return (
    <div className="mobile-intro-wrapper">
      <div className="header">
        <div>
          <div className="header-text">국내 최초 화물넘버</div>
          <div className="header-text" style={{ marginTop: "-10px" }}>
            안심거래 플랫폼<span className="text-24"> 넘버로</span>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="price-box">이달의 시세</div>

        <Carousel>
          {priceData?.map((item) => {
            return (
              <Carousel.Item>
                <div className="d-flex">
                  <div className="gray-box">
                    {item?.deal_category_cd === 2102 && "법인매매"}
                    {item?.deal_category_cd === 2103 && "법인임대"}
                    {item?.deal_category_cd === 2104 && "주선면허"}
                    {item?.car_weight_cd === 2301 && "개인소형"}
                    {item?.car_weight_cd === 2302 && "개인중형"}
                    {item?.car_weight_cd === 2303 && "개인대형"}
                    {item?.car_weight_cd === 2304 && "전기차 소형"}
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className={`${
                        (item?.deal_category_cd === 2101 &&
                          item?.car_weight_cd === 2301 &&
                          "lbl-red") ||
                        (item?.deal_category_cd === 2101 &&
                          item?.car_weight_cd === 2302 &&
                          "lbl-blue") ||
                        (item?.deal_category_cd === 2101 &&
                          item?.car_weight_cd === 2303 &&
                          "lbl-purple") ||
                        (item?.deal_category_cd === 2101 &&
                          item?.car_weight_cd === 2304 &&
                          "lbl-green") ||
                        (item?.deal_category_cd === 2104 && "lbl-orange")
                      } amount`}
                    >
                      {item?.avg_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                    <span className="price-unit">만원</span>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div className="mobile-intro-img-wrapper">
        <img
          src={process.env.PUBLIC_URL + `/img/mobile-intro-left-1.png`}
          alt=""
          className="mobile-intro-img-sell"
          style={{ "--left-x": leftX, "--opacity": opacity }}
        />
        <img
          src={process.env.PUBLIC_URL + `/img/sell-white-2.png`}
          alt=""
          className="mobile-intro-img-sell-2"
          style={{ "--opacity": opacity2 }}
        />
        <img
          src={process.env.PUBLIC_URL + `/img/mobile-intro-left-3.png`}
          alt=""
          className="mobile-intro-img-sell-3"
          style={{ "--opacity": opacityLeft }}
        />
        <div
          className="sell-text"
          style={{ "--opacity": opacity2, "--color": textColor1 }}
          onClick={() => {
            setOpacityLeft(1);
            setTextColor1("white");

            setTimeout(function () {
              navigate("/selling");
            }, 300);
          }}
        >
          {text?.sell}
        </div>
        <img
          src={process.env.PUBLIC_URL + `/img/mobile-intro-right-1.png`}
          alt=""
          className="mobile-intro-img-buy"
          style={{ "--right-x": rightX }}
        />
        <img
          src={process.env.PUBLIC_URL + `/img/buy-white-2.png`}
          alt=""
          className="mobile-intro-img-buy-2"
          style={{ "--opacity": opacity2 }}
        />
        <img
          src={process.env.PUBLIC_URL + `/img/mobile-intro-right-3.png`}
          alt=""
          className="mobile-intro-img-buy-3"
          style={{ "--opacity": opacityRight }}
        />
        <div
          className="buy-text"
          onClick={() => {
            setOpacityRight(1);
            setTextColor2("white");
            setTimeout(function () {
              navigate("/buying");
            }, 300);
          }}
          style={{ "--opacity": opacity2, "--color": textColor2 }}
        >
          {text?.buy}
        </div>
      </div>
      <div className="footer">
        <div className="phone-box d-nonex">
          <div className="phone-text w-100">
            <div>넘버로 고객센터(평일 09:00~18:00)</div>
            <div className="phone d-flex align-items-center justify-content-between">
              <a href={`tel:${servicePhoneNum}`}
                className="phone"
                style={{ textDecoration: 'none' }}
              >
                {servicePhoneNum}
              </a>
              <a href={`tel:${servicePhoneNum}`}>
                <img
                  src={"/img/icon_phone.svg"}
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
              </a>
            </div>
          </div>
          {/*<div*/}
          {/*  className="footer-phone-img"*/}
          {/*  onClick={() => {*/}
          {/*    goToPhonCallCommunicationToApp(servicePhoneNum);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <img src="/img/icon_phone.svg" alt="" />*/}
          {/*</div>*/}

          <a href={`tel:${servicePhoneNum}`}>
            <img src="/img/icon_phone.svg" alt="" />
          </a>
        </div>
        <div className="copy-right">
          Copyright ⓒ NUMBERRO Co., Ltd. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
