import { APIClient } from "./apiCore";
import * as url from "./urls";

// 팝니다 sell api
const api = new APIClient();

export const postNumbaInsSellIndi = (data) =>
  api.createWithFile(url.POST_NUMBA_INSSELLINDI, data);
export const postNumbaInsSellCorp = (data) =>
  api.createWithFile(url.POST_NUMBA_INSSELLCORP, data);
export const postNumbaInsSellLic = (data) =>
  api.createWithFile(url.POST_NUMBA_INSSELLLIC, data);
export const postNumbaSellList = (data) =>
  api.create(url.POST_NUMBA_SELLLIST, data);
export const postNumbaSellDetailList = (data) =>
  api.create(url.POST_NUMBA_SELLDETAILLIST, data);
export const postNumbaSellCodeDescription = (data) =>
  api.create(url.POST_NUMBA_SELLCODEDESCRIPTION, data);
