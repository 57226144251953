import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileFooter from "../../../componant/mobileFooter";
import moment from "moment";
import { postReportList } from "../../../api/sale";
import useInfiniteScroll from "../../../utils/hooks/infinite-scroll/useInfiniteScroll";

function ReportList() {
  const navigate = useNavigate();
  const [count, setCount] = useState(10);
  const [reportListData, setReportListData] = useState([]);
  const callReportList = async (cnt) => {
    const response = await postReportList({
      startIdx: 0,
      rowCnt: cnt,
      // dt_f:,
      // dt_l:,
      // selll_user_nm:,
      // buy_user_nm:,
    });
    setReportListData(response?.resultList);
    console.log(response?.resultList);
  };

  const { setBottom } = useInfiniteScroll(() => {
    setCount((prev) => {
      callReportList(prev + 10);
      return prev + 10;
    });
  });

  const reportView = (data) => {
    console.log(data);
    navigate("/report-view", {
      state: { data: {...data} },
    });
  };

  useEffect(() => {
    callReportList(count);
  }, []);

  const completeButton = (data) => {
    console.log(data);
    if(data?.auth_dt){
      return (
        <button 
          style={{ backgroundColor: "#ff824e" }} 
          onClick={() => {
            reportView({main_seq: data?.main_seq, deal_str: data?.deal_str});
          }}
        > 
          승인완료
        </button>);
    } else {
      return (
        <button style={{ backgroundColor: "#adadad" }}>승인대기</button>
      );
    }    
  };

  return (
    <>
      <div>
        <div className="content-container mypage_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-heading">
                  <h1>신고서 발급조회</h1>
                </div>                
              </div>
            </div>
          </div>
          <div
            className="container pb-md-0 pb-5 mb-md-0 mb-5 top-border"
            style={{ height: "650px", overflow: "scroll" }}
          >
            <div className="card_part">
              {reportListData.length > 0 ? (
                <>
                  {reportListData?.map((data) => {
                    return (
                      <div className="card_box intrestCard">
                        <div className="top_part">
                          <div className="breadcrumb d-flex justify-content-between">                            
                            <div> 승인일자 : {data?.auth_dt && (moment(data?.auth_dt).format("YYYY-MM-DD"))}
                              {/* {data?.auth_dt ? (
                                moment(data?.auth_dt).format("YYYY-MM-DD")
                              ) : (
                                <span style={{color: '#ff824e', fontSize: '13px', marginLeft: '8px'}}>
                                  양도·양수인 신고서 신청 완료된 후, 승인이 이루어지면 확정됩니다
                                </span>
                              )}  */}
                            </div>
                            <div>매물번호: {data?.target_seq}&nbsp;&nbsp;/&nbsp;&nbsp;매매번호 : {data?.main_seq}</div>                                                                     
                          </div>
                        </div>
                        <div className="content_part">
                          <div className="left_part">                            
                            <div className="inner_part">
                              <div className="part_top ">
                                  <div className="left_part_value">
                                    <div className="first_value">양도인</div>
                                    <div className="second_value2">
                                      {data?.sell_user_nm}
                                    </div>
                                  </div>
                                  <div className="right_part_value">
                                    <div className="first_value">양도인 신청일자</div>
                                    <div className="second_value2">
                                      {data?.sellsign_dt ? (
                                        moment(data?.sellsign_dt).format("YYYY.MM.DD")
                                      ):(
                                        <span style={{color: 'red'}}>( 신청전 )</span> 
                                      )}
                                    </div>
                                  </div>
                              </div>
                                <div className="part_bottom">
                                  <div className="left_part_value">
                                    <div className="first_value">양수인</div>
                                    <div className="second_value2">
                                    {data?.buy_user_nm}
                                    </div>
                                  </div>
                                  <div className="right_part_value">
                                    <div className="first_value">양수인 신청일자</div>
                                    <div className="second_value2">
                                     {data?.buysign_dt ? (
                                        moment(data?.buysign_dt).format("YYYY.MM.DD")
                                      ):(
                                        <span style={{color: 'red'}}>( 신청전 )</span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>                         
                          <div className="right_part">                            
                            <div className="inner_right_part">
                              {completeButton(data)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {reportListData.length > 0 && <div ref={setBottom} />}
                </>
              ) : (
                <div className="mypage_wrapper">
                  <div className="container">
                    <div className="card_part">
                      <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                        데이터가 없습니다.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default ReportList;
