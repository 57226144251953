import styled from "styled-components";

export const BtnWhite = styled.button`
width: ${props=>props.width?props.width:"40px"};
height:${props=>props.height?props.height:"51px"};
background-color: ${props=>props.bg?props.bg:"#000"};
border: none;
border-radius: 5px;
color: #656565;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: 1px solid #cdcdcd;
color: #000;
@media screen and (max-width:480px) {
    width:140px;
  }
  @media screen and (max-width:767px) {
font-size: 12px;
  }
`