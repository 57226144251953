import { postNumbaInsInterest } from "../../../api/mypage";
import { toast } from "wc-toast";

export default async function insInterest(data) {
  // data = {code, sell_seq, target_seq} or {code, buy_seq, target_seq}
  if (data.code === "sell") {
    try {
      const response = await postNumbaInsInterest({
        sell_seq: data.sell_seq,
        target_seq: data.target_seq,
      });
      if (response.code === 1000) {
        console.log("관심등록 등록성공");
      } else {
        console.log("관심등록 등록실패");
      }
    } catch (err) {
      toast.error("등록 서버 오류");
    }
  }
  if (data.code === "buy") {
    try {
      const response = await postNumbaInsInterest({
        buy_seq: data.buy_seq,
        target_seq: data.target_seq,
      });
      if (response.code === 1000) {
        console.log("관심등록 등록성공");
      } else {
        console.log("관심등록 등록실패");
      }
    } catch (err) {
      toast.error("등록 서버 오류");
    }
  }
}
