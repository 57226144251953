export function DUMMY_DATA(number = 10) {
  let array = [];

  for (let i = 0; i < number; i++) {
    const data = {
      reg_dt: "2024-04-17",
      contents: `더미 데이터 내용 ${i + 1}`,
      files: [],
      title: `더미 데이터 제목 ${i + 1}`,
      data_seq: i + 1, // 각 데이터마다 고유한 번호 부여
    };

    array.push(data);
  }

  return array;
}
