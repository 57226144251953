import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileFooter from "../../componant/mobileFooter";
import { headerText } from "../../App";
import { useAtom } from "jotai";

export default function Information() {
  const [activeTab, setActiveTab] = useState(1);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const tabShow = (id) => {
    setActiveTab(id);
  };
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setActiveTab(location?.state);
    } else {
      setActiveTab(1);
    }
    setHeaderTextAtom("이용안내");
  }, [location?.state]);
  return (
    <>
      <div className="customer_tab viewPrice-tab bg-white">
        <div className="container">
          <div className="main-heading d-md-block d-none">
            <h1 className="text-center">이용안내</h1>
          </div>
          <hr className="information-hr pc_view" />
          {/* 양도 */}
          <div>
            <div
              className="information-title-sell"
              style={{ "--information-type": "#4386D6" }}
            >
              양도할때
            </div>
            <div className="information-section">
              <div className="icons-part">
                <div className="number">01</div>

                <div className="icons">
                  <img className="w-100" src="/img/sell-1.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">'팝니다' 등록하기</div>
                <div className="icon-contents">
                  <div>로그인 후 ‘팝니다 등록하기’ 에서</div>
                  <div>양도 매물을 등록합니다.</div>
                </div>
              </div>
              <div className="icons-part">
                <div className="number">02</div>
                <div className="icons w-102px">
                  <img className="w-100" src="/img/sell-2.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">매물 매칭</div>
                <div className="icon-contents">
                  <div>등록한 매물을 양수자가</div>
                  <div>구매신청을 통해 양수신청을 합니다.</div>
                </div>
              </div>
              <div className="icons-part">
                <div className="number">03</div>
                <div className="icons w-102px">
                  <img className="w-100" src="/img/sell-3.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">양도양수 진행</div>
                <div className="icon-contents">
                  <div>양수자와 양도자의 서류를 받아서</div>
                  <div>체크후 이전등록을 진행합니다.</div>
                </div>
              </div>
              <div className="icons-part">
                <div className="number">04</div>

                <div className="icons ">
                  <img className="w-100" src="/img/sell-4.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">양도 완료</div>
                <div className="icon-contents">
                  <div>이전 등록이 완료되면</div>
                  <div>양도 대금이 입금됩니다.</div>
                </div>
              </div>
            </div>
          </div>
          {/* 양수 */}
          <div>
            <div
              className="information-title-sell"
              style={{ "--information-type": "#EC78A4" }}
            >
              양수할때
            </div>
            <div className="information-section">
              <div className="icons-part">
                <div className="number">01</div>
                <div className="icons ">
                  <img className="w-100" src="/img/buy-1.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">'삽니다' 등록하기</div>
                <div className="icon-contents">
                  <div>로그인 후 ‘삽니다 등록하기’ 에서</div>
                  <div>양수 매물을 등록합니다.</div>
                </div>
              </div>
              <div className="icons-part">
                <div className="number">02</div>
                <div className="icons w-102px">
                  <img className="w-100" src="/img/buy-2.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">매물 매칭</div>
                <div className="icon-contents">
                  <div>등록한 매물을 양도자가</div>
                  <div>판매신청을 통해 양도신청을 합니다.</div>
                </div>
              </div>
              <div className="icons-part">
                <div className="number">03</div>

                <div className="icons w-102px">
                  <img className="w-100" src="/img/buy-3.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">양도양수 진행</div>
                <div className="icon-contents">
                  <div>양수자와 양도자의 서류를 받아서</div>
                  <div>체크후 이전등록을 진행합니다.</div>
                </div>
              </div>
              <div className="icons-part">
                <div className="number">04</div>
                <div className="icons ">
                  <img className="w-100" src="/img/buy-4.png" alt="" />
                </div>
                {/* 3003 */}
                <div className="icon-title">양수 완료</div>
                <div className="icon-contents">
                  <div>이전 등록이 완료되면</div>
                  <div>이전 서류가 전달됩니다.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobileFooter main={true} />
    </>
  );
}
