import Accordion from 'react-bootstrap/Accordion';
import * as S from "../../../css/SignUp/RegularUser.style"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
function AccordianCorporationUser({
    regObj, 
    setRegObj, 
    setIsChecked, 
    isChecked1, 
    setIsChecked1, 
    isChecked2, 
    setIsChecked2,
    isChecked3, 
    setIsChecked3,
    isChecked4, 
    setIsChecked4
}) {
    const handleIconClick1 = () => {
        setIsChecked1(!isChecked1);
        setRegObj({...regObj, agree_yn:'Y'})
        if (isChecked1){
            setIsChecked(false)
            setRegObj({...regObj, agree_yn:'N'})
        }
    };

    const handleIconClick2 = () => {
        setIsChecked2(!isChecked2);
        setRegObj({...regObj, privacy_yn:'Y'})
        if (isChecked2){
            setIsChecked(false)
            setRegObj({...regObj, privacy_yn:'N'})
        }
    };

    const handleIconClick3 = () => {
        setIsChecked3(!isChecked3);
        setRegObj({...regObj, alarm_yn:'Y'})
        if (isChecked3){
            setIsChecked(false)
            setRegObj({...regObj, alarm_yn:'N'})
        }
    };

    const handleIconClick4 = () => {
        setIsChecked4(!isChecked4);
        setRegObj({...regObj, ads_yn:'Y'})
        if (isChecked4){
            setIsChecked(false)
            setRegObj({...regObj, ads_yn:'N'})
        }
    };

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <S.AccordionHeading padding={"0"}>
                        {isChecked1 ? (
                            <S.AccordionIcon onClick={handleIconClick1}>
                                <FontAwesomeIcon icon={faCheck}  />
                            </S.AccordionIcon>
                        ) : (
                            <S.AccordionIcon color="#DADADA" onClick={handleIconClick1}>
                                <FontAwesomeIcon icon={faCheck} />
                            </S.AccordionIcon>
                        )}
                        <S.AccordionValue color="#888888">서비스 이용 약관(필수)</S.AccordionValue>
                    </S.AccordionHeading>
                </Accordion.Header>
                <Accordion.Body>
                    <S.AccordionCollapse_Text>
                        <strong>제1조(목적)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        이 약관은 주식회사 체인버스가 운영하는 넘버로(이하 플랫폼이라 합니다)에서 제공하는 서비스(이하 서비스라 합니다)를 플랫폼과 이용자(이하 이용자라 합니다)의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제2조(정의)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 허가증이란 화물자동차운송사업허가증, 화물자동차운송주선사업허가증 등 각종 화물자동차 관련 허가된 증서를 말합니다.<br/>
                        ② 이용자란 플랫폼을 이용하는 회원, 비회원 또는 플랫폼을 이용한 양도인, 양수인을 말합니다.<br/>
                        ③ 회원이란 플랫폼에 회원가입을 한 자로서, 계속적으로 플랫폼이 제공하는 서비스를 이용할 수 있는 자를 말합니다.<br/>
                        ④ 비회원이란 회원가입 하지 않고 플랫폼이 제공하는 서비스를 이용하는 자를 말합니다<br/>
                        ⑤ 양도인이란 허가증을 양도하는 자를 말합니다.<br/>
                        ⑥ 양수인이란 허가증을 양수하는 자를 말합니다.<br/>
                        ⑦ 서비스란 플랫폼에 기반한 허가증의 시세확인과 양도양수 계약체결 및 행정업무대행을 플랫폼에 의뢰, 위탁 하는것을 말합니다.<br/>
                        ⑧ 플랫폼이란 이용자가 등록하는 또는 이용자를 대리하여 플랫폼이 등록하는 허가증을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 서비스할 수 있도록 설정한
                        가상의 영업장을 말합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제3조(약관 등의 명시와 설명 및 개정)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 전화번호, 팩스번호, 전자우편주소, 사업자등록번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수
                        있도록 플랫폼의 하단에 게시합니다.<br/>
                        ② 플랫폼은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망
                        이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
                        ③ 플랫폼이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 플랫폼의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만,
                        이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 10일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 플랫폼은 개정 전 내용과 개정 후 내용을 명확하게 비교하여
                        이용자가 알기 쉽도록 표시합니다.<br/>
                        ④ 플랫폼이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로
                        적용됩니다. 다만, 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 플랫폼에 송신하여 플랫폼의 동의를 받은
                        경우에는 개정약관 조항이 적용됩니다.<br/>
                        ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래
                        등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제4조(서비스의 제공 및 변경)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼은 다음과 같은 업무를 수행합니다.<br/>
                        1. 허가증의 시세정보 제공<br/>
                        2. 허가증의 양도양수 계약체결 및 양도양수 행정업무대행<br/>
                        3. 기타 플랫폼이 정하는 업무<br/>
                        ② 플랫폼은 허가증 또는 운영상, 행정적, 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 서비스의 내용을 변경할 수 있습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제5조(서비스의 중단)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        플랫폼은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제6조(회원가입)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 이용자는 플랫폼이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br/>
                        ② 플랫폼은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br/>
                        1. 가입신청자가 이전에 플랫폼의 회원으로써 회원자격을 상실한 적이 있는 경우 다만, 플랫폼의 재가입 승낙을 얻은 경우는 예외로 합니다.<br/>
                        2. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                        3. 기타 회원으로 등록하는 것이 플랫폼의 운영상에 지장이 있다고 판단되는 경우<br/>
                        ③ 회원가입의 성립시기는 플랫폼에 회원가입완료 버튼을 클릭한 시점으로 합니다.<br/>
                        ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 플랫폼에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제7조(회원 탈퇴 및 자격 상실 등)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 회원은 플랫폼에 언제든지 탈퇴를 요청할 수 있으며 플랫폼은 특별한 사정이 없는 한 즉시 회원탈퇴를 처리합니다.<br/>
                        ② 회원이 다음 각 호의 사유에 해당하는 경우, 플랫폼은 회원자격을 제한 및 정지시킬 수 있습니다.<br/>
                        1. 가입 신청 시에 허위 내용을 등록한 경우<br/>
                        2. 플랫폼의 서비스를 이용한 양수비용, 기타 플랫폼이 정한 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br/>
                        3. 다른 사람의 플랫폼 이용을 방해하거나 그 정보를 도용하는 등 상거래 질서를 위협하는 경우<br/>
                        4. 플랫폼을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
                        ③ 플랫폼이 회원자격을 제한, 정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 플랫폼은 회원자격을 상실시킬 수
                        있습니다.<br/>
                        ④ 플랫폼이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                        부여합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제8조(회원에 대한 통지)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼이 회원에 대한 통지를 하는 경우, 회원이 플랫폼과 미리 약정하여 지정한 전자우편 주소 또는 APP PUSH(앱 알림), 문자(SMS)로 할 수 있습니다.<br/>
                        ② 플랫폼은 불특정다수 회원에 대한 통지의 경우 1주일 이상 플랫폼 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을
                        미치는 사항에 대하여는 개별통지를 합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제9조(서비스 신청 및 개인정보 제공 동의 등)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 이용자는 다음 또는 이와 유사한 방법으로 서비스를 신청함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.<br/>
                        1. 이용자의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
                        2. 약관내용, 청약철회권이 제한되는 서비스의 비용부담과 관련한 내용에 대한 확인<br/>
                        3. 이 약관에 동의하고 위 2. 호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)<br/>
                        4. 허가증 등의 서비스 신청 및 이에 관한 확인 또는 플랫폼의 확인에 대한 동의<br/>
                        ② 플랫폼이 제3자에게 이용자의 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의
                        항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 이용자에게 알리고 동의를 받아야 합니다.<br/>
                        ③ 플랫폼이 제3자에게 이용자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을
                        이용자에게 알리고 동의를 받아야 합니다. 다만, 서비스 제공에 관한 계약이행을 위해 필요하고 이용자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한
                        법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제10조 (계약의 성립)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼은 제9조와 같은 서비스 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지
                        못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>
                        1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                        2. 기타 서비스 신청에 승낙하는 것이 플랫폼 운영상, 기술상 지장이 있다고 판단하는 경우<br/>
                        ② 플랫폼의 승낙이 이용자에게 유선상 고지 또는 이메일이 도달한 시점에 계약이 성립한 것으로 봅니다.<br/>
                        ③ 플랫폼의 승낙의 의사표시에는 이용자의 구매신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제11조 (지급방법)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        플랫폼에서 계약체결한 허가증의 양수비용은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다.<br/>
                        ① 양수인이 직접 양도인의 계좌에 입금<br/>
                        ② 기타 플랫폼이 지정한 지급방식
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제12조(환급)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        플랫폼은 이용자가 체결한 서비스가 행정상의 이유로 양수인에게 이전이 불가한때에는 지체없이 그 사유를 이용자에게 통지하고 양수금액은 당일, 중개 수수료, 행정업무대행 수수료는 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제13조(청약철회 및 계약무효 등)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 이용자는 계약금 입금전까지 언제든지 청약을 철회할 수 있습니다.<br/>
                        ② 이용자는 계약금이 입금된 경우에는 일방으로 청약을 철회할 수 없습니다. 다만, 이용자 상호간 협의가 이루어질 경우 계약무효가 가능합니다.<br/>
                        ③ 제2항에서 이용자가 일방으로 청약을 철회할 경우 아래에 해당하면 계약무효가 가능합니다.<br/>
                        1. 양수인이 양도인에게 지급한 계약금을 포기한다는 의사를 표시한 경우<br/>
                        2. 양도인이 양수인에게 지급받은 계약금의 2배에 해당하는 금액을 위약금으로 지불한다는 의사를 표시한 경우<br/>
                        ④ 제3항에서 계약무효가 이루어질 경우 양도인, 양수인은 플랫폼에게 중개수수료, 행정업무수수료를 지불해야 합니다.<br/>
                        ⑤ 양수인은 허가증을 양수 받은 뒤에는 청약을 철회할 수 없습니다. 다만, 양수인 관할 지자체에서 양수 거부된 상황에서는 양도인과 협의 하에 청약을 철회할 수
                        있습니다.<br/>
                        ⑥ 양도인은 허가증이 양수인이 양수 받은 뒤에는 청약을 철회할 수 없습니다. 다만, 양수인 관할 지자체에서 양수 거부된 상황에서는 양수인과 협의 하에 청약을 철회할 수
                        있습니다.<br/>
                        ⑦ 이용자는 제5항 및 제6항의 규정에 불구하고 허가증 등의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 청약을 철회할 수 있으며, 청약철회에 필요한
                        비용은 플랫폼이 부담합니다. 다만, 양수인 관할 지자체에서 양수가 허가된 상황에서는 청약을 철회할 수 없습니다.<br/>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제14조(개인정보보호)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼은 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.<br/>
                        ② 플랫폼은 회원가입 시 양도양수 계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 양도양수 계약 이전에 본인확인이 필요한 경우로서
                        최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.<br/>
                        ③ 플랫폼은 이용자의 개인정보를 수집, 이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.<br/>
                        ④ 플랫폼은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용, 제공단계에서 당해 이용자에게 그
                        목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.<br/>
                        ⑤ 플랫폼이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할
                        정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수
                        있습니다.<br/>
                        ⑥ 이용자는 언제든지 플랫폼이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 플랫폼은 이에 대해 지체 없이 필요한 조치를 취할 의무를
                        집니다.<br/>
                        ⑦ 플랫폼은 개인정보보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한합니다.<br/>
                        ⑧ 플랫폼 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.<br/>
                        ⑨ 플랫폼은 개인정보의 수집, 이용, 제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다.

                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제15조(플랫폼의 의무)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는데 최선을 다하여야
                        합니다.<br/>
                        ② 플랫폼은 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안 시스템을 갖추어야 합니다.<br/>
                        ③ 플랫폼이 상품이나 용역에 대하여 「표시, 광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시, 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을
                        집니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제16조(회원의 ID 및 비밀번호에 대한 의무)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 제14조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br/>
                        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.<br/>
                        ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 플랫폼에 통보하고 플랫폼의 안내가 있는 경우에는 그에 따라야 합니다.

                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제17조(이용자의 의무)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        이용자는 다음 행위를 하여서는 안 됩니다.<br/>
                        ① 신청 또는 변경 시 허위 내용의 등록<br/>
                        ② 타인의 정보 도용<br/>
                        ③ 플랫폼에 게시된 정보의 변경<br/>
                        ④ 플랫폼이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
                        ⑤ 플랫폼에 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
                        ⑥ 플랫폼에 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
                        ⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위

                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제18조(연결 플랫폼과 피연결 플랫폼 간의 관계)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 상위 플랫폼과 하위 플랫폼이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 플랫폼이라고 하고 후자를
                        피연결 플랫폼이라고 합니다.<br/>
                        ② 연결 플랫폼은 피연결 플랫폼이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제19조(저작권의 귀속 및 이용제한)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼이 작성한 저작물에 대한 저작권 기타 지적재산권은 플랫폼에 귀속합니다.<br/>
                        ② 이용자는 플랫폼을 이용함으로써 얻은 정보 중 플랫폼에게 지적재산권이 귀속된 정보를 플랫폼의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여
                        영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.<br/>
                        ③ 플랫폼은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제20조(분쟁해결)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 신속하게
                        통보합니다.<br/>
                        ② 플랫폼과 이용자 간에 발생한 상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시, 도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수
                        있습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>제21조(재판권 및 준거법)</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 플랫폼과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
                        다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.<br/>
                        ② 플랫폼과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
                    </S.AccordionCollapse_Text>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <S.AccordionHeading padding="0">
                        {isChecked2 ? (
                            <S.AccordionIcon onClick={handleIconClick2}>
                                <FontAwesomeIcon icon={faCheck}  />
                            </S.AccordionIcon>
                        ) : (
                            <S.AccordionIcon color="#DADADA" onClick={handleIconClick2}>
                                <FontAwesomeIcon icon={faCheck} />
                            </S.AccordionIcon>
                        )}
                        <S.AccordionValue color="#888888">개인정보 취급 방침(필수)</S.AccordionValue>
                    </S.AccordionHeading>
                </Accordion.Header>
                <Accordion.Body>
                    <S.AccordionCollapse_Text>
                        주식회사 체인버스(이하 넘버로라 함)가 제공하는 회사 서비스는 이용자의 개인정보를 중시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 개인정보와 관련된 법령상의
                        개인정보보호규정 및 방송통신위원회가 제정한 개인정보보호지침을 준수하고 있습니다. 넘버로는 개인정보보호정책을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로
                        이용되고 있으며, 개인정보보호를 위해 항상 노력하고 있음을 알려드립니다.
                        <br/><br/>
                        넘버로의 개인정보보호정책은 다음과 같은 내용으로 구성되어 있습니다.<br/>
                        1. 수집하는 개인정보의 항목 및 수집방법<br/>
                        2. 개인정보의 수집 및 이용목적<br/>
                        3. 개인정보의 공유 및 제공<br/>
                        4. 개인정보의 취급위탁<br/>
                        5. 개인정보의 보유 및 이용기간<br/>
                        6. 개인정보의 파기절차 및 방법<br/>
                        7. 이용자 및 법정대리인의 권리와 그 행사방법<br/>
                        8. 개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항<br/>
                        9. 개인정보의 기술적/관리적 보호 대책<br/>
                        10. 개인정보관리책임자 및 담당자<br/>
                        11. 적용범위<br/>
                        12. 고지의 의무
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>1. 수집하는 개인정보의 항목 및 수집방법</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        ① 넘버로는 각종 서비스 제공을 위해 아래와 같은 항목의 개인정보를 수집하고 있습니다.<br/>
                        - 성명, 휴대폰번호<br/>
                        ② 또한 서비스 이용과정이나 행정업무대행에서 다음과 같은 정보들이 생성되어 수집될 수 있습니다.<br/>
                        - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록<br/>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>2. 개인정보의 수집 및 이용목적</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 수집한 개인정보를 컨텐츠 제공, 특정 맞춤 서비스 제공 목적을 위해 활용합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>3. 개인정보의 공유 및 제공</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 이용자들의 개인정보를 2. 개인정보의 수집 및 이용목적에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
                        이용자의 개인정보를 외부에 공개하지 않습니다.<br/>
                        다만, 아래의 경우에는 예외로 합니다.<br/>
                        ① 이용자들이 사전에 공개에 동의한 경우<br/>
                        ② 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>4. 개인정보의 취급위탁</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 이용자의 동의 없이 이용자 정보를 외부 업체에 위탁하지 않습니다.<br/>
                        향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 이용자에게 통지하고 필요한 경우 사전 동의를 받을 것입니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>5. 개인정보의 보유 및 이용기간</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>6. 개인정보의 파기절차 및 방법</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.<br/>
                        파기절차 및 방법은 다음과 같습니다.<br/>
                        ① 파기절차<br/>
                        - 이용자가 상담 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
                        따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.<br/>
                        - 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/>
                        ② 파기방법<br/>
                        - 전자적 파일 형태로 저장된 개인정보는 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>7. 이용자 및 법정대리인의 권리와 그 행사방법</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정(질문과 답변)할 수 있으며 가입해지(게시물 삭제)를 요청할 수도 있습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>8. 개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 귀하의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등을 운용합니다.<br/>
                        쿠키(cookie)란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.<br/>
                        넘버로는 방문기록을 위해서만 쿠키를 사용하며, 그 외 다른 용도로 사용하지 않습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>9. 개인정보의 기술적/관리적 보호 대책</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        넘버로는 고객의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고
                        있습니다.<br/>
                        ① 비밀번호 암호화<br/>
                        넘버로 웹사이트에서 각종 게시판 등록 시 등록된 비밀번호는 암호화저장 및 관리되어 고객 본인만이 알고 있으며, 게시물의 열람 및 변경도 비밀번호를 알고 있는 고객 본인에
                        의해서만 가능합니다.<br/>
                        ② 해킹 등에 대비한 대책<br/>
                        - 넘버로는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.<br/>
                        - 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 고객의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신
                        등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br/>
                        - 그리고 침입차단시스템, 침입탐지(방지)시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를
                        갖추려 노력하고 있습니다.<br/>
                        ③ 취급직원의 최소화 및 교육<br/>
                        - 넘버로의 개인정보관련 취급직원을 최소한의 인원으로 한정시키고 있으며, 담당자에 대한 수시교육 및 외부 위탁교육을 통하여 넘버로의 개인정보보호정책 준수를 항상 강조하고
                        있습니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>10. 개인정보관리책임자 및 담당자</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>

                        넘버로는 이용자의 정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.<br/>
                        넘버로의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하시면 신속하게 답변을 드릴 것입니다.<br/>
                        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>
                        ① 개인분쟁조정위원회 (www.1336.or.kr, 1336)<br/>
                        ② 정보보호마크인증위원회 (www.eprivacy.or.kr, 02-580-0533~4)<br/>
                        ③ 대검찰청 인터넷범죄수사센터 (www.icic.sppo.go.kr, 02-3480-3600)<br/>
                        ④ 경찰청 사이버테러대응센터 (www.ctrc.go.kr, 02-392-0330)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>11. 적용범위의 제한</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        본 넘버로의 개인정보보호정책은 넘버로가 제공하는 넘버로 사이트에 적용되며, 넘버로에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 넘버로 개인정보보호정책이 적용되지 않음을 알려 드립니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        <strong>12. 고지의 의무</strong>
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        현 개인정보보호정책 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 공지사항을 통해 고지할 것입니다.
                    </S.AccordionCollapse_Text>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <S.AccordionHeading padding="0">
                            {isChecked3 ? (
                                <S.AccordionIcon onClick={handleIconClick3}>
                                    <FontAwesomeIcon icon={faCheck}  />
                                </S.AccordionIcon>
                            ) : (
                                <S.AccordionIcon color="#DADADA" onClick={handleIconClick3}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </S.AccordionIcon>
                            )}
                            <S.AccordionValue color="#888888">매물알람, 시세 수신동의(필수)</S.AccordionValue>
                        </S.AccordionHeading>
                    </Accordion.Header>
                    <Accordion.Body>
                        <S.AccordionCollapse_Text>
                        넘버로는 회원님이 수집 및 이용에 동의한 개인정보를 이용하여 
                        SMS(MMS), SNS 메시지, 이메일, APP PUSH 알림 등 
                        다양한 전자적 전송 매체를 통해 매물시세를 전송할 수 있습니다.
                        <br/><br/>
                        본 동의는 거부할 수 있으나, 거부 시 실시간으로 매물시세를 받아볼 수 없습니다.<br/><br/>
                        매물시세 수신의 변경은<br/><br/>
                        1. 넘버로 고객센터(02-2276-0601)로 요청하거나<br/>
                        2. 넘버로 홈페이지(또는 애플리케이션)에서 로그인 후, 메인페이지 상단 '{'≡'}' 클릭 후 <br/>
                        [ 마이넘버로 {'>'} 알림정보 {'>'} 알림설정 ]에서 언제든지 변경하실 수 있습니다.<br/>
                    </S.AccordionCollapse_Text>
                    </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <S.AccordionHeading padding="0">
                            {isChecked4 ? (
                                <S.AccordionIcon onClick={handleIconClick4}>
                                    <FontAwesomeIcon icon={faCheck}  />
                                </S.AccordionIcon>
                            ) : (
                                <S.AccordionIcon color="#DADADA" onClick={handleIconClick4}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </S.AccordionIcon>
                            )}
                            <S.AccordionValue color="#888888">광고성 정보 수신동의(필수)</S.AccordionValue>
                        </S.AccordionHeading>
                    </Accordion.Header>
                    <Accordion.Body>
                        <S.AccordionCollapse_Text>
                        넘버로는 회원님이 수집 및 이용에 동의한 개인정보를 이용하여 
                        SMS(MMS), SNS 메시지, 이메일, APP PUSH 알림 등 
                        다양한 전자적 전송 매체를 통해 개인 맞춤형 광고 및 정보를 전송할 수 있습니다.
                        <br/><br/>
                        본 동의는 거부할 수 있으나, 거부 시 이벤트 및 프로모션 안내, 유용한 광고를 받아볼 수 없습니다.<br/><br/>
                        광고성 정보 수신의 변경은<br/><br/>
                        1. 넘버로 고객센터(02-2276-0601)로 요청하거나<br/>
                        2. 넘버로 홈페이지(또는 애플리케이션)에서 로그인 후, 메인페이지 상단 '{'≡'}' 클릭 후 <br/>
                        [ 마이넘버로 {'>'} 알림정보 {'>'} 알림설정 ]에서 언제든지 변경하실 수 있습니다.<br/>
                        </S.AccordionCollapse_Text>
                    </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordianCorporationUser;