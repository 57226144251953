import { APIClient } from "./apiCore";
import * as url from "./urls";

// 삽니다 buy api
const api = new APIClient();

export const postNumbaInsBuyIndi = (data) => api.createWithFile(url.POST_NUMBA_INSBUYINDI, data);
export const postNumbaInsBuyCorp = (data) => api.createWithFile(url.POST_NUMBA_INSBUYCORP, data);
export const postNumbaInsBuyLic = (data) => api.createWithFile(url.POST_NUMBA_INSBUYLIC, data);
export const postNumbaBuyList = (data) => api.create(url.POST_NUMBA_BUYLIST, data);
export const postNumbaBuyDetailList = (data) => api.create(url.POST_NUMBA_BUYDETAILLIST, data);