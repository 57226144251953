import { useState, useEffect } from "react";

// 화면 크기를 감지하는 커스텀 훅
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  //   리사이즈 이벤트 핸들러
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    // 이벤트 추가
    window.addEventListener("resize", handleResize);

    // 클린업 함수
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
