import React from "react";
import * as B from "../../css/components/button/buttonWhite"

const WhiteButton= ({height, width, name, bg, onClick}) => {
    return (
        <>
            <B.BtnWhite onClick={onClick} height={height} width={width} bg={bg}>{name}</B.BtnWhite>
        </>
    );
};

export default WhiteButton;