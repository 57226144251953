import React, { useEffect } from "react";
import * as F from "../../css/footer/footer";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <>
      <F.FooterTop_Stripe className="d-none d-md-none d-lg-block">
        <Container>
          <Row>
            <Col md={4} className="m-auto">
              <div className="d-flex justify-content-between">
                <F.FooterTop_Link
                  onClick={() => {
                    navigate("/terms_of_use");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  이용약관
                </F.FooterTop_Link>
                <F.FooterTop_Link
                  onClick={() => {
                    navigate("/privacy_policy");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  개인정보처리방침
                </F.FooterTop_Link>
              </div>
            </Col>
          </Row>
        </Container>
      </F.FooterTop_Stripe>
      <div className="d-none d-md-none d-lg-block">
        <Container>
          <Row className="py-5">
            <Col md={4} className="align-self-center text-md-start text-center">
              <div>
                <img 
                 height= "30px"
                 src={process.env.PUBLIC_URL + "/img/footer_logo.svg"} 
                />
              </div>
            </Col>
            <Col md={8} className="text-md-start text-center mt-md-0 mt-4">
              <div>
                <F.FooterBottom_Span className="pe-3">
                  주식회사 체인버스
                </F.FooterBottom_Span>
                <F.FooterBottom_SpanBorder className="ps-3 pe-3">
                  대표자 : 최종욱
                </F.FooterBottom_SpanBorder>
                <F.FooterBottom_SpanBorder className="ps-3">
                  {/* 서울 강서구 마곡중앙로 165, 11층 7호(마곡동,프라이빗타워1차) */}
                  서울특별시 중구 퇴계로 286, 10층(쌍림동, 쌍림빌딩)
                </F.FooterBottom_SpanBorder>
              </div>
              <div>
                <F.FooterBottom_Span className="pe-3">
                  사업자등록번호 : 768-87-02406
                </F.FooterBottom_Span>
                <F.FooterBottom_SpanBorder className="ps-3 pe-3">
                  Tel : 02-2276-0601
                </F.FooterBottom_SpanBorder>
                <F.FooterBottom_SpanBorder className="ps-3">
                  Fax : 02-2262-5333
                </F.FooterBottom_SpanBorder>
              </div>
              <div>
                <F.FooterBottom_Span>
                  Copyright ⓒ NUMBER-RO Co., Ltd. All Rights Reserved.
                </F.FooterBottom_Span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
