import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { postNumbaMValueList } from "../../api/mypage";
import { toast } from "wc-toast";
import moment from "moment";
// import { setLabels } from 'react-chartjs-2/dist/utils';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const ChartOptions = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      // position: 'top'
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  elements: {
    arc: {
      borderwidth: 0,
    },
  },
};

const PersonalNumber = ({ state_number }) => {
  const curYear = moment(new Date()).format("YYYY");
  const refSelectYYYY = useRef();
  //
  const [selectYYYY, setSelectYYYY] = useState(curYear);
  //
  const [selectCategory, setSelectCategory] = useState("2304"); // 전기차
  //
  const [dataWeightCat1, setDataWeightCat1] = useState([]);
  const [dataWeightCat2, setDataWeightCat2] = useState([]);
  const [dataWeightCat3, setDataWeightCat3] = useState([]);
  //
  const [lineChartData, setLineChartData] = useState(null);

  const options = Array(parseInt(curYear) - 2020 + 1)
    .fill(2020)
    .map((n, idx) => {
      return { key: n + idx, value: n + idx };
    })
    .reverse();

  const generateLabels = (year) => {
    let labels = [];
    for (let i = 1; i <= 12; i++) {
      labels.push(year + i.toString().padStart(2, "0"));
    }
    return labels;
  };

  const callPostMValueList = (deal_category_cd, yyyy) => {
    var labels = generateLabels(yyyy);
    postNumbaMValueList({
      deal_category_cd: deal_category_cd,
      yyyy: yyyy,
    })
      .then((res) => {
        var avg_price1 = [];
        var avg_price2 = [];
        var avg_price3 = [];

        if (res.code === 1000) {
          if (state_number === "2101") {  // 전기차 때문에 수정 deal_category_cd === "2101"
            //
            if (typeof res[2301] !== "undefined") {
              // setDataWeightCat1(res[2301]);
              // avg_price1 = res[2301].map((item) => item.avg_price);
              avg_price1 = labels.map(label => {
                const foundItem = res[2301].find(item => item.yyyymm === label);
                return foundItem ? foundItem.avg_price : null;
              }) 
              setDataWeightCat1(avg_price1);
            } else {
              setDataWeightCat1([]);
            }
            if (typeof res[2302] !== "undefined") {
              // setDataWeightCat2(res[2302]);
              // avg_price2 = res[2302].map((item) => item.avg_price);
              avg_price2 = labels.map(label => {
                const foundItem = res[2302].find(item => item.yyyymm === label);
                return foundItem ? foundItem.avg_price : null;
              }) 
              setDataWeightCat2(avg_price2);
            } else {
              setDataWeightCat2([]);
            }
            if (typeof res[2303] !== "undefined") {
              // setDataWeightCat3(res[2303]);
              // avg_price3 = res[2303].map((item) => item.avg_price);
              avg_price3 = labels.map(label => {
                const foundItem = res[2303].find(item => item.yyyymm === label);
                return foundItem ? foundItem.avg_price : null;
              }) 
              setDataWeightCat3(avg_price3);
            } else {
              setDataWeightCat3([]);
            }
            setLineChartData({
              labels,
              datasets: [
                {
                  label: "소형매물",
                  data: avg_price1,
                  borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                  borderWidth: 1,
                },
                {
                  label: "중형매물",
                  data: avg_price2,
                  borderColor: "rgb(53, 162, 235)",
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                  borderWidth: 1,
                },
                {
                  label: "대형매물",
                  data: avg_price3,
                  borderColor: "#c36ceb",
                  backgroundColor: "#c36ceb",
                  borderWidth: 1,
                },
              ],
            });
          } else if (state_number === "2104") {  // deal_category_cd === "2104"
            const result = res?.resultList;
            // setDataWeightCat1(result);
            // setDataWeightCat2([]);
            // setDataWeightCat3([]);
            // avg_price1 = result.map((item) => item.avg_price);
            avg_price1 = labels.map(label => {
              const foundItem = result.find(item => item.yyyymm === label);
              return foundItem ? foundItem.avg_price : null;
            }) 
            setDataWeightCat1(avg_price1);
            setDataWeightCat2([]);
            setDataWeightCat3([]);

            setLineChartData({
              labels,
              datasets: [
                {
                  label: "주선면허",
                  data: avg_price1,
                  borderColor: "#fe7e00",
                  backgroundColor: "#fe7e00",
                  borderWidth: 1,
                },
                {
                  label: "Dataset 2",
                  data: [],
                  borderColor: "rgb(53, 162, 235)",
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                  borderWidth: 1,
                },
                {
                  label: "Dataset 3",
                  data: [],
                  borderColor: "#c36ceb",
                  backgroundColor: "#c36ceb",
                  borderWidth: 1,
                },
              ],
            });
          } else if (state_number === "2304") {
            if (typeof res[2304] !== "undefined") {
              // setDataWeightCat1(res[2304]);
              // setDataWeightCat2([]);
              // setDataWeightCat3([]);
              // avg_price1 = res[2304].map((item) => item.avg_price);
              avg_price1 = labels.map(label => {
                const foundItem = res[2304].find(item => item.yyyymm === label);
                return foundItem ? foundItem.avg_price : null;
              })             
              setDataWeightCat1(avg_price1);
              setDataWeightCat2([]);
              setDataWeightCat3([]);
            } else {
              setDataWeightCat1([]);
              setDataWeightCat2([]);
              setDataWeightCat3([]);
            }

            setLineChartData({
              labels,
              datasets: [
                {
                  label: "전기차 소형",
                  data: avg_price1,
                  borderColor: "#59b00b",
                  backgroundColor: "#59b00b",
                  borderWidth: 1,
                },
                {
                  label: "Dataset 2",
                  data: [],
                  borderColor: "rgb(53, 162, 235)",
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                  borderWidth: 1,
                },
                {
                  label: "Dataset 3",
                  data: [],
                  borderColor: "#c36ceb",
                  backgroundColor: "#c36ceb",
                  borderWidth: 1,
                },
              ],
            });
          }
        } else {
          console.log("정보 조회에 실패했습니다.");
        }
      })
      .catch((err) => console.log("정보 조회에 실패했습니다."));
  };

  useEffect(() => {
    if (state_number !== "2304") {
      callPostMValueList(state_number, selectYYYY);
    } else {
      callPostMValueList("2101", selectYYYY); // 전기차가 카테고리가 아니라서
    }
  }, [selectYYYY, state_number]);

  if (lineChartData !== null) {
    lineChartData.labels = lineChartData.labels.map((label) => {
      // let temp = parseInt(label.substring(4)).toString();
      let temp = label.substring(4);
      // return temp.length < 2 ? "0" + temp : temp;

      return temp[0] === "0" ? temp.slice(1) : temp;
    });
  }

  return (
    <>
      <div className="virePrice-personalNumberDiv">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="perNumbContentDIv">
                <p className="mb-0 text-end">단위 : 만원</p>
                <div className="pc_view">
                  <div className="tableDiv">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th className="mainTh" scope="col">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={selectYYYY}
                              onChange={(e) => setSelectYYYY(e.target.value)}
                              ref={refSelectYYYY}
                            >
                              {options?.map((option) => {
                                return (
                                  <option value={option?.key}>
                                    {option?.value}
                                  </option>
                                );
                              })}
                            </select>
                          </th>
                          <th scope="col">1월</th>
                          <th scope="col">2월</th>
                          <th scope="col">3월</th>
                          <th scope="col">4월</th>
                          <th scope="col">5월</th>
                          <th scope="col">6월</th>
                          <th scope="col">7월</th>
                          <th scope="col">8월</th>
                          <th scope="col">9월</th>
                          <th scope="col">10월</th>
                          <th scope="col">11월</th>
                          <th scope="col">12월</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataWeightCat1.length > 0 && (
                          <tr key={0} className={
                            (state_number === "2101" && "redTr") ||
                            (state_number === "2104" && "orangeTr redTr") ||
                            (state_number === "2304" && "greenTr redTr")}
                          >
                            <th className="mainTh" scope="row">
                              {/* {state_number === "2101"
                                ? "소형매물"
                                : "주선면허"} */}
                              {
                                (state_number === "2101" && "소형매물") ||
                                (state_number === "2104" && "주선면허") ||
                                (state_number === "2304" && "전기차 소형")
                              }
                            </th>
                            {dataWeightCat1.map((item, index) => (
                              <td key={"0" + index}>
                                {item === null ? '' : Number(item).toLocaleString("en-US")}
                              </td>
                            ))}
                          </tr>
                        )}
                        {dataWeightCat2.length > 0 && (
                          <tr key={1} className="blueTr redTr">
                            <th className="mainTh" scope="row">
                              중형매물
                            </th>
                            {dataWeightCat2.map((item, index) => (
                              <td key={"1" + index}>
                                {item === null ? '' : Number(item).toLocaleString("en-US")}
                              </td>
                            ))}
                          </tr>
                        )}
                        {dataWeightCat3.length > 0 && (
                          <tr key={2} className="purpleTr redTr">
                            <th className="mainTh" scope="row">
                              대형매물
                            </th>
                            {dataWeightCat3.map((item, index) => (
                              <td key={"2" + index}>
                                {item === null ? '' : Number(item).toLocaleString("en-US")}
                              </td>
                            ))}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mobile_view">
                  <div className="tableDivMobile">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th className="mainTh" style={{ width: "31%" }}>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={selectYYYY}
                              onChange={(e) => setSelectYYYY(e.target.value)}
                              ref={refSelectYYYY}
                            >
                              {options?.map((option) => {
                                return (
                                  <option value={option?.key}>
                                    {option?.value}
                                  </option>
                                );
                              })}
                            </select>
                          </th>
                          {dataWeightCat1.length > 0 && (
                            <th
                              style={{
                                width: state_number === "2101" ? "23%" : "69%",
                              }}
                            >
                              {
                                (state_number === "2101" && "소형매물") ||
                                (state_number === "2104" && "주선면허") ||
                                (state_number === "2304" && "전기차 소형")
                              }
                            </th>
                          )}
                          {dataWeightCat2.length > 0 && (
                            <th style={{ width: "23%" }}>중형매물</th>
                          )}
                          {dataWeightCat3.length > 0 && <th>대형매물</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          "1월",
                          "2월",
                          "3월",
                          "4월",
                          "5월",
                          "6월",
                          "7월",
                          "8월",
                          "9월",
                          "10월",
                          "11월",
                          "12월",
                        ].map((item, index) => (
                          <tr>
                            <th>{item}</th>
                            {dataWeightCat1.length > 0 && (
                              <td className={
                                (state_number === "2101" && "red") ||
                                (state_number === "2104" && "orange") ||
                                (state_number === "2304" && "green")}
                              >
                                {dataWeightCat1[index] &&
                                  Number(
                                    dataWeightCat1[index],
                                  ).toLocaleString("en-US")}
                              </td>
                            )}
                            {dataWeightCat2.length > 0 && (
                              <td className="blue">
                                {dataWeightCat2[index] &&
                                  Number(
                                    dataWeightCat2[index],
                                  ).toLocaleString("en-US")}
                              </td>
                            )}
                            {dataWeightCat3.length > 0 && (
                              <td className="purple">
                                {dataWeightCat3[index] &&
                                  Number(
                                    dataWeightCat3[index],
                                  ).toLocaleString("en-US")}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="graphDiv">
                  {lineChartData != null && (
                    <Line
                      responsive={true}
                      maintainAspectRatio={false}
                      options={ChartOptions}
                      data={lineChartData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalNumber;
