import React, { useEffect, useState } from "react";
import { postNumbaUserCompletedList } from "../../../api/mypage";
import moment from "moment";

const DeskTopHistory = ({ data }) => {
  return (
    <div className="d-none d-md-block">
      <table className="table ">
        <tbody>
          <tr>
            <td className="grey">매물번호</td>
            <td width="27%">{data?.target_seq}</td>
            <td className="grey border-left-color">매매번호</td>
            <td width="27%">{data?.main_seq}</td>    
          </tr>
          <tr>
            <td width="20%" className="grey">
              신청/등록일
            </td>
            <td width="30%">
              {data?.reg_dt
                ? moment(data?.reg_dt).format("YYYY.MM.DD")
                : data?.reg_dt}
            </td>
            <td width="20%" className="grey border-left-color">
              매매완료일
            </td>
            <td width="30%">
              {data?.end_dt
                ? moment(data?.end_dt).format("YYYY.MM.DD")
                : data?.end_dt}
            </td>
          </tr>
          <tr>
            <td className="grey">넘버종류</td>
            <td width="27%">{data?.deal_category_nm}</td>
            <td className="grey  border-left-color">톤수</td>
            <td width="27%">
              {data?.car_weight
                ? data?.car_weight + " 톤"
                : data?.car_weight_nm}
            </td>    
          </tr>
          <tr>
            <td className="grey">판매자 지역</td>
            <td width="27%">{data?.region_nm || "-"}</td>
            <td className="grey  border-left-color">차량연식</td>
            <td width="27%">
              {data?.car_yyyymm
                ? moment(data?.car_yyyymm).format("YYYY.MM")
                :  "-"}
            </td>
          </tr>
          <tr>
            <td className="grey border-left-color">매도/매수</td>
              {data?.process_str === "거래완료" ? (
                (data?.deal_str === "sell" || data?.deal_str === "csell") ? (
                  <td style={{ color: "rgba(27, 111, 207, 0.9)" }}>{data?.deal_str === "csell" ? "(판매신청) 매도" : "매도"}완료</td>
                ) : (
                  <td style={{ color: "#f66176" }}>{data?.deal_str === "cbuy" ? "(구매신청) 매수" : "매수"}완료</td>
                )
              ) : (
                <td>거래취소</td>
              )}
            <td className="grey">매매금액</td>
            <td>
              {data?.deal_str === "buy" || data?.deal_str === "csell" ?  (
                <>
                  <span className="me-1" style={{ color: "#f66176" }}>
                    {data?.price?.toLocaleString()}
                  </span>
                  만원
                </>
              ) : (
                <>
                  <span className="me-1">{data?.price?.toLocaleString()}</span>
                  만원
                </>
              )}{" "}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const MobileHistory = ({ data }) => {
  return (
    <div className="d-block d-md-none">
      <table className="table ">
        <tbody>
          <tr>
            <td width="27%" className="grey">매물번호</td>
            <td width="25%">{data?.target_seq}</td>
            <td width="23%" className="grey">매매번호</td>
            <td>{data?.main_seq}</td>    
          </tr>
          <tr>
            <td width="27%" className="grey">
              신청/등록일
            </td>
            <td colSpan={3}>
              {data?.reg_dt
                ? moment(data?.reg_dt).format("YYYY.MM.DD")
                : data?.reg_dt}
            </td>
          </tr>
          <tr>
            <td width="27%" className="grey">
              매매완료일
            </td>
            <td colSpan={3}>
              {data?.end_dt
                ? moment(data?.end_dt).format("YYYY.MM.DD")
                : data?.end_dt}
            </td>
          </tr>
          <tr>
            <td className="grey">넘버종류</td>
            <td colSpan={3}>{data?.deal_category_nm}</td>
          </tr>
          <tr>
            <td className="grey">톤수</td>
            <td colSpan={3}>
              {data?.car_weight
                ? data?.car_weight + " 톤"
                : data?.car_weight_nm}
            </td>
          </tr>
          <tr>
            <td className="grey">판매자 지역</td>
            <td colSpan={3}>{data?.region_nm || "-"}</td>
          </tr>
          <tr>
            <td className="grey">차량 연식</td>
            <td colSpan={3}>
              {data?.car_yyyymm
                ? moment(data?.car_yyyymm).format("YYYY.MM")
                : "-"}
            </td>
          </tr>
          <tr>
            <td className="grey">매도/매수</td>
            {/* {data?.deal_str === "buy" ? (
              <>
                <td style={{ color: "#f66176" }}>
                  {data?.process_str.substring(0, data.process_str.length - 2)}
                </td>
              </>
            ) : (
              <>
                <td style={{ color: "rgba(27, 111, 207, 0.9)" }}>
                  {data?.process_str.substring(0, data.process_str.length - 2)}
                </td>
              </>
            )} */}
            {data?.process_str === "거래완료" ? (
              (data?.deal_str === "sell" || data?.deal_str === "csell") ? (
                <td colSpan={3} style={{ color: "rgba(27, 111, 207, 0.9)" }}>{data?.deal_str === "csell" ? "(판매신청) 매도" : "매도"}완료</td>
              ) : (
                <td colSpan={3} style={{ color: "#f66176" }}>{data?.deal_str === "cbuy" ? "(구매신청) 매수" : "매수"}완료</td>
              )
            ) : (
              <td colSpan={3}>거래취소</td>
            )}
          </tr>
          <tr>
            <td className="grey">매매금액</td>
            <td colSpan={3}>
              {data?.deal_str === "buy" || data?.deal_str === "csell" ?  (
                <>
                  <span className="me-1" style={{ color: "#f66176" }}>
                    {data?.price?.toLocaleString()}
                  </span>
                  만원
                </>
              ) : (
                <>
                  <span className="me-1">{data?.price?.toLocaleString()}</span>
                  만원
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default function TransactionHistory() {
  const [historyList, setHistoryList] = useState();

  const callTransactionHistory = () => {
    postNumbaUserCompletedList({
      startIdx: 0,
      rowCnt: 10,
    })
      .then((res) => {
        if (res?.code === 1000) {
          setHistoryList(res?.resultList);
          console.log("T_List", res?.resultList);          
        } else {
          console.log("거래 내역 조회에 실패했습니다.");
        }
      })
      .catch((err) => {
        console.log("거래 내역 조회에 실패했습니다.");
      });
  };

  useEffect(() => {
    callTransactionHistory();
  }, []);

  return (
    <div className="request-transiction my-listing">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">
              <h1>나의 거래 내역</h1>
            </div>
          </div>
        </div>
        {historyList?.length > 0 ? (
          historyList?.map((data) => {
            return (
              <div className="request-information request-information-border-top-none">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-part">
                      <div className="breadcrumb">
                        <ul>
                          {data?.deal_str === "buy" || data?.deal_str === "csell" ? (
                            <li style={{ "--color": "#f66176" }}>삽니다</li>
                          ) : (
                            <li
                              style={{ "--color": "rgba(27, 111, 207, 0.9)" }}
                            >
                              팝니다
                            </li>
                          )}
                          {/* <li>{data?.deal_category_nm}</li> */}
                          <li>{data?.deal_category_nm} 매물</li>
                        </ul>
                      </div>
                      <div className="my_page_transaction_header">매물정보</div>
                      <DeskTopHistory data={data} />
                      <MobileHistory data={data} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mypage_wrapper top-border">
            <div className="container">
              <div className="card_part">
                <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                  조회 결과가 없습니다.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
