import React from "react";
import * as B from "../../css/components/button/cardButton"
import { ClipLoader } from "react-spinners"; // BeatLoader

const CardButton = ({ name, bg, height, width, onClick, disabled, maxWidth, minWidth, className, loading }) => {
    return (
      <>
        <B.CardButton
          className={className}
          onClick={onClick}
          width={width}
          height={height}
          disabled={disabled}
          bg={bg}
          maxWidth={typeof maxWidth != "undefined" ? maxWidth : width}
          minWidth={typeof minWidth != "undefined" ? minWidth : width}
        >
          {loading ? (
            <ClipLoader loading={true} color="#fff" size={23} />
          ) : (
            name
          )}
        </B.CardButton>
      </>
    );
};

export default CardButton;