import React, { useEffect, useState } from "react";
import { faqList } from "../../api/customerCenter";
import { toast } from "wc-toast";

function FAQ() {
  const [faq, setFaq] = useState([]);

  const callFaq = async () => {
    const response = await faqList({});
    try {
      if (response?.code === 1000) {
        setFaq(response?.resultList);
      } else {
        console.log("FAQ 리스트를 불러오는데 실패했습니다.");
      }
    } catch (err) {
      console.log("FAQ 리스트를 불러오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    callFaq();
  }, []);

  return (
    <>
      <div className="customer-service">
        <div className="accordion" id="accordionExample">
          {faq.length > 0 ? (
            faq.map((data, idx) => {
              return (
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button collapsed`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`${"#collapse" + idx}`}
                      aria-expanded="true"
                      aria-controls={`${"collapse" + idx}`}
                    >
                      <div className="d-flex flex-column">
                        <div className="btn-text">{data?.title}</div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`${"collapse" + idx}`}
                    className={`accordion-collapse collapse`}
                    data-bs-parent="#accordionExample"
                  >
                    {/* <div className="under-accordian">{data?.contents}</div> */}
                    <div className="under-accordian">
                      {data?.contents?.split("\n").map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mypage_wrapper ">
              <div className="container">
                <div className="card_part">
                  <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                    FAQ가 없습니다.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <div className="d-flex flex-column">
                  <div className="btn-text">
                    개인정보 도용 피해를 예방하기 위해 비밀번호를 정기적으로
                    변경해 주세요{" "}
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="under-accordian">
                <p>안녕하세요 넘버로입니다.</p>
                <p>
                  개인화물넘버와 주선면허 카테고리에 월별 거래가 및 최근 거래가
                  를 표시 하였습니다.
                </p>
                <p>
                  PC 홈페이지에서는 개인화물넘버 카테고리를 클릭 하시면 우측에
                  표시가 되고 있으며..모바일 홈페이지에서는 개인화물넘버
                  카테고리 클릭 후 "월별 거래가" 탭 메뉴를 클릭 하시면 됩니다.
                </p>
                <p>
                  기나긴 장마가 끝나고 폭염이 시작되었네요. 모쪼록 건강에
                  유의하시길 바랍니다.
                </p>
                <p>
                  더욱 전진하는 넘버로이 되도록 끊임없이 노력하겠습니다.
                  감사합니다.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <div className="d-flex flex-column">
                  <div className="btn-text">
                    개인정보 도용 피해를 예방하기 위해 비밀번호를 정기적으로
                    변경해 주세요{" "}
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="under-accordian">
                <p>안녕하세요 넘버로입니다.</p>
                <p>
                  개인화물넘버와 주선면허 카테고리에 월별 거래가 및 최근 거래가
                  를 표시 하였습니다.
                </p>
                <p>
                  PC 홈페이지에서는 개인화물넘버 카테고리를 클릭 하시면 우측에
                  표시가 되고 있으며..모바일 홈페이지에서는 개인화물넘버
                  카테고리 클릭 후 "월별 거래가" 탭 메뉴를 클릭 하시면 됩니다.
                </p>
                <p>
                  기나긴 장마가 끝나고 폭염이 시작되었네요. 모쪼록 건강에
                  유의하시길 바랍니다.
                </p>
                <p>
                  {" "}
                  더욱 전진하는 넘버로이 되도록 끊임없이 노력하겠습니다.
                  감사합니다.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <div className="d-flex flex-column">
                  <div className="btn-text">
                    개인정보 도용 피해를 예방하기 위해 비밀번호를 정기적으로
                    변경해 주세요{" "}
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="under-accordian">
                <p>안녕하세요 넘버로입니다.</p>
                <p>
                  개인화물넘버와 주선면허 카테고리에 월별 거래가 및 최근 거래가
                  를 표시 하였습니다.
                </p>
                <p>
                  PC 홈페이지에서는 개인화물넘버 카테고리를 클릭 하시면 우측에
                  표시가 되고 있으며..모바일 홈페이지에서는 개인화물넘버
                  카테고리 클릭 후 "월별 거래가" 탭 메뉴를 클릭 하시면 됩니다.
                </p>
                <p>
                  기나긴 장마가 끝나고 폭염이 시작되었네요. 모쪼록 건강에
                  유의하시길 바랍니다.
                </p>
                <p>
                  {" "}
                  더욱 전진하는 넘버로이 되도록 끊임없이 노력하겠습니다.
                  감사합니다.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default FAQ;
