import { APIClient } from "./apiCore";
import * as url from "./urls";
import {
  POST_NUMBA_CHKUSRFORW,
  POST_NUMBA_INSREF,
  POST_NUMBA_WITHDRAWUSER,
} from "./urls";
const api = new APIClient();

export const postNumbaLogin = (data) => api.create(url.POST_NUMBA_LOGIN, data);
export const postNumbaLogout = (data) =>
  api.create(url.POST_NUMBA_LOGOUT, data);
export const postNumbaSignUp = (data) =>
  api.create(url.POST_NUMBA_SIGNUP, data);
export const postNumbaCheckId = (data) =>
  api.create(url.POST_NUMBA_CHECKID, data);
export const postNumbaSendSms = (data) =>
  api.create(url.POST_NUMBA_SENDSMS, data);
export const postNumbaSelCheckno = (data) =>
  api.create(url.POST_NUMBA_SELCHECKNO, data);
export const postNumbaCodeList = (data) =>
  api.create(url.POST_NUMBA_CODELIST, data);
export const postNumbaSelUserInfo = (data) =>
  api.create(url.POST_NUMBA_SELUSERINFO, data);
export const postNumbaRefresh = (data) =>
  api.create(url.POST_NUMBA_REFRESH, data);
export const postNumbaRefreshAfterTimeOut = (data) =>
  api.create(url.POST_NUMBA_REFRESHAFTERTIMEOUT, data);
export const postNumbaChgPasswd = (data) =>
  api.create(url.POST_NUMBA_CHGPASSWD, data);
export const postNumbaFindId = (data) =>
  api.create(url.POST_NUMBA_FINDID, data);
export const postNumbaFindPassword = (data) =>
  api.create(url.POST_NUMBA_FINDPASSWORD, data);
export const postNumbaChkUsrForW = (data) =>
  api.create(url.POST_NUMBA_CHKUSRFORW, data);
export const postNumbaWithdrawUser = (data) =>
  api.create(url.POST_NUMBA_WITHDRAWUSER, data);
export const postNumbaKaKaoSignUp = (data) =>
  api.create(url.POST_NUMBA_KAKAO_SIGNUP, data);
export const postNumbaKakaoLogin = (data) =>
  api.create(url.POST_NUMBA_KAKAO_LOGIN, data);
export const postNumbaInsRef = (data) =>
  api.create(url.POST_NUMBA_INSREF, data);
