import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import CardButton from "../../../componant/buttons/cardButton";
import { postNumbaCodeList } from "../../../api/auth";
import { toast } from "wc-toast";
import { DaumPostcode } from "../../../utils/DaumPostCode";
import { useLocation, useNavigate } from "react-router-dom";
import { postNumbaUpdSellBuy } from "../../../api/mypage";
import axios from "axios";
import config from "../../../config";

const SellingEditForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [regionCdList, setRegionCdList] = useState([]);
  const [corpCarCdList, setCorpCarCdList] = useState([]);
  const [editData, setEditData] = useState();
  const [isPartner, setIsPartner] = useState(null);
  const [isInCarYn, setIsInCarYn] = useState(null);
  const [priceData, setPriceData] = useState([]);
  const [newPrice, setNewPrice] = useState(0);

  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  const now = new Date();
  let years = [];
  for (let y = now.getFullYear(); y >= 1995; y -= 1) {
    years.push(y);
  }
  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push("0" + m.toString());
    } else {
      month.push(m.toString());
    }
  }

  const callGetPrice = async (dealCd, carCd) => {
    await axios({
      url: config.API_URL + "/comm/selMvalue",
      method: "post",
      data: {},
      headers: {
        ...axios.defaults.headers,
        Authorization: "",
      },
    }).then((res) => {
      setPriceData(res?.data?.resultList);
      if (parseInt(dealCd) === 2104) {
        const foundData = res.data.resultList.find(data => data.deal_category_cd === 2104);
        if (foundData) {
          setNewPrice(foundData.avg_price);
          console.log('---', foundData.avg_price);
        }
      }else if(parseInt(dealCd) === 2101){
        const foundData = res.data.resultList.find(data => 
          data.deal_category_cd === 2101 && data.car_weight_cd === parseInt(carCd)
        );
        if (foundData) {
          setNewPrice(foundData.avg_price);
          console.log('---', foundData.avg_price);
        }
      }
    }).catch((error)=>{
      console.log('시세가격정보 가져오기 실패');
      return 0;    
    });   
  }

  const getRegionCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1000 });
      if (response.code !== 1000) {
        console.log("지역코드 가져오기 실패");
        return;
      }
      setRegionCdList(response.resultList[0]);
    } catch (error) {
      console.log("지역코드 가져오기 실패");
    }
  };

  const getCorpCarCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 2400 });
      if (response.code !== 1000) {
        console.log("법인 톤수 가져오기 실패");
        return;
      }
      setCorpCarCdList(response.resultList[0]);
    } catch (error) {
      console.log("법인 톤수 가져오기 실패");
    }
  };

  useEffect(() => {
    getRegionCd();
    getCorpCarCd();
    if (location?.state?.cp_cnt > 0) {
      setIsPartner(true);
    }
    if (location?.state?.in_car_yn === "Y") {
      setIsInCarYn(true);
    }
    setEditData(location.state);    
    callGetPrice(location.state.deal_category_cd, location.state.car_weight_cd);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "car_weight_cd"){      
      const foundData = priceData.find(data => 
        data.deal_category_cd === 2101 && data.car_weight_cd === parseInt(value)
      );
      if (foundData) {
        setNewPrice(foundData.avg_price);
        console.log('---', foundData.avg_price);
      }
    }
    const yyyymm =
      document.getElementById("yyyy")?.value +
      document.getElementById("mm")?.value;

    if (name === "car_weight") {
      setEditData((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9.]/g, ""),
        car_yyyymm: yyyymm,
      }));
    } else if (name === "car_weight_cd") {
      if(parseInt(value) === 2302 || parseInt(value) === 2303){
        setEditData((prevProps) => ({
          ...prevProps,
          [name]: value.replace(/[^0-9.]/g, ""),
          car_yyyymm: yyyymm || "",
          ecar_yn: "N",
        }));
      }else {
        setEditData((prevProps) => ({
          ...prevProps,
          [name]: value.replace(/[^0-9.]/g, ""),
          car_yyyymm: yyyymm || "",
        }));
      }
    } else if (name === "price") {
      setEditData((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: yyyymm,
      }));
    } else {
      setEditData((prevProps) => ({
        ...prevProps,
        [name]: value,
        car_yyyymm: yyyymm,
      }));
    }
  };

  const handleFblFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setEditData((prevState) => ({
        ...prevState,
        fbl_file: file,
      }));
    }   
  };
  const renderFblFileInfo = () => {
    if (!editData.fbl_file) {
      return (
        // <R.Btn_Value className="d-none d-lg-inline-block">
        <R.Btn_Value className="d-lg-inline-block">
          {editData.fbl_file_nm ? editData.fbl_file_nm : "선택된 파일이 없음."}
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {editData.fbl_file.name}
      </R.Btn_Value>
    );
  };
  const handleFablFileSelect = (event) => {
    const file = event.target.files[0];
    setEditData((prevState) => ({
      ...prevState,
      fabl_file: file,
    }));
  };
  const renderFablFileInfo = () => {
    if (!editData.fabl_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          {editData.fabl_file_nm
            ? editData.fabl_file_nm
            : "선택된 파일이 없음."}
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">        
        {editData.fabl_file.name}
      </R.Btn_Value>
    );
  };

  const handleBrnoFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setEditData((prevState) => ({
        ...prevState,
        brno_file: file,
      }));
    } 
  };
  const renderBrnoFileInfo = () => {
    if (!editData.brno_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          {editData.brno_file_nm
            ? editData.brno_file_nm
            : "선택된 파일이 없음."}
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {editData.brno_file.name}
      </R.Btn_Value>
    );
  };

  const handleCarFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE){
      alert('업로드할 수 없습니다. 파일 사이즈는 5MB 이하이어야 합니다.');
      event.target.value = '';
    } else {
      setEditData((prevState) => ({
        ...prevState,
        car_file: file,
      }));
    }
  };
  const renderCarFileInfo = () => {
    if (!editData.car_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          {editData.car_file_nm ? editData.car_file_nm : "선택된 파일이 없음."}
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {editData.car_file.name}
      </R.Btn_Value>
    );
  };

  const validateEditReq = (editData) => {
     if (parseInt(editData.deal_category_cd) === 2101) {           
      if (!editData.car_weight_cd || editData.car_weight_cd === "") {
        return "넘버 구분을 선택하세요.";
      }
      if (!editData.car_weight || !/^[0-9.]+$/.test(editData.car_weight)) {
        return "차량 톤수를 확인하세요.";
      }
      if (parseInt(editData.car_weight_cd) === 2301) {
        if (parseFloat(editData.car_weight) > 1.5) {
          return "차량 톤수를 확인하세요.";
        }
        if (!editData.ecar_yn || editData.ecar_yn === "") {
          return "차량구분을 선택하세요.";
        }
      } else if (parseInt(editData.car_weight_cd) === 2302) {
        if (
          parseFloat(editData.car_weight) <= 1.5 ||
          parseFloat(editData.car_weight) > 16
        ) {
          return "차량 톤수를 확인하세요.";
        }
      } else {
        if (parseFloat(editData.car_weight) <= 16) {
          return "차량 톤수를 확인하세요.";
        }
      }
      if (
        parseFloat(editData.car_weight) <= 0 ||
        parseFloat(editData.car_weight) > 100
      ) {
        return "차량 톤수를 확인하세요.";
      }
      if (!editData.car_yyyymm || editData.car_yyyymm === "") {
        return "차량 연식을 입력하세요.";
      }
      if (!editData.car_file && editData.car_file_seq === 0) {
        return "자동차등록증을 확인해 주세요.";
      }
      if (!editData.fbl_file && editData.fbl_file_seq === 0) {
        return "화물자동차운송사업허가증을 확인해 주세요.";
      }
    }

    if (
      parseInt(editData.deal_category_cd) === 2102 ||
      parseInt(editData.deal_category_cd) === 2103
    ) {
      if (parseInt(editData.deal_category_cd) === 2102) {
        if (!editData.whole_yn || editData.whole_yn === "") {
          return "판매방법을 선택하세요.";
        }
        if (!editData.jigong_flag || editData.jigong_flag === "") {
          return "넘버종류를 선택하세요.";
        }
        if (
          // editData.jigong_flag === "J" &&
          !editData.car_file && editData.car_file_seq === 0
        ) {
          return "자동차등록증을 확인해 주세요.";
        }
        if (
          // editData.jigong_flag === "J" &&
          !editData.car_yyyymm && editData.car_yyyymm === ""
        ) {
          return "차량연식을 입력하세요.";
        }
        if (!/^\d{4}(0[1-9]|1[0-2])$/.test(editData.car_yyyymm)) {
          return "차량연식을 확인하세요.";
        }
        if (
          !editData.price ||
          editData.price === "" ||
          parseInt(editData.price) <= 0
        ) {
          return "구매금액을 입력하세요.";
        }
        if (editData.price % 10 !== 0) {
          return "구매금액을 확인해 주세요(10만원 단위)";
        }
      }
      if (parseInt(editData.deal_category_cd) === 2103) {
        if (
          !editData.ji_price ||
          editData.ji_price === "" ||
          parseInt(editData.ji_price) <= 0
        ) {
          return "지입료를 입력하세요.";
        }
        if (
          !editData.price ||
          editData.price === "" ||
          parseInt(editData.price) <= 0
        ) {
          return "임대금액을 입력하세요.";
        }
        if (editData.price % 10 !== 0) {
          return "임대금액을 확인해 주세요(10만원 단위)";
        }
        if (!editData.car_file && editData.car_file_seq === 0) {
          return "자동차등록증을 확인해 주세요.";
        }
        if (!editData.car_yyyymm || editData.car_yyyymm === "") {
          return "차량연식을 입력하세요.";
        }
        if (!/^(19|20)\d{2}(0[1-9]|1[0-2])$/.test(editData.car_yyyymm)) {
          return "차량연식을 확인하세요.";
        }
      }
      if (!editData.car_weight_cd || editData.car_weight_cd === "") {
        return "톤수를 선택하세요.";
      }
      if (!editData.corp_nm || editData.corp_nm === "") {
        return "법인명을 입력하세요.";
      }
      // if (!editData.brno_file && editData.brno_file_seq === 0) {
      //   return "사업자등록증을 입력하세요.";
      // }
    }

    if (parseInt(editData.deal_category_cd) === 2104) {
      if (editData?.brno_auth_flag === "Y" && !editData?.corp_nm) {
        return "법인명을 확인해주세요.";
      }
      if (!editData.tax_paper_yn || editData.tax_paper_yn === "") {
        return "세금계산서를 확인해 주세요.";
      }
      if (isPartner && parseInt(editData.cp_cnt) <= 0) {
        return "거래처 수를 확인해 주세요.";
      }
      if (
        isPartner &&
        editData?.cp_cnt > 0 &&
        parseInt(editData.monthly_amount) <= 0
      ) {
        return "평균 월 매출을 확인해 주세요.";
      }
      if (
        editData.quick_rate === undefined ||
        editData.quick_rate === null ||
        parseInt(editData.quick_rate) < 0
      ) {
        return "퀵 비율을 확인해 주세요.";
      }
      if (
        editData?.cp_cnt > 0 &&
        (editData.freight_rate === undefined ||
          editData.freight_rate === null ||
          parseInt(editData.freight_rate) < 0)
      ) {
        return "화물 비율을 확인해 주세요.";
      }
      if (
        editData?.cp_cnt > 0 &&
        parseInt(editData.quick_rate) + parseInt(editData.freight_rate) !== 100
      ) {
        return "퀵, 화물 비율을 확인해 주세요.";
      }
      if (
        editData?.cp_cnt > 0 &&
        (!editData.cp_price ||
          editData.cp_price === "" ||
          parseInt(editData.cp_price) <= 0)
      ) {
        return "희망 거래가격을 확인해 주세요.";
      }
      if (editData?.brno_auth_flag === "Y") {
        if (!editData.brno_file && editData.brno_file_seq === 0) {
          return "사업자등록증을 확인해 주세요.";
        }
      }
      if (!editData.fabl_file && editData.fabl_file_seq === 0) {
        return "화물자동차운송 주선사업허가증을 확인해 주세요.";
      }
    }

    if (parseInt(editData.deal_category_cd) !== 2104) {
      if (editData.jigong_flag !== "G") {
        if (!/^\d{4}(0[1-9]|1[0-2])$/.test(editData.car_yyyymm)) {
          return "차량연식을 확인하세요.";
        }
      }
      // if (!editData.fbl_file && editData.fbl_file_seq === 0) {
      //   return "화물자동차운송사업허가증을 확인해 주세요.";
      // }
    } 
    
    if(
      parseInt(editData.deal_category_cd) === 2101 ||
      parseInt(editData.deal_category_cd) === 2104
    ){
      if (
        !editData.price ||
        editData.price === "" ||
        parseInt(editData.price) <= 0
      ) {
        return "판매금액을 입력하세요.";
      }
      if (editData.price % 10 !== 0) {
        return "판매금액을 확인해 주세요(10만원 단위)";
      }
      if(editData?.in_car_yn !== "Y"){
        if (
          parseInt(editData.price) < newPrice - 400 ||
          parseInt(editData.price) > newPrice + 400
        ) {
          return "판매금액을 확인해 주세요 (시세의 상하 400만원 범위)";
        } 
      }
    }

    if (!editData.region_cd || editData.region_cd === "") {
      return "판매자 지역을 선택하세요.";
    }

    if (!editData.deal_category_cd || editData.deal_category_cd === "") {
      return "매물 종류를 선택하세요.";
    }

    if (!editData.user_nm || editData.user_nm === "" || editData.user_nm === undefined) {
      return "판매자 이름을 입력하세요.";
    }

    if (!editData.phone || editData.phone === "" || editData.phone === undefined) {
      return "판매자 휴대폰 번호를 입력하세요.";
    }

    if (!editData.zip || editData.zip === "" || editData.zip === undefined) {
      return "판매자 우편번호를 입력하세요.";
    }

    if (!editData.addr1 || editData.addr1 === "" || editData.addr1 === undefined) {
      return "판매자 도로명 주소를 입력하세요.";
    }

    if (!editData.addr2 || editData.addr2 === "" || editData.addr2 === undefined) {
      return "판매자 상세 주소를 입력하세요.";
    }
  };

  const handleSubmit = async () => {
    const msg = validateEditReq(editData);
    if (msg) {
      return toast.error(msg);
    }

    try {
      console.log(editData);
      const response = await postNumbaUpdSellBuy(editData);

      if (response.code !== 1000) {
        toast.error("수정 실패");
        return;
      }
      toast.success("수정되었습니다.");
      navigate("/main");
    } catch (error) {
      toast.error("수정 서버 오류");
    }
  };

  if (parseInt(editData?.deal_category_cd) === 2101) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div className="pc_view">
              <div className="FormHeading_div">
                <h1 className="Form_Heading">팝니다 수정하기</h1>
              </div>
            </div>
            <div className="">
              <div className="FormDetails_Div">
                <div className="FormDiv">
                  <div className="d-flex justify-content-between">
                    <h3 className="FormDetails_Heading">매물정보 등록</h3>
                  </div>
                  <div className="FormDetails_Div">
                    <div className="">
                      <table className="table table-bordered table-responsive table-form">
                        <tbody>
                          <tr>
                            <th style={{ width: "25.92%" }}>매물 종류</th>
                            <td>
                              <div className="d-flex">
                                <Form.Check
                                  inline
                                  label="개인넘버"
                                  name="deal_category_cd"
                                  type="radio"
                                  value={2101}
                                  className="CustomCheckbox w-33"
                                  checked
                                />
                                <Form.Check
                                  inline
                                  label="법인넘버"
                                  name="deal_category_cd"
                                  type="radio"
                                  className="CustomCheckbox w-33"
                                  checked={false}
                                  disabled={true}
                                />
                                <Form.Check
                                  inline
                                  label="주선면허"
                                  name="deal_category_cd"
                                  type="radio"
                                  value={2104}
                                  className="CustomCheckbox w-33"
                                  checked={false}
                                  disabled={true}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>넘버구분</th>
                            <td>
                              <div className="td_chk3_outer">
                                <Form.Check
                                  inline
                                  label="소형(용달 1.5톤 이하)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2301}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  checked={
                                    parseInt(editData.car_weight_cd) === 2301 &&
                                    true
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="중형(1.5톤 초과~16톤 이하)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2302}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  checked={
                                    parseInt(editData.car_weight_cd) === 2302 &&
                                    true
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="대형(16톤 초과)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2303}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  checked={
                                    parseInt(editData.car_weight_cd) === 2303 &&
                                    true
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          {parseInt(editData.car_weight_cd) === 2301 && (
                            <tr>
                              <th>차량구분</th>
                              <td>
                                <div className="d-flex">
                                  <Form.Check
                                    inline
                                    label="전기차"
                                    name="ecar_yn"
                                    type="radio"
                                    value={`Y`}
                                    className="CustomCheckbox w-33"
                                    onChange={handleInputChange}
                                    checked={editData.ecar_yn === "Y" && true}
                                  />
                                  <Form.Check
                                    inline
                                    label="일반차"
                                    name="ecar_yn"
                                    type="radio"
                                    value={`N`}
                                    className="CustomCheckbox w-33"
                                    onChange={handleInputChange}
                                    checked={editData.ecar_yn === "N" && true}
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>톤수입력</th>
                            <td>
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control Table_Input w-300px me-2"
                                  name={"car_weight"}
                                  onChange={handleInputChange}
                                  value={editData.car_weight}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>차량 연식</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <select
                                  className="form-select Table_Select w-100px me-10px"
                                  id={"yyyy"}
                                  onChange={handleInputChange}
                                  value={editData.car_yyyymm?.substring(0, 4)}
                                >
                                  <option value="">년</option>
                                  {years.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className="form-select Table_Select w-75px w-sm-65px"
                                  id={"mm"}
                                  onChange={handleInputChange}
                                  value={editData.car_yyyymm?.substring(4, 6)}
                                >
                                  <option value="">월</option>
                                  {month.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>판매자 지역</th>
                            <td>
                              <select
                                className="form-select Table_Select w-300px w-sm-175px"
                                name={"region_cd"}
                                onChange={handleInputChange}
                                value={editData?.region_cd}
                              >
                                <option>지역을 선택하세요</option>
                                {regionCdList.map((region, index) => (
                                  <option key={index} value={region.cd_seq}>
                                    {region.cd_nm}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <th>판매금액</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  placeholder="10만원 단위로 입력"
                                  type="text"
                                  className="form-control Table_Input w-300px me-2"
                                  name={"price"}
                                  onChange={handleInputChange}
                                  value={editData.price}
                                />
                                <span className="text-nowrap">&nbsp;만원</span>
                                <div className="CustomCheckerBox ms-4" >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="in_car_yn"
                                    // onChange={handleCheckboxChange3}
                                    // checked={editData.in_car_yn === "Y" && true}
                                    checked={isInCarYn}
                                    onClick={() => {
                                      setIsInCarYn(!isInCarYn);
                                      if (isInCarYn) {
                                        //  초기화
                                        setEditData({
                                          ...editData,
                                          in_car_yn: "N",
                                        });
                                        setIsInCarYn(false);
                                      }else if(!isInCarYn){
                                        setEditData({
                                          ...editData,
                                          in_car_yn: "Y",
                                        });
                                        setIsInCarYn(true);
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label-ml-7 f-medium"
                                    htmlFor="inlineCheckbox7"
                                  >
                                    차량포함
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>자동차등록증</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <label className="fileBtn" htmlFor="carFile">
                                  파일선택
                                </label>
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  id="carFile"
                                  onChange={handleCarFileSelect}
                                />
                                <div className="txt_attach ms-2">
                                  {renderCarFileInfo()}
                                </div>
                              </div>
                              <R.Btn_Value
                                className="d-lg-inline-block"
                                color="#c1c1c1"
                              >
                                ※ 사진 또는 이미지 파일로 제출해 주세요.
                              </R.Btn_Value>
                              <R.Btn_Value
                                className="d-lg-inline-block"
                                color="#f60"
                              >
                                &nbsp;(관리자 확인용입니다)
                              </R.Btn_Value>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frm_request_wrapper">
              <div className="frm_request_container2">
                <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
              </div>
              <div className="FormDetails_Div">
                <div className="">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      <tr>
                        <th style={{ width: "25.92%" }}>판매자 이름</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"user_nm"}
                            value={editData.user_nm}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>판매자 휴대폰번호</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"phone"}
                            maxLength={11}
                            value={editData.phone.replace(/[^0-9]/g, "")}
                            placeholder={"(-)없이 입력"}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>판매자 주소</th>
                        <td>
                          <div className="d-flex flex-column">
                            <div className="daum_search_wrapper">
                              <div className="d-flex w-100 mb-2">
                                <input
                                  type="text"
                                  name={"zip"}
                                  value={editData.zip}
                                  onChange={handleInputChange}
                                  className="form-control Table_Input input-zip"
                                  readOnly={true}
                                />
                                <DaumPostcode
                                  className="fileBtn btn btn-primary btn-zip border-1 h-sm-31px"
                                  onCompleteCallback={(data) => {
                                    setEditData({
                                      ...editData,
                                      zip: data.zonecode,
                                      addr1: data.fullAddr,
                                    });
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                name={"addr1"}
                                value={editData.addr1}
                                readOnly={true}
                                className="form-control Table_Input ms-0 mb-2"
                              />
                            </div>
                            <input
                              type="text"
                              width={"w-100"}
                              name={"addr2"}
                              value={editData.addr2}
                              onChange={handleInputChange}
                              placeholder={"상세 주소 입력"}
                              className="form-control Table_Input"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>화물자동차운송사업허가증</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="fblFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="fblFile"
                              onChange={handleFblFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderFblFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea
                            className="form-control form-textarea"
                            name={"req_comment"}
                            onChange={handleInputChange}
                            value={editData.req_comment}
                            maxLength={200}
                          ></textarea>
                          <div className="textarea_count">
                            {editData?.req_comment?.length}/200
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="Para_Content">
              {/* ※ 판매자정보는 관리자 확인용이며 자동차등록증을 반드시 제출해야 정보 등록이 가능합니다. */}
              ※ 판매자정보는 관리자 확인용이며 자동차등록증과
              화물자동차운송사업허가증을 반드시 제출해야 정보 등록이 가능합니다.
            </div>
            <div className="d-flex justify-content-center mt-4">
              <CardButton
                width="390px"
                bg="#FF9C00"
                name="수정하기"
                className="btn-main"
                onClick={handleSubmit}
              ></CardButton>
            </div>
          </div>
        </div>
      </>
    );
  } else if (
    parseInt(editData?.deal_category_cd) === 2102 ||
    parseInt(editData?.deal_category_cd) === 2103
  ) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div className="pc_view">
              <div className="FormHeading_div">
                <h1 className="Form_Heading">팝니다 수정하기</h1>
              </div>
            </div>
            <div className="">
              <h3 className="FormDetails_Heading">매물정보 등록</h3>
              <div className="FormDetails_Div">
                <div className="">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      <tr>
                        <th style={{ width: "25.92%" }}>매물 종류</th>
                        <td>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="개인넘버"
                              name="deal_category_cd"
                              type="radio"
                              className="CustomCheckbox w-33"
                              checked={false}
                              disabled={true}
                            />
                            <Form.Check
                              inline
                              label="법인넘버"
                              type="radio"
                              className="CustomCheckbox w-33"
                              checked
                            />
                            <Form.Check
                              inline
                              label="주선면허"
                              name="deal_category_cd"
                              type="radio"
                              className="CustomCheckbox w-33"
                              checked={false}
                              disabled={true}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>매매/임대</th>
                        <td>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="매매"
                              name="deal_category_cd"
                              type="radio"
                              value={2102}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              checked={
                                parseInt(editData.deal_category_cd) === 2102 &&
                                true
                              }
                              disabled={
                                parseInt(editData.deal_category_cd) === 2103 &&
                                true
                              }
                            />
                            <Form.Check
                              inline
                              label="임대"
                              name="deal_category_cd"
                              type="radio"
                              value={2103}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              checked={
                                parseInt(editData.deal_category_cd) === 2103 &&
                                true
                              }
                              disabled={
                                parseInt(editData.deal_category_cd) === 2102 &&
                                true
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      {editData.whole_yn && (
                        <tr>
                          <th>판매 방법</th>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                inline
                                label="일부판매"
                                name="whole_yn"
                                type="radio"
                                value={`N`}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                checked={editData.whole_yn === "N" && true}
                              />
                              <Form.Check
                                inline
                                label="전체판매"
                                name="whole_yn"
                                type="radio"
                                value={`Y`}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                checked={editData.whole_yn === "Y" && true}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>판매자 지역</th>
                        <td>
                          <select
                            className="form-select Table_Select w-300px"
                            name={"region_cd"}
                            onChange={handleInputChange}
                            value={editData?.region_cd}
                          >
                            <option>지역을 선택하세요</option>
                            {regionCdList.map((region, index) => (
                              <option key={index} value={region.cd_seq}>
                                {region.cd_nm}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {editData &&
                    (parseInt(editData.deal_category_cd) === 2102 ? (
                      <>
                        <table className="table table-bordered table-responsive table-form mt-10px">
                          <thead>
                            <tr className="tr_header">
                              <th colSpan={2}>{1}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th style={{ width: "25.92%" }}>넘버종류</th>
                              <td>
                                <div className="d-flex">
                                  <div className="CustomCheckbox m-0 w-33">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`jigong_flag`}
                                      id={`inlineRadio_1`}
                                      value="J"
                                      onChange={handleInputChange}
                                      checked={
                                        editData.jigong_flag === "J" && true
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`inlineRadio_1`}
                                    >
                                      지입넘버
                                    </label>
                                  </div>
                                  <div className="CustomCheckbox m-0 w-33x">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`jigong_flag`}
                                      id={`inlineRadio_2`}
                                      value="G"
                                      onChange={handleInputChange}
                                      checked={
                                        editData.jigong_flag === "G" && true
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`inlineRadio_2`}
                                    >
                                      공T/E넘버
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>톤수선택</th>
                              <td>
                                <div className="d-flex">
                                  <div className="CustomCheckbox m-0 w-33">
                                    <select
                                      className="form-select Table_Select w-300px w-sm-175px"
                                      name={"car_weight_cd"}
                                      onChange={handleInputChange}
                                      value={editData?.car_weight_cd}
                                    >
                                      <option>톤수를 선택하세요</option>
                                      {corpCarCdList.map((corpCarCd, index) => (
                                        <option
                                          key={index}
                                          value={corpCarCd.cd_seq}
                                        >
                                          {corpCarCd.cd_nm}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            {/* {editData.jigong_flag === "J" && ( */}
                            <tr>
                              <th>차량연식</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <select
                                    className="form-select Table_Select w-100px me-10px"
                                    id={"yyyy"}
                                    onChange={handleInputChange}
                                    value={editData.car_yyyymm?.substring(
                                      0,
                                      4
                                    )}
                                  >
                                    <option value="">년</option>
                                    {years.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="form-select Table_Select w-75px w-sm-65px"
                                    id={"mm"}
                                    onChange={handleInputChange}
                                    value={editData.car_yyyymm?.substring(
                                      4,
                                      6
                                    )}
                                  >
                                    <option value="">월</option>
                                    {month.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            </tr>
                            {/* )} */}
                            <tr>
                              <th>판매금액</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    placeholder="10만원 단위로 입력"
                                    type="text"
                                    className="form-control Table_Input w-300px me-2"
                                    width="180px"
                                    name={"price"}
                                    onChange={handleInputChange}
                                    value={editData.price}
                                  />
                                  <span className="text-nowrap">만원</span>
                                  {/* <R.Table_Input
                                    className="ms-0"
                                    width="180px"
                                    name={"price"}
                                    onChange={handleInputChange}
                                    value={editData.price}
                                  ></R.Table_Input>{" "}
                                  만원                                 */}
                                <div className="CustomCheckerBox ms-4" >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="in_car_yn"
                                    // onChange={handleCheckboxChange3}
                                    // checked={editData.in_car_yn === "Y" && true}
                                    checked={isInCarYn}
                                    onClick={() => {
                                      setIsInCarYn(!isInCarYn);
                                      if (isInCarYn) {
                                        //  초기화
                                        setEditData({
                                          ...editData,
                                          in_car_yn: "N",
                                        });
                                        setIsInCarYn(false);
                                      } else if (!isInCarYn) {
                                        setEditData({
                                          ...editData,
                                          in_car_yn: "Y",
                                        });
                                        setIsInCarYn(true);
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label-ml-7 f-medium"
                                    htmlFor="inlineCheckbox7"
                                  >
                                    차량포함
                                  </label>
                                </div>
                              </div>
                              </td>
                            </tr>
                            {/* {editData.jigong_flag === "J" && ( */}
                            <tr>
                              <th>자동차등록증</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <label
                                    className="fileBtn"
                                    htmlFor={`carFile`}
                                  >
                                    파일선택
                                  </label>
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id={`carFile`}
                                    onChange={handleCarFileSelect}
                                  />
                                  <div className="txt_attach ms-2">
                                    {renderCarFileInfo()}
                                  </div>
                                </div>
                                <R.Btn_Value
                                  className="d-lg-inline-block"
                                  color="#c1c1c1"
                                >
                                  ※ 사진 또는 이미지 파일로 제출해 주세요.
                                </R.Btn_Value>
                                <R.Btn_Value
                                  className="d-lg-inline-block"
                                  color="#f60"
                                >
                                  &nbsp;(관리자 확인용입니다)
                                </R.Btn_Value>
                              </td>
                            </tr>
                            {/* )} */}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <table className="table table-bordered table-responsive table-form mt-10px">
                          <thead>
                            <tr className="tr_header">
                              <th colSpan={2}>{1}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>톤수선택</th>
                              <td>
                                <div className="d-flex">
                                  <div className="CustomCheckbox m-0 w-33">
                                    <select
                                      className="form-select Table_Select w-300px"
                                      name={"car_weight_cd"}
                                      onChange={handleInputChange}
                                      value={editData?.car_weight_cd}
                                    >
                                      <option>톤수를 선택하세요</option>
                                      {corpCarCdList.map((corpCarCd, index) => (
                                        <option
                                          key={index}
                                          value={corpCarCd.cd_seq}
                                        >
                                          {corpCarCd.cd_nm}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>차량연식</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <select
                                    className="form-select Table_Select w-100px me-10px"
                                    id={"yyyy"}
                                    onChange={handleInputChange}
                                    value={editData.car_yyyymm?.substring(0, 4)}
                                  >
                                    <option value="">년</option>
                                    {years.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="form-select Table_Select w-75px w-sm-65px"
                                    id={"mm"}
                                    onChange={handleInputChange}
                                    value={editData.car_yyyymm?.substring(4, 6)}
                                  >
                                    <option value="">월</option>
                                    {month.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>임대금액</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    placeholder="10만원 단위로 입력"
                                    className="form-control Table_Input w-300px "
                                    type="text"
                                    name={"price"}
                                    onChange={handleInputChange}
                                    value={editData.price}
                                  />
                                  <span className="text-nowrap ms-2">만원</span> 
                                  {/* <R.Table_Input
                                    className="form-control Table_Input w-sm-175px me-10px ms-0"
                                    width="180px"
                                    name={"price"}
                                    onChange={handleInputChange}
                                    value={editData.price}
                                  ></R.Table_Input>
                                  <span className="text-nowrap">&nbsp;만원</span> */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>지입료</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    placeholder="1만원 단위로 입력"
                                    className="form-control Table_Input w-300px "
                                    type="text"
                                    name={"ji_price"}
                                    onChange={handleInputChange}
                                    value={editData.ji_price}
                                  />
                                  <span className="text-nowrap ms-2">만원
                                    <span style={{ color: '#FF6600' }}> (VAT 별도)</span>
                                  </span>
                                  {/* <R.Table_Input
                                    className="form-control Table_Input w-sm-175px me-10px ms-0"
                                    width="180px"
                                    name={"ji_price"}
                                    onChange={handleInputChange}
                                    value={editData.ji_price}
                                  ></R.Table_Input>{" "}
                                  <span className="text-nowrap">
                                    만원
                                    <R.Para_Content>(VAT 별도)</R.Para_Content>
                                  </span> */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>자동차등록증</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <label
                                    className="fileBtn"
                                    htmlFor={`carFile`}
                                  >
                                    파일선택
                                  </label>
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id={`carFile`}
                                    onChange={handleCarFileSelect}
                                  />
                                  <div className="txt_attach ms-2">
                                    {renderCarFileInfo()}
                                  </div>
                                </div>
                                <R.Btn_Value
                                  className="d-lg-inline-block"
                                  color="#c1c1c1"
                                >
                                  ※ 사진 또는 이미지 파일로 제출해 주세요.
                                </R.Btn_Value>
                                <R.Btn_Value
                                  className="d-lg-inline-block"
                                  color="#f60"
                                >
                                  &nbsp;(관리자 확인용입니다)
                                </R.Btn_Value>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ))}
                </div>
              </div>
            </div>

            <div className="frm_request_wrapper">
              <div className="frm_request_container2">
                <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
              </div>
            </div>
            <div className="FormDetails_Div">
              <table className="table table-bordered table-responsive table-form">
                <tbody>
                  <tr>
                    <th style={{ width: "25.92%" }}>법인명</th>
                    <td>
                      <input
                        type="text"
                        className="form-control Table_Input w-100"
                        name={"corp_nm"}
                        value={editData.corp_nm}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>담당자 이름</th>
                    <td>
                      <input
                        type="text"
                        className="form-control Table_Input w-300px"
                        name={"user_nm"}
                        value={editData.user_nm}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>담당자 휴대폰번호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control Table_Input w-300px"
                        name={"phone"}
                        maxLength={11}
                        value={editData.phone.replace(/[^0-9]/g, "")}
                        placeholder={"(-)없이 입력"}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>법인회사 주소</th>
                    <td>
                      <div className="d-flex flex-column">
                        <div className="daum_search_wrapper">
                          <div className="d-flex w-100 mb-2">
                            <input
                              type="text"
                              name={"zip"}
                              value={editData.zip}
                              onChange={handleInputChange}
                              className="form-control Table_Input input-zip"
                              readOnly={true}
                            />
                            <DaumPostcode
                              className="fileBtn btn btn-primary btn-zip border-1 h-sm-31px"
                              onCompleteCallback={(data) => {
                                setEditData({
                                  ...editData,
                                  zip: data.zonecode,
                                  addr1: data.fullAddr,
                                });
                              }}
                            />
                          </div>
                          <input
                            type="text"
                            name={"addr1"}
                            value={editData.addr1}
                            readOnly={true}
                            className="form-control Table_Input ms-0 mb-2"
                          />
                        </div>
                        <input
                          type="text"
                          name={"addr2"}
                          value={editData.addr2}
                          onChange={handleInputChange}
                          placeholder={"상세 주소 입력"}
                          className="form-control Table_Input"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>사업자등록증(선택)</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <label className="fileBtn" htmlFor="brnoFile">
                          파일선택
                        </label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="brnoFile"
                          onChange={handleBrnoFileSelect}
                        />
                        <div className="txt_attach ms-2">
                          {renderBrnoFileInfo()}
                        </div>
                      </div>
                      <R.Btn_Value
                        className="d-lg-inline-block"
                        color="#c1c1c1"
                      >
                        ※ 사진 또는 이미지 파일로 제출해 주세요
                      </R.Btn_Value>
                    </td>
                  </tr>
                  <tr>
                    <th>화물자동차운송사업허가증(선택)</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <label className="fileBtn" htmlFor="fblFile">
                          파일선택
                        </label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="fblFile"
                          onChange={handleFblFileSelect}
                        />
                        <div className="txt_attach ms-2">
                          {renderFblFileInfo()}
                        </div>
                      </div>
                      <R.Btn_Value
                        className="d-lg-inline-block"
                        color="#c1c1c1"
                      >
                        ※ 사진 또는 이미지 파일로 제출해 주세요
                      </R.Btn_Value>
                    </td>
                  </tr>
                  <tr>
                    <th>요청내용</th>
                    <td>
                      <textarea
                        className="form-control form-textarea"
                        name={"req_comment"}
                        onChange={handleInputChange}
                        value={editData.req_comment}
                        maxLength={200}
                      ></textarea>
                      <div className="textarea_count">
                        {editData?.req_comment?.length}/200
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="Para_Content">
                ※ 판매자정보는 관리자 확인용이며{" "}<span>자동차등록증</span>을 반드시
                제출해야 정보 등록이 가능합니다.
                {/* ※ 판매자정보는 관리자 확인용이며{" "}
                <span>자동차등록증과 화물자동차운송사업허가증</span>을 반드시
                제출해야 정보 등록이 가능합니다. */}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <CardButton
                  width="390px"
                  bg="#FF9C00"
                  name="수정하기"
                  className="btn-main"
                  onClick={handleSubmit}
                ></CardButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (parseInt(editData?.deal_category_cd) === 2104) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div>
              <div className="pc_view">
                <div className="FormHeading_div">
                  <h1 className="Form_Heading">팝니다 수정하기</h1>
                </div>
              </div>
              <div className="">
                <h3 className="FormDetails_Heading">매물정보 등록</h3>
                <div className="FormDetails_Div">
                  <div className="">
                    <table className="table table-bordered table-responsive table-form">
                      <tbody>
                        <tr>
                          <th style={{ width: "25.92%" }}>매물 종류</th>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                inline
                                label="개인넘버"
                                name="deal_category_cd"
                                type="radio"
                                className="CustomCheckbox w-33"
                                checked={false}
                                disabled={true}
                              />
                              <Form.Check
                                inline
                                label="법인넘버"
                                name="deal_category_cd"
                                type="radio"
                                className="CustomCheckbox w-33"
                                checked={false}
                                disabled={true}
                              />
                              <Form.Check
                                inline
                                label="주선면허"
                                name="deal_category_cd"
                                type="radio"
                                value={editData.deal_category_cd}
                                className="CustomCheckbox w-33"
                                checked
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>세금 계산서</th>
                          <td>
                            <div className="d-flex">
                              <div className="CustomCheckbox m-0 w-33">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="tax_paper_yn"
                                  id="inlineRadio4"
                                  value="N"
                                  onChange={handleInputChange}
                                  checked={
                                    editData.tax_paper_yn === "N" && true
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio4"
                                >
                                  불가능
                                </label>
                              </div>
                              <div className="CustomCheckbox">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="tax_paper_yn"
                                  id="inlineRadio5"
                                  value="Y"
                                  onChange={handleInputChange}
                                  checked={
                                    editData.tax_paper_yn === "Y" && true
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio5"
                                >
                                  가능(VAT 별도)
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>판매금액</th>
                          <td>
                            <div className="d-flex align-items-center mb-2">
                              <input
                                className="form-control Table_Input w-300px w-sm-175px"
                                width="180px"
                                name={"price"}
                                onChange={handleInputChange}
                                value={editData.price}
                              />
                              <span className="text-nowrap me-2">&nbsp;만원</span>
                            </div>
                            <div className="Para_Content mt-0 mb-2">
                              ※ 금액은 10만원 단위로 입력
                            </div>
                            <div className="td_caption mb-2">
                              <div>
                                마지막 1개월 평균 거래가 : <span>5,260</span>
                                만원
                              </div>
                              <div>
                                판매 금액은 마지막 1개월 평균 거래가 + 500만원 ={" "}
                                <span>5,760</span> 만원 보다 높게 입력할 수
                                없습니다.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>판매자 지역</th>
                          <td>
                            <select
                              className="form-select Table_Select w-300px"
                              name={"region_cd"}
                              onChange={handleInputChange}
                              value={editData?.region_cd}
                            >
                              <option>지역을 선택하세요</option>
                              {regionCdList.map((region, index) => (
                                <option key={index} value={region.cd_seq}>
                                  {region.cd_nm}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th>거래처 판매</th>
                          <td>
                            <div className="CustomCheckerBox my-10px flex-wrap">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox6"
                                value="option1"
                                checked={isPartner}
                                onClick={() => {
                                  setIsPartner(!isPartner);
                                  if (isPartner) {
                                    //  초기화
                                    setEditData({
                                      ...editData,
                                      cp_cnt: 0,
                                      monthly_amount: 0,
                                      quick_rate: 0,
                                      freight_rate: 0,
                                      cp_price: 0,
                                    });
                                    setIsPartner(false);
                                  }
                                }}
                              />
                              <label
                                className="form-check-label me-10px"
                                htmlFor="inlineCheckbox6"
                              >
                                팝니다
                              </label>
                              <div className="td_caption">
                                * 기존 거래처 판매를 원하시는분은 체크 후 정보를
                                입력해주세요
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {isPartner && (
                      <div className="pc_view">
                        <table className="table table-bordered table-responsive table-form mt-10px">
                          <tbody>
                            <tr>
                              <th style={{ width: "25.92%" }}>거래처 수</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-133px me-2"
                                    name={"cp_cnt"}
                                    onChange={handleInputChange}
                                    value={editData.cp_cnt}
                                  />{" "}
                                  개
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>1년 평균 월 매출</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-300px me-2"
                                    name={"monthly_amount"}
                                    onChange={handleInputChange}
                                    value={editData.monthly_amount}
                                  />{" "}
                                  만원
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>퀵 · 화물 매출 비율</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                    퀵
                                    <input
                                      type="text"
                                      className="form-control Table_Input w-100px ms-15px me-10px"
                                      name={"quick_rate"}
                                      onChange={handleInputChange}
                                      value={editData.quick_rate}
                                    />
                                    %
                                  </div>
                                  <div className="d-flex align-items-center ms-20px">
                                    화물
                                    <input
                                      type="text"
                                      className="form-control Table_Input w-100px ms-15px me-10px"
                                      name={"freight_rate"}
                                      onChange={handleInputChange}
                                      value={editData.freight_rate}
                                    />
                                    %
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>희망 거래가격</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-300px me-2"
                                    name={"cp_price"}
                                    onChange={handleInputChange}
                                    value={editData.cp_price}
                                  />
                                  만원
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="frm_request_wrapper">
                <div className="frm_request_container2">
                  <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
                </div>
                <div className="FormDetails_Div">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      {editData?.corp_nm && (
                          <tr>
                            <th style={{ width: "25.92%" }}>법인명</th>
                            <td>
                              <input
                                type="text"
                                className="form-control Table_Input w-100"
                                name={"corp_nm"}
                                value={editData.corp_nm}
                                onChange={handleInputChange}
                              />
                            </td>
                          </tr>
                        )}
                      <tr>
                        <th style={{ width: "25.92%" }}>{editData?.corp_nm ? "담당자" : "판매자"} 이름</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"user_nm"}
                            value={editData.user_nm}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{editData?.corp_nm ? "담당자" : "판매자"} 휴대폰번호</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"phone"}
                            maxLength={11}
                            value={editData.phone.replace(/[^0-9]/g, "")}
                            placeholder={"(-)없이 입력"}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{editData?.corp_nm ? "담당자" : "판매자"} 주소</th>
                        <td>
                          <div className="d-flex flex-column">
                            <div className="daum_search_wrapper">
                              <div className="d-flex w-100 mb-2">
                                <input
                                  type="text"
                                  name={"zip"}
                                  value={editData.zip}
                                  onChange={handleInputChange}
                                  className="form-control Table_Input input-zip"
                                  readOnly={true}
                                />
                                <DaumPostcode
                                  className="fileBtn btn btn-primary btn-zip border-1 h-sm-31px"
                                  onCompleteCallback={(data) => {
                                    setEditData({
                                      ...editData,
                                      zip: data.zonecode,
                                      addr1: data.fullAddr,
                                    });
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                name={"addr1"}
                                value={editData.addr1}
                                readOnly={true}
                                className="form-control Table_Input ms-0 mb-2"
                              />
                            </div>
                            <input
                              width={"w-100"}
                              type="text"
                              name={"addr2"}
                              value={editData.addr2}
                              onChange={handleInputChange}
                              placeholder={"상세 주소 입력"}
                              className="form-control Table_Input"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>{editData?.corp_nm ? "사업자등록증" : "사업자등록증(선택)"}</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="brnoFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="brnoFile"
                              onChange={handleBrnoFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderBrnoFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr> 
                      <tr>
                        <th>화물자동차운송 주선사업허가증</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="fablFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="fablFile"
                              onChange={handleFablFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderFablFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea
                            className="form-control form-textarea"
                            name={"req_comment"}
                            onChange={handleInputChange}
                            value={editData.req_comment}
                            maxLength={200}
                          ></textarea>
                          <div className="textarea_count">
                            {editData?.req_comment?.length}/200
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="Para_Content">
                    ※ 판매자정보는 관리자 확인용이며{" "}
                    <span>화물자동차운송 주선사업허가증을</span> 반드시 제출해야 정보
                    등록이 가능합니다.                                
                    {/* ※ 판매자정보는 관리자 확인용이며{" "}
                    <span>사업자등록증과 화물자동차운송 주선사업허가증을</span> 반드시 제출해야 정보
                    등록이 가능합니다. */}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <CardButton
                      width="390px"
                      bg="#FF9C00"
                      name="수정하기"
                      className="btn-main"
                      onClick={handleSubmit}
                    ></CardButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SellingEditForm;
