import React from "react";

const ThirdTab = () => {
  return (
    <>
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <div>
                <div className="FormHeading_div">
                  <h1 className="Form_Heading">팝니다 등록하기</h1>
                </div>
              </div>
              <div className="">
                <h3 className="FormDetails_Heading">매물정보 등록</h3>
                <div className="FormDetails_Div">
                  <div className="">
                    <table className="table table-bordered table-responsive table-form">
                      <tbody>
                        <tr>
                          <th style={{ width: "25.92%" }}>유형</th>
                          <td>
                            <div className="d-flex">
                              <div className="CustomCheckbox m-0 w-33">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptionsA"
                                  id="inlineRadio1"
                                  value="option1"
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio1"
                                >
                                  개인넘버
                                </label>
                              </div>
                              <div className="CustomCheckbox m-0 w-33">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptionsA"
                                  id="inlineRadio2"
                                  value="option2"
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio2"
                                >
                                  법인넘버
                                </label>
                              </div>
                              <div className="CustomCheckbox m-0 w-33">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptionsA"
                                  id="inlineRadio3"
                                  value="option3"
                                  checked
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio3"
                                >
                                  주선면허
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>넘버구분</th>
                          <td>
                            <div className="d-flex">
                              <div className="CustomCheckbox m-0 w-33">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptionsB"
                                  id="inlineRadio4"
                                  value="option4"
                                  checked
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio4"
                                >
                                  불가능
                                </label>
                              </div>
                              <div className="CustomCheckbox">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptionsB"
                                  id="inlineRadio5"
                                  value="option5"
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio5"
                                >
                                  가능(VAT 별도)
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>판매금액</th>
                          <td>
                            <div className="d-flex align-items-center my-10px">
                              <input
                                placeholder="10만원 단위로 입력"
                                type="text"
                                className="form-control Table_Input w-300px me-2"
                              />{" "}
                              대
                            </div>
                            <div className="td_caption mb-2">
                              마지막 1개월 평균 거래가 : <span>5,260</span>만원
                              + 500만원 / 판매대수는 <span>5,760</span>만원 보다
                              높게 입력할 수 없음
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>판매자 지역</th>
                          <td>
                            <select className="form-select Table_Select w-300px">
                              <option>지역을 선택하세요</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th>거래처 판매</th>
                          <td>
                            <div className="CustomCheckerBox my-10px flex-wrap">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox6"
                                value="option1"
                              />
                              <label
                                className="form-check-label me-10px"
                                for="inlineCheckbox6"
                              >
                                팝니다
                              </label>
                              <div className="td_caption">
                                * 기존 거래처 판매를 원하시는분은 체크 후 정보를
                                입력해주세요
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="pc_view">
                      <table className="table table-bordered table-responsive table-form mt-10px">
                        <tbody>
                          <tr>
                            <th style={{ width: "25.92%" }}>거래처 수</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control Table_Input w-133px me-2"
                                />{" "}
                                개
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>1년 평균 월 매출</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control Table_Input w-300px me-2"
                                />{" "}
                                만원
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>퀵 · 화물 매출 비율</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  퀵
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-100px ms-15px me-10px"
                                  />
                                  %
                                </div>
                                <div className="d-flex align-items-center ms-20px">
                                  화물
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-100px ms-15px me-10px"
                                  />
                                  %
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>희망 거래가격</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control Table_Input w-300px me-2"
                                />{" "}
                                만원
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-45px">
                <div className="d-flex align-items-center justify-content-between mb-25px">
                  <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
                  <div className="CustomCheckerBox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox7"
                      value="option1"
                    />
                    <label
                      className="form-check-label f-medium"
                      for="inlineCheckbox7"
                    >
                      회원정보와 동일
                    </label>
                  </div>
                </div>
                <div className="FormDetails_Div">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      <tr>
                        <th style={{ width: "25.92%" }}>판매자 이름</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-wrap">판매자 휴대폰번호</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>판매자 주소</th>
                        <td>
                          <input
                            type="text"
                            className="form-control Table_Input Input_Search me-2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>화물자동차운송사업허가증</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <button className="btn btn-primary btn-attach">
                              파일선택
                            </button>
                            <div className="txt_attach ms-2">
                              ※ 사진 또는 이미지 파일로 제출해 주세요
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea className="form-control form-textarea"></textarea>
                          <div className="textarea_count">0/200</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="Para_Content">
                    ※ 판매자정보는 관리자 확인용이며{" "}
                    <span>화물자동차운송사업허가증을</span> 반드시 제출해야 정보
                    등록이 가능합니다.
                  </div>

                  <button className="btn btn-main btn-light">다음</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdTab;
