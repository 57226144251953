import { postNumbaDelInterest } from "../../../api/mypage";
import { toast } from "wc-toast";

export const deInterest = async (data) => {
  // console.log("data", data);

  if (data.code === "sell") {
    try {
      const response = await postNumbaDelInterest({
        interest_seq: data.interest_seq,
      });

      if (response.code === 1000) {
        console.log("관심등록 삭제성공");
      } else {
        console.log("관심등록 삭제실패");
      }

      // console.log(response);
    } catch (err) {
      console.log(err);
      toast.error("삭제 서버 오류");
    }
  }

  if (data.code === "buy") {
    try {
      const response = await postNumbaDelInterest({
        interest_seq: data.interest_seq,
      });

      // console.log(response);

      if (response.code === 1000) {
        console.log("관심등록 삭제성공");
      } else {
        console.log("관심등록 삭제실패");
      }
    } catch (err) {
      console.log(err);
      toast.error("삭제 서버 오류");
    }
  }
};
