import styled from "styled-components";

export const BtnGray = styled.button`
  width: ${(props) => (props.width ? props.width : "40px")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "40px")};
  height: ${(props) => (props.height ? props.height : "51px")};
  border: none;
  //background-color: #C7C7C7;
  background-color: ${(props) => (props.bg ? props.bg : "#a1a1a1")};
  border-radius: 5px;
  color: #fff;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "13px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 767px) {
    height: ${(props) => (props.height ? "40px" : "51px")};
    font-size: 14px;
  }
`;
