import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  //height: 100vh;
  padding: 137px 0 112px;
  min-height: calc(100vh - 347px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;

  @media screen and (max-width: 991.98px) {
    padding: 50px 0 50px;
  }

  @media only screen and (max-width: 767.98px) {
    height: 100vh;
    align-items: flex-start;
    padding-top: 137px;
  }
`;
export const Heading = styled.h3`
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
  @media screen and (max-width: 991.98px) {
    font-size: 17px;
    margin-bottom: 8px;
  }
`;
export const Para = styled.p`
  color: #838383;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  @media screen and (max-width: 991.98px) {
    font-size: 13px;
    margin-bottom: 11px;
  }
`;
export const CardSection = styled.div`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
`;
export const WrapperBox = styled.div`
  padding: 0px 5px;
  @media screen and (max-width: 991.98px) {
    padding: 0px;
  }
`;
export const Box = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 30px 40px;
  margin-top: 36px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
  @media screen and (max-width: 991.98px) {
    padding: 20px 30px;
    border-radius: 9px;
    justify-content: space-between !important;
    margin-top: 22px;
  }
`;

export const Img_div = styled.div`
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100px;
  }
  @media screen and (max-width: 991.98px) {
    width: 82px;
    height: 82px;
    img {
      max-width: 50px;
    }
  }
`;
export const Content_div = styled.div`
  padding-left: 30px;
  width: calc(100% - 115px);
  @media screen and (max-width: 991.98px) {
    padding-left: 24px;
    width: calc(100% - 82px);
    max-width: 240px;
    & button {
      max-width: 145px;
    }
  }
`;
export const ContentHead = styled.div`
  color: #000;
  font-size: 20.014px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  @media screen and (max-width: 991.98px) {
    font-size: 15px;
    margin-bottom: 5px;
  }
`;
export const ContentPara = styled.div`
  color: #ff9c00;
  font-size: 16.011px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  @media screen and (max-width: 991.98px) {
    font-size: 12px;
    margin-bottom: 12px;
  }
`;
