import React from 'react'
import * as S from "../../css/SignUp/RegularUser.style"
import * as A from "../../css/login/login";
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import CardButton from '../../componant/buttons/cardButton'
import { BtnLightYellow } from '../../css/components/button/buttonYellow';
import AccordianRegularUser2 from './component/AccordianRegularUser2';
import { Link } from 'react-router-dom';
// 사용안하는 페이지 같음(RegularUser사용)
function RegularUser2() {
    return (
        <>
            <S.Wrapper>
                <Container>
                    <Row>
                        <Col md={12}>
                            <S.MainHeading>일반 회원가입</S.MainHeading>
                            <S.SubHeading>약관동의</S.SubHeading>
                            <S.SubPara>전체동의는 주식회사 체인버스의 서비스 동의를 포함하고 있습니다. </S.SubPara>
                            <S.SubPara>필수 동의사항 거부시 서비스 이용이 불가능합니다. </S.SubPara>
                            <S.AccordionDiv>
                                <S.AccordionHeading>
                                    <S.AccordionIcon>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </S.AccordionIcon>
                                    <S.AccordionValue>전체동의</S.AccordionValue>
                                </S.AccordionHeading>
                                <AccordianRegularUser2 />
                            </S.AccordionDiv>
                            <S.AccordionBtn_Div>
                            <Link to="/signup-regularuser" ><CardButton width={"390px"} height={"60px"} bg={"#FF9C00"} name="넘버로 회원가입" ></CardButton></Link>
                                <BtnLightYellow width="390px" mt="20px" color='#000' className="position-relative"><A.Message_icon src={process.env.PUBLIC_URL+"/img/message_icon.svg"} alt=""></A.Message_icon> 카카오 로그인</BtnLightYellow>
                            </S.AccordionBtn_Div>
                        </Col>
                    </Row>
                </Container>
            </S.Wrapper>
        </>
    )
}

export default RegularUser2