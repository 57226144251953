import React from 'react'
import Announcement from './Announcement'
import QA from './QA'
import FAQ from './FAQ'

function Customer({ activeTab }) {
    if (activeTab == 1) {
        return <Announcement />
    }
    else if (activeTab == 2) {
        return <QA />
    }
    else if (activeTab == 3) {
        return <FAQ />
    }
}

export default Customer