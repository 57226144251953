import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavItem } from "react-bootstrap";
import MobileFooter from "../../../componant/mobileFooter";
import {
  postNumbaAlarmList,
  postNumbaAlarmSel,
  postNumbaDelAlarm,
  postNumbaUpdAlarm,
} from "../../../api/mypage";
import { toast } from "wc-toast";
import moment from "moment";
import { header_mainData } from "../../../data/atom/mypage/mypage_atom";
import { useAtom } from "jotai";
import callMainData from "../../../utils/hooks/callMainData";

function NotificationInformation() {
  const [alarmList, setAlarmList] = useState([]);
  const [mainData, setMainList] = useAtom(header_mainData);

  const navigate = useNavigate();

  const callAlarmList = async () => {
    try {
      const response = await postNumbaAlarmList({});
      if (response === null) {
        return console.log("알림 조회에 실패했습니다.");
      }
      // console.log("response: ", response);
      setAlarmList(response?.resultList);
      callMainList();
    } catch (err) {
      console.log("알림 조회에 실패했습니다.");
    }
  };

  // API call - 마이넘버원 메인페이지
  const callMainList = async () => {
    const data = await callMainData();
    setMainList(data);
  };

  const checkAlarm = async (seq, comment) => {
    try {
      const response = await postNumbaUpdAlarm({ alarm_d_seq: seq });

      if (response?.code === 1000) {
        // 탭 변경과 네비게이션을 동시에 처리
        if (comment === "1:1문의 답변이 등록되었습니다.")
          navigate("/main-tab", {
            state: {
              main: 4, // 정보관리 탭으로 설정
              sub: "first", // 하위 탭을 'first'로 리셋(1:1 문의내역)
            },
          });
        callAlarmList();
      } else {
        toast.error("알림 확인에 실패했습니다.");
      }
    } catch (e) {
      toast.error("알림 확인에 실패했습니다.");
    }
  };

  const delAlarm = async (seq) => {
    try {
      const response = await postNumbaDelAlarm({ alarm_d_seq: seq });
      if (response?.code === 1000) {
        callAlarmList();
      } else {
        toast.error("알림 삭제에 실패했습니다.");
      }
    } catch (e) {
      toast.error("알림 삭제에 실패했습니다.");
    }
  };

  useEffect(() => {
    callAlarmList();
  }, []);

  return (
    <>
      <div className="content-container notification-information-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading ">
                <h1>알림보기</h1>
              </div>
            </div>
          </div>
          <div className="row cardDIv">
            <div className="col-md-12">
              {alarmList.length > 0 ? (
                alarmList.map((data, idx) => {
                  return (
                    <div className="cardSection" key={idx}>
                      <div className="d-flex justify-content-between">
                        <div
                          onClick={() => {
                            if (!data.confirm_dt) {
                              checkAlarm(data?.alarm_d_seq, data?.comment);
                            }
                          }}
                          style={{
                            pointerEvents: data.confirm_dt ? "none" : "auto",
                            cursor: data.confirm_dt ? "none" : "pointer",
                          }}
                        >
                          <p
                            className="font16"
                            style={{
                              color: data.confirm_dt ? "#ccc" : "initial",
                            }}
                          >
                            {data?.comment}
                          </p>
                          <span>
                            {moment(data?.reg_dt).format("YYYY.MM.DD")}
                          </span>
                        </div>
                        <div className="align-self-center">
                          <img
                            role="button"
                            src="/img/cross.png"
                            alt=""
                            onClick={() => {
                              delAlarm(data?.alarm_d_seq);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="mypage_wrapper top-border mt-0">
                  <div className="container">
                    <div className="card_part">
                      <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                        알림이 없습니다.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MobileFooter main={true} />
    </>
  );
}

export default NotificationInformation;
