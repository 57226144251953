import React, { useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import MobileFooter from "../../componant/mobileFooter";
import { headerText } from "../../App";
import { useAtom } from "jotai";
import { archiveDetailPageApi } from "../../api/archive";
import { DUMMY_DATA } from "../../searchData/searchData";

import useWindowSize from "../../utils/hooks/useWindowSize";
import useTable from "../../utils/hooks/useTable";
import { Pagination } from "react-bootstrap";
import axios from "axios";
export default function ArchiveView({ children }) {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [activeTab, setActiveTab] = useState(1);
  const [postData, setPostData] = useState({});
  const [files, setFiles] = useState([]);

  const location = useLocation();

  useEffect(() => {
    // if (location?.state) {
    //   setActiveTab(location?.state);
    // } else {
    //   setActiveTab(1);
    // }
    setHeaderTextAtom("자료실 상세보기");
  }, [location?.state]);

  const { width } = useWindowSize();
  const {
    dense,
    order,
    page,
    totalPage,
    orderBy,
    rowsPerPage,
    selected,
    visiblePages,
    onSort,
    onSelectRow,
    onSelectAllRow,
    onChangePage,
    onChangeTotalPage,
    onChangeDense,
    onChangeRowsPerPage,
    // setTotalPage,
  } = useTable({
    defaultDense: "30px",
    defaultOrder: "name",
    defaultOrderDirection: "asc",
    defaultCurrentPage: 1,
    defaultRowsPerPage: 10,
    defaultSelected: {},
    mobileBreakpoint: 768, // px 붙이지 말 것
    maxPagesMobile: 10,
    maxPagesDesktop: 10,
    paginationType: "dynamic",
    Pagination,
  });

  const { id } = useParams();

  const navigate = useNavigate();

  // const [currentPage, setCurrentPage] = useState(0);

  const [pageNumbers, setPageNumbers] = useState([]);

  const dummyData = DUMMY_DATA(200);

  // 파일 가져오는 함수

  const getFiles = useCallback(async (fileSeq) => {
    const access_token = localStorage.getItem("accessToken");

    // const data = { file_seq: 859 }; // 다운로드 테스트용

    const data = { file_seq: fileSeq };

    // console.log("data: ", data);

    axios
      .post("/comm/getCommFile", data, {
        headers: {
          Accept: "application/json;charset=utf-8",
          Authorization: `Bearer ${access_token}`,
        },
        // 응답으로 오는 데이터를 blob 데이터로 자동 변환
        responseType: "blob",
      })
      .then((response) => {
        // 성공적으로 응답을 받은 시점에서 이미 JS의 메모리에 파일이 저장된 것임. 사용자가 다운로드를 취소했다고 해도 브라우저 자체, 페이지, 탭이 닫히기 전에는 메모리를 차지함.
        // console.log("response: ", response);

        const contentDisposition = response.headers["content-disposition"]; // content-disposition: "inline; filename*=UTF-8''Example%20File.png" :utf-8으로 파일명이 인코딩 돼 있음.
        const filename = contentDisposition.match(/filename\*=UTF-8''(.+)/)[1]; // [0]은 filename*=UTF-8''Example%20File.png, [1]은 Example%20File.png
        const decodedFilename = decodeURIComponent(filename); // 인코딩 된 파일명을 원래 이름으로 디코딩 : Example%20File.png ==> 'Example File.png

        const contentType = response.headers["content-type"]; // content-type:"image/png"
        const blob = new Blob([response.data], { type: contentType }); // data : Blob {size: 47605, type: 'image/png'}

        //Blob에서 생성된 데이터에 대한 URL을 만든다.
        // 이 URL은 브라우저 메모리에 저장된 데이터를 가리키며, <img>, <video> 태그에서 사용하거나 파일을 다운로드하는 데 사용할 수 있다. 이 URL은 브라우저 세션이 끝날 때까지 유효하다.
        const previewUrl = URL.createObjectURL(blob);

        // 파일 다운로드 링크 생성 및 클릭 이벤트 트리거
        const downloadLink = document.createElement("a"); // a 태그 생성
        downloadLink.href = previewUrl; // 해당 a태그에 생성한 url 연결
        downloadLink.setAttribute("download", decodedFilename); // 다운로드될 파일 이름 설정
        document.body.appendChild(downloadLink); // html 페이지에 보이지 않게 부착
        downloadLink.click(); // 해당 a태그 자동 클릭. 사용자가 메모리에 있는 파일을 로컬 파일 시스템에 저장할지 여부를 선택함.
        document.body.removeChild(downloadLink); // 페이지의 body에서 a태그 제거

        // 파일 정보 상태 업데이트 (선택적)
        setFiles((prevFiles) => [
          ...prevFiles,
          { fileName: decodedFilename, previewUrl },
        ]);
      })
      .catch((error) => {
        alert("파일 다운로드에 실패했습니다.");
        // console.error(error);
      });
  }, []);

  // ------------------------------------------------------------------------------------------------

  // useEffect(() => {
  //   console.log("files: ", files);
  // }, []);

  const fecthData = useCallback(async ({}) => {
    try {
      // alert("fetchData");
      const data = { data_seq: id };
      const response = await archiveDetailPageApi(data);
      // console.log("archive response: ", response);
      if (response.code === 1000) {
        // console.log("response from archiveView:", response);
        setPostData(response.result);
      }
    } catch (error) {
      // console.error(error);
      alert("상세 정보를 가져오지 못했습니다.");
    }
  }, []);

  useEffect(() => {
    fecthData({});
  }, []);

  return (
    <>
      {/* customer_tab 상단 네비게이션바와 위 아래 간격 주는 div */}
      <div className="customer_tab viewPrice-tab bg-white">
        {/* max-width: 1080px; width: calc(100% - 56px) */}
        <div className="container">
          <div className="main-heading d-md-block d-none">
            <h3 className="text-left">자료실 상세보기</h3>
          </div>
          <hr className="information-hr pc_view" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>{postData?.title}</div>
            {postData?.reg_dt}
          </div>

          {/* <hr className="information-hr mobile_view" /> */}

          <hr />
          <div
            style={{
              padding: "2em 0",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            {postData?.contents}

            {/* {postData?.contents?.split("\n").map((line) => (
              <p>{line}</p>
            ))} */}
          </div>
          <hr />
          <div className="list-container">
            <div>
              {postData?.files?.length > 0 && (
                <>
                  {postData.files.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => getFiles(item?.file_seq)}
                      style={{
                        fontSize: "0.8rem",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      {item.file_org_nm}
                    </div>
                  ))}
                  <hr />
                </>
              )}
            </div>

            <div className="pagination">
              <div
                style={{ display: "flex ", cursor: "pointer" }}
                onClick={() => navigate("/archive")}
              >
                목록으로
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobileFooter main={true} />
    </>
  );
}

// import React, { useCallback, useMemo } from "react";
// import { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import MobileFooter from "../../componant/mobileFooter";
// import { headerText } from "../../App";
// import { useAtom } from "jotai";
// import { archiveListApi } from "../../api/archive";
// import { DUMMY_DATA } from "../../searchData/searchData";
// // import { DUMMY_DATA } from "../../searchData/searchData";

// export default function Archive({ children }) {
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   // 리사이즈 이벤트 핸들러
//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     // 리사이즈 이벤트 리스너 추가
//     window.addEventListener("resize", handleResize);

//     // 클린업 함수
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   // const MAX_VISIBLE_PAGES = 10;
//   const MAX_VISIBLE_PAGES = windowWidth < 768 ? 5 : 10; // 예를 들어 768px 아래면 모바일로 가정

//   const HALF_VISIBLE_PAGES = Math.floor(MAX_VISIBLE_PAGES / 2);

//   const [tableData, setTableData] = useState([]);
//   const [totalPage, setTotalPage] = useState(1);
//   const [currentPage, setCurrentPage] = useState(0);

//   const [pageNumbers, setPageNumbers] = useState([]);

//   const dummyData = DUMMY_DATA(200);

//   const fecthData = useCallback(async ({}) => {
//     try {
//       // alert("fetchData");
//       const data = {};
//       const response = await archiveListApi(data);
//       console.log("archive response: ", response);
//       if (response.code === 1000) {
//         // 추후 더미데이터 제거
//         // setTableData(response.resultList);
//         setTableData([...dummyData, ...response.resultList]);

//         // 추후 더미데이터 길이 제거
//         // const totPage = Math.ceil(response.totCnt / 10)

//         const totPage = Math.ceil((dummyData.length + response.totCnt) / 10);
//         setTotalPage(totPage);
//         const pages = Array.from({ length: totPage }, (_, i) => i + 1);
//         setPageNumbers(pages);
//       }
//     } catch (error) {
//       console.error(error);
//       alert("자료실 정보를 가져오지 못했습니다.");
//     }
//   }, []);

//   useEffect(() => {
//     fecthData({});
//   }, []);

//   useEffect(() => {
//     console.log(tableData);
//   }, [tableData]);

//   // 페이지 이동 처리 함수
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   // 동적 윈도우 계산
//   // 현재 페이지와 총 페이지 수에 따라 보여줄 페이지 번호들을 계산
//   const visiblePages = useMemo(() => {
//     let startPage = Math.max(currentPage - HALF_VISIBLE_PAGES, 1);
//     let endPage = startPage + MAX_VISIBLE_PAGES - 1;

//     if (endPage > totalPage) {
//       endPage = totalPage;
//       startPage = Math.max(totalPage - MAX_VISIBLE_PAGES + 1, 1);
//     }

//     return pageNumbers.slice(startPage - 1, endPage);
//   }, [currentPage, totalPage, pageNumbers, windowWidth]);

//   return (
//     <>
//       {/* customer_tab 상단 네비게이션바와 위 아래 간격 주는 div */}
//       <div className="customer_tab viewPrice-tab bg-white">
//         {/* max-width: 1080px; width: calc(100% - 56px) */}
//         <div className="container">
//           <div className="main-heading d-md-block d-none">
//             <h1 className="text-center">자료실</h1>
//           </div>
//           <hr className="information-hr pc_view" />

//           <div className="list-container">
//             <ul className="responsive-list border-top-2px">
//               {/* <li class="table-header list-item">
//                 <div class="col col-1">작성일</div>
//                 <div class="col col-2">제목</div>
//                 <div class="col col-3">파일</div>
//               </li> */}
//               {tableData.map((item) => {
//                 return (
//                   <li className="list-item" key={item.data_seq}>
//                     <span className="date">{item.reg_dt}</span>
//                     <span className="text">{item.title}</span>
//                     <span className="icon">{item.files.length}📄</span>
//                   </li>
//                 );
//               })}
//             </ul>
//             {/* {totalPage} */}
//             {/* <div className="pagination">페이지네이션</div> */}
//             <div className="pagination">
//               <span
//                 onClick={() => handlePageChange((prev) => 1)}
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   fontSize: "1rem",
//                   padding: "0.5em",
//                 }}
//               >
//                 &lt;&lt;
//               </span>
//               <span
//                 onClick={() =>
//                   handlePageChange((prev) => {
//                     const prevPage = prev - 1;
//                     if (prevPage < 1) {
//                       return prev;
//                     }
//                     return prevPage;
//                   })
//                 }
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   fontSize: "1rem",
//                   padding: "0.5em",
//                   marginLeft: "0.5em",
//                 }}
//               >
//                 &lt;
//               </span>

//               {visiblePages.map((pageNo) => (
//                 <>
//                   <div
//                     style={{
//                       backgroundColor:
//                         pageNo === currentPage ? "#FFDD00" : "transparent",
//                       padding: "0.5em",
//                       margin: "0.5em",
//                       width: "2.5em",
//                       height: "2.5em",
//                       borderRadius: "50%",
//                       textAlign: "center",
//                     }}
//                   >
//                     <button
//                       className={pageNo === currentPage ? "active" : ""}
//                       key={pageNo}
//                       onClick={() => handlePageChange(pageNo)}
//                     >
//                       {pageNo}
//                     </button>
//                   </div>
//                 </>
//               ))}
//               <span
//                 onClick={() =>
//                   handlePageChange((prev) => {
//                     const nextPage = prev + 1;
//                     if (nextPage > totalPage) {
//                       return prev;
//                     }
//                     return nextPage;
//                   })
//                 }
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   fontSize: "1rem",
//                   padding: "0.5em",
//                   marginRight: "0.5em",
//                 }}
//               >
//                 &gt;
//               </span>
//               <span
//                 onClick={() => handlePageChange((prev) => totalPage)}
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   fontSize: "1rem",
//                   padding: "0.5em",
//                 }}
//               >
//                 &gt;&gt;
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <MobileFooter main={true} />
//     </>
//   );
// }
