import { isMobile } from "react-device-detect";

export const getFcmCommunication = () => {
  console.log(">>> isMobile [" + isMobile + "]");
  if (isMobile) {
    const accessToken = localStorage.getItem("access_token");

    console.log(">>>>> accessToken [" + accessToken + "]")
    console.log(">>>>> fcmToken [" + localStorage.getItem("fcmToken") + "]")
    if (accessToken) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: "ACCESS_TOKEN",
          data: {
            accessToken: accessToken,
          },
        }),
      );
    }
  } else {
    return "not available";
  }
};
