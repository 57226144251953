import React, { useEffect, useState, useRef } from "react";
import * as A from "../../css/login/login";
import { Container, Row, Col } from "react-bootstrap";
import {
  BtnLightYellow,
  BtnYellow,
} from "../../css/components/button/buttonYellow";
import { postNumbaLogin } from "../../api/auth";
import { toast } from "wc-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { headerText, userLoginJotai } from "../../App";
import Modal from "react-bootstrap/Modal";
import { scrollToTop } from "../../utils/hooks/scrollToTop";
import { getFcmCommunication } from "../../utils/hooks/app-communication/webToApp/getFcm";

export default function Login() {
  const navigate = useNavigate();
  const idRef = useRef(null);
  const isIOS = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
  const access_token = localStorage.getItem("access_token");
  const [accessToken, setAccessToken] = useState("");
  const [userLoginAtom, setUserLoginAtom] = useAtom(userLoginJotai);
  const [loginState, setLoginState] = useState({
    user_id: "",
    passwd: "",
  });
  const [show, setShow] = useState(true);

  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  // const { refreshTimeOut } = useRefresh();

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (access_token) {
      navigate("/");
    }
    scrollToTop();
    setHeaderTextAtom("로그인");
    idRef.current.focus();

    // 아이디 저장 추가
    const savedUserId = localStorage.getItem('user_id');
    if (savedUserId) {
      setLoginState((prevProps) => ({
        ...prevProps,
        user_id: savedUserId,
      }))
      setIsChecked(true);
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);    
  };

  const handleLogin = async () => {
    if (loginState.user_id === "" || loginState.passwd === "") {
      toast.error("아이디 또는 비밀번호를 입력 해 주세요.");
      return;
    } else {
      try {
        console.log("login access_token [" + localStorage.getItem("access_token") + "]")
        const response = await postNumbaLogin(loginState);

        if (response.code > 0) {
          setUserLoginAtom(response);
          localStorage.setItem("access_token", response?.access_token);
          localStorage.setItem("refresh_token", response?.refresh_token);
          localStorage.setItem("device_id", response?.device_id);
          if(isChecked){
            localStorage.setItem('user_id', loginState.user_id);
          }else{
            localStorage.removeItem('user_id');
          }

          console.log("login access_token [" + localStorage.getItem("access_token") + "]");
          // setTimeout(function () {
          //    console.log("인증완료");
          //  }, 1000);
           getFcmCommunication();
          setTimeout(function () {
            navigate("/");
          }, 2000);

        } else {
          switch (response.code) {
            case -12:
              toast.error("일치하는 유저가 없습니다.");
              break;
            case -13:
              toast.error("아이디 또는 비밀번호가 일치하지 않습니다.");
              break;
            case -14:
              toast.error("로그인 횟수를 초과했습니다.");
              setTimeout(() => navigate("/find-password"), 1000);
              break;
          }
        }
      } catch (err) {
        switch (err.code) {
          case -10:
            toast.error("아이디를 입력해주세요");
            break;
          case -11:
            toast.error("비밀번호를 입력해주세요");
            break;
        }
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  // 카카오 로그인
  const KAKAO_URL =
    process.env.REACT_APP_KAKAO_HOST_URL +
    "redirect_uri=" +
    process.env.REACT_APP_KAKAO_REDIRECT_URL;

  return (
    <>
      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        fullscreen={"sm-down"}
        dialogClassName="my_modal modal-600px"
      >
        <Modal.Header closeButton>
          <Modal.Title>알려드립니다</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: 20, marginBottom: 30, textAlign: "center" }}>
            기존 회원이신 분들은 <br />
            <span
              onClick={() => handleNavigate("/find-password")}
              style={{ fontSize: 20, cursor: "pointer", color: "#FF9900" }}
            >
              비밀번호 찾기
            </span>
            를 클릭하여
            <br /> 비밀번호를 변경해주세요.
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-primary w-50 h-60px h-sm-50px mt-30px f-sm-16"
              onClick={() => setShow(false)}
            >
              확인
            </button>
          </div>
        </Modal.Body>
      </Modal> */}

      <A.ContentWrapper_div>
        <Container>
          <div style={{ fontSize: 15, marginBottom: 30 }}>
            기존 회원이신 분들은{" "}
            <span
              onClick={() => handleNavigate("/find-password")}
              style={{ cursor: "pointer", color: "#FF9900", fontSize: 20 }}
            >
              비밀번호 찾기
            </span>
            를 눌러 비밀번호를 변경해주세요.
          </div>
          <Row>
            <Col md={12}>
              <A.bodyLogoBrand>
                <img src={process.env.PUBLIC_URL + "/img/logo.svg"} alt="" />
              </A.bodyLogoBrand>
              <A.LoginInput_Wrapper>
                <A.LoginInput
                  placeholder="아이디를 입력하세요"
                  name="user_id"
                  ref={idRef}
                  value={loginState.user_id}
                  onChange={handleInputChange}
                />
                <A.LoginInput
                  type="password"
                  placeholder="비밀번호를 입력하세요"
                  name="passwd"
                  onChange={handleInputChange}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                <BtnYellow onClick={() => handleLogin()} className="mt-3">
                  로그인
                </BtnYellow>
                <div className="CustomCheckerBox" >
                  <div className="mt-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                    />
                    <label
                      className="form-check-label f-medium"
                      htmlFor="inlineCheckbox7"
                    >
                      아이디 저장
                    </label>
                  </div>
                </div>
                {!isIOS && (
                  <>
                    <div>
                      <A.LoginOr_Txt> 또는</A.LoginOr_Txt>
                    </div>
                    <a href={KAKAO_URL}>
                      <BtnLightYellow
                        // onClick={kakaoLogin}
                        color="#14090780"
                        className="position-relative"
                      >
                        <A.Message_icon
                          src={process.env.PUBLIC_URL + "/img/message_icon.svg"}
                          alt=""
                        />
                        카카오 로그인
                      </BtnLightYellow>
                    </a>
                  </>
                )}
                <A.login_SpanWrapper>
                  <A.Login_SpanOne className="pe-4">
                    <Link className="LoginLink" to={"/find-id"}>
                      아이디 찾기
                    </Link>
                  </A.Login_SpanOne>
                  <A.Login_SpanTwo className="ps-4">
                    <Link className="LoginLink" to={"/find-password"}>
                      비밀번호 찾기
                    </Link>
                  </A.Login_SpanTwo>
                </A.login_SpanWrapper>
                <A.LoginBottom_Para>
                  <div>아직 넘버로 회원이 아니세요?</div>
                </A.LoginBottom_Para>
                <A.LoginBottom_ParaYellow>
                  <Link className="LoginLink" to="/signup">
                    회원가입
                  </Link>
                </A.LoginBottom_ParaYellow>
              </A.LoginInput_Wrapper>
            </Col>
          </Row>
        </Container>
      </A.ContentWrapper_div>
    </>
  );
}
