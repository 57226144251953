import React, { useEffect, useState } from "react";
import * as A from "../../css/FindId/findId.style";
import { Container, Row, Col } from "react-bootstrap";
import { BtnYellow } from "../../css/components/button/buttonYellow";
import { Link, useNavigate } from "react-router-dom";
import { postNumbaFindId } from "../../api/auth";
import { toast } from "wc-toast";
import { useAtom } from "jotai/index";
import { headerText } from "../../App";

export default function FindId() {
  const navigate = useNavigate();
  const [reqObj, setReqObj] = useState({
    user_nm: "",
    phone: "",
  });

  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);

  useEffect(() => {
    setHeaderTextAtom("아이디 찾기");
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReqObj((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const handleFindId = async () => {
    if (!reqObj.user_nm) {
      return toast.error("이름을 입력하세요");
    }
    if (!reqObj.phone) {
      return toast.error("휴대폰 번호를 입력하세요");
    }
    if (!/^\d{10,11}$/.test(reqObj.phone)) {
      return toast.error("휴대폰번호를 확인해 주세요.");
    }
    try {
      const response = await postNumbaFindId(reqObj);
      if (response.code === 1000) {
        toast.success("휴대폰 문자로 아이디를 발송하였습니다.");
        navigate("/login");
      } else {
        return toast.error("문자발송에 실패");
      }
    } catch (e) {
      return toast.error("아이디 찾기 실패");
    }
  };
  return (
    <>
      <A.ContentWrapper_div>
        <Container>
          <Row>
            <Col md={12}>
              <A.bodyLogoBrand>
                <img src={process.env.PUBLIC_URL + "/img/logo.svg"} alt="" />
              </A.bodyLogoBrand>
              <A.IdInput_Wrapper>
                <A.IdInput
                  placeholder="이름을 입력하세요"
                  name={"user_nm"}
                  onChange={handleInputChange}
                />
                <A.IdInput
                  placeholder="휴대폰 번호를 입력하세요('-' 없이 입력)"
                  name={"phone"}
                  onChange={handleInputChange}
                />
                <BtnYellow className="mt-3" onClick={handleFindId}>
                  아이디 찾기
                </BtnYellow>
                <A.FindId_SpanWrapper>
                  <A.Login_SpanOne className="pe-4">
                    <Link className="LoginLink" to={"/find-password"}>
                      비밀번호 찾기
                    </Link>
                  </A.Login_SpanOne>
                  <A.Login_SpanTwo className="ps-4">
                    <Link className="LoginLink" to="/signup">
                      회원가입
                    </Link>
                  </A.Login_SpanTwo>
                </A.FindId_SpanWrapper>
              </A.IdInput_Wrapper>
            </Col>
          </Row>
        </Container>
      </A.ContentWrapper_div>
    </>
  );
}
