import styled from "styled-components";

export const BtnYellow = styled.button`
  background-color: #ff9d2f;
  max-width: 100%;
  height: 60px;
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  width: ${(props) => (props.width ? props.width : "100%")};
  color: #fff;
  font-size: 19.997px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-radius: 6px;
  @media only screen and (max-width: 767.98px) {
    height: 50px;
    padding: 12px 24px;
    font-size: 15px;
  }
`;
export const BtnLightYellow = styled.button`
  background-color: #ffdc13;
  max-width: 100%;
  height: 60px;
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  width: ${(props) => (props.width ? props.width : "100%")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: 19.997px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-radius: 6px;
  @media only screen and (max-width: 767.98px) {
    height: 56px;
    padding: 12px 24px;
    font-size: 15px;
  }
`;
