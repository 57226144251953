import styled from "styled-components";

export const FormHeading_div = styled.div`
  border-bottom: 1px solid #e1e1e1;
`;
export const Form_Heading = styled.h1`
  color: #000;
  font-size: 35px;
  font-style: normal;
  font-family: "NotoSansKR-Bold";
  letter-spacing: 4px;
  margin-bottom: 10px;
  line-height: normal;
  letter-spacing: 2.6px;
  @media screen and (max-width: 592px) {
    font-size: 25px;
    letter-spacing: -0.85px;
    margin-top: 20px;
  }
`;

export const FormDetails_Heading = styled.h3`
  color: #000;
  font-size: 24.01px;
  line-height: normal;
  font-family: "NotoSansKR-Regular";
  letter-spacing: 2px;
  margin-bottom: 20px;
  @media screen and (max-width: 592px) {
    font-size: 17px;
    letter-spacing: -0.85px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
`;
export const FormTable_Th = styled.th`
  background-color: #f5f5f5 !important;
  color: #888 !important;
  font-size: 16.01px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: ${(props) => (props.width ? props.width : "auto")};

  @media screen and (max-width: 768px) {
    width: ${(props) => (props.width ? "33.33%" : "auto")};
  }
`;
export const Td_Number = styled.h3`
  color: #4e92e1;
  font-size: 22.01px;
  font-weight: 700;
  line-height: normal;
  margin-right: 5px;
  margin-bottom: 0;
  display: inline-block;
`;
export const Table_Link = styled.a`
  color: #4e92e1;
  font-size: 22.01px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
`;
export const Para_Content = styled.p`
  color: #f60;
  font-family: "Malgun Gothic";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
`;
