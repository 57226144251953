import React, { useEffect, useState } from "react";
import { noticeList } from "../../api/customerCenter";
import { toast } from "wc-toast";
import moment from "moment";

function Announcement() {
  const [readItems, setReadItems] = useState([0]);
  const [notice, setNotice] = useState([]);

  const handleCheckRead = (index) => {
    if (!readItems.includes(index)) {
      const newItems = [...readItems, index];
      setReadItems(newItems);
    }
  };

  const checkReadAnnouncement = (index) => {
    return readItems.includes(index);
  };

  const callNoticeList = async () => {
    const response = await noticeList({});
    try {
      if (response?.code === 1000) {
        setNotice(response?.resultList);
      } else {
        return console.log("공지사항 불러오는데 실패했습니다.");
      }
    } catch (err) {
      return console.log("공지사항 불러오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    callNoticeList();
  }, []);

  return (
    <div className="customer-service">
      {notice.length > 0 ? (
        notice.map((data, idx) => {
          return (
            <div className="accordion" id="accordionExample">
              <div
                className="accordion-item"
                onClick={() => handleCheckRead(idx)}
              >
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button collapsed`}
                    type="button"
                    data-bs-toggle="collapse"
                    // data-bs-target="#collapseOne"
                    data-bs-target={`${"#collapse" + idx}`}
                    aria-expanded="false"
                    aria-controls={`${"collapse" + idx}`}
                  >
                    <div className="d-flex flex-column">
                      <div className="btn-text">{data?.title}</div>
                      <div className="btn-date">
                        {data?.reg_d
                          ? moment(data?.reg_dt).format("YYYY.MM.DD")
                          : ""}
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={`${"collapse" + idx}`}
                  className={`accordion-collapse collapse`}
                  data-bs-parent="#accordionExample"
                >
                  {/* <div className="under-accordian">{data?.contents}</div> */}
                  <div className="under-accordian">
                    {data?.contents?.split("\n").map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="mypage_wrapper ">
          <div className="container">
            <div className="card_part">
              <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                공지사항이 없습니다.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Announcement;
