import styled from "styled-components";

export const FooterTop_Stripe = styled.div`
padding-top: 22px;
padding-bottom: 22px;
border-top: 1px solid #cdcdcd;
border-bottom: 1px solid #cdcdcd;
`
export const FooterTop_Link = styled.a`
 font-size: 13px;
  font-family: 'NotoSansKR-Medium';
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: -0.65px;
  text-align: left;
  color: #666;
text-decoration: none;
cursor: pointer;
`

export const FooterBottom_Span = styled.a`
font-size: 13px;
    font-family: 'NotoSansKR-Medium';
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -0.98px;
  text-align: left;
  color: #666;
text-decoration: none;
cursor: pointer;
`
export const FooterBottom_SpanBorder = styled.a`
font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -0.98px;
  text-align: left;
  color: #666;
border-left: 1px solid #cdcdcd;
text-decoration: none;
cursor: pointer;
`