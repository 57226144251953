import React from "react";
import QADetailsPage from "./QADetails";
import EditMember from "./EditMember/EditMember";
import WithdrawSection from "./Withdraw";

function QADetails({ subActiveTab }) {
  if (subActiveTab == "first") {
    return <QADetailsPage />;
  } else if (subActiveTab == "second") {
    return <EditMember />;
  } else if (subActiveTab == "third") {
    return <WithdrawSection />;
  }
}

export default QADetails;
