import React from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import CardButton from "../../../componant/buttons/cardButton";
const BrokerageLicenseSellingForm = ({ activeTab }) => {
  if (activeTab === 1) {
    return (
      <>
        <div className="py-5">
          <Container>
            <Row>
              <Col md={12}>
                <div>
                  <R.FormHeading_div>
                    <R.Form_Heading>구매신청</R.Form_Heading>
                  </R.FormHeading_div>
                </div>
                <div className="mt-5">
                  <R.FormDetails_Heading>매물정보</R.FormDetails_Heading>
                  <R.FormDetails_Div>
                    {/* desktop table */}
                    <div className="">
                      <Table bordered responsive>
                        <tbody>
                          <tr>
                            <R.FormTable_Th width="25%">유형</R.FormTable_Th>
                            <td>
                              <div className="d-flex gap-0 gap-md-3">
                                <Form.Check
                                  inline
                                  label="개인넘버"
                                  name="group1"
                                  type="radio"
                                  className="CustomCheckbox"
                                />
                                <Form.Check
                                  inline
                                  label="법인넘버"
                                  name="group1"
                                  type="radio"
                                  className="CustomCheckbox"
                                />
                                <Form.Check
                                  inline
                                  label="주선면허"
                                  name="group1"
                                  type="radio"
                                  className="CustomCheckbox"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              차량연식
                            </R.FormTable_Th>
                            <td>
                              {" "}
                              <div className="d-flex gap-0 gap-md-3">
                                <Form.Check
                                  inline
                                  label="매매"
                                  name="group1"
                                  type="radio"
                                  className="CustomCheckbox"
                                />
                                <Form.Check
                                  inline
                                  label="임대"
                                  name="group1"
                                  type="radio"
                                  className="CustomCheckbox"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              판매금액
                            </R.FormTable_Th>
                            <td>
                              <R.Table_Input width="25%"></R.Table_Input>{" "}
                              <R.Btn_Value
                                className="d-none d-lg-inline-block"
                                color="#FF6600"
                              >
                                ※ 넘버등록을 클릭후 넘버정보를 등록하세요.
                              </R.Btn_Value>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {/* mobile table */}
                    <div className="d-none">
                      <Table bordered responsive>
                        <tbody>
                          <tr>
                            <R.FormTable_Th width="25%">유형</R.FormTable_Th>
                            <td>개인넘버</td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              차량연식
                            </R.FormTable_Th>
                            <td>2022년 12월</td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">톤</R.FormTable_Th>
                            <td>2.5~4.5 톤</td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              판매자 지역
                            </R.FormTable_Th>
                            <td>인천</td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              판매금액
                            </R.FormTable_Th>
                            <td colSpan={3}>
                              <R.Table_Link>4500</R.Table_Link>
                              &nbsp; 만원
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </R.FormDetails_Div>
                </div>
                <div className="mt-5">
                  <div className="d-flex justify-content-between">
                    <R.FormDetails_Heading>
                      판매자정보 등록
                    </R.FormDetails_Heading>
                    <div>
                      <Form.Check
                        inline
                        label="회원정보와 동일"
                        name="group1"
                        className="CustomCheckbox"
                        // type={type}
                        // id={`inline-${type}-1`}
                      />
                    </div>
                  </div>
                  <R.FormDetails_Div>
                    <div>
                      <Table bordered responsive>
                        <tbody>
                          <tr>
                            <R.FormTable_Th width="25%">법인명</R.FormTable_Th>
                            <td>
                              <R.Table_Input width="100%"></R.Table_Input>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              담당자 이름
                            </R.FormTable_Th>
                            <td>
                              <R.Table_Input></R.Table_Input>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              판매금액
                            </R.FormTable_Th>
                            <td>
                              <R.Table_Input></R.Table_Input>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              담당자 휴대폰번호
                            </R.FormTable_Th>
                            <td className="position-relative">
                              <R.TableInput_Search width="100%"></R.TableInput_Search>
                              <R.TableInput_Icon
                                src={
                                  process.env.PUBLIC_URL + "/img/SearchIcon.svg"
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              사업자등록증
                            </R.FormTable_Th>
                            <td className="position-relative">
                              <R.Table_Btn>파일선택</R.Table_Btn>{" "}
                              <R.Btn_Value>
                                ※ 사진 또는 이미지 파일로 제출해 주세요
                              </R.Btn_Value>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              화물자동차운송사업허가증
                            </R.FormTable_Th>
                            <td className="position-relative">
                              <R.Table_Btn>파일선택</R.Table_Btn>{" "}
                              <R.Btn_Value>
                                ※ 사진 또는 이미지 파일로 제출해 주세요
                              </R.Btn_Value>
                            </td>
                          </tr>
                          <tr>
                            <R.FormTable_Th width="25%">
                              요청내용
                            </R.FormTable_Th>
                            <td colSpan={3}>
                              <R.Table_Textarea rows={4}></R.Table_Textarea>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </R.FormDetails_Div>
                </div>
                <R.Para_Content>
                  ※ 임대금을 은행 에스크로 계좌에 입금 하시고 임대에 필요한
                  서류를 넘버로에 등기로 제출하시면 매매가 진행됩니다.
                </R.Para_Content>
                <div className="d-flex justify-content-center mt-4">
                  {" "}
                  <CardButton
                    width="390px"
                    bg="#C7C7C7"
                    name="다음"
                  ></CardButton>{" "}
                </div>
                {/* <div className='mt-3 text-center'>
                                    <GrayButton width="360px" name="다음" height="60px"></GrayButton>
                                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  } else if (activeTab === 2) {
    return (
      <Container>
        <h1>Hello 2</h1>
      </Container>
    );
  } else if (activeTab === 3) {
    return (
      <Container>
        <h1>Hello 3</h1>
      </Container>
    );
  }
  return null;
};

export default BrokerageLicenseSellingForm;
