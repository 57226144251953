import styled from "styled-components";

export const Header_Link = styled.a`
  font-size: ${(props) => (props.color ? "14px" : "17px")};
  font-weight: normal;
  font-stretch: normal;
  font-family: "NotoSansKR-Medium";
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.85px;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "#000")};
  text-decoration: none;
`;
export const Header_LinkBorder = styled.a`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.85px;
  text-align: left;
  color: #666;
  border-left: 1px solid #e1e1e1;
  text-decoration: none;
`;
export const HeaderWrapper_div = styled.div`
  display: inline-grid;
  width: 100%;
  .container {
    max-width: 1208px !important;
  }

  position: fixed;
  top: 0;
  z-index: 999;

  @media (max-width: 767.98px) {
    border: 0 solid red;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
`;
export const Header_Container = styled.div`
  max-width: 1184px;
`;
export const TopStripe_Header = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  padding-top: 14px;
  padding-bottom: 9px;
  text-align: end;
  /* height: 50px; */
`;
export const TopStripe_Link = styled.a`
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  text-decoration: none;
  letter-spacing: 0.7px;
  cursor: pointer;
  &.f-orange {
    color: #ff7200;
    font-family: "NotoSansKR-Bold";
  }
`;

export const TopStripe_Hi = styled.div`
  color: #888;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  text-decoration: none;
  letter-spacing: 0.7px;
  & span {
    color: #000;
    font-family: "NotoSansKR-Bold";
  }
`;
