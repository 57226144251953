import React from "react";
import styled from "styled-components";

// ----------------------------------------------------------------------

export default function Sidebar() {

    return (
        // <S.Box>
        //     <div className="pc_view">
        //         <img
        //             src={
        //                 process.env.PUBLIC_URL + "/img/textSidebar_s.png"
        //             }
        //             alt=""
        //         />
        //     </div>
        // </S.Box>
        <div className="pc_view">
            <S.Box2>
                <S.Content_div>
                    <S.ContentHead>넘버로 고객센터</S.ContentHead>
                    <S.ContentMain>02-2276-0601</S.ContentMain>
                    <S.ParaHead>고객센터 업무시간</S.ParaHead>
                    <S.Para>평일 09:00~18:00<br />토/일/공휴일 휴무</S.Para>
                </S.Content_div>
            </S.Box2>
        </div>
    );
}

// ----------------------------------------------------------------------

const S = {
    Box: styled.div`
        width: 150px;
        height: 225px;
        border-radius: 20px;
        position: fixed;
        right: 0px;
        bottom: 300px;
        z-index: 9999;
        @media screen and (max-width: 1170px) {
            img {
                width: 80%;
            }
            right: -30px;
            bottom: 250px;
        }
     `,
    Box2: styled.div`
        width: 140px;
        height: 207px;
        background: #fff;
        border-radius: 17px;
        position: fixed;
        right: 1px;
        top:180px;
        // bottom: 300px;
        z-index: 9999;  
        background-image: url(${process.env.PUBLIC_URL}/img/textSidebar_bg_noStroke.png);  
        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1)); 
        @media screen and (max-width: 1170px) {
            width: 126px;
            height: 186px;
            right: 1px;
            top:180px;
        }
    `,
    Content_div: styled.div`
        padding: 15px 10px;
        width:  140px;  
        @media screen and (max-width: 1170px) {
            width: 126px;
        }
    `,
    ContentHead: styled.div`
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
        @media screen and (max-width:1170px) {
            font-size: 15px;
        }
    `,
    ContentMain: styled.div`
        color: #ff7200;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 16px;
        padding-bottom: 16px;
        @media screen and (max-width: 1170px) {
            font-size: 21px;
            padding-top: 18px;
        }
    `,
    ParaHead: styled.p`
        color: #000;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 11px;
        margin-bottom: 9px;
        @media screen and (max-width: 1170px) {
            font-size: 12px;
            margin-top: 11px;
            margin-bottom: 4px;
        }
    `,
    Para: styled.p`
        color: #777;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 1170px) {
            font-size: 11px;
        }
    `,
};


