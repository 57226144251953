import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table, Form, Button } from "react-bootstrap";
// import * as R from "../../css/PersonalSellingRequest/requestForm";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { scrollToTop } from "../../../utils/hooks/scrollToTop";
import moment from "moment";
// import getUserInfo from "../../../../../utils/hooks/getUserInfo";
import { headerText } from "../../../App";
import { useAtom } from "jotai";
import { BtnYellow } from "../../../css/components/button/buttonYellow";
import CardButton from "../../../componant/buttons/cardButton";
import { postUpdReport, postGetSignFile } from "../../../api/sale";
import { postNumbaSelUserInfo } from "../../../api/auth";
import { toast } from "wc-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Signature from "../../../componant/Signature";
import { CommonData, CommonData2 } from "./CommonData";
import { PersonInfo } from "./PersonInfo";
// import CommonRegistration from "../registration-information/CommonRegistration";

// 서명
export const SignatureInfo = (props) => {
  const { reportReqData, setSaveFile, doneSignFileUrl } = props;  
  const [open, setOpen] = useState(false);
  console.log('doneSignFileUrl', doneSignFileUrl);

  const [dataURL, setDataURL] = useState(doneSignFileUrl ? doneSignFileUrl : '');
  const [isSigned, setIsSigned] = useState(!!doneSignFileUrl);
  const [isSaved, setIsSaved] = useState(false);  

  const handleOpen = () => {
    setIsSaved(false);
    setIsSigned(false);
    setDataURL("");
    setSaveFile("");
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  console.log("dataURL", dataURL);
  console.log("isSigned", isSigned);

  useEffect(() => {
    setDataURL(doneSignFileUrl ? doneSignFileUrl : '');
    setIsSigned(!!doneSignFileUrl);
  }, [doneSignFileUrl]);

  return (
    <div className="container">
      <div className="d-flex align-items-center gap-2 justify-content-end">
        <div>성 명: {reportReqData?.user_nm}</div>
        <div>
          {!isSigned ? (
            <Button onClick={handleOpen} variant="warning" style={{ color: "white"}}>
              서명
            </Button>
          ) : (
            <>
              <div style={{ position: "relative", display: "inline-block" }}>
                <span style={{ fontSize: "12px" }}>(서명)</span>
                <img
                  src={dataURL}
                  style={{
                    width: "100px",
                    position: "absolute",
                    top: "-10px",
                    left: "-15px",
                  }}
                  alt="서명"
                />
              </div>
              <Button 
                variant="warning" 
                style={{marginLeft:"50px", color: "white" }}
                onClick={handleOpen} 
              >
                다시 서명하기
              </Button>
            </>
          )}
          <Modal
            show={open}
            // 저장버튼을 누르거나 사인을 하지 않았을 경우만 모달창 닫음
            onHide={() => {
              if (isSigned === isSaved) handleClose();
            }}
            centered
            // fullscreen={"sm-down"}
            dialogClassName="my_modal modal-552px"
          >
            <Modal.Header closeButton>
              <Modal.Title>서명입력하기</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Signature
                isSigned={isSigned}
                reportReqData={reportReqData}
                setIsSigned={setIsSigned}
                setIsSaved={setIsSaved}
                setDataURL={setDataURL}
                setSaveFile={setSaveFile}
                onClose={handleClose}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};


export const ReportForm = () => {
  const location = useLocation();
  const dealData = location.state?.data;
  const doneReportData = location.state?.reportData;

  console.log('dealData', dealData);  
  console.log('doneReportData', doneReportData);  
  
  // const [modalShow, setModalShow] = useState(false);
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [reportReqData, setReportReqData] = useState({});
  const [saveFile, setSaveFile] = useState();
  const [doneSocialNo, setDoneSocialNo] = useState();
  const [doneSignFileUrl, setDoneSignFileUrl] = useState();
  const [doneReportRegion, setDoneReportRegion] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();


  const getFileApi = async (seq) => {
    console.log('SignFileSeq', seq);
    try {
      const data ={
        file_seq: seq,
        main_seq: dealData.main_seq,
        deal_str: dealData.deal_str,
        target_seq: dealData.target_seq || "",
      }
      console.log(data);
      const response = await postGetSignFile(data);
      console.log("getSignFile Res: ", response);
      const contentType = response.headers["content-type"];
      const previewUrl = URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      setDoneSignFileUrl(previewUrl);     
    } catch (e) {
      return console.log(e);
    }
  };

  const onRequest = async (data) => {
    if (isClicked) {
      return;
    }
    setIsClicked(true);
    console.log('신청버튼', data);
    const newData = {
      main_seq: data.main_seq,
      deal_str: data.deal_str,
      target_seq: data.target_seq ? data.target_seq : "",
      social_no: data.social_no,
      sign_file: saveFile,
      local_gov_cd: data.local_gov_cd,
    };

    const msg = validateSellReq({ ...reportReqData, sign_file: saveFile });
    if (msg) {
      setIsClicked(false);
      return toast.error(msg);
    }
    try {
      console.log('신청하기 데이터: ', newData);
      const response = await postUpdReport(newData);
      if (response?.code === 1000) {
        console.log("등록되었습니다.");
        navigate("/report-complete", { state: { data: newData }});
      } else {
        setIsClicked(false);
        toast.error("등록에 실패하였습니다.");
      }
    } catch (err) {
      setIsClicked(false);
      console.log(err);      
      toast.error("등록서버오류", {duration: 500,});
    }
  };

  useEffect(() => {
    setHeaderTextAtom(doneReportData ? "신고서 수정" : "신고서 신청");
    scrollToTop();
  }, []);

  useEffect(() => {
    setReportReqData({ ...dealData });    

    const fetchFiles = async () => {
      if (dealData.user_nm === doneReportData.sell_user_nm) {
        setDoneSocialNo(doneReportData.sell_socialno);
        await getFileApi(doneReportData.sellsign_file_seq);
      } else if (dealData.user_nm === doneReportData.buy_user_nm) {
        setDoneSocialNo(doneReportData.buy_socialno);
        setDoneReportRegion(doneReportData.local_gov_cd);  // 지자체
        await getFileApi(doneReportData.buysign_file_seq);
      }
    };
    if(doneReportData){
      fetchFiles();
    }
  }, [dealData, doneReportData]);

  const validateSellReq = (reportReqData) => { 
    console.log('신청버튼후 검사=====>',reportReqData);
    // 주민등록번호 유효성 검사   
    const regex = /^\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])[-]\d{7}$/
    const TODAY_YEAR = parseInt(new Date().getFullYear().toString().slice(-2));
    const errMsgSocialNo ="올바른 주민등록번호 형식이 아닙니다.";

    if (doneReportData) {
      if (!reportReqData.sign_file || reportReqData.sign_file === "" || reportReqData.sign_file === undefined) {
        return "다시 서명하여야 합니다.";
      }
    } 
    if (!reportReqData.sign_file || reportReqData.sign_file === "" || reportReqData.sign_file === undefined) {
      return "서명은 필수입니다.";
    }
    if (!reportReqData.social_no || reportReqData.social_no === "" || reportReqData.social_no === undefined) {
      return "주민등록번호는 필수입니다.";
    }
    if(reportReqData.deal_str === 'buy' || reportReqData.deal_str === 'cbuy'){
      if (!reportReqData.local_gov_cd || reportReqData.local_gov_cd === "" || reportReqData.local_gov_cd === undefined) {
        return "지역 선택은 필수입니다.";
      } 
    }

    // 주민등록번호 유효성 검사
    if(reportReqData.social_no.length !== 14){
      return errMsgSocialNo;
    }
    // 연도에 해당하는 숫자와 성별에 해당하는 숫자 비교
    const yearNum = parseInt(reportReqData.social_no.slice(0, 2));
    const sexNum = reportReqData.social_no.slice(7, 8); // 대시 있으므로
    
    if (sexNum === '1' || sexNum === '2') {
      if (yearNum < TODAY_YEAR) return errMsgSocialNo;
    } else if (sexNum === '3' || sexNum === '4') {
      if (yearNum > TODAY_YEAR) return errMsgSocialNo;
    } else return errMsgSocialNo;

    // 월과 일에 해당하는 숫자 조건 검사 (정규식으로)
    if (!regex.test(reportReqData.social_no)) return errMsgSocialNo;
  };

  return (
    <>
      <div className="requestFormMain-container">
        <Container className="p-0">
          <Row>
            <Col md={12}>
              <div className="pc_view">
                <R.FormHeading_div>
                  <R.Form_Heading>
                    {doneReportData
                      ? "양도·양수 신고서 수정"
                      : "양도·양수 신고서 신청"}
                  </R.Form_Heading>
                </R.FormHeading_div>
              </div>
              <PersonInfo
                reportData={reportReqData}
                setReportData={setReportReqData}
                doneSocialNo={doneSocialNo}
                doneReportRegion={doneReportRegion}
              />
              <CommonData />
              <SignatureInfo
                reportReqData={reportReqData}
                setSaveFile={setSaveFile}
                doneSignFileUrl={doneSignFileUrl}
              />
              <CommonData2 />
              <div className="mt-3 text-center">
                {/* <BtnYellow
                  width="360px"
                  height="60px"
                  onClick={() => {
                    onRequest(reportReqData);
                  }}
                >
                  신청하기
                </BtnYellow> */}
                <CardButton
                  width="390px"
                  bg="#FF9C00"
                  name="등록하기"
                  className="btn-main"
                  onClick={() => {
                    onRequest(reportReqData);
                  }}
                  loading={isClicked}
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* <TablePopup show={modalShow} onHide={() => setModalShow(false)} /> */}
      </div>
    </>
  );
};

export default ReportForm;
