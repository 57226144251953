import Accordion from 'react-bootstrap/Accordion';
import * as S from "../../../css/SignUp/RegularUser.style"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
function AccordianRegularUser2() {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <S.AccordionHeading padding={"0"}>
                        <S.AccordionIcon>
                            <FontAwesomeIcon icon={faCheck} />
                        </S.AccordionIcon>
                        <S.AccordionValue color="#888888">서비스 이용 약관(필수)</S.AccordionValue>
                    </S.AccordionHeading></Accordion.Header>
                <Accordion.Body>
                    <S.AccordionCollapse_Text>
                        제1조(목적)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        이 약관은 `넘버로` 에서 제공하는 온라인 정보제공 서비스(이하 `서비스`라 한다)를 이용함에 있어 `넘버로`와 `이용자`의 권리, 의무 및 책임사항을
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        제2조(정의)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        1. `넘버로`란 `넘버로` 회사가 온라인정보를 `이용자`에게 제공하기 하여 컴퓨터등 정보통신설비를 이용하여 온라인정보를 이용할 수 있도록
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        2. `이용자`란 `넘버로`에 접속하여 이 약관에 따라 `넘버로`가 제공하는 `서비스`를 받는 회원 및 비회원을 말합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        3. ‘회원’이라 함은 `넘버로`에 개인정보를 제공하여 회원등록을 한 자로서 `넘버로`의 정보를 지속적으로 제공받으며 `넘버로`가 제공하는 `서비스`
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        4. ‘비회원’이라 함은 회원에 가입하지 않고 `넘버로`가 제공하는 `서비스`를 이용하는 자를 말합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        제3조 (약관등의 명시와 설명 및 개정)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        1. `넘버로`는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 전화번호, 전자우편주소, 사업자등록번호 개인정보관리책임자등을 `이용
                    </S.AccordionCollapse_Text>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <S.AccordionHeading padding="0">
                        <S.AccordionIcon>
                            <FontAwesomeIcon icon={faCheck} />
                        </S.AccordionIcon>
                        <S.AccordionValue color="#888888">개인정보 취급 방침(필수)</S.AccordionValue>
                    </S.AccordionHeading>
                </Accordion.Header>
                <Accordion.Body>
                    <S.AccordionCollapse_Text>
                        제1조(목적)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        이 약관은 `넘버로` 에서 제공하는 온라인 정보제공 서비스(이하 `서비스`라 한다)를 이용함에 있어 `넘버로`와 `이용자`의 권리, 의무 및 책임사항을
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        제2조(정의)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        1. `넘버로`란 `넘버로` 회사가 온라인정보를 `이용자`에게 제공하기 하여 컴퓨터등 정보통신설비를 이용하여 온라인정보를 이용할 수 있도록
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        2. `이용자`란 `넘버로`에 접속하여 이 약관에 따라 `넘버로`가 제공하는 `서비스`를 받는 회원 및 비회원을 말합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        3. ‘회원’이라 함은 `넘버로`에 개인정보를 제공하여 회원등록을 한 자로서 `넘버로`의 정보를 지속적으로 제공받으며 `넘버로`가 제공하는 `서비스`
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        4. ‘비회원’이라 함은 회원에 가입하지 않고 `넘버로`가 제공하는 `서비스`를 이용하는 자를 말합니다.
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        제3조 (약관등의 명시와 설명 및 개정)
                    </S.AccordionCollapse_Text>
                    <S.AccordionCollapse_Text>
                        1. `넘버로`는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 전화번호, 전자우편주소, 사업자등록번호 개인정보관리책임자등을 `이용
                    </S.AccordionCollapse_Text>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordianRegularUser2;