import { useAtom } from "jotai";
import "../../css/terms.css";
import { headerText } from "../../App";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const TermsOfUse = () => {
  const location = useLocation();

  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);

  useEffect(() => {
    setHeaderTextAtom("이용안내");
  }, [location?.state]);
  return (
    <>
      <div className="container container-terms">
        <h1 className="terms_heading_main">넘버로 이용약관</h1>
        <h6 className="terms_sub_heading">제1조(목적)</h6>
        <p>
          이 약관은 주식회사 체인버스가 운영하는 Number-ro(이하 플랫폼이라
          합니다)에서 제공하는 서비스(이하 서비스라 합니다)를 플랫폼과
          이용자(이하 이용자라 합니다)의 권리, 의무 및 책임사항을 규정함을
          목적으로 합니다.{" "}
        </p>

        <h6 className="terms_sub_heading">제2조(정의)</h6>
        <p>
          <ul>
            <li>
              ① 허가증이란 화물자동차운송사업허가증,
              화물자동차운송주선사업허가증 등 각종 화물자동차 관련 허가된 증서를
              말합니다.
            </li>
            <li>
              ② 이용자란 플랫폼을 이용하는 회원, 비회원 또는 플랫폼을 이용한
              양도인, 양수인을 말합니다.{" "}
            </li>
            <li>
              ③ 회원이란 플랫폼에 회원가입을 한 자로서, 계속적으로 플랫폼이
              제공하는 서비스를 이용할 수 있는 자를 말합니다.
            </li>
            <li>
              ④ 비회원이란 회원가입 하지 않고 플랫폼이 제공하는 서비스를
              이용하는 자를 말합니다{" "}
            </li>
            <li>⑤ 양도인이란 허가증을 양도하는 자를 말합니다.</li>
            <li>⑥ 양수인이란 허가증을 양수하는 자를 말합니다.</li>
            <li>
              ⑦ 서비스란 플랫폼에 기반한 허가증의 시세확인과 양도양수 계약체결
              및 행정업무대행을 플랫폼에 의뢰, 위탁 하는것을 말합니다.{" "}
            </li>
            <li>
              ⑧ 플랫폼이란 이용자가 등록하는 또는 이용자를 대리하여 플랫폼이
              등록하는 허가증을 이용자에게 제공하기 위하여 컴퓨터 등
              정보통신설비를 이용하여 서비스할 수 있도록 설정한 가상의 영업장을
              말합니다.
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">
          제3조(약관 등의 명시와 설명 및 개정)
        </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
              주소, 전화번호, 팩스번호, 전자우편주소, 사업자등록번호,
              개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 플랫폼의 하단에
              게시합니다.
            </li>
            <li>
              ② 플랫폼은 「전자상거래 등에서의 소비자보호에 관한 법률」,
              「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」,
              「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
              정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
              「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을
              개정할 수 있습니다.{" "}
            </li>
            <li>
              ③ 플랫폼이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
              현행약관과 함께 플랫폼의 초기화면에 그 적용일자 7일 이전부터
              적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
              변경하는 경우에는 최소한 10일 이상의 사전 유예기간을 두고
              공지합니다. 이 경우 플랫폼은 개정 전 내용과 개정 후 내용을
              명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
            </li>
            <li>
              ④ 플랫폼이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에
              체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
              개정 전의 약관조항이 그대로 적용됩니다. 다만, 이미 계약을 체결한
              이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한
              개정약관의 공지기간 내에 플랫폼에 송신하여 플랫폼의 동의를 받은
              경우에는 개정약관 조항이 적용됩니다.
            </li>
            <li>
              이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
              전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한
              법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침
              및 관계법령 또는 상관례에 따릅니다.
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">제4조(서비스의 제공 및 변경)</h6>
        <p>
          <ul>
            <li>
              ① 플랫폼은 다음과 같은 업무를 수행합니다.
              <ol>
                <li>허가증의 시세정보 제공</li>
                <li>허가증의 양도양수 계약체결 및 양도양수 행정업무대행</li>
                <li>기타 플랫폼이 정하는 업무</li>
              </ol>
            </li>
            <li>
              ② 플랫폼은 허가증 또는 운영상, 행정적, 기술적 사양의 변경 등의
              경우에는 장차 체결되는 계약에 의해 서비스의 내용을 변경할 수
              있습니다.
            </li>
          </ul>
        </p>

        <h6 className="terms_sub_heading">제5조(서비스의 중단)</h6>
        <p>
          플랫폼은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절
          등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수
          있습니다.
        </p>

        <h6 className="terms_sub_heading">제6조(회원가입) </h6>
        <p>
          <ul>
            <li>
              ① 이용자는 플랫폼이 정한 가입 양식에 따라 회원정보를 기입한 후 이
              약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
            </li>
            <li>
              ② 플랫폼은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음
              각 호에 해당하지 않는 한 회원으로 등록합니다.
              <ol>
                <li>
                  가입신청자가 이전에 플랫폼의 회원으로써 회원자격을 상실한 적이
                  있는 경우 다만, 플랫폼의 재가입 승낙을 얻은 경우는 예외로
                  합니다.
                </li>
                <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>
                  기타 회원으로 등록하는 것이 플랫폼의 운영상에 지장이 있다고
                  판단되는 경우
                </li>
              </ol>
            </li>
            <li>
              ③ 회원가입의 성립시기는 플랫폼에 회원가입완료 버튼을 클릭한
              시점으로 합니다.
            </li>
            <li>
              ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간
              이내에 플랫폼에 대하여 회원정보 수정 등의 방법으로 그 변경사항을
              알려야 합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제7조(회원 탈퇴 및 자격 상실 등) </h6>
        <p>
          <ul>
            <li>
              ① 회원은 플랫폼에 언제든지 탈퇴를 요청할 수 있으며 플랫폼은 특별한 사정이 없는 한 즉시 회원탈퇴를 처리합니다.
            </li>
            <li>
              ② 회원이 다음 각 호의 사유에 해당하는 경우, 플랫폼은 회원자격을 제한 및 정지시킬 수 있습니다.
              <ol>
                <li>
                  1. 가입 신청 시에 허위 내용을 등록한 경우
                </li>
                <li>2. 플랫폼의 서비스를 이용한 양수비용, 기타 플랫폼이 정한 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
                <li>
                  3. 다른 사람의 플랫폼 이용을 방해하거나 그 정보를 도용하는 등 상거래 질서를 위협하는 경우
                </li>
                <li>
                  4. 플랫폼을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
                </li>
              </ol>
            </li>
            <li>
              ③ 플랫폼이 회원자격을 제한, 정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 플랫폼은 회원자격을 상실시킬 수 있습니다.
            </li>
            <li>
              ④ 플랫폼이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제8조(회원에 대한 통지) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼이 회원에 대한 통지를 하는 경우, 회원이 플랫폼과 미리 약정하여 지정한 전자우편 주소 또는 APP PUSH(앱 알림), 문자(SMS)로 할 수 있습니다.
            </li>
            <li>
              ② 플랫폼은 불특정다수 회원에 대한 통지의 경우 1주일 이상 플랫폼 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
              대하여는 개별통지를 합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제9조(서비스 신청 및 개인정보 제공 동의 등) </h6>
        <p>
          <ul>
            <li>
              ① 이용자는 다음 또는 이와 유사한 방법으로 서비스를 신청함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <ol>
                <li>
                  1. 이용자의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
                </li>
                <li>
                  2. 약관내용, 청약철회권이 제한되는 서비스의 비용부담과 관련한 내용에 대한 확인
                </li>
                <li>
                  3. 이 약관에 동의하고 위 2. 호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)
                </li>
                <li>
                  4. 허가증 등의 서비스 신청 및 이에 관한 확인 또는 플랫폼의 확인에 대한 동의
                </li>
              </ol>
            </li>
            <li>
              ② 플랫폼이 제3자에게 이용자의 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 이용자에게 알리고 동의를 받아야 합니다.
            </li>
            <li>
              ③ 플랫폼이 제3자에게 이용자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 이용자에게 알리고 동의를 받아야 합니다. 다만, 서비스 제공에 관한 계약이행을 위해 필요하고 이용자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제10조 (계약의 성립) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼은 제9조와 같은 서비스 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
              <ol>
                <li>
                  1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
                </li>
                <li>
                  2. 기타 서비스 신청에 승낙하는 것이 플랫폼 운영상, 기술상 지장이 있다고 판단하는 경우
                </li>
              </ol>
            </li>
            <li>
              ② 플랫폼의 승낙이 이용자에게 유선상 고지 또는 이메일이 도달한 시점에 계약이 성립한 것으로 봅니다.
            </li>
            <li>
              ③ 플랫폼의 승낙의 의사표시에는 이용자의 구매신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제11조 (지급방법)</h6>
        <p>
          <ul>
            <li>
              플랫폼에서 계약체결한 허가증의 양수비용은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다.
            </li>
            <li>
              ① 양수인이 직접 양도인의 계좌에 입금
            </li>
            <li>
              ② 기타 플랫폼이 지정한 지급방식
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제12조(환급) </h6>
        <p>
          플랫폼은 이용자가 체결한 서비스가 행정상의 이유로 양수인에게 이전이 불가한때에는 지체없이 그 사유를 이용자에게 통지하고 양수금액은 당일, 중개 수수료, 행정업무대행 수수료는 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제13조(청약철회 및 계약무효 등) </h6>
        <p>
          <ul>
            <li>
              ① 이용자는 계약금 입금전까지 언제든지 청약을 철회할 수 있습니다.
            </li>
            <li>
              ② 이용자는 계약금이 입금된 경우에는 일방으로 청약을 철회할 수 없습니다. 다만, 이용자 상호간 협의가 이루어질 경우 계약무효가 가능합니다.
            </li>
            <li>
              ③ 제2항에서 이용자가 일방으로 청약을 철회할 경우 아래에 해당하면 계약무효가 가능합니다.
              <ol>
                <li>
                  1. 양수인이 양도인에게 지급한 계약금을 포기한다는 의사를 표시한 경우
                </li>
                <li>
                  2. 양도인이 양수인에게 지급받은 계약금의 2배에 해당하는 금액을 위약금으로 지불한다는 의사를 표시한 경우
                </li>
              </ol>
            </li>
            <li>
              ④ 제3항에서 계약무효가 이루어질 경우 양도인, 양수인은 플랫폼에게 중개수수료, 행정업무수수료를 지불해야 합니다.
            </li>
            <li>
              ⑤ 양수인은 허가증을 양수 받은 뒤에는 청약을 철회할 수 없습니다. 다만, 양수인 관할 지자체에서 양수 거부된 상황에서는 양도인과 협의 하에 청약을 철회할 수 있습니다.
            </li>
            <li>
              ⑥ 양도인은 허가증이 양수인이 양수 받은 뒤에는 청약을 철회할 수 없습니다. 다만, 양수인 관할 지자체에서 양수 거부된 상황에서는 양수인과 협의 하에 청약을 철회할 수 있습니다.
            </li>
            <li>
              ⑦ 이용자는 제5항 및 제6항의 규정에 불구하고 허가증 등의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 청약을 철회할 수 있으며, 청약철회에 필요한 비용은 플랫폼이
              부담합니다. 다만, 양수인 관할 지자체에서 양수가 허가된 상황에서는 청약을 철회할 수 없습니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제14조(개인정보보호) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼은 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
            </li>
            <li>
              ② 플랫폼은 회원가입 시 양도양수 계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 양도양수 계약 이전에 본인확인이 필요한 경우로서 최소한의 특정
              개인정보를 수집하는 경우에는 그러하지 아니합니다.
            </li>
            <li>
              ③ 플랫폼은 이용자의 개인정보를 수집, 이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
            </li>
            <li>
              ④ 플랫폼은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용, 제공단계에서 당해 이용자에게 그 목적을 고지하고
              동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
            </li>
            <li>
              ⑤ 플랫폼이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등
              「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
            </li>
            <li>
              ⑥ 이용자는 언제든지 플랫폼이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 플랫폼은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다.
            </li>
            <li>
              ⑦ 플랫폼은 개인정보보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한합니다.
            </li>
            <li>
              ⑧ 플랫폼 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
            </li>
            <li>
              ⑨ 플랫폼은 개인정보의 수집, 이용, 제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제15조(플랫폼의 의무) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는데 최선을 다하여야 합니다.
            </li>
            <li>
              ② 플랫폼은 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안 시스템을 갖추어야 합니다.
            </li>
            <li>
              ③ 플랫폼이 상품이나 용역에 대하여 「표시, 광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시, 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제16조(회원의 ID 및 비밀번호에 대한 의무) </h6>
        <p>
          <ul>
            <li>
              ① 제14조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
            </li>
            <li>
              ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
            </li>
            <li>
              ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 플랫폼에 통보하고 플랫폼의 안내가 있는 경우에는 그에 따라야 합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제17조(이용자의 의무) </h6>
        <p>
          <ul>
            <li>
              이용자는 다음 행위를 하여서는 안 됩니다.
            </li>
            <li>
              ① 신청 또는 변경 시 허위 내용의 등록
            </li>
            <li>
              ② 타인의 정보 도용
            </li>
            <li>
              ③ 플랫폼에 게시된 정보의 변경
            </li>
            <li>
              ④ 플랫폼이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
            </li>
            <li>
              ⑤ 플랫폼에 기타 제3자의 저작권 등 지적재산권에 대한 침해
            </li>
            <li>
              ⑥ 플랫폼에 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </li>
            <li>
              ⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제18조(연결 플랫폼과 피연결 플랫폼 간의 관계) </h6>
        <p>
          <ul>
          <li>
            ① 상위 플랫폼과 하위 플랫폼이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 플랫폼이라고 하고 후자를 피연결 플랫폼이라고 합니다.
          </li>
            <li>
              ② 연결 플랫폼은 피연결 플랫폼이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않습니다.
            </li>

          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제19조(저작권의 귀속 및 이용제한) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼이 작성한 저작물에 대한 저작권 기타 지적재산권은 플랫폼에 귀속합니다.
            </li>
            <li>
              ② 이용자는 플랫폼을 이용함으로써 얻은 정보 중 플랫폼에게 지적재산권이 귀속된 정보를 플랫폼의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
            </li>
            <li>
              ③ 플랫폼은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제20조(분쟁해결) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 신속하게 통보합니다.
            </li>
            <li>
              ② 플랫폼과 이용자 간에 발생한 상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시, 도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>

        <h6 className="terms_sub_heading">제21조(재판권 및 준거법) </h6>
        <p>
          <ul>
            <li>
              ① 플랫폼과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
            </li>
            <li>
              ② 플랫폼과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
            </li>
          </ul>
          <br/>
          <br/>
        </p>
      </div>
    </>
  );
};
export default TermsOfUse;
