import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

export const postSaleInsCBuy = (data) =>
  api.updateWithFile(url.POST_NUMBA_INSCBUY, data);
export const postSaleInsCSell = (data) =>
  api.updateWithFile(url.POST_NUMBA_INSCSELL, data);
export const postSaleSelLic = (data) => api.create(url.POST_NUMBA_SELLIC, data);

// 신고증
export const postUpdReport = (data) => 
  api.updateWithFile(url.POST_UPD_REPORT, data);
export const postSelReport = (data) => 
  api.create(url.POST_SEL_REPORT, data);
export const postGetSignFile = (data) =>
  api.blobFile(url.POST_GETSIGNFILE, data);
export const postReportList = (data) => 
  api.create(url.POST_REPORT_LIST, data);
export const postGetReportFile = (data) =>
  api.blobFile(url.POST_GET_REPORTFILE, data);