import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";
export const RegisterUserInfo = ({ data }) => {
  const dealStr = data?.deal_str;
  const subject = 
    ((dealStr === "csell" || dealStr === "cbuy") && "거래신청자") ||
    ((dealStr === "sell" || dealStr === "buy") && "등록자") ;

  return (
    <div className="table-part">
      <div className="sub-heading">
        <h3>{subject} 정보</h3>
      </div>
      <table className="table">
        <tbody>
          {data?.corp_nm && (
            <tr>
              <td width="23%" className="grey">
                법인명
              </td>
              <td>{data?.corp_nm}</td>
            </tr>
          )}
          <tr>
            <td width="23%" className="grey">
              이름
            </td>
            <td>{data?.user_nm}</td>
          </tr>
          <tr>
            <td width="23%" className="grey">
              휴대폰번호
            </td>
            <td>{formatPhoneNumber(data?.phone)}</td>
          </tr>
          <tr>
            <td width="23%" className="grey">
              주소
            </td>
            <td>{data?.zip + " " + data?.addr1 + " " + data?.addr2}</td>
          </tr>
          <tr>
            <td width="23%" className="grey">
              요청내용
            </td>
            <td>{data?.req_comment}</td>
          </tr>

          {data?.brno_file_nm && (
            <tr>
              <td width="23%" className="grey">
                사업자등록증
              </td>
              <td>
                <div>{data?.brno_file_nm}</div>
              </td>
            </tr>
          )}
          {data?.car_file_nm && (
            <tr>
              <td width="23%" className="grey">
                자동차등록증
              </td>
              <td>{data?.car_file_nm}</td>
            </tr>
          )}
          {data?.fw_file_nm && (
            <tr>
              <td width="23%" className="grey">
                화물종사자격증
              </td>
              <td>{data?.fw_file_nm}</td>
            </tr>
          )}
          {data?.fbl_file_nm && (
            <tr>
              <td width="23%" className="grey">
                화물자동차운송사업허가증
              </td>
              <td>{data?.fbl_file_nm}</td>
            </tr>
          )}
          {data?.fabl_file_nm && (
            <tr>
              <td width="23%" className="grey">
                화물자동차운송 주선사업허가증
              </td>
              <td>{data?.fabl_file_nm}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
