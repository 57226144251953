import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as M from "../../css/components/modal/modalPopup"
import WhiteButton from '../buttons/buttonWhite';
import CardButton from '../buttons/cardButton';

function ModalPopup(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <M.Modal_Heading>
                        법인 실명확인 안내
                    </M.Modal_Heading>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <M.Modal_Para>
                    실명이 확인되지 않는 경우, 사업자등록증 사본에 연락처를 기재하여                    
                </M.Modal_Para>
                <M.Modal_Para>
                    팩스로 보내주시면 1시간 이내에 처리해 드립니다.
                </M.Modal_Para>
                {/* <M.Modal_Para>
                    하여 팩스나 이메일 중 하나를 택해서 보내주시면 1시간 이내에
                </M.Modal_Para>
                <M.Modal_Para>
                    처리해 드립니다.
                </M.Modal_Para> */}
                <M.Modal_Para>
                    (업무시간 외 접수건은 다음 업무일 업무시간에 처리됩니다.)
                </M.Modal_Para>
                <div className='mt-3'>
                    <M.Modal_Ul>
                        <M.Modal_Li>
                            팩스번호 : 02-2262-5333
                        </M.Modal_Li>
                        {/* <M.Modal_Li>
                            이메일 : info@namba.co.kr
                        </M.Modal_Li> */}
                        <M.Modal_Li>
                            문의전화 : 02-2276-0601
                        </M.Modal_Li>
                        <M.Modal_Li>
                            근무시간 : 평일 09:00~18:00 (토/일/공유일 휴무)
                        </M.Modal_Li>
                    </M.Modal_Ul>
                </div>
                <div className='mt-4'>
                    <CardButton height="60px" width="100%" bg="#FF9C00" name="확인" onClick={props.onHide}></CardButton>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalPopup;