import styled from "styled-components";
export const SellingTab_Header = styled.div`
  background: linear-gradient(90deg, #3b85de 0%, #26bbe3 100%);
  width: 100%;
  height: 50px;
  @media (max-width: 767.98px) {
    margin-top: 56px;
  }
`;
export const NavTab_Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  max-width: 485px;
  margin: 0 auto;
  @media screen and (max-width: 592px) {
    justify-content: space-around;
  }
`;
export const NavTab = styled.a`
  text-decoration: none;
  color: #fff;
  font-family: "NotoSansKR-Bold";
  line-height: 1.56;
  font-size: 16px;
  letter-spacing: 0.7px;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  text-wrap: nowrap;
  white-space: nowrap;
  &.active:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 0px;
    height: 5px;
    width: 100%;
    background-color: #fff;
  }
  @media screen and (max-width: 592px) {
    margin: 0 20px;
    font-family: "NotoSansKR-SemiBold";
    font-size: 17px;
    letter-spacing: -0.85px;
    padding-left: 0;
    padding-right: 0;
    &.active:after {
      bottom: -12px;
    }
  }
  @media screen and (max-width: 400px) {
    margin: 0 5px;
  }
`;

export const SubNavTab = styled.div`
  display: flex;
  justify-content: ${(props) => (props.position ? props.position : "center")};
  width: 100%;
  padding: 10px 0px;
  column-gap: 30px;
  @media screen and (max-width: 480px) {
    column-gap: 6px;
    // column-gap: 10px;
  }
`;

export const SubNavTab_Btn = styled.button`
  height: 31px;
  background: #fff;
  opacity: 0.9;
  font-size: 14px;
  font-family: "NotoSansKR-Regular";
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.6;
  text-align: left;
  color: rgba(163, 163, 163, 0.9);
  border: 2px solid #e1e1e1;
  border-radius: 30px;
  // min-width: 121px;
  min-width: 121px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: #3b87df;
    color: #fff;
    border: 1px solid #3b87df;
  }
  @media screen and (max-width: 767px) {
    // min-width: 90px !important;
    min-width: 80px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 592px) {
    font-family: "NotoSansKR-SemiBold";
    letter-spacing: -0.7px;
  }
`;

export const SubNavTab_Btn_lic = styled.button`
  height: 31px;
  background: #fff;
  opacity: 0.9;
  font-size: 14px;
  font-family: "NotoSansKR-Regular";
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.6;
  text-align: left;
  color: rgba(163, 163, 163, 0.9);
  border: 2px solid #e1e1e1;
  border-radius: 30px;
  min-width: 121px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: #3b87df;
    color: #fff;
    border: 1px solid #3b87df;
  }
  @media screen and (max-width: 767px) {
    min-width: 105px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 592px) {
    font-family: "NotoSansKR-SemiBold";
    letter-spacing: -0.7px;
  }
`;

export const SubNavTab_BtnPink = styled.button`
  background: #fff;
  opacity: 0.9;
  // border: 1px solid rgba(163, 163, 163, 0.9);
  border: 2px solid #e1e1e1;
  color: rgba(163, 163, 163, 0.9);
  font-size: 14px;
  font-family: "NotoSansKR-Regular";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  border-radius: 30px;
  min-width: 121px;
  text-align: center;
  padding: 0;
  height: 31px;

  &.active {
    background: #f03752;
    color: #fff;
    border: 1px solid #f03752;
  }
  @media screen and (max-width: 767px) {
    min-width: 80px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 592px) {
    font-family: "NotoSansKR-SemiBold";
    letter-spacing: -0.7px;
  }
`;
export const SubNavTab_BtnPink_lic = styled.button`
  background: #fff;opacity: 0.9;
  // border: 1px solid rgba(163, 163, 163, 0.9);
  border: 2px solid #e1e1e1;
  color: rgba(163, 163, 163, 0.9);
  font-size: 14px;
  font-family: "NotoSansKR-Regular";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  border-radius: 30px;
  min-width: 121px;
  text-align: center;
  padding: 0;
  height: 31px;

  &.active {
    background: #f03752;
    color: #fff;
    border: 1px solid #f03752;
  }
  @media screen and (max-width: 767px) {
    min-width: 105px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 592px) {
    font-family: "NotoSansKR-SemiBold";
    letter-spacing: -0.7px;
  }
`;

export const BuyingTab_Header = styled.div`
  background: linear-gradient(90deg, #f03752 0%, #fe814e 100%);
  width: 100%;
  height: 50px;
  @media (max-width: 767.98px) {
    margin-top: 56px;
  }
`;
export const Box_Shadow = styled.div`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05) !important;
`;
