import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postNumbaCodeList, postNumbaWithdrawUser } from "../../../api/auth";
import { toast } from "wc-toast";

function WithdrawSecond() {
  const navigate = useNavigate();
  const [active, setActive] = useState("서비스 기능 불만족");
  const [withdrawCdList, setWithdrawCdList] = useState();
  const [withdraw, setWithdraw] = useState({
    withdraw_str: "",
    withdraw_comment: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setWithdraw((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const getWithdrawCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 600 });

      if (response.code !== 1000) {
        console.log("탈퇴사유코드 가져오기 실패");
        return;
      }
      setWithdrawCdList(
        response.resultList[0].filter((el) => el.cd_nm !== "탈퇴코드"),
      );
    } catch (err) {
      console.log("탈퇴사유코드 가져오기 실패");
    }
  };

  useEffect(() => {
    getWithdrawCd();
  }, []);

  const handleActiveCheck = (type) => {
    let status = "child-container border-green";
    if (active === type) {
      status = "child-container-active border-green";
    }
    return status;
  };

  const handleSubmit = async () => {
    if (!withdraw.withdraw_str || withdraw.withdraw_str === "") {
      return toast.error("탈퇴사유를 선택 해 주세요");
    }

    try {
      const response = await postNumbaWithdrawUser(withdraw);

      if (response.code !== 1000) {
        toast.error("회원 탈퇴 실패");
        return;
      } else {
        toast.success("회원 탈퇴가 성공적으로 이루어졌습니다.");
        localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      toast.error("회원 탈퇴 서버 오류");
    }
  };

  return (
    <>
      <div className="content-container QAMainDivMember withdrawSecondMain">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">
                <h1>회원탈퇴</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="editMemberMainDiv borderBottom">
                <div className="borderDiv">
                  <div className="WithdrawSecondSection text-center">
                    <span>그동안 넘버로을 이용해 주신</span>
                    <br />
                    <span>고객님께 감사드립니다.</span>
                    <br />
                    <br />
                    <p className="grayTxt mb-0">탈퇴 사유를 알려주시면</p>
                    <p className="grayTxt mb-0">
                      더 나은 서비스를 만들기 위해 최선을 다하겠습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tableTabDiv">
                <p>탈퇴 사유 선택</p>
              </div>
              <div className="activeTabDiv">
                <div className="parent-container clearfix">
                  {withdrawCdList?.map((withdraw, index) => (
                    <div
                      key={index}
                      value={withdraw.cd_nm}
                      name={"withdraw_str"}
                      onChange={handleInputChange}
                      onClick={() => {
                        setActive(withdraw.cd_nm);
                        setWithdraw((prevState) => ({
                          ...prevState,
                          withdraw_str: withdraw.cd_nm,
                        }));
                      }}
                      className={handleActiveCheck(withdraw.cd_nm)}
                      role="button"
                    >
                      {withdraw.cd_nm}
                    </div>
                  ))}
                </div>
              </div>
              <div className="tableTabDiv">
                <p>기타 사유</p>
              </div>
              <div className="textArea">
                <textarea
                  placeholder="기타 의견을 자유롭게 작성해 주세요."
                  name={"withdraw_comment"}
                  onChange={handleInputChange}
                  rows={5}
                ></textarea>
              </div>
              <div className="button-wrapper">
                <button
                  style={{ "--bg-color": "#b9b9b9" }}
                  onClick={() => navigate("/main")}
                >
                  취소
                </button>
                <button
                  style={{ "--bg-color": "#ff9e2f" }}
                  onClick={handleSubmit}
                >
                  탈퇴
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawSecond;
