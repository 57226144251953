import React from "react";
import NotificationInformation from "./NotificationInformation";
import NotificationSetting from "./notificationSetting";
function NotificationTab({ subActiveTab }) {
  if (subActiveTab == "first") {
    return <NotificationInformation />;
  } else if (subActiveTab == "second") {
    return <NotificationSetting />;
  } else {
    return null;
  }
}

export default NotificationTab;
