import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import * as A from "../../../../css/Selling/subTabContent.style";
import * as B from "../../../../css/buying/buying";
import RoundButton from "../../../../componant/buttons/buttonRound";
import CardButton from "../../../../componant/buttons/cardButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useNavigate } from "react-router-dom";
import MobileFooter from "../../../../componant/mobileFooter";
import { postNumbaBuyDetailList, postNumbaBuyList } from "../../../../api/buy";
import { toast } from "wc-toast";
import InsInterest from "../../../MyPage/number-of-interest/InsInterest";
import { postNumbaInterestList } from "../../../../api/mypage";
import useInfiniteScroll from "../../../../utils/hooks/infinite-scroll/useInfiniteScroll";
import { useAtom } from "jotai";
import { header_mainData } from "../../../../data/atom/mypage/mypage_atom";
import callMainData from "../../../../utils/hooks/callMainData";
import moment from "moment/moment";
import useDeviceDetect from "../../../../helpers/useMobile";
import { deInterest } from "../../../MyPage/number-of-interest/deInterest";

function CorporateBuying({ activeTab, subActiveTab }) {
  const navigate = useNavigate();
  const [buyList, setBuyList] = useState([]);
  const [buyDetailList, setBuyDetailList] = useState([]);
  const [buyDetailListCheckbox, setBuyDetailListCheckbox] = useState([]);
  const [option, setOption] = useState({
    order: 2701,
    category: 2102,
    buyListCnt: 10,
  });
  const [selectedOption, setSelectedOption] = useState(2701);
  const [showBuyDetailList, setShowBuyDetailList] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [interestList, setInterestList] = useState([]);
  const [totCnt, setTotCnt] = useState(0);
  const [mainData, setMainList] = useAtom(header_mainData);
  const isMobile = useDeviceDetect();

  const { setBottom } = useInfiniteScroll(() => {
    setOption((prev) => {
      getBuyList({
        order: prev.order,
        category: prev.category,
        rowCnt: prev.buyListCnt + 10,
      }).then((res) => {
        setBuyList(res.resultList);
      });
      return { ...prev, buyListCnt: prev.buyListCnt + 10 };
    });
  });

  async function getBuyList(data) {
    try {
      const response = await postNumbaBuyList({
        startIdx: 0,
        rowCnt: data.rowCnt,
        order_cd: data.order,
        deal_category_cd: data.category,
      });

      if (response.code !== 1000) {
        console.log("삽니다 목록 가져오기 실패");
        return;
      }
      return response;
    } catch (error) {
      console.log("삽니다 목록 가져오기 서버 오류");
    }
  }

  const buyRequest = (data, type, option) => {
    // if (!localStorage.getItem("access_token")) {
    //   return toast.error("로그인 후 이용가능 합니다.", {
    //     duration: 500,
    //   });
    // }
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    if (option) {
      navigate("/buying-request1", {
        // state: { data: { ...data, type: type }, isEdit: false },
        state: { data: { 0: data, type: type }, isEdit: false },
      });
    } else {
      const newBuyDetailListCheckbox = [...buyDetailListCheckbox];

      newBuyDetailListCheckbox[
        buyDetailList.map((el) => el.target_seq).indexOf(data.target_seq)
      ] =
        !newBuyDetailListCheckbox[
          buyDetailList.map((el) => el.target_seq).indexOf(data.target_seq)
        ];

      setBuyDetailListCheckbox(newBuyDetailListCheckbox);
    }
  };

  useEffect(() => {
    if (subActiveTab === "first") {
      setOption({ ...option, order: 2701, category: 2102, buyListCnt: 10 });
    } else if (subActiveTab === "second") {
      setOption({ ...option, order: 2701, category: 2103, buyListCnt: 10 });
    }
    setSelectedIndex(null);
  }, [subActiveTab]);

  useEffect(() => {
    getBuyList({
      order: option.order,
      category: option.category,
      rowCnt: option.buyListCnt,
    })
      .then((res) => {
        setBuyList(res.resultList);
        setTotCnt(res.totCnt);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [option]);

  const getInterestList = async () => {
    if (!localStorage.getItem("access_token")) {
      return;
    }
    const data = await callMainData();
    setMainList(data);

    try {
      const response = await postNumbaInterestList({
        startIdx: -1,
      });
      if (response.code !== 1000) {
        console.log("관심목록 불러오기 실패");
        return;
      }
      setInterestList(response.resultList);
    } catch (err) {
      console.log("관심목록 불러오기 서버 오류");
    }
  };

  const handleItemClick = async (order) => {
    setOption({ ...option, order: order });
    setSelectedOption(order);
    setSelectedIndex(null);
  };

  async function getDetailList(index, buy_seq) {
    if (selectedIndex === index) {
      setShowBuyDetailList(!showBuyDetailList);
    } else {
      setShowBuyDetailList(true);
    }

    try {
      const response = await postNumbaBuyDetailList({ buy_seq: buy_seq });
      if (response.code !== 1000) {
        console.log("전체 매물 가져오기 실패");
        return;
      }
      setSelectedIndex(index);
      setBuyDetailList(response.resultList);
      setBuyDetailListCheckbox(
        Array.from({ length: response.resultList.length }, () => false)
      );
    } catch (error) {
      console.log("전체 매물 가져오기 서버 오류");
    }
  }
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      getInterestList();
    }
  }, []);

  useEffect(() => {}, [interestList]);

  const buyButton = (data, type, option, index = -1) => {
    const process_cd = data?.process_cd;

    switch (+process_cd) {
      case 3005:
        return (
          <CardButton
            className="btn_buy"
            bg={"#adadad"}
            width="100%"
            maxWidth={"200px"}
            height="60px"
            name="거래신청중"
            disabled={true}
          ></CardButton>
        );
      case 3006:
        return (
          <CardButton
            className="btn_buy"
            bg={"#7F7F7F"}
            width="100%"
            maxWidth={"200px"}
            height="60px"
            name="거래승인"
            disabled={true}
          ></CardButton>
        );
      case 3007:
        return (
          <CardButton
            className="btn_buy"
            bg={"#7F7F7F"}
            width="100%"
            maxWidth={"200px"}
            height="60px"
            name="거래진행중"
            disabled={true}
          ></CardButton>
        );
      case 3008:
        return (
          <CardButton
            className="btn_buy"
            bg={"#BFBFBF"}
            width="100%"
            maxWidth={"200px"}
            height="60px"
            name="거래완료"
            disabled={true}
          ></CardButton>
        );
      default:
        return (
          <CardButton
            className={"btn_buy"}
            bg={
              index !== -1 &&
              data.process_cd !== 3005 &&
              data.process_cd !== 3006 &&
              data.process_cd !== 3007 &&
              data.process_cd !== 3008
                ? "#fed6cf"
                : "#ff824e"
            }
            width="100%"
            maxWidth={"200px"}
            height="60px"
            // name="판매신청"
            name={
              <>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  {index !== -1 &&
                  data.process_cd !== 3005 &&
                  data.process_cd !== 3006 &&
                  data.process_cd !== 3007 &&
                  data.process_cd !== 3008 ? (
                    <>
                      <Form.Check
                        type="checkbox"
                        name="alarm_yn"
                        // label={"답변이 등록되면 알림으로 알려드릴까요?"}
                        onChange={(e) => {
                          const newBuyDetailListCheckbox = [
                            ...buyDetailListCheckbox,
                          ];
                          newBuyDetailListCheckbox[index] = e.target.checked;
                          setBuyDetailListCheckbox(newBuyDetailListCheckbox);
                        }}
                        className="CustomCheckbox chkButton"
                        checked={buyDetailListCheckbox[index]}
                      />
                      <div>매물선택</div>
                    </>
                  ) : (
                    <div>
                      {subActiveTab === "second" ? "임대신청" : "판매신청"}
                    </div>
                  )}
                </div>
              </>
            }
            onClick={() => {
              buyRequest(data, type, option);
            }}
          ></CardButton>
        );
    }
  };

  const goRequest = () => {
    // if (!localStorage.getItem("access_token")) {
    //   return toast.error("로그인 후 이용가능 합니다.", {
    //     duration: 500,
    //   });
    // }
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    // navigate("/buying-register");
    navigate("/buying-register", {state: {main: 2, sub: subActiveTab}});
  };

  if (subActiveTab === "first") {
    return (
      <>
        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg=""
            className="py-md-4 py-3 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#D81F61">
                          넘버로에 양수 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#D81F61">
                          넘버로에 양수 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#D81F61">
                          빠르고 안전하게 양도자를 매칭 시켜서 양수를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/taxiImg.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            width="150px"
                            height="150px"
                            name="삽니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>
          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value
                        color="#F03752"
                        fontSize={"16px"}
                        fontSizeSM={"16px"}
                      >
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {buyList.length > 0 ? (
                    <>
                      {buyList.map((data, index) =>
                        data.target_cnt > 1 ? (
                          <A.Card_Wrapper key={data.id}>
                            <A.Card className="card-dropdown flex-column">
                              <A.SubCard
                                className={
                                  showBuyDetailList && selectedIndex === index
                                    ? "borderbtm"
                                    : ""
                                }
                              >
                                <A.Card_left>
                                  <A.Main_Card>
                                    <A.CardLeft_Part>
                                      <A.NumberPart color="#F14B63">
                                        {data.price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </A.NumberPart>
                                      <A.ValuePart>만원</A.ValuePart>
                                    </A.CardLeft_Part>
                                    <A.CardRight_Part>
                                      <A.RightTop_Part mb={"0px"}>
                                        <A.RightTopPart_Value>
                                          <A.Value>대수</A.Value>
                                          <A.Value color="rgba(0, 0, 0, 0.9)">
                                            {data.target_cnt}
                                          </A.Value>
                                        </A.RightTopPart_Value>
                                        <A.RightTopPart_Value>
                                          <A.Value> </A.Value>
                                          <A.Value color="rgba(0, 0, 0, 0.9)">
                                            {" "}
                                          </A.Value>
                                        </A.RightTopPart_Value>
                                      </A.RightTop_Part>
                                    </A.CardRight_Part>
                                  </A.Main_Card>
                                </A.Card_left>
                                <A.Card_Right>
                                  <A.MainRight_Part>
                                    <A.RightLeft_Part
                                      style={{ justifyContent: "center" }}
                                    >
                                      <A.Value2>등록일 {data.reg_dt}</A.Value2>
                                    </A.RightLeft_Part>
                                    <A.RightRight_Part>
                                      <Dropdown.Toggle
                                        className="btn_buy"
                                        style={{
                                          background: "#f14b63",
                                          width: "100%",
                                          maxWidth: "200px",
                                          border: "none",
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          getDetailList(index, data.buy_seq);
                                        }}
                                      >
                                        전체매물보기
                                      </Dropdown.Toggle>
                                    </A.RightRight_Part>
                                  </A.MainRight_Part>
                                </A.Card_Right>
                              </A.SubCard>
                              {showBuyDetailList && selectedIndex === index && (
                                <>
                                  {buyDetailList &&
                                    buyDetailList.map((data, index) => (
                                      <>
                                        <A.SubCard border="#E1E1E1" key={index}>
                                          <A.Card_left>
                                            <A.Main_Card>
                                              <A.CardLeft_Part>
                                                <A.NumberPart color="#F14B63">
                                                  {data.price
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </A.NumberPart>
                                                <A.ValuePart>만원</A.ValuePart>
                                              </A.CardLeft_Part>
                                              <A.CardRight_Part className="e_div">
                                                <A.RightTop_Part mb={"0"}>
                                                  <A.RightTopPart_Value>
                                                    <A.Value>톤수</A.Value>
                                                    <A.Value
                                                      className="elipsis"
                                                      color="rgba(0, 0, 0, 0.9)"
                                                      title={data.car_weight_nm}
                                                    >
                                                      {data.car_weight_nm}
                                                    </A.Value>
                                                  </A.RightTopPart_Value>
                                                  <A.RightTopPart_Value>
                                                    <A.Value>대수</A.Value>
                                                    <A.Value color="rgba(0, 0, 0, 0.9)">
                                                      1대
                                                      {data.jigong_flag === "G"
                                                        ? "(공 T/E)"
                                                        : "(지입)"}
                                                    </A.Value>
                                                  </A.RightTopPart_Value>
                                                </A.RightTop_Part>
                                                <A.RightBottom_Part>
                                                  <A.RightTop_Part mb="0">
                                                    <A.RightTopPart_Value>
                                                      <A.Value>연식</A.Value>
                                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                                        {data?.car_yyyymm
                                                          ? moment(
                                                              data?.car_yyyymm
                                                            ).format("YYYY.MM")
                                                          : "-"}
                                                      </A.Value>
                                                    </A.RightTopPart_Value>
                                                    <A.RightTopPart_Value>
                                                      {/* <A.Value>대수</A.Value>
                                                    <A.Value color="rgba(0, 0, 0, 0.9)">
                                                      1대
                                                      {data.jigong_flag === "G"
                                                        ? "(공 T/E)"
                                                        : "(지압)"}
                                                    </A.Value> */}
                                                    </A.RightTopPart_Value>
                                                  </A.RightTop_Part>
                                                </A.RightBottom_Part>
                                              </A.CardRight_Part>
                                            </A.Main_Card>
                                          </A.Card_left>
                                          <A.Card_Right>
                                            <A.MainRight_Part>
                                              <A.RightLeft_Part
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <A.CardLeftPart_Top>
                                                  {interestList.some(
                                                    (item) =>
                                                      item.main_seq ===
                                                        data.buy_seq &&
                                                      item.target_seq ===
                                                        data.target_seq
                                                  ) ? (
                                                    <img
                                                      className="interest_img"
                                                      role="button"
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/img/icon_interest_click.png"
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          !localStorage.getItem(
                                                            "access_token"
                                                          )
                                                        ) {
                                                          return toast.error(
                                                            "로그인 후 이용가능합니다.",
                                                            {
                                                              duration: 500,
                                                            }
                                                          );
                                                        }
                                                        deInterest({
                                                          code: "buy",
                                                          interest_seq:
                                                            interestList.find(
                                                              (el2) =>
                                                                el2.target_seq ===
                                                                data.target_seq
                                                            ).interest_seq,
                                                        }).then(() => {
                                                          getInterestList();
                                                        });
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="interest_img"
                                                      role="button"
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/img/icon_interest.png"
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          !localStorage.getItem(
                                                            "access_token"
                                                          )
                                                        ) {
                                                          return toast.error(
                                                            "로그인 후 이용가능합니다.",
                                                            {
                                                              duration: 500,
                                                            }
                                                          );
                                                        }
                                                        InsInterest({
                                                          code: "buy",
                                                          buy_seq: data.buy_seq,
                                                          target_seq:
                                                            data.target_seq,
                                                        }).then(() => {
                                                          getInterestList();
                                                        });
                                                      }}
                                                    />
                                                  )}
                                                  <A.Value>관심등록</A.Value>
                                                </A.CardLeftPart_Top>
                                              </A.RightLeft_Part>
                                              <A.RightRight_Part>
                                                {buyButton(
                                                  data,
                                                  2102,
                                                  false,
                                                  index
                                                )}
                                              </A.RightRight_Part>
                                            </A.MainRight_Part>
                                          </A.Card_Right>
                                        </A.SubCard>
                                      </>
                                    ))}
                                  <div className="pb-30px">
                                    <CardButton
                                      className="btn_buy mt-30px"
                                      bg={"#ff824e"}
                                      width="100%"
                                      maxWidth={"273px"}
                                      minWidth={"273px"}
                                      height="60px"
                                      name="선택매물 신청하기"
                                      onClick={() => {
                                        const filter = buyDetailList.filter(
                                          (_, idx) => buyDetailListCheckbox[idx]
                                        );

                                        if (filter.length === 0) {
                                          return toast.error(
                                            "선택된 매물이 없습니다.",
                                            {
                                              duration: 500,
                                            }
                                          );
                                        }

                                        if (
                                          !localStorage.getItem("access_token")
                                        ) {
                                          return toast.error(
                                            "로그인 후 이용가능 합니다.",
                                            {
                                              duration: 500,
                                            }
                                          );
                                        }
                                        // if (!localStorage.getItem("access_token")) {
                                        //   navigate("/login");
                                        //   return;
                                        // }

                                        navigate("/buying-request1", {
                                          state: {
                                            // data: { ...data, type: type },
                                            data: { ...filter, type: 2102 },
                                            isEdit: false,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </A.Card>
                          </A.Card_Wrapper>
                        ) : (
                          <A.Card_Wrapper key={data.id}>
                            <A.Card>
                              <A.Card_left>
                                <A.Main_Card>
                                  <A.CardLeft_Part>
                                    <A.NumberPart color="#F14B63">
                                      {data.price
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </A.NumberPart>
                                    <A.ValuePart>만원</A.ValuePart>
                                  </A.CardLeft_Part>
                                  <A.CardRight_Part className="e_div">
                                    <A.RightTop_Part mb={"0"}>
                                      <A.RightTopPart_Value>
                                        <A.Value>톤수</A.Value>
                                        <A.Value
                                          className="elipsis"
                                          color="rgba(0, 0, 0, 0.9)"
                                          title={data.car_weight_nm}
                                        >
                                          {data.car_weight_nm}
                                        </A.Value>
                                      </A.RightTopPart_Value>
                                      <A.RightTopPart_Value>
                                        <A.Value>대수</A.Value>
                                        <A.Value color="rgba(0, 0, 0, 0.9)">
                                          1대
                                          {data.jigong_flag === "G"
                                            ? "(공 T/E)"
                                            : "(지입)"}
                                        </A.Value>
                                      </A.RightTopPart_Value>
                                    </A.RightTop_Part>
                                    <A.RightBottom_Part>
                                      <A.RightTopPart_Value>
                                        <A.Value>연식</A.Value>
                                        <A.Value color="rgba(0, 0, 0, 0.9)">
                                          {data?.car_yyyymm
                                            ? moment(data?.car_yyyymm).format(
                                                "YYYY.MM"
                                              )
                                            : "-"}
                                        </A.Value>
                                      </A.RightTopPart_Value>
                                      <A.RightTopPart_Value>
                                        <A.Value> </A.Value>
                                        <A.Value color="rgba(0, 0, 0, 0.9)">
                                          {" "}
                                        </A.Value>
                                      </A.RightTopPart_Value>
                                    </A.RightBottom_Part>
                                  </A.CardRight_Part>
                                </A.Main_Card>
                              </A.Card_left>
                              <A.Card_Right>
                                <A.MainRight_Part>
                                  <A.RightLeft_Part>
                                    <A.CardLeftPart_Top>
                                      {interestList.some(
                                        (item) => item.main_seq === data.buy_seq
                                      ) ? (
                                        <img
                                          className="interest_img"
                                          role="button"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/icon_interest_click.png"
                                          }
                                          onClick={() => {
                                            if (
                                              !localStorage.getItem(
                                                "access_token"
                                              )
                                            ) {
                                              return toast.error(
                                                "로그인 후 이용가능합니다.",
                                                {
                                                  duration: 500,
                                                }
                                              );
                                            }
                                            deInterest({
                                              code: "buy",
                                              interest_seq: interestList.find(
                                                (el2) =>
                                                  el2.main_seq === data.buy_seq
                                              ).interest_seq,
                                            }).then(() => {
                                              getInterestList();
                                            });
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className="interest_img"
                                          role="button"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/icon_interest.png"
                                          }
                                          onClick={() => {
                                            if (
                                              !localStorage.getItem(
                                                "access_token"
                                              )
                                            ) {
                                              return toast.error(
                                                "로그인 후 이용가능합니다.",
                                                {
                                                  duration: 500,
                                                }
                                              );
                                            }
                                            InsInterest({
                                              code: "buy",
                                              buy_seq: data.buy_seq,
                                              target_seq: data.target_seq,
                                            }).then(() => {
                                              getInterestList();
                                            });
                                          }}
                                        />
                                      )}
                                      <A.Value>관심등록</A.Value>
                                    </A.CardLeftPart_Top>
                                    <A.CardLeftPart_Top>
                                      <A.Value2>등록일</A.Value2>
                                      <A.Value2 color={"rgba(68, 68, 68, 0.9)"}>
                                        {data.reg_dt}
                                      </A.Value2>
                                    </A.CardLeftPart_Top>
                                  </A.RightLeft_Part>
                                  <A.RightRight_Part>
                                    {buyButton(data, 2102, true)}
                                  </A.RightRight_Part>
                                </A.MainRight_Part>
                              </A.Card_Right>
                            </A.Card>
                          </A.Card_Wrapper>
                        )
                      )}
                      {buyList.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab}/>
        </div>
      </>
    );
  } else if (subActiveTab === "second") {
    return (
      <>
        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg=""
            className="py-md-4 py-3 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#D81F61">
                          넘버로에 양수 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#D81F61">
                          넘버로에 양수 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#D81F61">
                          빠르고 안전하게 양도자를 매칭 시켜서 양수를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/taxiImg.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            width="150px"
                            height="150px"
                            name="삽니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>
          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value color="#F03752" fontSizeSM={"16px"}>
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            split
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {buyList.length > 0 ? (
                    <>
                      {buyList.map((data, index) =>
                        data.target_cnt > 1 ? (
                          <A.Card_Wrapper key={data.id}>
                            <A.Card className="card-dropdown flex-column">
                              <A.SubCard
                                className={
                                  showBuyDetailList && selectedIndex === index
                                    ? "borderbtm"
                                    : ""
                                }
                              >
                                <A.Card_left>
                                  <A.Main_Card>
                                    <A.CardLeft_Part>
                                      <A.NumberPart color="#F14B63">
                                        {data.price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </A.NumberPart>
                                      <A.ValuePart>만원</A.ValuePart>
                                    </A.CardLeft_Part>
                                    <A.CardRight_Part>
                                      <A.RightTop_Part mb={"0px"}>
                                        <A.RightTopPart_Value>
                                          <A.Value>대수</A.Value>
                                          <A.Value color="rgba(0, 0, 0, 0.9)">
                                            {data.target_cnt}
                                          </A.Value>
                                        </A.RightTopPart_Value>
                                        <A.RightTopPart_Value>
                                          <A.Value></A.Value>
                                          <A.Value color="rgba(0, 0, 0, 0.9)"></A.Value>
                                        </A.RightTopPart_Value>
                                      </A.RightTop_Part>
                                    </A.CardRight_Part>
                                  </A.Main_Card>
                                </A.Card_left>
                                <A.Card_Right>
                                  <A.MainRight_Part>
                                    <A.RightLeft_Part
                                      style={{ justifyContent: "center" }}
                                    >
                                      <A.Value2>등록일 {data.reg_dt}</A.Value2>
                                    </A.RightLeft_Part>
                                    <A.RightRight_Part>
                                      <Dropdown.Toggle
                                        className="btn_buy"
                                        style={{
                                          background: "#f14b63",
                                          width: "100%",
                                          maxWidth: "200px",
                                          border: "none",
                                          float: "right",
                                        }}
                                        onClick={() => {
                                          getDetailList(index, data.buy_seq);
                                        }}
                                      >
                                        전체매물보기
                                      </Dropdown.Toggle>
                                    </A.RightRight_Part>
                                  </A.MainRight_Part>
                                </A.Card_Right>
                              </A.SubCard>
                              {showBuyDetailList && selectedIndex === index && (
                                <>
                                  {buyDetailList &&
                                    buyDetailList.map((data, index) => (
                                      <>
                                        <A.SubCard border="#E1E1E1" key={index}>
                                          <A.Card_left>
                                            <A.Main_Card>
                                              <A.CardLeft_Part>
                                                <A.NumberPart color="#F14B63">
                                                  {data.price
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </A.NumberPart>
                                                <A.ValuePart>만원</A.ValuePart>
                                              </A.CardLeft_Part>
                                              <A.CardRight_Part className="e_div">
                                                <A.RightTop_Part mb={"0"}>
                                                  <A.RightTopPart_Value>
                                                    <A.Value>톤수</A.Value>
                                                    <A.Value
                                                      className="elipsis"
                                                      color="rgba(0, 0, 0, 0.9)"
                                                      title={data.car_weight_nm}
                                                    >
                                                      {data.car_weight_nm}
                                                    </A.Value>
                                                  </A.RightTopPart_Value>
                                                  <A.RightTopPart_Value>
                                                    <A.Value>대수</A.Value>
                                                    <A.Value color="rgba(0, 0, 0, 0.9)">
                                                      1{/* {data.target_cnt} */}
                                                    </A.Value>
                                                  </A.RightTopPart_Value>
                                                </A.RightTop_Part>
                                                <A.RightBottom_Part>
                                                  <A.RightTop_Part mb="0">
                                                    <A.RightTopPart_Value>
                                                      <A.Value>지입료</A.Value>
                                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                                        {data.ji_price} 만원
                                                      </A.Value>
                                                    </A.RightTopPart_Value>
                                                    <A.RightTopPart_Value>
                                                      {/* <A.Value>대수</A.Value>
                                                    <A.Value color="rgba(0, 0, 0, 0.9)">
                                                      1대
                                                      {data.jigong_flag === "G"
                                                        ? "(공 T/E)"
                                                        : "(지압)"}
                                                    </A.Value> */}
                                                    </A.RightTopPart_Value>
                                                  </A.RightTop_Part>
                                                </A.RightBottom_Part>
                                              </A.CardRight_Part>
                                            </A.Main_Card>
                                          </A.Card_left>
                                          <A.Card_Right>
                                            <A.MainRight_Part>
                                              <A.RightLeft_Part
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <A.CardLeftPart_Top>
                                                  {interestList.some(
                                                    (item) =>
                                                      item.main_seq ===
                                                        data.buy_seq &&
                                                      item.target_seq ===
                                                        data.target_seq
                                                  ) ? (
                                                    <img
                                                      className="interest_img"
                                                      role="button"
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/img/icon_interest_click.png"
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          !localStorage.getItem(
                                                            "access_token"
                                                          )
                                                        ) {
                                                          return toast.error(
                                                            "로그인 후 이용가능합니다.",
                                                            {
                                                              duration: 500,
                                                            }
                                                          );
                                                        }
                                                        deInterest({
                                                          code: "buy",
                                                          interest_seq: interestList.find(
                                                            (el2) =>
                                                              el2.main_seq === data.buy_seq
                                                          ).interest_seq,
                                                        }).then(() => {
                                                          getInterestList();
                                                        });
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="interest_img"
                                                      role="button"
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/img/icon_interest.png"
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          !localStorage.getItem(
                                                            "access_token"
                                                          )
                                                        ) {
                                                          return toast.error(
                                                            "로그인 후 이용가능합니다.",
                                                            {
                                                              duration: 500,
                                                            }
                                                          );
                                                        }
                                                        InsInterest({
                                                          code: "buy",
                                                          buy_seq: data.buy_seq,
                                                          target_seq:
                                                            data.target_seq,
                                                        }).then(() => {
                                                          getInterestList();
                                                        });
                                                      }}
                                                    />
                                                  )}
                                                  <A.Value>관심등록</A.Value>
                                                </A.CardLeftPart_Top>
                                              </A.RightLeft_Part>
                                              <A.RightRight_Part>
                                                {buyButton(
                                                  data,
                                                  2103,
                                                  false,
                                                  index
                                                )}
                                              </A.RightRight_Part>
                                            </A.MainRight_Part>
                                          </A.Card_Right>
                                        </A.SubCard>
                                      </>
                                    ))}
                                  <div className="pb-30px">
                                    <CardButton
                                      className="btn_buy mt-30px"
                                      bg={"#ff824e"}
                                      width="100%"
                                      maxWidth={"273px"}
                                      minWidth={"273px"}
                                      height="60px"
                                      name="선택매물 신청하기"
                                      onClick={() => {
                                        const filter = buyDetailList.filter(
                                          (_, idx) => buyDetailListCheckbox[idx]
                                        );

                                        if (filter.length === 0) {
                                          return toast.error(
                                            "선택된 매물이 없습니다.",
                                            {
                                              duration: 500,
                                            }
                                          );
                                        }

                                        if (
                                          !localStorage.getItem("access_token")
                                        ) {
                                          return toast.error(
                                            "로그인 후 이용가능 합니다.",
                                            {
                                              duration: 500,
                                            }
                                          );
                                        }
                                        // if (!localStorage.getItem("access_token")) {
                                        //   navigate("/login");
                                        //   return;
                                        // }

                                        navigate("/buying-request1", {
                                          state: {
                                            // data: { ...data, type: type },
                                            data: { ...filter, type: 2103 },
                                            isEdit: false,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </A.Card>
                          </A.Card_Wrapper>
                        ) : (
                          <A.Card_Wrapper key={data.id}>
                            <A.Card>
                              <A.Card_left>
                                <A.Main_Card>
                                  <A.CardLeft_Part>
                                    <A.NumberPart color="#F14B63">
                                      {data.price
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </A.NumberPart>
                                    <A.ValuePart>만원</A.ValuePart>
                                  </A.CardLeft_Part>
                                  <A.CardRight_Part className="e_div">
                                    <A.RightTop_Part mb="0">
                                      <A.RightTopPart_Value>
                                        <A.Value>톤수</A.Value>
                                        <A.Value
                                          className="elipsis"
                                          color="rgba(0, 0, 0, 0.9)"
                                          title={data.car_weight_nm}
                                        >
                                          {data.car_weight_nm}
                                        </A.Value>
                                      </A.RightTopPart_Value>
                                      <A.RightTopPart_Value>
                                        <A.Value>대수</A.Value>
                                        <A.Value color="rgba(0, 0, 0, 0.9)">
                                          {data.target_cnt
                                            ? data.target_cnt
                                            : 1}
                                        </A.Value>
                                      </A.RightTopPart_Value>
                                    </A.RightTop_Part>
                                    <A.RightBottom_Part>
                                      <A.RightTop_Part mb="0">
                                        <A.RightTopPart_Value>
                                          <A.Value>지입료</A.Value>
                                          <A.Value color="rgba(0, 0, 0, 0.9)">
                                            {data.ji_price} 만원
                                          </A.Value>
                                        </A.RightTopPart_Value>
                                        <A.RightTopPart_Value>
                                          {/* <A.Value>대수</A.Value>
                                          <A.Value color="rgba(0, 0, 0, 0.9)">
                                            {data.target_cnt}
                                          </A.Value> */}
                                        </A.RightTopPart_Value>
                                      </A.RightTop_Part>
                                    </A.RightBottom_Part>
                                  </A.CardRight_Part>
                                </A.Main_Card>
                              </A.Card_left>
                              <A.Card_Right>
                                <A.MainRight_Part>
                                  <A.RightLeft_Part>
                                    <A.CardLeftPart_Top>
                                      {interestList.some(
                                        (item) => item.main_seq === data.buy_seq
                                      ) ? (
                                        <img
                                          className="interest_img"
                                          role="button"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/icon_interest_click.png"
                                          }
                                          onClick={() => {
                                            if (
                                              !localStorage.getItem(
                                                "access_token"
                                              )
                                            ) {
                                              return toast.error(
                                                "로그인 후 이용가능합니다.",
                                                {
                                                  duration: 500,
                                                }
                                              );
                                            }
                                            deInterest({
                                              code: "buy",
                                              interest_seq: interestList.find(
                                                (el2) =>
                                                  el2.main_seq === data.buy_seq
                                              ).interest_seq,
                                            }).then(() => {
                                              getInterestList();
                                            });
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className="interest_img"
                                          role="button"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/icon_interest.png"
                                          }
                                          onClick={() => {
                                            if (
                                              !localStorage.getItem(
                                                "access_token"
                                              )
                                            ) {
                                              return toast.error(
                                                "로그인 후 이용가능합니다.",
                                                {
                                                  duration: 500,
                                                }
                                              );
                                            }
                                            InsInterest({
                                              code: "buy",
                                              buy_seq: data.buy_seq,
                                              target_seq: data.target_seq,
                                            }).then(() => {
                                              getInterestList();
                                            });
                                          }}
                                        />
                                      )}
                                      <A.Value>관심등록</A.Value>
                                    </A.CardLeftPart_Top>
                                    <A.CardLeftPart_Top>
                                      <A.Value2>등록일</A.Value2>
                                      <A.Value2 color={"rgba(68, 68, 68, 0.9)"}>
                                        {data.reg_dt}
                                      </A.Value2>
                                    </A.CardLeftPart_Top>
                                  </A.RightLeft_Part>
                                  <A.RightRight_Part>
                                    {buyButton(data, 2103, true)}
                                  </A.RightRight_Part>
                                </A.MainRight_Part>
                              </A.Card_Right>
                            </A.Card>
                          </A.Card_Wrapper>
                        )
                      )}
                      {buyList.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab}/>
        </div>
      </>
    );
  }
  return null;
}

export default CorporateBuying;
