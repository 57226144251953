import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { toast } from "wc-toast";
import { postInsQna } from "../../api/customerCenter";

function QA() {
  const [qnaReq, setQnaReq] = useState({
    qna_files: [],
    title: "",
    contents: "",
    alarm_yn: "N",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    const allowedExtensions = ["jpg", "jpeg", "png"];
    if (files?.length > 0) {
      const extension = files[0].name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        return toast.error("jpg, jpeg, png 파일만 첨부할 수 있습니다.");
      }
    }

    let newValue = null;

    if (name === "qna_files") {
      if (qnaReq?.qna_files.length > 4) {
        toast.error("파일은 5개만 등록 가능합니다.");
        return;
      }
      newValue = [...qnaReq?.qna_files, files[0]];
    } else {
      newValue = type === "checkbox" ? (checked ? "Y" : "N") : value;
    }

    setQnaReq((prevQnaReq) => ({
      ...prevQnaReq,
      [name]: newValue,
    }));
  };

  const handleSubmit = async () => {
    if (!localStorage.getItem("access_token")) {
      return toast.error("로그인 후 이용가능 합니다.", {
        duration: 500,
      });
    }
    if (!qnaReq.title || qnaReq.title === "") {
      return toast.error("제목을 입력해 주세요.");
    }
    if (!qnaReq.contents || qnaReq.contents === "") {
      return toast.error("내용을 입력해 주세요.");
    }
    if (!qnaReq.alarm_yn || qnaReq.alarm_yn === "") {
      return toast.error("알림 여부를 선택해 주세요.");
    }

    const response = await postInsQna(qnaReq);
    if (response.code !== 1000) {
      return console.log("등록 실패");
    }
    toast.success("등록되었습니다.");
    setQnaReq({
      qna_files: [],
      title: "",
      contents: "",
      alarm_yn: "N",
    });
  };

  const handleNavigate = (path) => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    navigate(path, {
      state: {
        main: 4, // 정보관리 탭으로 설정
        sub: "first", // 하위 탭을 'first'로 리셋
      },
    });
  };

  useEffect(() => {}, [qnaReq]);

  return (
    <div className="question_answer">
      <div className="sub_title">
        <p>제목</p>
      </div>
      <div className="subject">
        <input
          type="text"
          className="w-100"
          placeholder="제목을 입력해 주세요"
          name="title"
          value={qnaReq.title}
          onChange={handleChange}
          maxLength={50}
        />
        <span className="title">{qnaReq?.title.length}/50</span>
      </div>
      <div className="sub_title">
        <p>내용</p>
      </div>
      <div className="detail">
        <textarea
          className="w-100"
          placeholder="문의 내용을 입력해 주세요"
          name="contents"
          value={qnaReq.contents}
          onChange={handleChange}
          maxLength={100}
        ></textarea>
        <span className="title">{qnaReq?.contents.length}/100</span>
      </div>
      <div className="sub_title">
        <p>
          첨부파일 <span>(선택)</span>
        </p>
      </div>
      <input
        type="file"
        id="upload-qa"
        className="w-100 d-none"
        name="qna_files"
        multiple
        accept=".jpg, .jpeg, .png"
        onChange={handleChange}
      />
      <label htmlFor="" className="attached_file d-flex">
        <label
          htmlFor="upload-qa"
          style={{
            display: "block",
            width: "50px",
          }}
        >
          <img className="plus" src="/img/plus.png" alt="" />
        </label>
        <img className="attachment" src="/img/attach.svg" alt="" />
        {/*<br></br>*/}
        <span className="title">
          ※ 첨부가능 : jpg, jpeg, png / 첨부파일은 최대 5개 등록 가능합니다.
        </span>
        {qnaReq?.qna_files && qnaReq?.qna_files.length > 0 && (
          <ul>
            {qnaReq?.qna_files?.map((file, index) => (
              <div className="d-flex justify-content-between align-items-center">
                <div key={index}>{file?.name}</div>
                <img
                  role="button"
                  src="img/icon-close.png"
                  alt=""
                  className="delete-btn"
                  onClick={() => {
                    const newFiles = qnaReq.qna_files?.filter((item) => {
                      return item?.name !== file?.name;
                    });
                    setQnaReq({ ...qnaReq, qna_files: newFiles });
                  }}
                />
              </div>
            ))}
          </ul>
        )}
      </label>
      <div className="qa-label" style={{ paddingLeft: "25px" }}>
        <Form.Check
          type="checkbox"
          name="alarm_yn"
          label={"답변이 등록되면 알림으로 알려드릴까요?"}
          onChange={handleChange}
          className="CustomCheckbox"
          checked={qnaReq?.alarm_yn === "Y"}
        />
      </div>
      <div className="qa-label">
        ※ 문의하신 내용은{" "}
        <span
          onClick={() => handleNavigate("/main-tab")}
          style={{ cursor: "pointer" }}
        >
          [마이페이지 &gt; 1:1 문의내역]
        </span>{" "}
        에서 확인 하실 수 있습니다.
      </div>
      <div className="main-btn d-flex justify-content-center">
        <button onClick={handleSubmit}>문의하기</button>
      </div>
    </div>
  );
}

export default QA;
