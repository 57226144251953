import { Link } from "react-router-dom";
import * as R from "../../../../css/PersonalSellingRequest/requestForm";
import { toast } from "wc-toast";
import { DaumPostcode } from "../../../../utils/DaumPostCode";
import React, { useEffect, useState } from "react";
import {
  postNumbaChgPasswd,
  postNumbaCodeList,
  postNumbaSelCheckno,
  postNumbaSelUserInfo,
  postNumbaSendSms,
} from "../../../../api/auth";
import { postNumbaUpdUserInfo } from "../../../../api/mypage";
import { businessAxios } from "../../../../api/apiCore";

export const EditMemberCorp = () => {
  const regex_password =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&^#])[A-Za-z\d@$!%*?&^#]{8,}$/;
  const passwordsInit = {
    old_passwd: "",
    new_passwd1: "",
    new_passwd2: "",
    wrong_password: false,
  };

  const [changeIndex, setChangeIndex] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [loginUserInfo, setLoginUserInfo] = useState({
    user_nm: "",
    ceo_nm: "",
    zip: "",
    addr1: "",
    addr2: "",
    phone: "",
    region_cd: "",
    user_type_cd: 0,
    corp_nm: "",
    brno: "",
    brno_auth_flag: "",
    corp_kind_cd: 0,
  });
  const [passwords, setPasswords] = useState(passwordsInit);
  const [regionCdList, setRegionCdList] = useState([]);
  const [creditCode, setCreditCode] = useState("");
  const [corpInfo, setCorpInfo] = useState({
    b_no: "",
    b_nm: "",
    p_nm: "",
    start_dt: "",
  });
  const [senCode, setSendCode] = useState(false);

  const getRegionCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1000 });
      if (response.code === 1000) {
        setRegionCdList(response.resultList[0]);
      } else {
        console.log("지역코드 가져오기 실패");
      }
    } catch (err) {
      console.log("지역코드 가져오기 실패");
    }
  };

  useEffect(() => {
    selUserInfo();
    getRegionCd();
  }, []);

  // 회원정보 가져오기
  const selUserInfo = async () => {
    try {
      const response = await postNumbaSelUserInfo({});

      if (response.code === 1000) {
        setLoginUserInfo(response);
      } else {
        console.log("유저정보 가져오기 실패");
      }
    } catch (error) {
      console.log("유저정보 가져오기 실패");
    }
  };

  // 비밀번호 변경
  const handleChangePassword = async () => {
    if (passwords.new_passwd1 !== passwords.new_passwd2) {
      toast.error("새 비밀번호가 일치하지 않습니다.");
      return;
    }

    postNumbaChgPasswd(passwords)
      .then((res) => {
        if (res.code === 1000) {
          toast.success("비밀번호가 변경되었습니다!");
          setPasswords(passwordsInit);
          setChangeIndex([false, false, false, false, false]);
        } else {
          return toast.error("비밀번호가 일치하지 않습니다!");
        }
      })
      .catch((err) => {
        return toast.error("비밀번호가 일치하지 않습니다!");
      });
  };

  // 인증번호 전송
  const callSendSMS = async () => {
    postNumbaSendSms({
      user_id: loginUserInfo.user_id,
      phone: loginUserInfo.phone,
    })
      .then((res) => {
        if (res.code === 1000) {
          toast.success("인증번호가 전송되었습니다.");
          setSendCode(true);
        } else {
          toast.success("인증번호 전송이 실패했습니다.");
        }
      })
      .catch((err) => {
        toast.success("인증번호 전송이 실패했습니다.");
      });
  };

  // 인증번호 확인 및 휴대폰번호 변경
  const selCheckno = async () => {
    postNumbaSelCheckno({
      user_id: loginUserInfo.user_id,
      pass_no: Number(creditCode),
    })
      .then((res) => {
        if (res.code === 1000) {
          toast.success("변경되었습니다.");
          setChangeIndex([
            changeIndex[0],
            changeIndex[1],
            changeIndex[2],
            false,
            changeIndex[4],
          ]);
          setCreditCode("");
          postNumbaUpdUserInfo(loginUserInfo).then((res) => {
            if (res.code === 1000) {
              selUserInfo();
            }
          });
        } else {
          return toast.error("인증번호를 확인해 주세요.");
        }
      })
      .catch((err) => {
        return toast.error("인증번호를 확인해 주세요.");
      });
  };

  const btn_upd_address = () => {
    if (!loginUserInfo.region_cd) {
      toast.error("지역을 선택해 주세요.");
      return;
    }

    // if (!loginUserInfo.zip) {
    //   toast.error("우편번호를 입력해 주세요.");
    //   return;
    // }

    // if (!loginUserInfo.addr1) {
    //   toast.error("도로명주소를 입력해 주세요.");
    //   return;
    // }

    // if (!loginUserInfo.addr2) {
    //   toast.error("상세주소를 입력해 주세요.");
    //   return;
    // }

    postNumbaUpdUserInfo(loginUserInfo)
      .then((res) => {
        if (res.code === 1000) {
          toast.success("지역 변경에 성공했습니다.");
          selUserInfo();
          setChangeIndex([
            changeIndex[0],
            changeIndex[1],
            changeIndex[2],
            changeIndex[3],
            false,
          ]);
        } else {
          console.log(res.code);
          return toast.error("지역 변경에 실패했습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        return toast.error("지역 변경에 실패했습니다.");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "phone":
        setLoginUserInfo((prevProps) => ({
          ...prevProps,
          [name]: value.replace(/[^0-9]/g, ""),
        }));
        break;
      default:
        setLoginUserInfo((prevProps) => ({
          ...prevProps,
          [name]: value,
        }));
    }
  };

  const handleInputCorpChange = (event) => {
    const { name, value } = event.target;
    setCorpInfo((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const corpCheckReq = async () => {
    await businessAxios
      .post(
        "",
        {
          businesses: [corpInfo],
        },
        {
          headers: {
            Accept: "application/json;charset=utf-8",
            Authorization:
              "Infuser zDKnYdVLWkFMvTOpWnNxkngD1aBrSiCe2wMM6XLTDeeMYX+SDIAw9tMW3wuS7MbIjqhv6+inohw64RLLRh9ZiQ==",
          },
        }
      )
      .then((res) => {
        if (res.data.data[0].valid === "02") {
          return toast.error("법인정보를 확인할 수 없습니다.");
        }
        setLoginUserInfo((prev) => {
          postNumbaUpdUserInfo({
            ...prev,
            corp_nm: corpInfo.b_nm,
            brno: corpInfo.b_no,
            ceo_nm: corpInfo.p_nm,
          })
            .then((res) => {
              if (res.code === 1000) {
                toast.success("법인정보가 수정되었습니다.");
                selUserInfo();
                setChangeIndex([
                  false,
                  changeIndex[1],
                  changeIndex[2],
                  changeIndex[3],
                  changeIndex[4],
                ]);
              } else {
                return toast.error("법인정보 수정실패");
              }
            })
            .catch((err) => {
              return toast.error("법인정보 수정실패");
            });
          return {
            ...prev,
            corp_nm: corpInfo.b_nm,
            brno: corpInfo.b_no,
            ceo_nm: corpInfo.p_nm,
          };
        });
      })
      .catch((error) => {
        return toast.error("법인정보를 확인해 주세요.");
      });
  };

  const updUserNm = () => {
    if (!loginUserInfo.user_nm) {
      return toast.error("담당자명을 입력해 주세요.");
    }
    postNumbaUpdUserInfo(loginUserInfo)
      .then((res) => {
        if (res.code === 1000) {
          toast.success("담당자명이 수정되었습니다.");
          selUserInfo();
          setChangeIndex([
            changeIndex[0],
            changeIndex[1],
            false,
            changeIndex[3],
            changeIndex[4],
          ]);
        } else {
          return toast.error("담당자명 수정실패");
        }
      })
      .catch((err) => {
        return toast.error("담당자명 수정실패");
      });
  };

  return (
    <div className="content-container QAMainDiv mypage_wrapper number-interest QAMainDivMember">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">
              <h1>회원정보</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="editMemberMainDiv mb-120px">
              <div className="borderDiv">
                <table className="table m-0">
                  <tbody>
                    <tr className="EditTr">
                      <th>법인명</th>
                      <td>{loginUserInfo?.corp_nm}</td>
                      <td className="text-end">
                        {!changeIndex[0] && (
                          <Link
                            className="btn_outline"
                            onClick={(e) => {
                              e.preventDefault();

                              setChangeIndex([
                                !changeIndex[0],
                                false,
                                false,
                                false,
                                false,
                              ]);
                            }}
                          >
                            변경
                          </Link>
                        )}
                      </td>
                    </tr>
                    {changeIndex[0] && (
                      <>
                        <tr className="EditTr">
                          <th>새 법인명</th>
                          <td>
                            <R.Table_Input
                              className={"ms-0"}
                              placeholder={
                                "법인명을 입력하세요 ex) 주식회사 넘버로"
                              }
                              name={"b_nm"}
                              onChange={handleInputCorpChange}
                            />
                          </td>
                        </tr>
                        <tr className="EditTr">
                          <th>사업자등록번호</th>
                          <td>
                            <R.Table_Input
                              className={"ms-0"}
                              placeholder={"'-'없이 입력"}
                              name={"b_no"}
                              onChange={handleInputCorpChange}
                            />
                          </td>
                        </tr>
                        <tr className="EditTr">
                          <th>대표자명</th>
                          <td>
                            <div className="d-flex justify-content-between">
                              <R.Table_Input
                                className={"ms-0"}
                                placeholder={"대표자명을 입력하세요"}
                                name={"p_nm"}
                                onChange={handleInputCorpChange}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="EditTr">
                          <th className="va-sm-top">개업일자</th>
                          <td>
                            <div className="row align-items-center">
                              <div className="col-md-6 col-sm-12">
                                <R.Table_Input
                                  className={"ms-0 me-0"}
                                  placeholder={"개업일자 ex)20240101"}
                                  name={"start_dt"}
                                  onChange={handleInputCorpChange}
                                />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                {changeIndex[0] && (
                                  <>
                                    <div className="buttons-end">
                                      <Link
                                        className="bg-orange"
                                        onClick={corpCheckReq}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        법인명 변경 확인
                                      </Link>
                                      <Link
                                        className="bg-grey ms-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setChangeIndex([
                                            !changeIndex[0],
                                            changeIndex[1],
                                            changeIndex[2],
                                            changeIndex[3],
                                          ]);
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        취소
                                      </Link>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr className="EditTr">
                      <th>아이디</th>
                      <td>{loginUserInfo?.user_id ?? ""} </td>
                    </tr>
                    <tr className="EditTr">
                      <th>비밀번호</th>
                      <td>**********</td>
                      <td className="text-end">
                        {!changeIndex[1] && (
                          <Link
                            className="btn_outline"
                            onClick={(e) => {
                              e.preventDefault();

                              setChangeIndex([
                                false,
                                !changeIndex[1],
                                false,
                                false,
                                false,
                              ]);
                            }}
                          >
                            변경
                          </Link>
                        )}
                      </td>
                    </tr>

                    {changeIndex[1] && (
                      <>
                        <tr className="EditTr">
                          <th>현재 비밀번호</th>
                          <td>
                            <R.Table_Input
                              type="password"
                              className={"ms-0 me-3"}
                              value={passwords.old_passwd}
                              onChange={(e) =>
                                setPasswords({
                                  ...passwords,
                                  old_passwd: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr className="EditTr">
                          <th>새비밀번호</th>
                          <td>
                            <R.Table_Input
                              type="password"
                              className={"ms-0 me-3"}
                              placeholder={
                                "영문,숫자,특수문자 혼합 6-16자 이내"
                              }
                              value={passwords.new_passwd1}
                              onChange={(e) =>
                                setPasswords({
                                  ...passwords,
                                  new_passwd1: e.target.value,
                                })
                              }
                              onBlur={() => {
                                if (
                                  !regex_password.test(passwords.new_passwd1)
                                ) {
                                  setPasswords({
                                    ...passwords,
                                    wrong_password: true,
                                  });
                                } else {
                                  setPasswords({
                                    ...passwords,
                                    wrong_password: false,
                                  });
                                }
                              }}
                            />
                            {passwords.wrong_password && (
                              <div style={{ color: "red" }}>
                                비밀번호는 영문, 숫자, 특수문자를 포함한 6-16자
                                이내여야 합니다.
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr className="EditTr">
                          <th className="va-sm-top">새비밀번호 확인</th>
                          <td className="pe-0">
                            <div className="row align-items-center">
                              <div className="col-md-6 col-sm-12">
                                <R.Table_Input
                                  className={"ms-0 me-0"}
                                  placeholder={
                                    "영문,숫자,특수문자 혼합 6-16자 이내"
                                  }
                                  type="password"
                                  value={passwords.new_passwd2}
                                  onChange={(e) =>
                                    setPasswords({
                                      ...passwords,
                                      new_passwd2: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                {changeIndex[1] && (
                                  <div className="buttons-end">
                                    <Link
                                      className="bg-orange"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleChangePassword();
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      비밀번호 변경 확인
                                    </Link>
                                    <Link
                                      className="bg-grey ms-2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setChangeIndex([
                                          changeIndex[0],
                                          false,
                                          changeIndex[2],
                                          changeIndex[3],
                                          changeIndex[4],
                                        ]);
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      취소
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr className="EditTr">
                      <th className="va-sm-top">담당자명</th>
                      <td>
                        {changeIndex[2] ? (
                          <React.Fragment>
                            <R.Table_Input
                              className={"ms-0 me-0"}
                              name={"user_nm"}
                              onChange={(e) => handleInputChange(e)}
                            />
                            <div className="mt-10px buttons-end float-end">
                              <Link
                                className="bg-orange"
                                onClick={(e) => {
                                  e.preventDefault();
                                  updUserNm();
                                  setChangeIndex([
                                    changeIndex[0],
                                    changeIndex[1],
                                    !changeIndex[2],
                                    changeIndex[3],
                                    changeIndex[4],
                                  ]);
                                }}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                담당자명 변경 확인
                              </Link>
                              <Link
                                className="bg-grey ms-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setChangeIndex([
                                    changeIndex[0],
                                    changeIndex[1],
                                    !changeIndex[2],
                                    changeIndex[3],
                                  ]);
                                }}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                취소
                              </Link>
                            </div>
                          </React.Fragment>
                        ) : (
                          loginUserInfo?.user_nm ?? ""
                        )}
                      </td>

                      {changeIndex[2] ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <td className="text-end ps-0">
                          <Link
                            className="btn_outline"
                            onClick={(e) => {
                              e.preventDefault();
                              setChangeIndex([
                                false,
                                false,
                                !changeIndex[2],
                                false,
                                false,
                              ]);
                            }}
                          >
                            변경
                          </Link>
                        </td>
                      )}
                    </tr>
                    <tr className={changeIndex[3] ? "EditTr mb-0" : "EditTr"}>
                      <th>
                        담당자
                        <br className="mobile_view" />
                        휴대폰번호
                      </th>
                      {changeIndex[3] ? (
                        <td className="pb-xs-0">
                          <R.Table_Input
                            className={"ms-0 me-0"}
                            name="phone"
                            onChange={(e) => handleInputChange(e)}
                            maxLength={11}
                          />
                        </td>
                      ) : (
                        <td>{loginUserInfo?.phone}</td>
                      )}
                      <td className="ps-0 pb-xs-0">
                        {!changeIndex[3] && (
                          <Link
                            className="float-end btn_outline"
                            onClick={(e) => {
                              e.preventDefault();
                              if (!changeIndex[3]) {
                                setChangeIndex([
                                  false,
                                  false,
                                  false,
                                  !changeIndex[3],
                                  false,
                                ]);
                              }
                            }}
                          >
                            변경
                          </Link>
                        )}
                        {changeIndex[3] && !senCode && (
                          <button
                            className={"btn btn-primary float-end btn-auth"}
                            onClick={(e) => {
                              e.preventDefault();
                              if (changeIndex[3] === true) {
                                if (loginUserInfo.phone.length === 11) {
                                  callSendSMS();
                                } else {
                                  toast.error(
                                    "올바르지 않는 휴대폰번호 양식입니다."
                                  );
                                }
                              }
                            }}
                          >
                            인증번호 전송
                          </button>
                        )}
                      </td>
                    </tr>
                    {changeIndex[3] && (
                      <tr className="EditTr">
                        <th></th>
                        <td className="pt-sm-0">
                          <R.Table_Input
                            placeholder="인증번호"
                            className={"ms-0 me-0"}
                            value={creditCode}
                            onChange={(e) =>
                              setCreditCode(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                            }
                          />
                        </td>
                        <td className="ps-0 pt-sm-0">
                          <button
                            className="btn btn-primary float-end btn-auth"
                            onClick={(e) => {
                              e.preventDefault();
                              selCheckno();
                            }}
                          >
                            인증번호 확인
                          </button>
                        </td>
                      </tr>
                    )}

                    <tr className="EditTr">
                      <th>지역</th>
                      <td>
                        {changeIndex[4] ? (
                          <select
                            className="form-select Table_Select w-300px ms-0 h-sm-36px"
                            name={"region_cd"}
                            value={loginUserInfo.region_cd}
                            onChange={(e) => handleInputChange(e)}
                          >
                            {/*<option value="">지역을 선택해 주세요</option>*/}
                            {regionCdList.map((region, index) => (
                              <option key={index} value={region.cd_seq}>
                                {region.cd_nm}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div>
                            {regionCdList.map((region, index) => {
                              return (
                                region.cd_seq === loginUserInfo.region_cd &&
                                region.cd_nm
                              );
                            })}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr className="EditTr">
                      <th className="va-sm-top">
                        주소 <span>(선택)</span>
                      </th>
                      <td colSpan={3} className="position-relative">
                        {changeIndex[4] ? (
                          <React.Fragment>
                            <div className="d-flex flex-column">
                              <div className="daum_search_wrapper">
                                <div className="d-flex w-100 mb-2">
                                  <input
                                    type="text"
                                    className="form-control input-zip border-sm-1 h-sm-36px"
                                    name={"zip"}
                                    value={loginUserInfo?.zip ?? ""}
                                    readOnly={true}
                                    onClick={() => {
                                      setLoginUserInfo({
                                        ...loginUserInfo,
                                        zip: "",
                                        addr1: "",
                                        addr2: "",
                                      });
                                    }}
                                  />
                                  <DaumPostcode
                                    className="btn btn-primary h-40px me-0 btn-zip h-sm-36px"
                                    onCompleteCallback={(data) => {
                                      setLoginUserInfo({
                                        ...loginUserInfo,
                                        zip: data.zonecode,
                                        addr1: data.fullAddr,
                                      });
                                    }}
                                  />
                                </div>
                                <R.TableInput_Search
                                  className="w-100 mb-2 ms-md-10px ms-sm-0px h-sm-36px"
                                  name={"addr1"}
                                  value={loginUserInfo?.addr1 ?? ""}
                                  readOnly={true}
                                />
                              </div>
                              <div>
                                <R.TableInput_Search
                                  className="ms-0 h-md-46px h-sm-36px mb-2"
                                  name={"addr2"}
                                  value={loginUserInfo?.addr2 ?? ""}
                                  onChange={(e) => handleInputChange(e)}
                                  placeholder={"상세 주소 입력"}
                                />
                              </div>
                              <div className="mt-0 buttons-end">
                                <Link
                                  className="bg-orange"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    btn_upd_address();
                                  }}
                                >
                                  지역 변경 확인
                                </Link>
                                <Link
                                  className="bg-grey ms-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setChangeIndex([
                                      changeIndex[0],
                                      changeIndex[1],
                                      changeIndex[2],
                                      changeIndex[3],
                                      !changeIndex[4],
                                    ]);
                                  }}
                                >
                                  취소
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div>
                            <span>{loginUserInfo?.zip ?? "" + " "}</span>
                            <span>{loginUserInfo?.addr1 ?? "" + " "}</span>
                            <span>{loginUserInfo?.addr2 ?? ""}</span>
                          </div>
                        )}
                      </td>
                      {!changeIndex[4] && (
                        <td className="text-end">
                          <Link
                            className="btn_outline"
                            onClick={(e) => {
                              e.preventDefault();
                              setChangeIndex([
                                false,
                                false,
                                false,
                                false,
                                !changeIndex[4],
                              ]);
                            }}
                          >
                            변경
                          </Link>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
