import styled from "styled-components";

export const ContentWrapper_div = styled.div`
  text-align: center;
  margin: 0 auto;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 100px;
  @media only screen and (max-width: 767.98px) {
    margin-top: 56px;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100vh;
    align-items: flex-start;
  }
`;
export const bodyLogoBrand = styled.div`
  img {
    width: 184px;
    @media only screen and (max-width: 767.98px) {
      width: 149px;
    }
  }
`;

export const LoginInput_Wrapper = styled.div`
  margin-top: 50px !important;
  width: 390px;
  margin: 0 auto;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
    margin-top: 42px !important;
  }
`;

export const LoginInput = styled.input`
  height: 50px;
  width: 100%;
  padding: 13px 20px;
  color: #b3b3b3;
  font-size: 13.999px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 2px solid #e1e1e1;
  border-radius: 5px;
  & + input {
    margin-top: 9px;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #b3b3b3;
  }
  @media only screen and (max-width: 767.98px) {
    height: 40px;
    padding: 12px 16px;
    border-width: 1px;
    font-size: 12px;
    margin-top: 8px;
  }
`;
export const LoginOr_Txt = styled.div`
  margin-top: 23px;
  margin-bottom: 23px;
  color: #656565;
  position: relative;
  @media only screen and (max-width: 767.98px) {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  &:before {
    height: 1px !important;
    width: 44%;
    display: block;
    background: #e1e1e1;
    content: "";
    position: absolute;
    top: 11px;
  }
  &:after {
    height: 1px !important;
    width: 44%;
    display: block;
    background: #e1e1e1;
    content: "";
    position: absolute;
    top: 11px;
    right: 0;
  }
`;
export const login_SpanWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px !important;
  @media only screen and (max-width: 767.98px) {
    margin-top: 22px !important;
  }
`;
export const Login_SpanOne = styled.span`
  color: #656565;
  font-size: 14.998px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 767.98px) {
    font-size: 12px;
  }
`;
export const Login_SpanTwo = styled.span`
  color: #656565;
  font-size: 14.998px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  line-height: normal;
  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #e1e1e1;
    left: -1px;
    top: 5px;
    @media only screen and (max-width: 767.98px) {
      height: 10px;
    }
  }
  @media only screen and (max-width: 767.98px) {
    font-size: 12px;
  }
`;
export const LoginBottom_Para = styled.p`
  color: #656565;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 45px;
  margin-bottom: 0;
  @media only screen and (max-width: 767.98px) {
    margin-top: 36px;
    font-size: 13px;
  }
`;
export const LoginBottom_ParaYellow = styled.p`
  color: #f27d00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
  @media only screen and (max-width: 767.98px) {
    font-size: 14px;
  }
  a {
    color: #f27d00;
    &:hover {
      text-decoration-line: none;
    }
  }
`;
export const Message_icon = styled.img`
  position: absolute;
  left: 25px;
  top: 11px;
  @media only screen and (max-width: 767.98px) {
    width: 36px;
    top: 9px;
    left: 20px;
  }
`;
