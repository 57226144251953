import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import refresh from "./refresh";

export default function useRefresh() {
  const { refreshAPI } = refresh();

  const refreshTimeOut = (accessToken) => {
    console.log("here");
    const decoded = jwtDecode(accessToken);
    const exp = new Date(decoded?.token_expire_time);

    console.log(exp - Date.now() - 10000, exp);
    setTimeout(async () => {
      const res = await refreshAPI();
      const accessToken = res?.data?.access_token;
      console.log("accessToken", accessToken);
      localStorage.setItem("access_token", accessToken);
      console.log("타임아웃");
    }, exp - Date.now() - 10000);
  };

  return { refreshTimeOut };
}
