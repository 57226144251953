import React, { useEffect, useState } from "react";
import { Col, Container,Row, Table } from "react-bootstrap";
// import * as R from "../../css/PersonalSellingRequest/requestForm";
import * as R from "../../../../../css/PersonalSellingRequest/requestForm";
import moment from "moment";
import { headerText } from "../../../../../App";
import { useAtom } from "jotai";
import getUserInfo from "../../../../../utils/hooks/getUserInfo";
import { BtnYellow } from "../../../../../css/components/button/buttonYellow";
import { postSaleInsCBuy } from "../../../../../api/sale";
import { toast } from "wc-toast";
import { useNavigate } from "react-router-dom";
import CommonRegistration from "../../registration-information/CommonRegistration";
import { postNumbaUpdCSellCBuy } from "../../../../../api/mypage";
// import TablePopup from "../../../../componant/modal/tableModal";

export const TradingBuyingInfo = ({ buyReqData }) => {
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    setTableList(
      Object.keys(buyReqData)
        .filter((el) => buyReqData[el]?.target_seq)
        .map((el) => buyReqData[el])
    );
  }, [buyReqData]);

  return (
    <div className="FormDIv">
      <R.FormDetails_Heading>매물정보</R.FormDetails_Heading>
      <R.FormDetails_Div>
        {/* desktop table */}
        {/*<div className="d-none d-md-block">*/}
        {/*  <Table bordered responsive>*/}
        {/*    <tbody>*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th>유형</R.FormTable_Th>*/}
        {/*        <td className="paddingLeft">*/}
        {/*          {buyReqData?.type === 2102 && "법인넘버 매매"}*/}
        {/*        </td>*/}
        {/*        <R.FormTable_Th>톤수</R.FormTable_Th>*/}
        {/*        <td className="paddingLeft">{buyReqData?.car_weight_nm}</td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        /!* <R.FormTable_Th>차량연식</R.FormTable_Th>*/}
        {/*        <td className="paddingLeft">*/}
        {/*          {buyReqData?.car_yyyymm && buyReqData?.car_yyyymm.length > 6*/}
        {/*            ? moment(buyReqData?.car_yyyymm).format("YYYY.MM")*/}
        {/*            : buyReqData?.car_yyyymm}*/}
        {/*        </td> *!/*/}
        {/*        <R.FormTable_Th>대수</R.FormTable_Th>*/}
        {/*        <td className="paddingLeft" colSpan={3}>*/}
        {/*          {`1대 (${*/}
        {/*            buyReqData?.jigong_flag === "G" ? "공 T/E" : "지입"*/}
        {/*          })`}*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th>판매자 지역</R.FormTable_Th>*/}
        {/*        <td className="paddingLeft">{buyReqData?.region_nm}</td>*/}
        {/*        <R.FormTable_Th>판매금액</R.FormTable_Th>*/}
        {/*        <td className="paddingLeft">*/}
        {/*          <R.Table_Link>*/}
        {/*            {buyReqData?.price?.toLocaleString()}*/}
        {/*          </R.Table_Link>*/}
        {/*          &nbsp; 만원*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    </tbody>*/}
        {/*  </Table>*/}
        {/*</div>*/}

        {tableList.map((el, idx) => {
          return (
            <div className="d-none d-md-block">
              <Table bordered responsive>
                <tbody>
                  <tr>
                    <R.FormTable_Th width={"20%"}>유형</R.FormTable_Th>
                    <td className="paddingLeft" style={{ width: "35%" }}>
                      {buyReqData?.type === 2102 && "법인넘버 매매"}
                    </td>
                    <R.FormTable_Th>톤수</R.FormTable_Th>
                    <td className="paddingLeft" style={{ width: "35%" }}>
                      {el?.car_weight_nm}
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th>차량연식</R.FormTable_Th>
                    <td className="paddingLeft">
                      {el?.car_yyyymm
                        ? moment(el?.car_yyyymm).format("YYYY.MM")
                        : "-"}
                    </td>
                    <R.FormTable_Th>대수</R.FormTable_Th>
                    <td className="paddingLeft">
                      {`1대 (${el?.jigong_flag === "G" ? "공 T/E" : "지입"})`}
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th>판매자 지역</R.FormTable_Th>
                    <td className="paddingLeft">{el?.region_nm}</td>
                    <R.FormTable_Th>판매금액</R.FormTable_Th>
                    <td className="paddingLeft">
                      <R.Table_Link>{el?.price?.toLocaleString()}</R.Table_Link>
                      &nbsp; 만원 &nbsp;
                      {el?.in_car_yn === 'Y' && "(차량포함)"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          );
        })}

        {/* mobile table */}
        {/*<div className="d-block d-md-none">*/}
        {/*  <Table bordered responsive>*/}
        {/*    <tbody>*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th width={"25%"}>유형</R.FormTable_Th>*/}
        {/*        <td>{buyReqData?.type === 2102 && "법인넘버 매매"}</td>*/}
        {/*      </tr>*/}
        {/*      /!* <tr>*/}
        {/*        <R.FormTable_Th>차량연식</R.FormTable_Th>*/}
        {/*        <td>*/}
        {/*          {buyReqData?.car_yyyymm*/}
        {/*            ? moment(buyReqData?.car_yyyymm).format("YYYY.MM")*/}
        {/*            : buyReqData?.car_yyyymm}*/}
        {/*        </td>*/}
        {/*      </tr> *!/*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th>톤</R.FormTable_Th>*/}
        {/*        <td>{buyReqData?.car_weight_nm}</td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th>대수</R.FormTable_Th>*/}
        {/*        <td>*/}
        {/*          {`1대 (${*/}
        {/*            buyReqData?.jigong_flag === "G" ? "공 T/E" : "지입"*/}
        {/*          })`}*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th>판매자 지역</R.FormTable_Th>*/}
        {/*        <td>{buyReqData?.region_nm}</td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <R.FormTable_Th>판매금액</R.FormTable_Th>*/}
        {/*        <td>*/}
        {/*          <R.Table_Link>*/}
        {/*            {buyReqData?.price?.toLocaleString()}*/}
        {/*          </R.Table_Link>*/}
        {/*          &nbsp; 만원*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    </tbody>*/}
        {/*  </Table>*/}
        {/*</div>*/}

        {tableList.map((el, idx) => {
          return (
            <div className="d-block d-md-none">
              <Table bordered responsive className="table-form">
                <tbody>
                  <tr>
                    <R.FormTable_Th width={"25%"}>유형</R.FormTable_Th>
                    <td>{buyReqData?.type === 2102 && "법인넘버 매매"}</td>
                  </tr>
                  {/* <tr>
                <R.FormTable_Th>차량연식</R.FormTable_Th>
                <td>
                  {buyReqData?.car_yyyymm
                    ? moment(buyReqData?.car_yyyymm).format("YYYY.MM")
                    : buyReqData?.car_yyyymm}
                </td>
              </tr> */}
                  <tr>
                    <R.FormTable_Th>톤</R.FormTable_Th>
                    <td>{el?.car_weight_nm}</td>
                  </tr>
                  <tr>
                    <R.FormTable_Th>대수</R.FormTable_Th>
                    <td>
                      {`1대 (${el?.jigong_flag === "G" ? "공 T/E" : "지입"})`}
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th>차량연식</R.FormTable_Th>
                    <td>
                      {el?.car_yyyymm
                        ? moment(el?.car_yyyymm).format("YYYY.MM")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <R.FormTable_Th>판매자 지역</R.FormTable_Th>
                    <td>{el?.region_nm}</td>
                  </tr>
                  <tr>
                    <R.FormTable_Th>판매금액</R.FormTable_Th>
                    <td>
                      <R.Table_Link>{el?.price?.toLocaleString()}</R.Table_Link>
                      &nbsp; 만원 &nbsp;
                      {el?.in_car_yn === 'Y' && "(차량포함)"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          );
        })}
      </R.FormDetails_Div>
    </div>
  );
};

export const TradingRequestForm = ({ subActiveTab, buyingData, isEdit }) => {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [modalShow, setModalShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [buyReqData, setBuyReqData] = useState({});
  const navigate = useNavigate();

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      const userInfo = await getUserInfo();
      setBuyReqData({ ...buyReqData, ...userInfo });
    } else {
      setBuyReqData({ ...buyReqData, user_nm: "", phone: "" });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBuyReqData({ ...buyReqData, [name]: value });
  };

  const callInsCBuy = async (data) => {
    const filter = Object.keys(buyReqData)
      .filter((el) => buyReqData[el]?.target_seq)
      .map((el) => buyReqData[el]);

    const msg = validateSellReq(buyReqData);
    if (msg) {
      return toast.error(msg);
    }

    try {
      const response = await Promise.all(
        filter.map(async (el) => {
          return await postSaleInsCBuy({
            ...el,
            ...data,
            car_yyyymm: el.car_yyyymm
              ? el.car_yyyymm?.replace(/[^0-9]/g, "")
              : "",
          });
        })
      );

      response.map((el) => {
        switch (el?.code) {
          case 1000:
            navigate("/complete-request", {
              state: data,
              replace: true,
            });
            break;
          case -19:
            toast.error("이미 진행중인 매물입니다.");
            break;
          case -22:
            toast.error("자기 자신의 매물입니다.");
            break;
          case -23:
            toast.error("이미 신청한 매물입니다.");
            break;
          default:
            toast.error("구매신청 실패");
            navigate(-1);
            break;
        }
      });
    } catch (err) {
      switch (err.code) {
        case -19:
          toast.error("이미 진행중인 매물입니다.");
          break;
        case -22:
          toast.error("자기 자신의 매물입니다.");
          break;
        case -23:
          toast.error("이미 신청한 매물입니다.");
          break;
        default:
          toast.error("구매신청 실패");
          navigate(-1);
          break;
      }
    }

    // try {
    // const response = await postSaleInsCBuy({
    //   ...data,
    //   car_yyyymm: data.car_yyyym
    //     ? data.car_yyyymm?.replace(/[^0-9]/g, "")
    //     : "",
    // });

    // switch (response?.code) {
    //   case 1000:
    //     navigate("/complete-request", {
    //       state: data,
    //       replace: true,
    //     });
    //     break;
    //   case -19:
    //     toast.error("이미 진행중인 매물입니다.");
    //     break;
    //   case -22:
    //     toast.error("자기 자신의 매물입니다.");
    //     break;
    //   case -23:
    //     toast.error("이미 신청한 매물입니다.");
    //     break;
    //   default:
    //     toast.error("구매 신청 실패");
    //     navigate(-1);
    //     break;
    // }
    // } catch (err) {
    //   toast.error("구매 신청에 실패했습니다.");
    //   navigate(-1);
    // }
  };

  // 수정
  const editCallInsCBuy = async (data) => {
    try {
      const response = await postNumbaUpdCSellCBuy(data);
      if (response?.code === 1000) {
        toast.success("수정되었습니다.");
      } else {
        toast.error("수정이 실패하였습니다.");
      }
    } catch (err) {
      toast.error("수정이 실패하였습니다.");
    }
    navigate(-1);
  };

  useEffect(() => {
    setHeaderTextAtom("팝니다 구매신청");
  }, []);

  useEffect(() => {
    if (buyingData) {
      setBuyReqData({ ...buyingData });
    }
  }, [buyingData]);

  const validateSellReq = (buyReqData) => {
    if (buyReqData.corp_nm !== "-1") {
      if (!buyReqData.corp_nm || buyReqData.corp_nm === "" || buyReqData.corp_nm === undefined) {
        return "법인명을 확인 해 주세요.";
      }
    }
    if (!buyReqData.user_nm || buyReqData.user_nm === "" || buyReqData.user_nm === undefined) {
      return "구매자 이름을 확인 해 주세요.";
    }
    if (!buyReqData.phone || buyReqData.phone === "" || buyReqData.phone === undefined) {
      return "구매자 휴대폰번호를 확인 해 주세요.";
    }
    if (!buyReqData.zip || buyReqData.zip === "" || buyReqData.zip === undefined) {
      return "우편번호를 확인 해 주세요.";
    }
    if (!buyReqData.addr1 || buyReqData.addr1 === "" || buyReqData.addr1 === undefined) {
      return "도로명주소를 확인 해 주세요.";
    }
    if (!buyReqData.addr2 || buyReqData.addr2 === "" || buyReqData.addr2 === undefined) {
      return "상세주소를 확인 해 주세요.";
    }
  };

  return (
    <>
      <div className="requestFormMain-container">
        <Container className="p-0">
          <Row>
            <Col md={12}>
              <div className="pc_view">
                <R.FormHeading_div>
                  <R.Form_Heading>구매신청</R.Form_Heading>
                </R.FormHeading_div>
              </div>
              <TradingBuyingInfo buyReqData={buyReqData} />
              <CommonRegistration
                buyReqData={buyReqData}
                setBuyReqData={setBuyReqData}
                buyingData={buyingData}
              />
              <div className="mt-3 text-center">
                <BtnYellow
                  width="360px"
                  height="60px"
                  onClick={() => {
                    if (isEdit) {
                      editCallInsCBuy(buyReqData);
                    } else {
                      callInsCBuy(buyReqData);
                    }
                  }}
                >
                  {isEdit ? "수정하기" : "신청하기"}
                </BtnYellow>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <TablePopup show={modalShow} onHide={() => setModalShow(false)} /> */}
      </div>
    </>
  );
};
