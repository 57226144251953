import React, { useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import MobileFooter from "../../componant/mobileFooter";
import { headerText } from "../../App";
import { useAtom } from "jotai";
import { archiveListApi } from "../../api/archive";
import { DUMMY_DATA } from "../../searchData/searchData";

import useWindowSize from "../../utils/hooks/useWindowSize";
import useTable from "../../utils/hooks/useTable";
import { Pagination } from "react-bootstrap";

export default function Archive({ children }) {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [activeTab, setActiveTab] = useState(1);

  const location = useLocation();

  useEffect(() => {
    // if (location?.state) {
    //   setActiveTab(location?.state);
    // } else {
    //   setActiveTab(1);
    // }
    setHeaderTextAtom("자료실");
  }, [location?.state]);

  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    dense,
    order,
    page,
    totalPage,
    orderBy,
    rowsPerPage,
    selected,
    visiblePages,
    onSort,
    onSelectRow,
    onSelectAllRow,
    onChangePage,
    onChangeTotalPage,
    onChangeDense,
    onChangeRowsPerPage,
    // setTotalPage,
  } = useTable({
    defaultDense: "30px",
    defaultOrder: "name",
    defaultOrderDirection: "asc",
    defaultCurrentPage: 1,
    defaultRowsPerPage: 10,
    defaultSelected: {},
    mobileBreakpoint: 768, // px 붙이지 말 것
    maxPagesMobile: 5,
    maxPagesDesktop: 10,
    paginationType: "dynamic",
    Pagination,
  });

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  // const [currentPage, setCurrentPage] = useState(0);

  const [pageNumbers, setPageNumbers] = useState([]);

  const dummyData = DUMMY_DATA(200);

  const fecthData = useCallback(async ({ fetchStartIdx, fetchRowsPerPage }) => {
    try {
      // alert("fetchData");
      const data = { startIdx: fetchStartIdx, rowCnt: fetchRowsPerPage };
      // const data = { startIdx: 11, rowCnt: fetchRowsPerPage };
      // console.log("data:", data);
      const response = await archiveListApi(data);
      // console.log("archive response: ", response);
      if (response.code === 1000) {
        // 추후 더미데이터 제거
        setTableData(response.resultList);
        // setTableData([...dummyData, ...response.resultList]);

        // 추후 더미데이터 길이 제거
        // const totPage = Math.ceil(response.totCnt / 10)

        const totPage = Math.ceil(response.totCnt / 10);
        // const totPage = Math.ceil((dummyData.length + response.totCnt) / 10);
        // setTotalPage(totPage);
        // console.log("totPage: ", totPage);
        onChangeTotalPage(totPage);
        const pages = Array.from({ length: totPage }, (_, i) => i + 1);
        setPageNumbers(pages);
      }
    } catch (error) {
      console.error(error);
      alert("자료실 정보를 가져오지 못했습니다.");
    }
  }, []);

  // useEffect(() => {
  //   console.log("page:", page);
  // }, [page]);

  useEffect(() => {
    fecthData({
      fetchStartIdx: (page - 1) * rowsPerPage,
      fetchRowsPerPage: rowsPerPage,
    });
  }, [page]);

  // URL에서 페이지를 읽어와 내부 페이지 상태를 업데이트
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page"), 10);
    // URL의 page 파라미터와 내부 상태가 다르면 상태를 업데이트합니다.
    // 이 때, 페이지 번호가 유효 범위 내에 있는지 확인합니다.
    if (
      !isNaN(pageFromUrl) &&
      pageFromUrl !== page &&
      pageFromUrl >= 1 &&
      pageFromUrl <= totalPage
    ) {
      onChangePage(pageFromUrl);
    }
  }, [searchParams, totalPage]); // page를 종속성에서 제거

  // 페이지 상태 변경 시 URL의 쿼리스트링을 업데이트
  useEffect(() => {
    const currentPage = page.toString();
    const currentUrlPage = searchParams.get("page") || "1";
    // 내부 페이지 상태와 URL의 page 파라미터가 다르면 URL을 업데이트합니다.
    if (currentPage !== currentUrlPage) {
      setSearchParams({ page: currentPage }, { replace: true });
    }
  }, [page]); // searchParams 종속성 제거

  // useEffect(() => {
  //   console.log(tableData);
  // }, [tableData]);

  // 페이지 이동 처리 함수

  // const handlePageChange = (page) => {
  //   // setCurrentPage(page);
  //   onChangePage(page);
  // };

  // 동적 윈도우 계산
  // 현재 페이지와 총 페이지 수에 따라 보여줄 페이지 번호들을 계산

  return (
    <>
      {/* customer_tab 상단 네비게이션바와 위 아래 간격 주는 div */}
      <div className="customer_tab viewPrice-tab bg-white">
        {/* max-width: 1080px; width: calc(100% - 56px) */}
        <div className="container">
          <div className="main-heading d-md-block d-none">
            <h1 className="text-center">자료실</h1>
          </div>
          <hr className="information-hr pc_view" />

          <div className="list-container">
            <ul className="responsive-list border-top-2px">
              {tableData.map((item) => {
                return (
                  <li className="list-item" key={item.data_seq}>
                    <span className="date">{item.reg_dt}</span>
                    <span
                      className="text"
                      // onClick={() => alert(item.contents)}
                      onClick={() => navigate(`/archive/${item.data_seq}/view`)} // 경로 수정
                      style={{ cursor: "pointer" }}
                    >
                      {item.title}
                    </span>
                    {/* <span className="icon">{item.files.length}📄</span> */}
                    {item.files.length > 0 ? (
                      <span className="icon">📄</span>
                    ) : (
                      <span className="icon"></span>
                    )}
                  </li>
                );
              })}
            </ul>
            {/* {totalPage} */}
            {/* <div className="pagination">페이지네이션</div> */}
            <div className="pagination">
              <span
                onClick={() => onChangePage((prevPage) => 1)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  padding: "0.5em",
                  cursor: "pointer",
                }}
              >
                &lt;&lt;
              </span>
              <span
                onClick={() =>
                  onChangePage((prev) => {
                    const prevPage = prev - 1;
                    if (prevPage < 1) {
                      return prev;
                    }
                    return prevPage;
                  })
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  padding: "0.5em",
                  marginLeft: "0.5em",
                  cursor: "pointer",
                }}
              >
                &lt;
              </span>

              {visiblePages.map((pageNo) => (
                <>
                  <div
                    style={{
                      backgroundColor:
                        pageNo === page ? "#FFDD00" : "transparent",
                      padding: "0.5em",
                      margin: "0.5em",
                      width: "2.5em",
                      height: "2.5em",
                      borderRadius: "50%",
                      textAlign: "center",
                    }}
                  >
                    <button
                      className={pageNo === page ? "active" : ""}
                      key={pageNo}
                      onClick={() => onChangePage(pageNo)}
                    >
                      {pageNo}
                    </button>
                  </div>
                </>
              ))}
              <span
                onClick={() =>
                  onChangePage((prevPage) => {
                    const nextPage = prevPage + 1;
                    if (nextPage > totalPage) {
                      return prevPage;
                    }
                    return nextPage;
                  })
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  padding: "0.5em",
                  marginRight: "0.5em",
                  cursor: "pointer",
                }}
              >
                &gt;
              </span>
              <span
                onClick={() => onChangePage((prev) => totalPage)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  padding: "0.5em",
                  cursor: "pointer",
                }}
              >
                &gt;&gt;
              </span>
            </div>
          </div>
        </div>
      </div>

      <MobileFooter main={true} />
    </>
  );
}
