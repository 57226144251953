import { atom } from "jotai";

export const example_atom = atom("");
export const header_userInfo = atom({
  corp_nm: "",
  phone: "",
  user_nm: "",
  user_id: "",
  user_type_cd: "",
});
export const header_mainData = atom({
  cList: [],
  cListCnt: 0,
  sellBuyList: [],
  sellBuyListCnt: 0,
  interestCnt: 0,
  alramCnt: 0,
});
