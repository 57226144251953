import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../../../css/Selling/subTabContent.style";
import * as B from "../../../../css/buying/buying";
import RoundButton from "../../../../componant/buttons/buttonRound";
import CardButton from "../../../../componant/buttons/cardButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import MobileFooter from "../../../../componant/mobileFooter";
import { toast } from "wc-toast";
import { postNumbaSellList } from "../../../../api/sell";
import InsInterest from "../../../../pages/MyPage/number-of-interest/InsInterest";
import { useNavigate } from "react-router-dom";
import { postNumbaInterestList } from "../../../../api/mypage";
import { postSaleSelLic } from "../../../../api/sale";
import useInfiniteScroll from "../../../../utils/hooks/infinite-scroll/useInfiniteScroll";
import Modal from "react-bootstrap/Modal";
import { useAtom } from "jotai";
import { header_mainData } from "../../../../data/atom/mypage/mypage_atom";
import callMainData from "../../../../utils/hooks/callMainData";
import { deInterest } from "../../../MyPage/number-of-interest/deInterest";
function BrokerageSelling({ activeTab, subActiveTab }) {
  const navigate = useNavigate();
  const [sellList, setSellList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(1);
  const [interestList, setInterestList] = useState([]);
  const [totCnt, setTotCnt] = useState(0);
  const [modalContent, setModalContent] = useState(null);
  const [show, setShow] = useState(false);
  const [option, setOption] = useState({
    order: 2701,
    sellListCnt: 10,
  });
  const [mainData, setMainList] = useAtom(header_mainData);

  const { setBottom } = useInfiniteScroll(() => {
    setOption((prev) => {
      getSellList({ order: prev.order, rowCnt: prev.sellListCnt + 10 }).then(
        (res) => {
          setSellList(res.resultList);
        }
      );
      return { ...prev, sellListCnt: prev.sellListCnt + 10 };
    });
  });

  async function getSellList(data) {
    try {
      const response = await postNumbaSellList({
        startIdx: 0,
        rowCnt: data.rowCnt,
        order_cd: data.order,
        deal_category_cd: 2104,
      });

      if (response.code !== 1000) {
        console.log("팝니다 목록 가져오기 실패");
      }
      return response;
    } catch (error) {
      // console.error("Error fetching sell list:", error);
      console.log("팝니다 목록 가져오기 서버 오류");
    }
  }

  const sellRequest = (data, type) => {
    // if (!localStorage.getItem("access_token")) {
    //   return toast.error("로그인 후 이용가능 합니다.", {
    //     duration: 500,
    //   });
    // }
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    navigate("/selling-request1", {
      state: { data: { ...data, type: type }, isEdit: false },
    });
  };

  const getInterestList = async () => {
    if (!localStorage.getItem("access_token")) {
      return;
    }
    const data = await callMainData();
    setMainList(data);

    try {
      const response = await postNumbaInterestList({
        startIdx: -1,
      });
      if (response.code !== 1000) {
        console.log("관심목록 불러오기 실패");
        return;
      }
      setInterestList(response.resultList);
    } catch (err) {
      console.log("관심목록 불러오기 서버 오류");
    }
  };

  async function getSelLic(data) {
    const response = await postSaleSelLic(data);
    if (response.code !== 1000) {
      return console.log("거래처 판매 내용 불러오기 실패");
    }
    return response;
  }

  useEffect(() => {
    getSellList({ order: option.order, rowCnt: option.sellListCnt })
      .then((res) => {
        setSellList(res.resultList);
        setTotCnt(res.totCnt);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [option]);

  useEffect(() => {
    getInterestList();
  }, []);

  useEffect(() => {}, [interestList]);

  const goRequest = () => {
    // if (!localStorage.getItem("access_token")) {
    //   return toast.error("로그인 후 이용가능 합니다.", {
    //     duration: 500,
    //   });
    // }
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      return;
    }
    navigate("/selling-request", {state: {main: 3, sub: subActiveTab}});
  };

  const buyButton = (data, type) => {
    const process_cd = data?.process_cd;

    switch (+process_cd) {
      case 3005:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#adadad"}
            width="100%"
            height="60px"
            name="거래신청중"
            disabled={true}
          ></CardButton>
        );
      case 3006:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#7F7F7F"}
            width="100%"
            height="60px"
            name="거래승인"
            disabled={true}
          ></CardButton>
        );
      case 3007:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#7F7F7F"}
            width="100%"
            height="60px"
            name="거래진행중"
            disabled={true}
          ></CardButton>
        );
      case 3008:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#BFBFBF"}
            width="100%"
            height="60px"
            name="거래완료"
            disabled={true}
          ></CardButton>
        );
      default:
        return (
          <CardButton
            className="btn_buy"
            maxWidth={"200px"}
            bg={"#26BBE3"}
            width="100%"
            height="60px"
            name="구매신청"
            onClick={() => {
              sellRequest(data, type);
            }}
          ></CardButton>
        );
    }
  };

  const handleGetSelLic = async (data) => {
    await getSelLic({
      sell_seq: data.sell_seq,
      target_seq: data.target_seq,
    }).then((res) => {
      setShow(true);
      setModalContent(res);
    });
  };

  if (subActiveTab === "first") {
    const handleItemClick = async (order) => {
      setOption({ ...option, order: order });
      setSelectedOption(order);
    };

    return (
      <>
        {show && (
          <>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              centered
              // fullscreen={"sm-down"}
              dialogClassName="my_modal modal-552px"
            >
              <Modal.Header closeButton>
                <Modal.Title>거래처 판매내용</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table className="table table-bordered table-responsive table-form table-modal">
                  <tbody>
                    <tr>
                      <th>거래처수</th>
                      <td>{modalContent.cp_cnt}개</td>
                    </tr>
                    <tr>
                      <th>1년 평균 월 매출</th>
                      <td>
                        {modalContent.monthly_amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        만원
                      </td>
                    </tr>
                    <tr>
                      <th>퀵. 화물 매출 비율</th>
                      <td>
                        퀵 {modalContent.quick_rate}% / 화물{" "}
                        {modalContent.freight_rate}%
                      </td>
                    </tr>
                    <tr>
                      <th>희망 거래 가격</th>
                      <td>
                        <span className="text-primary f-bold f-22 f-sm-15">
                          {modalContent.cp_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>{" "}
                        <span className="f-15 f-sm-12">만원</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="btn btn-primary w-100 h-60px h-sm-50px mt-30px f-sm-16"
                  onClick={() => setShow(false)}
                >
                  확인
                </button>
              </Modal.Body>
            </Modal>
          </>
        )}

        <div className="paddingBottom personalSellingPage">
          <B.BuyingBanner_Wrapper
            bg="linear-gradient(270deg, #E5F1FF 0%, #D6E9FF 52%, #E5F1FF 100%);"
            className="py-3 py-md-4 d-md-none d-lg-block d-none"
          >
            <Container className="container-content">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={5} className="align-self-center">
                      <div>
                        <B.Banner_Heading color="#2667B6">
                          넘버로에 양도 매물을 등록하세요
                        </B.Banner_Heading>
                        <B.BannerContent_para color="#4C93E8">
                          넘버로에 양도 매물을 등록하시면
                        </B.BannerContent_para>
                        <B.BannerContent_para color="#4C93E8">
                          빠르고 안전하게 양수자를 매칭 시켜서 양수를 진행해
                          드립니다
                        </B.BannerContent_para>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex">
                        <B.Banner_Img
                          src={process.env.PUBLIC_URL + "/img/car.png"}
                        />
                        <div className="position-relative" onClick={goRequest}>
                          <RoundButton
                            bg="linear-gradient(0deg, #3B85DE 1.33%, #26BBE3 101.67%);"
                            width="150px"
                            height="150px"
                            name="팝니다"
                            secondName="등록하기"
                          ></RoundButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </B.BuyingBanner_Wrapper>
          <Container className="container-content">
            <div className="cardMainWrapper">
              <Row>
                <Col md={12}>
                  <A.BreadCrumb>
                    <A.BreadCrumb_Left>
                      <A.BreadCrumb_value color="#4E92E1" fontSizeSM={"16px"}>
                        {totCnt}
                      </A.BreadCrumb_value>
                      <A.BreadCrumb_value>개 매물</A.BreadCrumb_value>
                    </A.BreadCrumb_Left>
                    <div className="pageBreadcrumb">
                      <A.BreadCrumb_Right>
                        <Dropdown as={ButtonGroup}>
                          <Dropdown.Toggle
                            variant="light"
                            id="dropdown-split-basic"
                          >
                            {option.order === 2703
                              ? "가격 높은순"
                              : option.order === 2702
                              ? "가격 낮은순"
                              : "최신 등록순"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2701)}
                              href="#/action-1"
                            >
                              최신 등록순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2702)}
                              href="#/action-2"
                            >
                              가격 낮은순
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleItemClick(2703)}
                              href="#/action-3"
                            >
                              가격 높은순
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </A.BreadCrumb_Right>
                    </div>
                  </A.BreadCrumb>
                  {sellList.length > 0 ? (
                    <>
                      {sellList.map((data) => (
                        <A.Card_Wrapper key={data.id}>
                          <A.Card>
                            <A.Card_left>
                              <A.Main_Card>
                                <A.CardLeft_Part>
                                  <A.NumberPart color="#4E92E1">
                                    {data.price
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </A.NumberPart>
                                  <A.ValuePart>만원</A.ValuePart>
                                </A.CardLeft_Part>
                                <A.CardRight_Part>
                                  <A.RightTop_Part
                                    mb="0px"
                                    className={
                                      data.cp_cnt > 0
                                        ? "div_yellow_hand"
                                        : "w-100 justify-content-center"
                                    }
                                  >
                                    <A.RightTopPart_Value>
                                      <A.Value>지역</A.Value>
                                      <A.Value color="rgba(0, 0, 0, 0.9)">
                                        {data.region_nm}
                                      </A.Value>
                                    </A.RightTopPart_Value>
                                    {/* {data.cp_cnt == 0 && (
                                      <React.Fragment>
                                        <A.RightTopPart_Value className="yellow_hand">
                                          <A.Value color="rgba(0, 0, 0, 0.9)"></A.Value>
                                        </A.RightTopPart_Value>
                                      </React.Fragment>
                                    )} */}
                                    {data.cp_cnt > 0 && (
                                      <React.Fragment>
                                        <div className="d-flex gap-1">
                                          <img
                                            className="yellow-hand"
                                            src={
                                              process.env.PUBLIC_URL +
                                              `/img/yellow-hand.png`
                                            }
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              if (
                                                !localStorage.getItem(
                                                  "access_token"
                                                )
                                              ) {
                                                return toast.error(
                                                  "로그인 후 이용가능합니다.",
                                                  {
                                                    duration: 500,
                                                  }
                                                );
                                              }
                                              handleGetSelLic(data);
                                            }}
                                            alt=""
                                          />
                                          <A.RightTopPart_Value className="yellow_hand">
                                            <A.Value color="rgba(0, 0, 0, 0.9)">
                                              거래처 판매
                                            </A.Value>
                                          </A.RightTopPart_Value>
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </A.RightTop_Part>
                                </A.CardRight_Part>
                              </A.Main_Card>
                            </A.Card_left>
                            <A.Card_Right>
                              <A.MainRight_Part>
                                <A.RightLeft_Part>
                                  <A.CardLeftPart_Top>
                                    {interestList.some(
                                      (item) => item.main_seq === data.sell_seq
                                    ) ? (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest_click.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          deInterest({
                                            code: "sell",
                                            interest_seq: interestList.find(
                                              (el2) =>
                                                el2.main_seq === data.sell_seq
                                            ).interest_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="interest_img"
                                        role="button"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/icon_interest.png"
                                        }
                                        onClick={() => {
                                          if (
                                            !localStorage.getItem(
                                              "access_token"
                                            )
                                          ) {
                                            return toast.error(
                                              "로그인 후 이용가능합니다.",
                                              {
                                                duration: 500,
                                              }
                                            );
                                          }
                                          InsInterest({
                                            code: "sell",
                                            sell_seq: data.sell_seq,
                                            target_seq: data.target_seq,
                                          }).then(() => {
                                            getInterestList();
                                          });
                                        }}
                                      />
                                    )}
                                    <A.Value>관심등록</A.Value>
                                  </A.CardLeftPart_Top>
                                  <A.CardLeftPart_Top>
                                    <A.Value2>등록일</A.Value2>
                                    <A.Value2 color={"rgba(68, 68, 68, 0.9)"}>
                                      {data.reg_dt}
                                    </A.Value2>
                                  </A.CardLeftPart_Top>
                                </A.RightLeft_Part>
                                <A.RightRight_Part>
                                  {buyButton(data, 2104)}
                                </A.RightRight_Part>
                              </A.MainRight_Part>
                            </A.Card_Right>
                          </A.Card>
                        </A.Card_Wrapper>
                      ))}
                      {sellList.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <div className="card_box intrestCard d-flex align-items-center py-3 justify-content-center">
                      매물이 없습니다.
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <MobileFooter activeTab={activeTab} subActiveTab={subActiveTab} />
        </div>
      </>
    );
  }
  return null;
}

export default BrokerageSelling;
