import React, { useEffect, useState } from "react";
import { RegisterGoodsInfo } from "../registeredProperty/goodsInfo";
import { RegisterUserInfo } from "../registeredProperty/userInfo";
import { ProcessTab } from "../registeredProperty/process";
import moment from "moment";
import { postNumbaDelCSellCBuy } from "../../../../api/mypage";
import { toast } from "wc-toast";
import { useNavigate } from "react-router-dom";
import { postSelReport } from "../../../../api/sale";

// 나의 거래신청 정보메인
export const TransactionInfo = ({ data, callUserCList, count }) => {
  console.log('props 데이터', data);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [isReportData, setIsReportData] = useState();

  const callSelReport = async (seqObj) => {
    try {
      const response = await postSelReport(seqObj);
      if (response.code === 1000) {
        console.log("신고서 조회 데이터: ", response);
        setReportData({ ...response });
        console.log("신고서 조회 성공");
      } else {
        console.log("신고서 조회 실패");
      }
    } catch (e) {
       console.log("신고서 조회 오류");
    }
  };

  const callDel = async (seqObj) => {
    // ******* 취소시 확인창 띄워야 할 듯....
    try {
      const response = await postNumbaDelCSellCBuy(seqObj);
      if (response.code === 1000) {
        toast.success("거래 신청이 취소되었습니다.");
        callUserCList(count);
      } else {
        toast.error("거래 신청 취소중 오류가 발생하였습니다.");
      }
    } catch (e) {
      toast.error("거래 신청 취소중 오류가 발생하였습니다.");
    }
  };

  const goToRequest = () => {
    if (parseInt(data?.deal_category_cd) === 2102 || parseInt(data?.deal_category_cd) === 2103) {
      // 구매신청(cbuy) : RequestTab
      navigate("/selling-request1", {
        state: { data: { 0: data, type: data?.deal_category_cd }, isEdit: true },
      });
    } else {
      navigate("/selling-request1", {
        state: { data: { ...data, type: data?.deal_category_cd }, isEdit: true },
      });
    }
  };

  const goToCSellRequest = () => {
      if (parseInt(data?.deal_category_cd) === 2102 || parseInt(data?.deal_category_cd) === 2103) {
      // 판매신청(csell) : CSellingRequestTab
      navigate("/buying-request1", {
        state: { data: { 0: data, type: data?.deal_category_cd }, isEdit: true },
      });
    } else {
      navigate("/buying-request1", {
        state: { data: { ...data, type: data?.deal_category_cd }, isEdit: true },
      });
    }
  };

  const goToReport = (reportData) => {
    console.log('신고서 조회데이터 보냄:', reportData);
    navigate("/report", {
      state: { data: { ...data}, reportData: reportData ? {...reportData} : null },
    });
  };

  useEffect(() => {
    callSelReport({
      main_seq: data?.main_seq,
      deal_str: data?.deal_str,
      target_seq: data?.target_seq,
    });    
  }, []);

  useEffect(() => {
    const isData = !!(
      (data?.deal_str === "cbuy" && reportData?.buysign_dt) ||
      (data?.deal_str === "csell" && reportData?.sellsign_dt)
    );
    setIsReportData(isData);
    console.log("++++++++++++++++++++++신고데이터있어?", isData);
    console.log("++++++++++++++++++++++report?", reportData);
  }, [data?.deal_str, reportData]);

  return (
    <div className="request-information">
      <div className="row">
        <div className="col-md-12">
          <div className="breadcrumb">
            <ul>
              {data?.deal_str === "cbuy" ? (
                <li style={{ "--color": "rgba(27, 111, 207, 0.9)" }}>구매신청</li>
              ) : (
                <li style={{ "--color": "#f66176" }}>판매신청</li>
              )}
              <li>{data?.deal_category_nm}</li>
            </ul>
            <div className="date-part">
              <p>신청일</p>
              <p>{moment(data?.reg_dt).format("YYYY.MM.DD")}</p>
            </div>
          </div>
          {/* <Process data={data} /> */}
          <ProcessTab data={data} />
          <div className="table-part">
            <div className="sub-heading">
              <h3>매물정보</h3>
            </div>
            <RegisterGoodsInfo data={data} />
          </div>
          <RegisterUserInfo data={data} />
          {data?.process_cd === 3005 && (
            <div className="button-wrapper">
              <button
                style={{ "--bg-color": "#b9b9b9" }}
                onClick={() => {
                  callDel({
                    main_seq: data?.main_seq,
                    deal_str: data?.deal_str,
                  });
                }}
              >
                신청취소
              </button>
              <button
                style={{ "--bg-color": "#ff9e2f" }}
                onClick={() => {
                  data?.deal_str === "cbuy"
                    ? goToRequest()
                    : goToCSellRequest();
                }}
              >
                신청수정
              </button>
            </div>
          )}
          {data?.process_cd === 3007 && (
            <div className="button-wrapper">
              <button
                style={{ "--bg-color": "#ff9e2f" }}
                onClick={() => {                    
                  isReportData ? goToReport(reportData) : goToReport();
                }}
              >
                { isReportData ? "신고서 수정" : "신고서 신청" }
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
