import React, { useEffect, useState } from "react";
import { DaumPostcode } from "../../../../../utils/DaumPostCode";
import * as R from "../../../../../css/PersonalSellingRequest/requestForm";
import { Form, Table } from "react-bootstrap";
// import getUserInfo from "../../../../../utils/hooks/getUserInfo";
import { postNumbaSelUserInfo } from "../../../../../api/auth";
import { toast } from "wc-toast";

export default function CommonRegistration(props) {
  const { buyReqData, setBuyReqData, setUserTypeCd } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [userInfo, setUserInfo] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBuyReqData({ ...buyReqData, [name]: value });
  };

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setBuyReqData({
        ...buyReqData,
        user_nm: userInfo?.user_nm,
        phone: userInfo?.phone,
        corp_nm: userInfo?.corp_nm,
        zip: userInfo?.zip,
        addr1: userInfo?.addr1,
        addr2: userInfo?.addr2,
      });
    } else {
      setBuyReqData({
        ...buyReqData,
        user_nm: "",
        phone: "",
        corp_nm: "",
        zip: "",
        addr1: "",
        addr2: "",
      });
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setBuyReqData({ ...buyReqData, [name]: file });
  };

  const callUserInfo = async () => {
    try {
      const response = await postNumbaSelUserInfo({});
      if (response.code === 1000) {
        //법인시
        if (userInfo?.user_type_cd === 202) {
          setBuyReqData({ ...buyReqData, corp_nm: "" });
        }
        setUserTypeCd(response?.user_type_cd);
        setUserInfo(response);
      } else {
        console.log("유저정보 가져오기 실패");
      }
    } catch (err) {
      console.log("유저정보 가져오기 실패");
    }
  };

  useEffect(() => {
    callUserInfo();
  }, []);

  return (
    <div className="SecondSection">
      <div className="d-flex justify-content-between">
        <R.FormDetails_Heading>
          {buyReqData?.type === 2103 ? "임대" : "판매"}신청자 정보등록
        </R.FormDetails_Heading>
        <div>
          <Form.Check
            inline
            role="button"
            label="회원정보와 동일"
            name="group1"
            className="CustomCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      <R.FormDetails_Div>
        <div>
          <Table bordered responsive className="table-form">
            <tbody>
              <tr>
                <R.FormTable_Th width="25%">
                  {buyReqData?.type === 2103 ? "임대" : "판매"}자 유형
                </R.FormTable_Th>
                <td colSpan={3}>
                  <div className="d-flex gap-2 gap-md-3 ms-chk">
                    <Form.Check
                      inline
                      label="법인"
                      name="userInfoType"
                      type="radio"
                      className="CustomCheckbox"
                      checked={userInfo?.user_type_cd === 202 && true}
                      readOnly
                    />
                    <Form.Check
                      inline
                      label="개인"
                      name="userInfoType"
                      type="radio"
                      className="CustomCheckbox"
                      readOnly
                      checked={userInfo?.user_type_cd === 201 && true}
                    />
                  </div>
                </td>
              </tr>

              {userInfo?.user_type_cd === 202 && (
                <tr>
                  <R.FormTable_Th width="25%">법인명</R.FormTable_Th>
                  <td colSpan={3}>
                    <R.Table_Input
                      className="form-control Table_Inputx"
                      value={buyReqData?.corp_nm}
                      name="corp_nm"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <R.FormTable_Th width="25%">
                  {/* {buyReqData?.type === 2103 ? "임대인 이름" : "판매자 이름"} */}
                  {userInfo?.user_type_cd === 202
                    ? "담당자"
                    : buyReqData?.type === 2103
                    ? "임대인"
                    : "판매자"}
                  &nbsp;이름
                </R.FormTable_Th>
                <td colSpan={3}>
                  <R.Table_Input
                    className="form-control Table_Inputx"
                    value={buyReqData?.user_nm}
                    name="user_nm"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <R.FormTable_Th width="25%">
                  {/* {buyReqData?.type === 2103 ? "임대인 " : "판매자 "} */}
                  {userInfo?.user_type_cd === 202
                    ? "담당자"
                    : buyReqData?.type === 2103
                    ? "임대인"
                    : "판매자"}
                  &nbsp;휴대폰번호
                </R.FormTable_Th>
                <td colSpan={3}>
                  <R.Table_Input
                    className="form-control Table_Inputx"
                    placeholder={"'-' 없이 입력"}
                    value={buyReqData?.phone?.replace(/[^0-9]/g, "")}
                    name={"phone"}
                    maxLength={11}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />                  
                </td>
              </tr>
              <tr>
                <R.FormTable_Th width="25%">
                  {/* {buyReqData?.type === 2103 ? "임대인 " : "판매자 "} */}
                  {userInfo?.user_type_cd === 202
                    ? "법인회사"
                    : buyReqData?.type === 2103
                    ? "임대인"
                    : "판매자"}
                  &nbsp;주소
                </R.FormTable_Th>
                <td colSpan={3} className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <div className="daum_search_wrapper">
                      <div className="d-flex w-100 mb-2">
                        <R.Table_Input
                          className="input-zip"
                          width="120px"
                          name={"zip"}
                          value={buyReqData?.zip}
                          readOnly={true}
                        ></R.Table_Input>
                        <DaumPostcode
                          className="btn btn-primary btn-zip"
                          onCompleteCallback={(data) => {
                            setBuyReqData({
                              ...buyReqData,
                              zip: data.zonecode,
                              addr1: data.fullAddr,
                            });
                          }}
                        />
                      </div>
                      <R.TableInput_Search
                        width={"100%"}
                        name={"addr1"}
                        value={buyReqData?.addr1}
                        readOnly={true}
                        className="ms-0 mb-2 h-md-46px h-sm-40px"
                      ></R.TableInput_Search>
                    </div>
                    <R.TableInput_Search
                      width={"w-100"}
                      className={"h-md-46px h-sm-40px"}
                      name={"addr2"}
                      value={buyReqData?.addr2}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder={"상세 주소 입력"}
                    ></R.TableInput_Search>
                  </div>
                </td>
              </tr>
              {buyReqData?.type === 2103 && (
                <tr>
                  <R.FormTable_Th>자동차등록증(선택)</R.FormTable_Th>
                  <td>
                    <div className="d-flex align-items-center ms-4">
                      <label className="fileBtn" htmlFor="carFile">
                        파일선택
                      </label>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="carFile"
                        name="car_file"
                        onChange={(e) => handleFileSelect(e)}
                      />
                      <div className="txt_attach ms-2">
                        {buyReqData?.car_file
                          ? buyReqData?.car_file?.name
                          : buyReqData?.car_file_nm}
                      </div>
                    </div>
                    <R.Btn_Value
                      className="d-none d-lg-inline-block ms-4"
                      color="#c1c1c1"
                    >
                      ※ 사진 또는 이미지 파일로 제출해 주세요
                    </R.Btn_Value>
                    <R.Btn_Value
                      className="d-none d-lg-inline-block"
                      color="#f60"
                    >
                      &nbsp;(관리자 확인용입니다)
                    </R.Btn_Value>
                  </td>
                </tr>
              )}
              {buyReqData?.type === 2103 && (
                <tr>
                  <R.FormTable_Th>화물운송종사자격증명서(선택)</R.FormTable_Th>
                  <td>
                    <div className="d-flex align-items-center ms-4">
                      <label className="fileBtn" htmlFor="fw_file">
                        파일선택
                      </label>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="fw_file"
                        name="fw_file"
                        onChange={(e) => handleFileSelect(e)}
                      />
                      <div className="txt_attach ms-2">
                        {buyReqData?.fw_file
                          ? buyReqData?.fw_file?.name
                          : buyReqData?.fw_file_nm}
                      </div>
                    </div>
                    <R.Btn_Value
                      className="d-none d-lg-inline-block ms-4"
                      color="#c1c1c1"
                    >
                      ※ 사진 또는 이미지 파일로 제출해 주세요
                    </R.Btn_Value>
                  </td>
                </tr>
              )}
              <tr>
                <R.FormTable_Th>요청내용</R.FormTable_Th>
                <td>
                  <R.Table_Textarea
                    className="form-control form-textarea"
                    name="req_comment"
                    value={buyReqData?.req_comment}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></R.Table_Textarea>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </R.FormDetails_Div>
    </div>
  );
}
