import config from "../../config";
import axios from "axios";
export default function refresh() {
  const refreshToken = localStorage.getItem("refresh_token");
  const refreshAPI = () => {
    return axios({
      url: config.API_URL + "/auth/refreshAfterTimeOut",
      method: "post",
      data: { refreshToken: refreshToken },
      headers: {
        ...axios.defaults.headers,
        Authorization: "",
      },
    });
  };

  const validateRefreshAPI = () => {
    return axios({
      url: config.API_URL + "/auth/selRefreshToken",
      method: "post",
      data: { refreshToken: refreshToken },
      headers: {
        ...axios.defaults.headers,
        Authorization: "",
      },
    });
  };

  return { refreshAPI, validateRefreshAPI };
}
