import { postNumbaUserMainList } from "../../api/mypage";
import { toast } from "wc-toast";

export default async function callMainData() {
  if (!localStorage.getItem("access_token")) {
    return;
  }

  try {
    const res = await postNumbaUserMainList({
      startIdx: 0,
      rowCnt: 10,
    });

    if (res.code === 1000) {
      const cntInfo = res?.cntList?.reduce((acc, cur) => {
        if (cur?.cntStr === "alramCnt") {
          return { ...acc, alramCnt: cur?.cnt };
        } else if (cur?.cntStr === "interestCnt") {
          return { ...acc, interestCnt: cur?.cnt };
        }
      }, {});
      return {
        cList: res?.cList,
        cListCnt: res?.cListCnt,
        sellBuyList: res?.sellBuyList,
        sellBuyListCnt: res?.sellBuyListCnt,
        ...cntInfo,
      };
    }
  } catch (err) {
    console.log("유저 리스트 err", err);
    callMainData();
  }
}
